import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { LoadExpertJobs, ExpertSaveJob } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import ReactCountryFlag from "react-country-flag";


export default function ExpertJobs(props) {

    const [maxRecord, setmaxRecord ]= React.useState(5);
    const [currentPage, setcurrentPage] = React.useState(0);
    const [currentData, setcurrentData] = React.useState([]);
    const [totalPage, setTotalPage] = React.useState(0);
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(false);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [loaded, setLoaded] = useState(false);

    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(props.rowsJobs, page, gContext.paginationSize);

    function UnsaveJob(id) {
        if (id) {
            gContext.setconfirmModal({ header: "Job withdraw confirmation", ["text"]: "Do you want to unsave this job?", ["confirmed"]: false, index: null, id: id, showButton: true, mode: 'post',page:"jobs" });
            gContext.toggleConfirmModal();
        }
    }

    useEffect(() => {
        if (gContext.confirmModal.confirmed && gContext.confirmModal.page=="jobs") {

            if (gContext.confirmModal.mode == "post") {
                UpdateUnsaveJob(gContext.confirmModal.id);
                gContext.setconfirmModal({
                    ...gContext.confirmModal, ["page"]: "", ["confirmed"]: false, ["id"]: null
                });
            }
        }
        else { setCurrentValue({ index: null, id: null,page:"" }); }
    }, [gContext.confirmModal]);

    async function UpdateUnsaveJob(id) {
        try {
            gContext.setLoaderModalVisible(true);
            const result = await ExpertSaveJob(gContext.userInfo.userID, false, id);
            if (result.data.saveJob.responseData.code == "200") {
                props.updateData(props.mode);
            }
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
        }
    }
    useEffect(() => {
        document.title = (props.mode === "saved") ? "Saved Job | Syncuppro" : "Applied Job | Syncuppro";
    }, [props.mode]);

    useEffect(() => {
         
        //    setcurrentPage(1);          
        //    let q = props.rowsJobs.length / 5;
        //    let r = props.rowsJobs.length % 5;
        //    setTotalPage(q + (r == 0) ? 0 : 1); 
        //const currentItems = props.rowsJobs.slice(currentPage - 1 * maxRecord, (props.rowsJobs.length >= (currentPage * maxRecord)) ? currentPage * maxRecord : props.rowsJobs.length-1);
        //setcurrentData(currentItems);
        

    }, [props.rowsJobs]);
    useEffect(() => { 
        
        //const currentItems = props.rowsJobs.slice(currentPage - 1 * maxRecord, (props.rowsJobs.length >= (currentPage * maxRecord)) ? currentPage * maxRecord : props.rowsJobs.length - 1);
        //setcurrentData(currentItems);


    }, [currentPage]);
    useEffect(() => {
        document.title = (props.mode === "saved") ? "Saved Job | Syncuppro" : "Applied Job | Syncuppro";
    }, []);
    return (
        <>


            {message &&
                <Message message="Job deleted successfully" />
            }

            {(props.rowsJobs && props.rowsJobs.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No job found</span>
            </div>}
            {(props.rowsJobs && props.rowsJobs.length > 0) && <>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th

                                    className="  min-lg-cell"
                                >
                                    Job Title
                        </th> <th className=" min-lg-cell"


                                >
                                    Company
                        </th> <th
                                    className=" min-lg-cell"

                                >
                                    Location
                        </th>
                                <th className=" min-lg-cell"


                                >
                                    Exp.Level
                        </th>
                                <th
                                    className=" min-md-cell"
                                >
                                   Job Type 
                        </th>
                                <th

                                    className=" min-lg-cell"
                                >
                                    Weekly Hours
                        </th>
                               
                                <th

                                    className=" min-md-cell"
                                >
                                    {props.mode === "saved" ? "  Posted on" : "Applied on"}
                                </th> <th


                                >

                                </th>


                            </tr>
                        </thead>
                        <tbody>
                            {slice && slice.map((data, index) => {
                                const { id, title, city, state, countrycode, datecreated, dateposted, weeklyhrs, explevel, guid, clientcompanyname,type, clientlogo, clientsub } = data;
                                 
                                return (
                                    <tr key={index}>
                                       
                                       
                                       <td>
                                            <span className="d-flex justify-content-left align-items-left">
                                                <span className="title d-flex pointer" data-toggle="tooltip" title={title} onClick={(e) => { window.open("/job/" + guid, "_blank") }}><span className="one-line">{title} </span> <a className="m-l-5   text-theme m-icon material-icons pointer" onClick={(e) => { window.open("/job/" + guid, "_blank") }}>open_in_new</a></span>
                                                 
                                                </span>
                                               
                                                     
                                            </td>
                                       
                                       
                                       
                                        <td className="d-none">

                                            <div className="d-flex justify-content-left align-items-left">
                                                <div className="info-meta company pointer" data-toggle="tooltip" title={title} onClick={(e) => { window.open("/job/" + guid, "_blank") }}>

                                                    <span className="table-title one-line text-black ">{title}  </span>   <a  className="m-l-5   text-theme m-icon material-icons">open_in_new</a>
                                                </div>


                                            </div>

                                           
                                        </td>
                                        <td>

                                            <div className="d-flex justify-content-center align-items-center" onClick={(e) => { window.open("/client/" + clientsub, "_blank") }} >
                                                <div className="info-meta company pointer"   >
                                                    <img className="m-r-10 "   src={s3URL + data.clientlogo} alt={clientcompanyname} />
                                                    <span className="one-line text-black ">{clientcompanyname}</span> 
                                                     <a   className="m-l-5 text-theme m-icon material-icons pointer">open_in_new</a> 
                                                </div>

                                              
                                            </div>

                                        </td>
                                        <td>
                                            <div className="info-meta flag">
                                                <ReactCountryFlag
                                                    countryCode={countrycode}
                                                    svg
                                                    style={{
                                                        width: '1.5em',
                                                        height: '.7em',
                                                    }}
                                                    title={countrycode}
                                                />
                                                <span className="m-l-5 one-line text-black "> 
                                               
                                                <span className={`${(city != null && city.length > 2) ? 'text-capi' : 'text-upper'}`}>{city?city.toLowerCase():''}{' '}</span>
                                                <span className={`    ${(state != null && state.length > 2) ? 'text-capi' : 'text-upper'}`}>{state?state.toLowerCase():''}</span> {' ' + countrycode}</span>
                                            </div>
                                        </td>
                                        <td>  {explevel} </td>
                                        <td><span className="text-capi"> { type.toLowerCase()}</span> </td>
                                        <td>  {weeklyhrs} </td>
                                        <td> {props.mode === "saved" ? (dateposted ? getMonthDateYear(dateposted) : "") : (data.applieddate ? getMonthDateYear(data.applieddate) : "")}
                                            </td>


                                        <td>
                                            <div className="btn-table">

                                                {props.mode === "saved" &&
                                                    <button className="btn btn-sm btn-gray"  onClick={() => { UnsaveJob(id) }}>Unsave</button>

                                                }

                                            </div>
                                        </td>
                                    </tr>)
                            })}</tbody>
                    </table>


                  

                
                </div>
                {props.rowsJobs && props.rowsJobs.length > 5 &&
                <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                <div className="bg-white  pt-5   pb-9 px-2 d-none">
                    <div className="table-responsive ">
                        <table className="table table-striped">
                            <thead>
                                <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                    <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Title
                        </th> <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Company
                        </th>
                                    <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Exp_level
                        </th> <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5 d-none"
                                    >
                                        Weekly Hours
                        </th>
                                    <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        Location
                        </th>
                                    <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >
                                        {props.mode === "saved" ? "  Posted on" : "Applied on"}
                                    </th> <th

                                        className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                    >

                                    </th>

                                </tr>
                            </thead>
                            <tbody>
                                {props.rowsJobs && props.rowsJobs.map((data, index) => {
                                    const { id, title, city, state, countrycode, datecreated, dateposted, weeklyhrs, explevel, guid, clientcompanyname, clientlogo, clientsub } = data;

                                    return (
                                        <tr className=" border border-color-2" key={index}>
                                            <td
                                                scope="row"
                                                className="pl-6 border-0 py-5 table-y-top max-width-px-235 media"
                                            >


                                                <a data-toggle="tooltip" title={title} style={{ wordWrap: "break-word" }} className="font-size-4 mb-0 font-weight-semibold text-black-2 pointer ellipsis max-width-px-235" target="_blank" rel="noopener noreferrer" href={"/job/" + guid} >
                                                    {title}
                                                </a>
                                                <i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-3"></i>

                                            </td>
                                            <td className="table-y-top py-5 min-width-px-125">
                                                <a className="media min-width-px-235 align-items-center font-weight-semibold text-black-2 pointer" target="_blank" rel="noopener noreferrer" href={"/Client/" + clientsub}  >


                                                    <img src={s3URL + clientlogo} alt="" className=" circle-30 w-100 mr-3" />  {clientcompanyname} <i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-2"></i>

                                                </a>
                                            </td>
                                            <td className="table-y-top py-5 max-width-px-185">
                                                <h3 data-toggle="tooltip" title={explevel} style={{ wordWrap: "break-word" }} className="font-size-4 font-weight-normal max-width-px-185 ellipsis text-black-2 mb-0">
                                                    {explevel}
                                                </h3>
                                            </td>
                                            <td className="table-y-top py-5 min-width-px-135 d-none">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    {weeklyhrs}
                                                </h3>
                                            </td>
                                            <td className="table-y-top py-5 max-width-px-185">
                                                <h3 data-toggle="tooltip" style={{ wordWrap: "break-word" }} title={city + ' ' + state + ' ' + countrycode} className="font-size-4 font-weight-normal max-width-px-185 ellipsis text-black-2 mb-0">
                                                    <ReactCountryFlag
                                                        countryCode={countrycode}
                                                        svg
                                                        style={{
                                                            width: '2em',
                                                            height: '1em',
                                                        }}
                                                        title={countrycode}
                                                    /> {city + ' '} {state} {' ' + countrycode}
                                                </h3>
                                            </td>

                                            <td className="table-y-top py-5 min-width-px-155">
                                                <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                    {getMonthDateYear(dateposted)}
                                                </h3>
                                            </td>
                                            <td className="table-y-top py-5 min-width-px-155 text-right">
                                                {props.mode === "saved" && <div data-toggle="tooltip"  >

                                                    <a className="border font-size-3 font-weight-bold  px-5 rounded-5 mr-1 text-red  border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Unsave" onClick={() => { UnsaveJob(id) }}>
                                                        Unsave 
                              </a>

                                                </div>}

                                            </td>
                                        </tr>)
                                })}


                            </tbody>
                        </table>
                    </div>
                </div></>}


        </>
    );
};