 import React, { useState, useContext, useEffect } from "react";
import AvatarImageCropper from 'react-avatar-image-cropper';
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import validator, { isIn } from 'validator';
import { GetIndustryTypes, GetCountry } from "../../utils/GetAPIUtils";
import { GetTimeZone, GetClientBusinessProfile, UpdateClientBusinessProfile, FetchCompanyProfile } from "../../utils/apiCalls";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
import { ProgressBar } from 'react-bootstrap';
import ClipLoader from "react-spinners/ClipLoader";
import { FileUpload, getFileName } from "../../utils/fileUpload";
import { isEmailContain, isHtml, isInvalid } from "../../utils/helperFn";
import ReactQuill from 'react-quill';
import DOMPurify from 'isomorphic-dompurify';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import isValid from "date-fns/fp/isValid";
//import AvatarImageCropper from 'react-avatar-image-cropper';

const imgLoader = "../assets/image/svg/spinning-circles.svg";
const imgP = "../assets/image/avatar.png";
const s3URL = process.env.REACT_APP_AWS_S3_URL;

const Profile = (props) => {
    const gContext = useContext(GlobalContext);
    const data = { industryType: '', cdescText: '', certificatename: '', idverified: false, resetcertificatename: '', resetClogoName: '', clogoname: '', cdesc: '', fileupload: null, clogo: null, city: '', state: '', zip: '', tagline: '', timezoneid: 0, countryid: 0, industryid: null, industryId: 0, cwebsite: '', country: null, timezone: null, address1: '', address2: '', cname: '', error: { cdesc: '', fileupload: '', clogo: '', cname: '', city: '', state: '', country: '', timezone: '', industryType: '', address1: '', cwebsite: '', address2: "", tagline:"",zip:"" }, percentage: 50 }
    const [userInfo, setUserInfo] = useState({ email: "", userType: "", profileInfo: null });
    const [onLoadUserInfo, setOnLoadUserInfo] = useState({
        industryType: '', cdescText: '',
        certificatename: '', idverified: false, resetcertificatename: '', resetClogoName: '',
        clogoname: '', cdesc: '', fileupload: null, clogo: null, city: '', state: '', tagline: '',
        timezoneid: 0, countryid: 0, industryid: null, industryId: 0, cwebsite: '', country: null, timezone: null,
        address1: '', address2: '', cname: '', error: {
            cdesc: '', fileupload: '', clogo: '', cname: '', city: '', state: '', zip: '', address2: "",
            country: '', timezone: '', industryType: '', address1: '', cwebsite: '', tagline: ''
        }, percentage: 50
    });


    const [initialValues, setInitialValues] = React.useState(data);
    const [values, setValues] = React.useState(data);
    // const [countriesList, setCountriesList] = useState([{}]);
    //const [industryTypes, setIndustryTypes] = useState([{}]);
    const [industryTypeList, setIndustryTypeList] = useState([]);
    const [selectedIndustryData, setSelectedIndustryData] = useState([]);
    const [initialIndustryTypeList, setInitialIndustryTypeList] = useState([]);
    const [initialSelectedIndustryData, setInitialSelectedIndustryData] = useState([]);
    const [fullIndustryType, setFullIndustryType] = useState([]);
    const [percentage, setPercentage] = useState([]);
    const reactQuillRef = React.useRef();

    const [timeZone, setTimeZone] = useState([{}]);
    const [initialtimeZone, setinitialTimeZone] = useState([{}]);
    const [onLoading, setOnLoading] = useState(false);
    const [fullTimeZone, setFullTimeZone] = useState([{}]);
    const [loader, setLoader] = useState(false);
    const [isexistingCompany, setisexistingCompany] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(false);
    const [clogo, setClogo] = useState(null);
    const [editvalue, setEditvalue] = useState('');
    const [editLength, setEditLength] = useState(0);
    const [loaded, setLoaded] = useState(false);
    const [cancel, setCancel] = useState(false);
     
    const actions = [
        <button type="button" className="btn btn-sm bg-green m-r-5" key={0}><i className="fa fa-times" /></button>,
        <button type="button" className="btn btn-sm bg-green" key={1}><i className="fa fa-check" /></button>,
    ]

    const popoverLogo = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p   >
                    Allowed format jpeg,png,jpg. Max file size 5MB
                 </p>

            </div>



        </Popover>
    );
    const popoverWebsite = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>
                    Provide <br /> company website link or Upload company registration certificate.
                </p>

            </div>


        </Popover>
    );
    const popoverCert = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p>
                    Allowed format pdf,jpg,png,jpeg file. 
                                                                   </p>

            </div>


        </Popover>
    );
    const apply = (file) => {
        // handle the blob file you want
        // such as get the clogo src
        let error = { ...values.error };
        error.clogo = "";
        var src = window.URL.createObjectURL(file);
        setValues({ ...values, error, ["clogo"]: file });
    }
    const getCompanyinfo = (e) => {
        const { name, value } = e.target;
        if (validator.isEmail(value)) {
            let error = { ...values.error };
            error.cwebsite = validator.isEmail(value) ? "Invalid format.":"";           
            setValues({ ...values, error });
            return;
        }
        

        if (validator.isURL(value) && !validator.isEmail(value))
        {

            let url = value.replace("www.", "").replace("http://", "").replace("https://", "");

            let comUrl = (url.slice(-1) == "/") ? url.substring(0, url.length - 1) : url;
            GetCompanyProfile(comUrl);
        }
    }
    useEffect(() => {

        if (gContext.confirmModal.confirmed) {
            let profile = gContext.confirmModal.companyInfo;
            if (profile && profile.website && profile.website != "") {
                let profileData = { ...values };
                const country = gContext.countriesList.filter(item => item["value"] == profile.countryid);

                profileData.cname = profile.company;
                profileData.address1 = profile.address1;
                profileData.city = profile.city;
                profileData.cwebsite = profile.website;
                profileData.state = profile.state;
                profileData.zip = profile.zip;
                profileData.resetClogoName = (profile.logo) ? profile.logo : "";
                profileData.resetcertificatename = (profile.certificate) ? profile.certificate : "";

                profileData.countryid = (profile.countryid) ? profile.countryid : 0;

                profileData.timezoneid = (profile.timezoneid) ? profile.timezoneid : 0;

                profileData.country = (country.length > 0) ? country[0] : null;


                profileData.industryType = (profile.indtypeids) ? profile.indtypeids : "";

                //profileData.percentage = profile.percentage;
                profileData.certificatename = (profile.certificate && profile.certificate != 'null') ? profile.certificate : '';
                profileData.clogoname = (profile.logo && profile.logo != 'null') ? profile.logo : '';
                profileData.tagline = (profile.companytagline && profile.companytagline != 'null') ? profile.companytagline : '';
                profileData.address2 = (profile.address2 && profile.address2 != 'null') ? profile.address2 : '';
                profileData.cdesc = profile.companydesc;
                profileData.timezone = { value: profile.timezoneid, label: '(' + profile.tzvalue + ') ' + profile.tzname };
                profileData.error = { cdesc: '', fileupload: '', clogo: '', cname: '', city: '', state: '', country: '', timezone: '', industryType: '', address1: '', cwebsite: '', address2: '', tagline:'',zip:"" };
                setInitialValues(profileData);
                setValues(profileData);
                bindTimezone((country.length > 0) ? country[0] : null);
                bindFullTimezone((country.length > 0) ? country[0] : null);
                setisexistingCompany(true);
            }
        }

    }, [gContext.confirmModal]);
    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };


    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];

    const editorKeyDown = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
       

        if (unprivilegedEditor.getText().trim().length > 4000 ) {
            event.preventDefault();
        }
    };

    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }

    const editorChange = (content, delta, source, editor) => {
        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 4000) {
            setEditvalue(editor.getHTML());
            setEditLength(editor.getText().trim().length);
            if (isHtml(editor.getText()) || isEmailContain(editor.getText())) {
                error.desc = "Invalid format";
                setValues({ ...values, error, ['cdescText']: editor.getText().trim(), ['cdesc']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
              
            }
            else {
                error.desc = "";
                setValues({ ...values, error, ['cdescText']: editor.getText().trim(), ['cdesc']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
        }
        else {
            setEditvalue(editvalue + " ");
            setEditLength(editLength);
        }

    };

    //function getCompanyinfo(e) {
    //    const { name, value } = e.target;

    //    if (validator.isURL(value)) {
    //        alert(value);
    //        let url = value.replace("www.", "").replace("http://", "").replace("https://", "");

    //        GetCompanyProfile(url);
    //    }
    //}

    /*  Check validity of the fields on submit */
    function checkValidity() {
        let inValid = false;
        let { error, ...rest } = values;

        error.country = (rest.country) ? "" : "Country is required";
        // ((isHtml(value) || validator.isURL(value)) ? "Invalid format" : "" )
        error.timezone = (rest.timezone) ? "" : "TimeZone is required";
        //error.cname = (rest.cname != '') ? "Company name is required" : "";
        error.cname = (rest.cname && rest.cname.trim() != "") ? ((isHtml(rest.cname) || validator.isURL(rest.cname) || isInvalid(rest.cname)) ? "Invalid format" : "") : "Company name is required";
        //error.cdesc = validator.isEmpty(rest.cdesc) ? "Company description is required" :"";
        //error.cdesc = validator.isEmpty(rest.cdesc) ? "Company description is required" : (values.length > 4000) ? "Company description cannot exceed 4000 characters" : "";
        error.cdesc = ((reactQuillRef.current.unprivilegedEditor.getText()).length == 1) ? "Company description is required" : ((isHtml(reactQuillRef.current.unprivilegedEditor.getText()) || isInvalid(reactQuillRef.current.unprivilegedEditor.getText()) || isInvalid(reactQuillRef.current.unprivilegedEditor.getText())) ? "Invalid format" : "");


        //error.clogo = (rest.clogo) ? "" : "Company logo is required";
        error.industryType = (rest.industryType) ? "" : "Industry Type  is required";
        // error.address1 = validator.isEmpty(rest.address1) ? "Address is required" : "";
        error.address1 = (!rest.address1) ? "Address is required" : ((isInvalid(rest.address1)) ? "Invalid format" : "");
        error.address2 = (isInvalid(rest.address2) ) ? "Invalid format" : "";
        error.tagline = (isInvalid(rest.tagline)) ? "Invalid format" : "";
        //error.city = validator.isEmpty(rest.city) ? "City is required" : "";
        error.city = (!rest.city) ? "City is required" : ((isInvalid(rest.city)) ? "Invalid format" : "");
        // error.state = validator.isEmpty(rest.state) ? "State is required" : "";
        error.state = (!rest.state) ? "State is required" : ((isInvalid(rest.state)) ? "Invalid format" : "");
        error.zip = rest.zip?((isHtml(rest.zip) || validator.isURL(rest.zip ? rest.zip:"") || rest.zip < 0 || rest.zip.length > 10) ? "Invalid format" : ""):"";
        if (rest.clogoname == '')
            error.clogo = (rest.clogo) ? "" : "Company logo is required";



        /*  if ((rest.certificatename == '') || (rest.cwebsite == ''))
           {
              error.fileupload = validator.isEmpty(rest.certificatename) ? "Company Registration certificate is required":"";
             // error.cwebsite = validator.isEmpty(rest.cwebsite) ? "Company website  is required" : "";
              //console.log(validator.isEmpty(rest.cwebsite));
              console.log(validator.isEmpty(rest.cwebsite));
              //setValues({ ...values, error });
          }*/


           //if ((rest.certificatename == ''))
           //{ error.fileupload = validator.isEmpty(rest.certificatename) ? "Company Registration certificate is required":"";
   
           //   if(rest.cwebsite == '')
           //   {
           //    error.cwebsite = validator.isEmpty(rest.cwebsite) ? "Company website  is required" : "";
           //   }
   
           //}

       // 
        if ((rest.certificatename !="" || !rest.fileupload) && !rest.cwebsite) {
            
            error.cwebsite = "Company website  is required";
            error.fileupload = "Company Registration certificate is required";
        }
        if (rest.cwebsite && rest.cwebsite != "")
            error.cwebsite = (validator.isURL(rest.cwebsite) || !validator.isEmail(rest.cwebsite)) ? "" : "Enter a valid website"  ; 


        /* if(rest.certificatename == 'null'  && rest.cwebsite == '')
         {
            console.log(rest.cwebsite);
             error.cwebsite = "Company website  is required";
             error.fileupload = "Company Registration certificate is required";
         }*/

        /*if ((rest.fileupload != null) && (rest.cwebsite == null)) {
            error.fileupload = (rest.fileupload) ? "" : "";
            error.cwebsite = validator.isEmpty(rest.cwebsite) ? "" : "";
            //setValues({ ...values, error });
        }

        if ((rest.certificatename == null) || (rest.cwebsite != null)) {
            error.fileupload = (rest.fileupload) ? "" : "";
            error.cwebsite = "";
            //setValues({ ...values, error });
        }*/

        setValues({ ...values, error });
        
        //if (values.address2 == '') {
        //    setValues({ ...values, error, ["address2"]: values.address2 });
        //}
        //if (values.tagline == '') {
        //    setValues({ ...values, error, ["tagline"]: values.tagline });
        //}
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {

                inValid = true;
            }
        });
        if (inValid)
            return false;
        else
            return true;

    }




    function removeSelectedIndustryType(id) {

        const selected = industryTypeList.filter(item => item["id"] == id);

        if (selected.length > 0) {

            setChanges('industryType', [...selectedIndustryData, selected[0]]);
            setSelectedIndustryData([...selectedIndustryData, selected[0]]);
        }
        const removed = industryTypeList.filter(item => item["id"] != id);
        setIndustryTypeList(removed)
        setInitialIndustryTypeList(removed);
        //setSkillsList(removed);
    }

    function setChanges(fields, lists) {

        let value = '';
        lists.map((data) => (value += data.id + ','));
        let error = { ...values.error };
        if (value !== "")
            error[fields] = '';
        setValues({ ...values, error, [fields]: (value !== '') ? value.substring(0, value.length - 1) : '' });

    }


    function addToIndustryType(id) {
        if (industryTypeList) {
            const deleted = selectedIndustryData.filter(item => item["id"] == id.toString());
            if (deleted.length > 0) {
                setIndustryTypeList([...industryTypeList, deleted[0]]);
                setInitialIndustryTypeList([...industryTypeList, deleted[0]]);
            }
            const added = selectedIndustryData.filter(item => item["id"] != id);
            setSelectedIndustryData(added);
            setChanges('industryType', added);

        }
    }




    /*  Update Profile */
    async function updateProfile() {
        gContext.setVisibleProfileMessage(false);
        setMessage(false);


        if (checkValidity()) {

            gContext.setLoaderModalVisible(true);
            var data = { ...values };
            if (values.clogo) {
                data.clogoname = getFileName(values.clogo, "CompanyLogo");
                FileUpload(values.clogo, data.clogoname.split('/').pop());
            }

            if (values.fileupload) {

                data.certificatename = getFileName(values.fileupload, "certificate");
                FileUpload(values.fileupload, data.certificatename.split('/').pop());
            }
            try {
                let timezoneList = [];
                const result = await UpdateClientBusinessProfile(data);
                if (result.data.clientBusUpdate.responseData.code == "200") {
                    //gContext.setCurrentPage("5"); 
                    setMessage(true);
                    gContext.setVisibleProfileMessage(true);
                }
                else {
                    setErrorMessage(JSON.parse(result.data.clientBusUpdate.responseData.message));
                }
            }
            catch (e) {
                setErrorMessage("Server error");
            }
        }

        else {
            setErrorMessage("Required fields are missing or Invalid input.");


        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'

        });

        gContext.setLoaderModalVisible(false);
    }

    /* Select change event */
    const handleSelectChange = (e, name) => {
        setErrorMessage('');
        const value = e;
        if (e) {
            let error = { ...values.error };
            switch (name) {

                case "industryType":
                    error.industryType = '';
                    setValues({ ...values, error, [name]: value, ['industryid']: value.value });
                    break;
                case "country":
                    setOnLoading(true);

                    if (value != "") {

                        bindTimezone(e);
                    }
                    error.country = "";

                    setValues({ ...values, error, [name]: value, countryid: value.value, ["timezone"]: null, ["timezoneid"]: 0 });

                    break;
                case "timezone":
                    if (value) {
                        error.timezone = "";
                        setValues({ ...values, error, [name]: value, [name + 'id']: value.value });
                    }
                    break;
                default:
                    break;
            }
        }
      
    }




    async function bindTimezone(value) {
        try {
            if (value) {
                setLoader(true);
                let timezoneList = [];
                const result = await GetTimeZone(value.code);

                if (result.data && result.data.timezone.responseData) {
                    if (result.data.timezone.responseData.code == "200") {

                        JSON.parse(result.data.timezone.responseData.message).map((value) => (
                            timezoneList.push({ value: value.id, label: '(' + value.tzvalue + ') ' + value.tzname })));
                    }
                }
                setTimeZone(timezoneList);

            }
            setLoader(false);
        } catch (e) {
            console.log(e);
            setLoader(false);
        }
    }


    async function bindFullTimezone(value) {
        if (value) {

            let timezoneList = [];
            const result = await GetTimeZone(value.code);

            if (result.data && result.data.timezone.responseData) {
                if (result.data.timezone.responseData.code == "200") {
                    JSON.parse(result.data.timezone.responseData.message).map((value) => (
                        timezoneList.push({ value: value.id, label: '(' + value.tzvalue + ') ' + value.tzname })));
                }
            }
            setFullTimeZone(timezoneList);

        }
    }


    /* Binding fields using API */
    async function GetCompanyProfile(url) {
         
        try {
            setMessage(false);
            gContext.setLoaderModalVisible(true);
            const response = await FetchCompanyProfile(url);

            if (response.data && response.data.fetchCompanyProfile.responseData.code == "200" && response.data.fetchCompanyProfile.responseData.message != "{}") {


                let existingCompany = JSON.parse(response.data.fetchCompanyProfile.responseData.message);

                gContext.setconfirmModal({ header: "Information", ["text"]: ConfirmMessages.existingCompany, ["confirmed"]: false, index: null, id: url, showButton: true, mode: "post", companyInfo: existingCompany });
                gContext.toggleConfirmModal();

            }
            //else
            //{
            //    gContext.setconfirmModal({ header: "Information", ["text"]: ConfirmMessages.Nocompanyfound, ["confirmed"]: false, index: null, id: url, showButton: true, mode: "post",companyMode:true });
            //    gContext.toggleConfirmModal();
            //}
            gContext.setLoaderModalVisible(false);
        } catch (e) {
            gContext.setLoaderModalVisible(false);

        }

    }


    /* Binding fields using API*/
    async function getUtils() {


        const response = await GetIndustryTypes();

        if (response.data && response.data.industryTypes) {

            setIndustryTypeList(response.data.industryTypes);
            setInitialIndustryTypeList(response.data.industryTypes);
            setFullIndustryType(response.data.industryTypes)
        }


    }

    /*Cancel profile edit*/
    function cancelEdit() {
        setMessage(false); //profileComplete
        
        if (gContext.userInfo.profileInfo == null) {
           
            setInitialValues(data);
            setValues(data);
            setSelectedIndustryData([]);
            setIndustryTypeList(fullIndustryType);
            setisexistingCompany(false);
            setTimeZone([{}]);
            setEditvalue(""); //initialValues.tagline
            setEditLength(0);
            setisexistingCompany(false);
            //   BindProfileInfo();

        }
        if (gContext.userInfo.profileInfo != null) {

            if (gContext.userInfo.userID != null && gContext.userInfo.profileComplete == false) {
                setCancel(true);
                if (initialValues.company) {                   
                    setInitialValues(onLoadUserInfo);
                    setValues(initialValues);
                    setSelectedIndustryData(initialValues.industryType);
                    setEditvalue(initialValues.cdesc);  
                    setEditLength(initialValues.cdesc.replace(/(<([^>]+)>)/ig, '').length);
                    setTimeZone(fullTimeZone);
                    values.timezone = initialValues.timezone;

                    BindProfileInfo();
                }
                else {                   
                    setValues(data);
                    setInitialValues(data);                   
                    setSelectedIndustryData([]);
                    setIndustryTypeList(fullIndustryType);
                    setisexistingCompany(false);
                    setTimeZone([{}]);
                    //setEditvalue(""); 
                    //setEditLength(0);  
                     
                }
            }

            if (gContext.userInfo.userID != null && gContext.userInfo.profileComplete == true) {
                try {
                    setLoaded(false);
                    setCancel(true);
                    
                    //setValues(initialValues);

                    let inititalvalue = [];

                    initialValues.industryType.toString().split(',').forEach((value) => {
                        let sdata = fullIndustryType.filter(item => item["id"] == value);
                        inititalvalue.push(sdata[0]);
                    });



                    /** completele diff-false
                     * same value -true
                     */
                    if (JSON.stringify(inititalvalue) === JSON.stringify(selectedIndustryData)) {


                        let selected = [];
                        let removelist = [];
                        initialValues.industryType.toString().split(',').forEach((value) => {

                            let data = selectedIndustryData.filter(item => item["id"] == value);
                            let rdata = fullIndustryType.filter(item => item["id"] != value);
                            selected.push(data[0]);
                            removelist = rdata;


                        });

                        setSelectedIndustryData(selected);
                        setIndustryTypeList(removelist);

                    }
                    else if (JSON.stringify(inititalvalue) !== JSON.stringify(selectedIndustryData)) {


                        let selected = [];
                        let removelist = [];


                        initialValues.industryType.toString().split(',').forEach((value) => {

                            let data = fullIndustryType.filter(item => item["id"] == value);
                            let rdata = fullIndustryType.filter(item => item["id"] != value);
                            selected.push(data[0]);
                            removelist = rdata;


                        });




                        setSelectedIndustryData(selected);

                        setIndustryTypeList(removelist);






                    }

                    setValues(initialValues);

                    if (initialValues.cdesc != values.cdesc) {

                        values.cdesc = initialValues.cdesc;

                        setEditvalue(values.cdesc);
                        setEditLength(values.cdesc.replace(/(<([^>]+)>)/ig, '').length);
                        // setValues({...values,["cdesc"]:initialValues,cdesc});
                    }

                    if (initialValues.timezone != values.timezone) {

                        BindProfileInfo();
                        values.timezone = fullTimeZone.filter(item => item["value"] == initialValues.timezoneid);
                        //console.log('values.timezone', values.timezone); 

                    }


                    setLoaded(true);

                }
                catch (e) {
                    setLoaded(true);
                }
            }



        }

    }



    function prevPage() {
        gContext.setCurrentPage('5');
        // gContext.setLoaderModalVisible(true);
    }

    const handleChange = (e) => {
        setErrorMessage('');
        const { name, value } = e.target;
       
        let error = { ...values.error };
        setErrorMessage("");

        switch (name) {
            case "cname":
                error.cname = validator.isEmpty(value.trim()) ? ((isHtml(value) || validator.isURL(value)) ? "Invalid format":"") : "";
                break;
            case "cdesc":
                error.cdesc = validator.isEmpty(reactQuillRef.current.unprivilegedEditor.getText()) ? "*" : "";
                break;
            case "fileupload":
                error.fileupload = (value) ? "" : "*";
                break;
            case "address2":
                error.address2 = (isHtml(value) || validator.isURL(value)) ? "Invalid format" : "";
                break;
            case "tagline":
                error.tagline = (isInvalid(value)) ? "Invalid format" : "";
                break;
            case "cwebsite":
                error.cwebsite = validator.isEmpty(value) ? "" : (validator.isURL(value)) ? "" : "Enter a valid website";
                break;
            case "zip":
                error.zip = (isHtml(value) || validator.isURL(value) || value < 0 || value.length>10) ? "Invalid format" : "";
                break;
            default:
                error[name] = validator.isEmpty(value) ? "*" : "";
                break;
        }
         
        if (name == "fileupload" || name == "cwebsite") {

            if (value != '' || value != null)

                error.fileupload = '';

            error.cwebsite = (name == "cwebsite") ? (validator.isURL(value) || !validator.isEmail(value)) ? "" : "Enter a valid website" : "";

            setValues({ ...values, error, [name]: value });
        }
        if (name != "fileupload" && name != "clogo") {
           
            if (name != "zip")
                setValues({ ...values, error, [name]: value  });
            else
                setValues({ ...values, error, [name]: value =="" ?"": (value.match(/[0-9]$/) ? value.trim() :  values[name]) });
            

        }
        else {
            if (e.target.files.length > 0) {
                //setImage(e.target.files[0]);
                setValues({ ...values, error, [name]: e.target.files[0] });
            }

        }
    }
    /* To bind on load */
    useEffect(() => {

        document.title = "Business | Syncuppro";
        setisexistingCompany(false);
        getUtils(); 
        if(values.cdesc != null)
        {
        setEditvalue(values.cdesc);
        setEditLength(values.cdesc.replace(/(<([^>]+)>)/ig, '').length);
        }

    }, [])



    useEffect(() => {

        if (gContext.userInfo.profileInfo == null) { setLoaded(true); }

        else if (gContext.userInfo.profileInfo != null) {
            BindProfileInfo();
        }
    }, [gContext.countriesList]);


    useEffect(() => {


        if (cancel == false) {
            if (gContext.userInfo.profileInfo != null && (values.timezoneid != '' && values.timezone == null)) {
                const country = gContext.countriesList.filter(item => item["value"] == values.countryid);
                const timezone = timeZone.filter(item => item["value"] == values.timezoneid);
                setValues({ ...values, ['country']: (country.length > 0) ? country[0] : null, ["timezone"]: (timezone.length > 0) ? timezone[0] : null });

            }
            if (values.industryType && values.industryType != "" && onLoading == false) {
                let selected = [];
                let removedlist = [];
                values.industryType.toString().split(',').forEach((value) => {
                    let removed = [];
                    let data = industryTypeList.filter(item => item["id"] == value);

                    if (data.length > 0) { selected.push(data[0]) }
                    removed = (removedlist.length > 0) ? removedlist.filter(item => item["id"] != value) : industryTypeList.filter(item => item["id"] != value);
                    removedlist = removed;
                });
                setSelectedIndustryData(selected)
                setIndustryTypeList(removedlist);
                setEditvalue(values.cdesc)

                setEditLength(values.cdesc.replace(/(<([^>]+)>)/ig, '').length);
            }
            // BindProfileInfo();
        }
        else { setEditvalue(""); 
            setEditLength(0);  

        }
    }, [timeZone]);




    async function BindProfileInfo() {

        try {
            
            setLoaded(false);
            
            const result = await GetClientBusinessProfile();
            if (result.data.clientBusLoad.responseData.code == "200") {

                let profile = JSON.parse(result.data.clientBusLoad.responseData.message);


                if (profile && profile.length > 0) {
                    let profileData = { ...values };
                    const country = gContext.countriesList.filter(item => item["value"] == profile[0].countryid);
                     
                    profileData.cname = profile[0].company;
                    profileData.address1 = profile[0].address1;
                    profileData.city = profile[0].city;
                    profileData.cwebsite = profile[0].website;
                    profileData.state = profile[0].state;
                    profileData.zip = profile[0].zip;
                    profileData.resetClogoName = (profile[0].clogo) ? profile[0].clogo : "";;
                    profileData.resetcertificatename = (profile[0].fileupload) ? profile[0].fileupload : "";

                    profileData.countryid = (profile[0].countryid) ? profile[0].countryid : 0;

                    profileData.timezoneid = (profile[0].timezoneid) ? profile[0].timezoneid : 0;

                    profileData.country = (country.length > 0) ? country[0] : null;


                    profileData.industryType = (profile[0].indtypeids) ? profile[0].indtypeids : "";

                    profileData.percentage = profile[0].percentage;
                    profileData.certificatename = (profile[0].certificate && profile[0].certificate != 'null') ? profile[0].certificate : '';
                    profileData.clogoname = (profile[0].logo && profile[0].logo != 'null') ? profile[0].logo : '';
                    profileData.tagline = (profile[0].tagline && profile[0].tagline != 'null') ? profile[0].tagline : '';
                    profileData.address2 = (profile[0].address2 && profile[0].address2 != 'null') ? profile[0].address2 : '';
                    profileData.cdesc = profile[0].desc;
                    
                    profileData.idverified = gContext.userInfo.idVerified;
                        
                    setInitialValues(profileData);
                    setValues(profileData);
                    bindTimezone((country.length > 0) ? country[0] : null);
                    bindFullTimezone((country.length > 0) ? country[0] : null);

                }
            }
            setTimeout(() => {
                gContext.setLoaderModalVisible(false);
                setLoaded(true);
            }, 2000);

        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoaded(true);
        }

    }

    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
            setErrorMessage("");
        }
    }, [errorMessage]);

    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
            setMessage(false);
        }
    }, [message, gContext.loaderModalVisible]);

    const errorHandler = (type) => {
        let error = { ...values.error };
        
        setValues({ ...values, error });
    }


    return (
        <>
            <div className="row d-none">
                {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                {errorMessage !== "" && <><span className="error text-red">{errorMessage} </span><br /></>}
            </div>
            {loaded && <div className="card panel  p-0">

                <div className="card-header">

                    <div className="panel-title">
                        <h6>BUSINESS INFORMATION</h6>
                    </div>

                    <div className="panel-action mobile-none">
                        <button className="btn btn-orange  btn-sm bg-green m-r-20 text-white" onClick={() => {
                            updateProfile();

                        }}>Save</button>
                        <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                            cancelEdit();

                        }}>Cancel</button>
                    </div>

                </div>

                <div className="card-body p-b-0">
                    <div className="mandatory-field">
                        <span className="text-red">All * fields are mandatory</span>
                    </div>


                    <div className="row">

                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10 d-flex">Company Website<span className="text-red">  </span> <OverlayTrigger trigger="hover" placement="auto" overlay={popoverWebsite} >
                                    <span variant="primary" className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                </OverlayTrigger> </label>
                                
                                <input
                                    type="text" name="cwebsite" defaultValue={values.cwebsite} value={values.cwebsite != 'null' ? values.cwebsite : ''}
                                    className={`form-control   ${(values.error.cwebsite.length > 0) ? 'error' : ''}`}
                                    onBlur={getCompanyinfo}
                                    onChange={handleChange} onKeyUp={handleChange}
                                    id="formGroupExampleInput" disabled={values.idverified === true || isexistingCompany ? true : false}
                                    placeholder={values.idverified === true || isexistingCompany ? "" : "https://www.example.com"}  />
                                {values.error.cwebsite.length > 1 && (
                                    <span className="text-red  ">{values.error.cwebsite} </span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-12">
                            <div className="text-or">OR</div>
                        </div>

                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10  d-flex">Company Registration Certificate<span className="text-red d-none"> * </span>  <OverlayTrigger trigger="hover" placement="auto" overlay={popoverCert} >
                                    <span variant="primary" className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                </OverlayTrigger></label>
                               


                                {(values.certificatename === "") &&
                                    <div>{(!values.fileupload) && <>
                                        <label className="upload-file p-relative pointer" htmlFor="fileupload">
                                            <span className="text-light-gray m-icon material-icons m-r-10 pointer" htmlFor="fileupload">upload_file</span>Click here to Upload

                      </label></>}

                                        {(values.fileupload) &&
                                            <> {(values.fileupload["name"].split('.').pop().toLowerCase() !== 'pdf') ? <>
                                                <div className="upload-doc-img m-b-10">
                                                    <div className="workers-img">
                                                        <img src={URL.createObjectURL(values.fileupload)} htmlFor="fileupload" />
                                                 


                                                    {!isexistingCompany && <div className="btn-icon-close" data-toggle="tooltip" title="Remove Company Certificate" onClick={() => setValues({ ...values, ['fileupload']: null, ['certificatename']: values.resetcertificatename })}>
                                                        <span className="m-icon material-icons">close</span>
                                                    </div>}
                                            </div>
                                                </div>
                                            </> : <>
                                                    <div className="upload-doc-img m-b-10">
                                                        <div className="workers-img">
                                                            <img src="../images/pdf.png" htmlFor="fileupload" />
                                                       


                                                        {!isexistingCompany && <div className="btn-icon-close" data-toggle="tooltip" title="Remove Company Certificate" onClick={() => setValues({ ...values, ['fileupload']: null, ['certificatename']: values.resetcertificatename })}>
                                                            <span className="m-icon material-icons">close</span>
                                                    </div>}  </div>

                                                    </div> </>} </>}</div>}

                                {(values.certificatename && values.certificatename !== "") && <>
                                    {(values.certificatename.split('.').pop().toLowerCase() !== 'pdf') ? <>

                                        <div className="upload-doc-img m-b-10">
                                            <div className="workers-img">
                                                <img src={s3URL + values.certificatename} htmlFor="fileupload" />
                                           


                                            {values.idverified == false && !isexistingCompany && <div className="btn-icon-close" data-toggle="tooltip" title="Remove Company Certificate" onClick={() => setValues({ ...values, ['fileupload']: null, ['certificatename']: "" })}>
                                                <span className="m-icon material-icons">close</span>
                                            </div>}
                                            </div>
                                        </div>


                                    </> : <>

                                            <div className="upload-doc-img m-b-10">
                                                <div className="workers-img pointer " data-toggle="tooltip" title="Click here to view." onClick={(e) => { window.open(s3URL + values.certificatename, "_blank") }}>
                                                    <img src="../images/pdf.png" htmlFor="fileupload" />
                                              


                                                {values.idverified == false && !isexistingCompany && <div className="btn-icon-close" data-toggle="tooltip" title="Remove Company Certificate" onClick={() => setValues({ ...values, ['fileupload']: null, ['certificatename']: "" })}>
                                                    <span className="m-icon material-icons">close</span>
                                                </div>}
                                                </div>
                                            </div>

                                        </>} </>}



                                <div classname=" ">{(values.fileupload) ? values.fileupload["name"] : ''}</div>

                                <input
                                    type="file"
                                    id="fileupload" name="fileupload" value={values.fileupload ? values.fileupload.filename:""}
                                    className="sr-only mb-0 p-0" disabled={values.idverified === true || isexistingCompany ? true : false}
                                    onChange={handleChange} onSelect={handleChange} accept="application/pdf,image/png,image/jpeg,image/jpg"
                                />
                                {values.error.fileupload.length > 1 && (
                                    <span className="text-red font-size-3">{values.error.fileupload} </span>
                                )}


                            </div>
                        </div>

                    </div>


                    <div className="row">

                        <div className="col-lg-5 col-md-5 col-sm-12">  
                                                                        <div className="forn-group-input">
                                <label className="form-label m-b-10  d-flex">Company logo upload<span className="text-red"> * </span>  <OverlayTrigger trigger="hover" placement="auto" overlay={popoverLogo} >
                                    <span variant="primary" className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                </OverlayTrigger> </label>
                                 



                                {values.error.clogo.length > 1 && (
                                    <> <span className="text-red">{values.error.clogo}</span></>
                                )}





                                {(values.clogoname === "") && <>


                                    {(!values.clogo) && <div style={{ height: '210px', width: '170px', backgroundImage: (values.clogo) ? "url('" + URL.createObjectURL(values.clogo) + "')" : "" }} >
                                        <div style={{ height: '180px', width: '180px' }} className="  w-full">
                                            <AvatarImageCropper maxsize={5000000} apply={apply} icon={<span className="m-icon material-icons"
                                                htmlFor="idUpload">  </span>} actions={actions} errorHandler={errorHandler}
                                                text={<span className="form-control upload"  >Click here to upload</span>} />
                                        </div></div>}
                                    {(values.clogo) &&


                                        <div className="upload-doc-img">
                                            <img src={(values.clogo) ? URL.createObjectURL(values.clogo) : imgP} alt=" " />

                                            {!isexistingCompany && <div className="btn-edit pointer" data-toggle="tooltip" title="Remove logo"
                                                onClick={() => setValues({ ...values, ['clogo']: null, ['clogoname']: values.resetClogoName })}>
                                                <span className="m-icon material-icons">delete</span>

                                            </div>}

                                        </div>
                                    }


                                </>}
                                {(values.clogoname != "") &&
                                    <div className="upload-doc-img">
                                        <img src={s3URL + values.clogoname} alt=" " />

                                        {!isexistingCompany && <div className="btn-edit pointer" data-toggle="tooltip" title="Remove logo" onClick={() => setValues({ ...values, ['clogo']: null, ['clogoname']: "" })}>
                                            <span className="m-icon material-icons">delete</span>

                                        </div>}

                                    </div>
                                }


                                <input
                                    type="file"
                                    id="clogo"
                                    name="clogo"
                                    className="sr-only" accept="image/png,image/jpeg,image/jpg"
                                    onChange={handleChange} onSelect={handleChange}
                                />
                            </div>


                        </div>

                        <div className="col-lg-2 col-md-2 col-sm-12"></div>

                        <div className="col-lg-5 col-md-5 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Company Name<span className="text-red"> * </span></label>
                                <input
                                    type="text" name="cname" defaultValue={values.cname} value={values.cname}
                                   
                                    className={`form-control    ${(values.error.cname.length > 0) ? 'error' : ''}`}
                                    id="namedash"
                                    placeholder="eg. Apple" onKeyUp={handleChange} onChange={handleChange} disabled={values.idverified === true || isexistingCompany ? true : false}

                                />
                                {values.error.cname.length > 1 && (
                                    <span className="text-red  ">{values.error.cname} </span>
                                )}
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Company Tag Line</label>

                                <input type="text" name="tagline" defaultValue={values.tagline} value={values.tagline}
                                    className="w-full form-control"
                                    id="tagline"
                                    placeholder="Company Tag Line" onChange={handleChange} onKeyUp={handleChange} />
                                {values.error.tagline.length > 1 && (
                                    <span className="text-red  ">{values.error.tagline} </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="company-meta">
                        <h6>Industry types<span className="text-red"> * </span> </h6>



                        {values.error.industryType.length > 1 && (
                            <span className="text-red">{values.error.industryType}</span>
                        )}
                        {selectedIndustryData.length > 0 && <>


                            <div className="badge-btn-group">
                                {selectedIndustryData.map((data, index) => {
                                    const { id, name } = data;
                                    return ((selectedIndustryData.length > 0) ? <span key={index} className=" badge  industry-types btn btn-sm  m-t-5 pointer" style={{ cursor: "pointer" }} onClick={() => { addToIndustryType(id) }} >{name}
                                        <button className="btn-icon text-light-gray m-l-5 m-icon material-icons">close</button>
                                    </span> : '')
                                })}


                            </div> <div className="divider-line-20"></div></>}
                        <div className="badge-group ">
                            {industryTypeList.map((data, index) => {
                                const { id, name } = data;
                                return (<span key={index} className=" badge industry-types m-t-5  pointer " style={{ cursor: "pointer" }} onClick={() => { removeSelectedIndustryType(id) }}>{name}

                                </span>)
                            })}


                        </div>

                    </div>





                    <div className="company-desc ">
                        <div className="desc-info">
                            <label className="form-label m-b-0"><b>Company Description </b><span className="text-red"> * (Max 4000 character )</span></label>
                            <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green">  {4000 - ((editLength) ? editLength : 0)}</span></p>
                        </div>


                        <ReactQuill modules={modules}
                            maxLength="4000" formats={formats} theme="snow" id="editor" style={{ height: "20vh" }} className="w-full m-b-40"

                            ref={reactQuillRef}
                            value={editvalue} defaultValue={ editvalue} onChange={editorChange}
                        ></ReactQuill>
                        {values.error.cdesc.length > 1 && (
                            <span className="text-red  m-t-5">{values.error.cdesc} </span>
                        )} 
                    </div>

                    <div className="row">
                        
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Address 1<span className="text-red"> * </span></label>
                                <input
                                    name="address1"
                                    type="text" defaultValue={values.address1} value={values.address1}
                                    className={`form-control   ${(values.error.address1.length > 0) ? 'error' : ''}`}
                                    id="address1"
                                    placeholder="Address" onChange={handleChange} onKeyUp={handleChange}
                                />{values.error.address1.length > 1 && (
                                    <span className="text-red">{values.error.address1}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Address 2<span className="text-red"> </span></label>
                                <input
                                    name="address2"
                                    type="text" defaultValue={values.address2} value={(values.address2) == null ? '' : values.address2}
                                    className="form-control "
                                    id="address2"
                                    placeholder="Address" onChange={handleChange} onKeyUp={handleChange}
                                />
                                {values.error.address2.length > 1 && (
                                    <span className="text-red  ">{values.error.address2} </span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-6">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">City<span className="text-red"> * </span></label>
                                <input
                                    name="city" defaultValue={values.city?values.city.toLowerCase():''} value={values.city?values.city.toLowerCase():''}
                                    type="text"
                                    className={`form-control  ${(values.city != null && values.city.length > 2) ? 'text-capi' : 'text-upper'} ${(values.error.city.length > 0) ? ' error' : ''}`}
                                    id="city"
                                    placeholder="City" onChange={handleChange} onKeyUp={handleChange}
                                />{values.error.city.length > 1 && (
                                    <span className="text-red">{values.error.city}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12 col-6">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">State <span className="text-red"> * </span></label>
                               
                                <input
                                    name="state"
                                    type="text" defaultValue={values.state?values.state.toLowerCase():''} value={values.state?values.state.toLowerCase():''}
                                    className={`form-control   ${(values.error.state.length > 0) ? 'error' : ''}  ${(values.state != null && values.state.length > 2) ? 'text-capi' : 'text-upper'}`}
                                    id="state"
                                    placeholder="State" onChange={handleChange} onKeyUp={handleChange}
                                />
                                {values.error.state.length > 1 && (
                                    <span className="text-red">{values.error.state}</span>
                                )}
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-6">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Zip</label>
                                <input
                                    name="zip"
                                    type="number" defaultValue={values.zip ? values.zip : ""} value={values.zip ? values.zip : ""}
                                    className="form-control "
                                    id="zip" length="10"
                                    placeholder="Zip" onChange={handleChange} onKeyUp={handleChange}
                                />
                                {values.error.zip.length > 1 && (
                                    <span className="text-red">{values.error.zip}</span>
                                )}
                               
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Country<span className="text-red"> * </span></label>
                                <Select
                                    options={gContext.countriesList} value={values.country}
                                    className={`form-select padding-select   ${(values.error.country.length > 0) ? ' error' : ''}`}

                                    border={false} onChange={(e) => handleSelectChange(e, 'country')} onKeyUp={(e) => handleSelectChange(e, 'country')}
                                />{values.error.country.length > 1 && (
                                    <span className="text-red">{values.error.country}</span>
                                )}
                            </div>
                        </div>

                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="forn-group-input">
                                <label className="form-label m-b-10">Time Zone<span className="text-red"> * </span></label>
                                <span className="d-flex"><Select
                                    value={values.timezone}
                                    className={`form-select padding-select  ${(values.error.timezone.length > 0) ? ' error' : ''}`}
                                    options={timeZone}
                                    border={false} onChange={(e) => handleSelectChange(e, 'timezone')} onKeyUp={(e) => handleSelectChange(e, 'timezone')}
                                />{loader && <span className=" align-item-center justify-content-center m-l-10 m-t-10"><ClipLoader color="#569d11" size="30px" /> </span>}</span>
                                {values.error.timezone.length > 1 && (
                                    <span className="text-red">{values.error.timezone}</span>
                                )}
                            </div>
                        </div>

                    </div>

                </div>

                <div className="card-footer">
                    <div className="panel-action">
                        <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                            updateProfile();

                        }}>Save</button>
                        <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                            cancelEdit();

                        }}>Cancel</button>
                    </div>
                </div>

            </div>}

            {!loaded &&
                <div className="tab-pane active show" role="tabpanel">




                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title movingLoader w-150">
                                <h6>&nbsp;</h6>
                            </div>

                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>

                            </div>

                        </div>

                        <div className="card-body p-b-0">


                            <div className="row">



                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input ">
                                        <label for="first name" className="form-label m-b-10 d-flex movingLoader w-150">  &nbsp;
                                        </label>
                                        <div  >
                                            <div style={{ height: '100px', width: '100px' }} className=" movingLoader w-full">
                                                &nbsp;
                                            </div></div>

                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName" style={{ height: '150px' }}

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input">

                                        <input name="fName"

                                            className={`form-control   movingLoader }`}

                                        />
                                    </div>
                                </div>




                            </div>
                        </div>

                        <div className="card-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white movingLoader w-50" > &nbsp;</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center movingLoader w-50">&nbsp; </button>
                            </div>
                        </div>

                    </div>
                </div>}
             
        </>
    );
};

export default Profile;