import React, { useContext, useState, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext";
import { GetClientJobs, DeleteClientJob, ClientJobsStatusUpdate } from "../../utils/apiCalls";
import { getMonthDateYear  } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
import ReactCountryFlag from "react-country-flag";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

export default function PostedJobs(props) {
  
    const gContext = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowsJobs, setRowsJobs] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [message, setMessage] = React.useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(rowsJobs, page, gContext.paginationSize);
    function formatdate(date)
    {
        var d1 = new Date(date);
var newdate= (d1.getMonth() + 1) + '/' + d1.getDate() + '/' +  d1.getFullYear();

return newdate
    }


    function LoadEditPage(id) {
        gContext.setJobsDetail({ currentJobId: id });
        gContext.setCurrentPage("11");
    }
    function LoadJobPage(id) {
        gContext.setJobsDetail({ currentJobId: id,mode:'new' });
        gContext.setCurrentPage("12");
    } 
    const postJob = async (index, id) => {
      
        if (id) {
            setMessage(false);
            setLoad(true, index);
            try {
              
                const result = await ClientJobsStatusUpdate(id, gContext.userInfo.userID,200);
              
                if (result.data.jobStatusUpdate.responseData.code == "200") {
                    BindJobs(); 
                    props.callBackonPosted();
                }
                else
                {
                    setLoad(false, index);
                }
            }
            catch (e) {
                console.log(e);
                setLoad(false, index);
            }
        }
        else {
            setMessage(false);

        }
    }
    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);
            setLoad(true, index);
            try {

                const result = await DeleteClientJob(id,gContext.userInfo.userID );
              
                if (result.data.jobDelete.responseData.code == "200") {
                    BindJobs();
                }
                else
                {
                    setLoad(false, index);
                }
            }
            catch (e) {
                console.log(e);
                setLoad(false, index);
            }
        }
        else {
            setMessage(false);
            
        }
    }
    function setLoad(status, index) {
        const rows = [...rowsJobs];
       // rows[index]['loader'] = status;
        setRowsJobs(rows);
    }
    const postTableRows = (index, id) => {
        setMessage(false);
        if (id) { 
            gContext.setconfirmModal({ header: "Job post confirmation", ["text"]: "Do you want to post this job?", ["confirmed"]: false, index: index, id: id, showButton: true, mode: 'post', page: "wjobs" });
            gContext.toggleConfirmModal();
        }
        else
        {
            setMessage(false);
        }
    }
    /*For edu table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Job delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true,page:"wjobs" });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false); 
        }
    }

    async function BindJobs() {
        try {


            if (props.jobList) {
                let filterData = (props.mode == "withdrawn") ? props.jobList.closed : ((props.mode == "hired") ? props.jobList.hired : props.jobList.archived); 
               
                setRowsJobs(filterData);
              
                setLoaded(true);
            }

         
        }
        catch (e) {
            console.log(e);
           // gContext.setLoaderModalVisible(false);
        } 
    }
    useEffect(() => {
        if (gContext.confirmModal.confirmed && gContext.confirmModal.page=="wjobs") {
           
            if (!gContext.confirmModal.mode) { deleteData(gContext.confirmModal.index, gContext.confirmModal.id); }
            else {
                if (gContext.confirmModal.mode == "post") {
               
                    postJob(gContext.confirmModal.index, gContext.confirmModal.id);
                }
                }
               
           
        }
        else { setCurrentValue({ index: null, id: null ,page:""}); }
    }, [gContext.confirmModal]);
    
    useEffect( () => {
        BindJobs();

    }, [props.jobList]);
    useEffect(() => {
        BindJobs();
    }, [props.mode]);
    useEffect(() => {
    
        document.title = "Withdraw Jobs | Syncuppro";
    }, []);
    const jobReopen = (index, id) => {
        setMessage(false);
        if (id) {
            gContext.setconfirmModal({ header: "Job reopen confirmation", ["text"]: "Do you want to reopen this job?", ["confirmed"]: false, index: index, id: id, showButton: true, mode: 'post', page:"wjobs" });
            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {
            if (gContext.confirmModal.mode == "post") {
                UpdatejobReOpen(gContext.confirmModal.index, gContext.confirmModal.id);
                 
            }
            
        }
        else { setCurrentValue({ index: null, id: null }); }
    }, [gContext.confirmModal]);
    const UpdatejobReOpen = async (index, id) => {

        if (id) {
            setMessage(false);
            try {
                const result = await ClientJobsStatusUpdate(id, gContext.userInfo.userID, 100);
                if (result.data.jobStatusUpdate.responseData.code == "200") {
                    BindJobs();
                    props.callBackonPosted();
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            setMessage(false);
        }
    }
    return (
        <>
           
                            {message && <div className="col-lg-12 col-xl-12 text-left ml-6">
                                <Message message="Job deleted successfully" /> 
                            </div>}
                        
            {(loaded && rowsJobs && rowsJobs.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No job found</span>
            </div>}



            {(loaded && rowsJobs && rowsJobs.length > 0) && <>


                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th className="w-200 min-lg-cell">Job Title</th>
                               
                                <th className="  min-lg-cell"

                                >
                                    Experience
                                                </th>
                                <th className="  min-lg-cell">Weekly Hours</th>
                                
                                <th className="  min-lg-cell">Location</th>
                                <th className="  min-lg-cell">{props.mode === "hired" ? "Hired on" : " Closed on"}</th>
                                <th className="  min-lg-cell"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {slice && slice.map((data, index) => {
                                const { id, title, city, state, loader, countrycode, datemodified, countryname, datewithdrawn, weeklyhrs, explevel, guid } = data;

                                return (
                                    <tr key={index}>
                                       
                                       <td>
                                            <span className="d-flex justify-content-left align-items-left" onClick={() => { window.open("job/" + guid, "_blank"); }}>
                                                <span className="title d-flex pointer" data-toggle="tooltip" title={title} ><span className="one-line" >{title}</span>  <a className="m-l-5 text-theme m-icon material-icons pointer">open_in_new</a></span>
                                                   
                                                </span>
                                               
                                            </td>
                                       
                                       
                                        <td className="d-none">
                                            <div className="d-flex justify-content-left align-items-left">
                                                <div className="info-meta company pointer" data-toggle="tooltip" title={title} onClick={() => { window.open("job/" + guid, "_blank"); }} >
                                                    <span className="table-title one-line pointer" >{title}</span><a   className="m-l-5 text-theme m-icon material-icons">open_in_new</a></div>
                                            </div>
                                        </td>   <td>
                                            {explevel}
                                        </td>
                                       
                                        
                                        <td>
                                            {weeklyhrs}
                                        </td>
                                        <td>
                                            <div className="info-meta flag">
                                                <ReactCountryFlag
                                                    countryCode={countrycode}
                                                    svg
                                                    style={{
                                                        width: '1.5em',
                                                        height: '.7em',
                                                    }}
                                                    title={countrycode}
                                                />
                                                <span className="one-line text-black m-l-5"> 
                                                <span className={`    ${(city != null && city.length > 2) ? 'text-capi' : 'text-upper'}`}>{city?city.toLowerCase():'' }</span>
                                                        
                                                <span className={`    ${(state != null && state.length > 2) ? 'text-capi' : 'text-upper'}`}>{' '}{state?state.toLowerCase():''}</span> {' ' + countrycode}</span>
                                            </div>
                                        </td>
                                        <td>
                                            {(datemodified) ? (props.mode == 'withdrawn' || props.mode == 'hired') ? getMonthDateYear(datemodified) : '' : ""}</td>

                                        <td>
                                            {props.mode == 'withdrawn' && <div className="btn-table"><button className="btn btn-sm btn-gray"   onClick={() => (jobReopen(index, id))}>Reopen</button></div>}
                                        </td>
                                    </tr>)
                            })}</tbody>
                    </table>
                </div>
                {(loaded && rowsJobs && rowsJobs.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                            <div className="table-responsive d-none ">
                                <table className="table table-striped">
                                    <thead>
                                        <tr className="bg-gray-opacity-2 border border-color-2 py-5">
                                            <th
                                                scope="col"
                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                            >
                                                Title
                        </th>
                                            <th
                                                scope="col"
                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                            >
                                                Experience
                        </th> <th
                                                scope="col"
                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                            >
                                                Weekly Hours
                        </th>
                                            <th
                                                scope="col"
                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                            >
                                                Location
                        </th>
                                            <th
                                                scope="col"
                                                className="pl-5 table-y-middle  border-0 font-size-4 font-weight-bold py-5"
                                            >
                                                {props.mode === "hired" ? "Hired on" : " Closed on"}
                        </th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {rowsJobs && rowsJobs.map((data, index) => {
                                            const { id, title, city, state, loader, countrycode,status,datemodified, countryname, datewithdrawn, weeklyhrs, explevel, guid } = data;

                                            return (
                                                <tr className="border border-color-2" key={index}>
                                                    <th
                                                        scope="row"
                                                        className="table-y-top pl-6 border-0 py-5 max-width-px-235 media"
                                                    >


                                                        <a data-toggle="tooltip" title={title} style={{ wordWrap: "break-word" }} className="font-size-4 mb-0 ellipsis max-width-px-235 font-weight-semibold text-black-2 pointer" target="_blank" rel="noopener noreferrer" href={"/job/" + guid} >
                                                            {title}
                                                        </a>
                                                        <i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-3"></i>

                                                    </th>

                                                    <td className=" table-y-top py-5 max-width-px-185">
                                                        <h3 data-toggle="tooltip" title={explevel} style={{ wordWrap: "break-word" }} className="font-size-4 font-weight-normal text-black-2 mb-0 ellipsis max-width-px-185">
                                                            {explevel}
                                                        </h3>
                                                    </td>
                                                    <td className=" table-y-top py-5 min-width-px-185">
                                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                            {weeklyhrs}
                                                        </h3>
                                                    </td>
                                                    <td className=" table-y-top py-5 max-width-px-185">
                                                        <h3 data-toggle="tooltip" title={city + ' ' + state + ' ' + countrycode} style={{ wordWrap: "break-word" }} className="font-size-4 font-weight-normal text-black-2 mb-0 ellipsis max-width-px-170">
                                                            <ReactCountryFlag
                                                                countryCode={countrycode}
                                                                svg
                                                                style={{
                                                                    width: '2em',
                                                                    height: '1em',
                                                                }}
                                                                title={countrycode}
                                                            />         {city + ' '} {state} {' ' + countrycode}
                                                        </h3>
                                                    </td>
                                                    <td className=" table-y-top py-5 min-width-px-155">
                                                        <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                            {(datemodified)?(status == 'Closed' || status == 'Hired')?getMonthDateYear(datemodified):'' :""}
                                                        </h3>
                                                    </td>

                                                </tr>)
                                        })}


                                    </tbody>
                                </table>
                            </div>
                        </>}
                   

        </>
    );
};