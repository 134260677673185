import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { MessageSummary } from "../../utils/apiCalls";
import UserMessage from "../../sections/Message/Message";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactCountryFlag from "react-country-flag";
const imgL = "assets/image/logo-main-black.png";
const Sidebar = (props) => {
  const s3URL = process.env.REACT_APP_AWS_S3_URL;
  const navigate = useNavigate();
  const pathName = useLocation().pathname.toLowerCase().trim();
  const gContext = useContext(GlobalContext);
    const [fromId, setfromId] = useState(null);
    const [currentSender, setCurrentSender] = useState(null);
    const [selectedIndex, setSelectedIndex] = useState(0);
  const [showMessage, setShowMessage] = useState(false);
  const [openProfile, setOpenProfile] = useState(pathName == "/profile");
  const [openSetting, setOpenSetting] = useState(pathName == "/changepassword");
  const [openMessage, setOpenMessage] = useState(pathName == "/messages");
  const [messageSummary, setMessageSummary] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("/client/" + gContext.userInfo.sub);
    const [showSideMenu, setShowSideMenu] = useState(false);
  function logout() {
    gContext.logout();
  }


  function deleteProfile() {

    gContext.setVisibleProfileMessage(false);
    //  e.preventDefault();

    //  gContext.setconfirmModal({ ["text"]: "Are you sure you want to delete the account?  You'll lose all the data in this platform.", ["confirmed"]: false });

    //  gContext.toggleConfirmModal();

    let deletetext = ["Your information will be deleted from this platform permanently.",
      <br />, <br />,
      "Are you sure you want to delete?"];

    gContext.setconfirmModal({ header: "Profile delete confirmation", ["text"]: deletetext, ["confirmed"]: false, showButton: true, ["navigate"]: true, ["deleteaccount"]: true });

    gContext.toggleConfirmModal();
  }
  async function BindMessageSummary() {
    try {

      const result = await MessageSummary(gContext.userInfo.userID, gContext.userInfo.userType);

        if (result.data.messageSummary.responseData.code == "200") {
           
        let data = JSON.parse(result.data.messageSummary.responseData.message);
           
        if (data.length > 0) {

          setMessageSummary(data);

          setfromId(data[0].userid);
            setCurrentSender(data[0]);
        }
        else
          setMessageSummary([]);

        if (pathName.toLowerCase() == "/messages") {
          setOpenMessage(true);
          gContext.setCurrentPage("message");
          setShowMessage(true);
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {

    BindMessageSummary();
  }, [gContext.userInfo]);
  useEffect(() => {

    BindMessageSummary();
  }, [gContext.msgCount]);

  return (
      <>         
          <span className="screen-darken"></span>

          <div className="page-wrapper">
              <div className="message-wrapper-block"> 
                  <div className={`user-list  ${showSideMenu? "show":"hide"}` } id="user-list-menu">


                

              <div className="contacts-block-wrapper">

                   

                          {(messageSummary && messageSummary.length > 0) && <div className="contacts-block">

                              <span className="title-sm">Messages</span>

                              <ul className="contact-list">

                                  {openMessage && <>


                                      {messageSummary.map((data, index) =>


                                          <li key={index}>
                                              <a href="#" className={selectedIndex == index ? "active" : ""} onClick={() => {
                                                  setShowSideMenu(false);  setSelectedIndex(index);   gContext.setCurrentPage("message"); setfromId(data.userid); setCurrentSender(data); setShowMessage(true); navigate("/messages", { replace: true });
                                              }}>

                                                   
                                                  {(data.count && (data.count.toString() == "0" || data.count > 0)) ? <span className="chat-count  ">{data.count}</span> : ""}

                                                  <div className="d-flex align-items-center">
                                                      <div className="contact-img">
                                                          {(data.avatar) ? <img src={s3URL + data.avatar} alt="" /> : <img src="../images/avtar-img.svg" alt="" />}
                                                          <span className="online_icon"></span>
                                                      </div>

                                                      <div className="contact-meta">
                                                          <span className="contact-name one-line"> {(data.firstname) ? data.firstname : ''}  {(data.lastname) ? data.lastname : ''}</span>
                                                          <div className="info-meta flag ">
                                                              <span className="inline-meta m-r-10 one-line"><span className="text-capi">{data.state}</span>, {data.code}</span>
                                                              <ReactCountryFlag
                                                                  countryCode={data.code}
                                                                  svg

                                                              />
                                                          </div>
                                                      </div>
                                                  </div>

                                              </a>
                                          </li>


                                      )}



                                  </>}




                              </ul>

                          </div>}

              </div>

                  </div>

                  {(!messageSummary || (messageSummary && messageSummary.length == 0)) && <div style={{ height: "90vh" }} className="d-flex  f-18 align-item-center justify-content-center w-full " >
                      <span className="text-light-gray m-icon material-icons m-r-10">chat</span>
                      <span>No message found</span>
                  </div>}
                  {(showMessage && gContext.currentPage === "message") ? <> <UserMessage currentSender={currentSender} setShowSideMenu={() => setShowSideMenu(!showSideMenu)} showSideMenu={showSideMenu} fromUserID={fromId} fromTypeId="1" /> </> : ''}
              </div></div>

          
      <Collapse in={gContext.showSidebarDashboard}>
              <div className="dashboard-sidebar-wrapper  pt-11 d-none " id="sidebar">
                      <div className="brand-logo px-11">

                          <a>
                              <img src={imgL} alt="" />
                          </a>

                      </div>
                      <div className="my-15 px-11">



                      </div>
                      
             <div className="overflow-scroll  ">
            <ul className="list-unstyled dashboard-layout-sidebar">
              
                          <li className="nav-item dropdown">

                              <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center nav-link dropdown-toggle gr-toggle-arrow  ${(gContext.currentPage === "message") ? 'active' : ''}`} href="#" onClick={() => { setOpenMessage(!openMessage); }}>
                                  <i className="fas fa-message mr-7"></i> Messages  {gContext.msgCount > 0 && <span className="ml-auto px-2 py-1   font-weight-normal  bg-dodger text-white font-size-2 rounded-5 max-height-px-18 flex-all-center">
                                      {gContext.msgCount}
                                  </span>}
                              </a>

                          </li>
                          {openMessage && <>  <li className=" pl-10">

                              {messageSummary.map((data, index) =>

                                  <a key={index} className={`px-5  py-1 my-5 font-size-4 font-weight-semibold flex-y-center  ${(gContext.currentPage === "message" && fromId === data.userid) ? 'active bg-green-opacity-1' : ''}`} href="#" onClick={() => {
                                      gContext.setCurrentPage("message"); setfromId(data.userid); setShowMessage(true); navigate("/messages", { replace: true });
                                  }}>
                                      <div className="  mr-3">
                                          {(data.avatar) && <img src={s3URL + data.avatar} className="circle-32" alt="" />}
                                      </div>
                                      {/* <!-- media logo end --> */}
                                      {/* <!-- media texts start --> */}
                                      <div>
                                          <span className="font-size-4 mb-0">
                                              {(data.firstname) ? data.firstname : ''}  {(data.lastname) ? data.lastname : ''}
                                          </span>

                                      </div>
                                  </a>
                              )}
                          </li>


                          </>}



               

              <li  >

                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center pointer ${(gContext.currentPage === "alerts") ? 'active' : ''}`} onClick={() => {
                  gContext.setCurrentPage("alerts"); gContext.setVisibleProfileMessage(false); navigate("/notifications", { replace: true });
                }}>
                  <i className="fa   fa-bell mr-7"></i>Notifications  {gContext.alertCount > 0 && <span className="ml-auto px-2 py-1   font-weight-normal  bg-dodger text-white font-size-2 rounded-5 max-height-px-18 flex-all-center">
                    {gContext.alertCount}
                  </span>}
                </a>

              </li>


              <li className="nav-item dropdown">

                <a className={`px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center nav-link dropdown-toggle gr-toggle-arrow  ${(gContext.currentPage === "7") ? 'active' : ''}`} href="#" onClick={() => { gContext.setVisibleProfileMessage(false); setOpenSetting(!openSetting); gContext.setCurrentPage("8"); navigate("/changepassword", { replace: true }); }}>
                  <i className="fas fa-cog mr-6"></i>Settings
                </a>   </li>  {openSetting &&
                  <>

                    <li className=" pl-10">

                      <a className={`px-10 py-1 my-5 font-size-4 pointer font-weight-semibold flex-y-center  ${(gContext.currentPage === "8") ? 'active' : ''}`} onClick={() => {
                        gContext.setVisibleProfileMessage(false); navigate("/changepassword", { replace: true });
                        gContext.setCurrentPage("8");
                      }}>
                        <i className="fas fa-key mr-3"></i>Change Password
                      </a>

                    </li></>

              }

             


              <li >

                <a style={{ cursor: gContext.userInfo.userID ? '' : 'not-allowed' }}
                  className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center pointer" onClick={() => {
                    gContext.setCurrentPage("18"); gContext.setVisibleProfileMessage(false); navigate("/reportissue", { replace: true });
                  }}>
                  <i className="fa text-orange fa-flag mr-6"></i>Report issue
                </a>

              </li>

              <li >

                <a className="px-10 py-1 my-5 font-size-4 font-weight-semibold flex-y-center pointer" onClick={logout}>
                  <i className="fa text-red fa-sign-out-alt mr-7"></i>Logout
                </a>

              </li>
              <div className="my-10 px-11 pt-25">

                <a className={`btn btn-red btn-md  rounded-5 w-100 text-uppercase text-white`} onClick={(e) => {
                  deleteProfile();
                }}>
                  <span className="mr-3 d-inline-block">  <i className="fas fa-trash ml-2"></i> </span> Delete my account
                </a>

              </div>
            </ul></div>
        </div>
      </Collapse>
      <a
        href="/#"
        className="sidebar-mobile-button d-none"
        onClick={(e) => {
          gContext.setVisibleProfileMessage(false);
          e.preventDefault();
          gContext.toggleSidebarDashboard();
        }}
      >
        <i className="icon icon-sidebar-2"></i><span className="px-3">{(gContext.showSidebarDashboard) ? "  Hide Side Menu" : "  Show Side Menu"}</span>
      </a> 
     
    </>
  );
};

export default Sidebar;
