import {
    stripeUSBankPaymentCreate, stripeCardPaymentCreate, paymentCreateAccount, paymentUserUpdate, paymentOnboardAccount, paymentRetrieveAccount, stripePaymentCreate
} from './../graphql/mutations';
 
import { API, graphqlOperation } from 'aws-amplify';
 
const user = (localStorage.getItem("connect")) ? JSON.parse(localStorage.getItem("connect")) : null;


export async function AddUserPayment(userInfo, userType) {

    try {
        
        if (userInfo && userInfo.length > 0) {
            const result = await createUserPayment(userInfo, userType);

            if (result.data.paymentCreateAccount.responseData.code == 200) {
                let stripeDetail = JSON.parse(result.data.paymentCreateAccount.responseData.message);

                const updateStatus = await updateUserPayment(stripeDetail.stripe_id, userType, userInfo);
                if (updateStatus.data.paymentUserUpdate.responseData.code == 200) {
                    const onboardStatus = await createOnboardAccount(stripeDetail.stripe_id);

                    if (onboardStatus.data.paymentOnboardAccount.responseData.code == 200) {

                        const onboardurl = JSON.parse(onboardStatus.data.paymentOnboardAccount.responseData.message);
                        if (onboardurl.url)
                            window.location.href = onboardurl.url;

                    } else {

                        return result;
                    }
                } else {

                    return result;
                }
            } else {

                return result;
            }


            return null;
        }

    }
    catch (e) {
        throw e;
    }


}

async function createUserPayment(userInfo, userType) {

    let response;
    try {


        if (userInfo && userInfo.length > 0) {

            const s3URL = window.location.origin + (userType == "1" ? "/expert/" : "/client/") + userInfo[0].sub;
            let data = paymentCreateAccount.replace("$email", (user) ? user.payload["email"] : "").
                replace("$firstName", userInfo[0].firstname).replace("$company", (userType == "2") ? userInfo[0].company : "").replace("$country", (userType == "2") ? userInfo[0].code : userInfo[0].countrycode).replace("$lastName", userInfo[0].lastname).
                replace("$state", userInfo[0].state).replace("$street1", userInfo[0].address1).replace("$street2", userInfo[0].address2).replace("$userId", userInfo[0].id).replace("$userType", userType).
                replace("$zip", userInfo[0].zip ? userInfo[0].zip:"40052").
                replace("$url", (userType == "1") ? s3URL : ((userInfo[0].website && userInfo[0].website != "" && userInfo[0].website != "null") ? userInfo[0].website : s3URL)).
                replace("$city", userInfo[0].city).
                replace("$phone", userInfo[0].phone ? userInfo[0].phone : "4458874567");



            await API.graphql(graphqlOperation(data)).then((result) => {


                response = result;
            }).catch((error) => { response = error; });

        }
        return response;
    }
    catch (e) {
        console.log("createUserPayment", e); return e;
    }

}

async function updateUserPayment(stripeId, userType, userInfo) {
    try {

        let response;
        if (userInfo && userInfo.length > 0) {

            let update = paymentUserUpdate.replace("$stripeId", stripeId).replace("$userId", userInfo[0].id).replace("$userType", userType);

            await API.graphql(graphqlOperation(update)).then((result) => {

                response = result;
            }).catch((error) => {

                response = error;
            });
        }
        return response;
    }
    catch (e) {
        console.log("updateUserPayment", e);
        return e;
    }

}

//refreshUrl: "$refreshUrl", returnUrl: "$returnUrl", stripeId: "$stripeId"
export async function createOnboardAccount(stripeId) {
    try {



        let response;
        let update = paymentOnboardAccount.replace("$refreshUrl", window.location.href).replace("$returnUrl", window.location.href.toLowerCase().trim().replace("settings", "dashboard")).replace("$stripeId", stripeId);

        await API.graphql(graphqlOperation(update)).then((result) => {

            response = result;
        }).catch((error) => {

            response = error;
        });

        return response;
    }
    catch (e) {

        return e;
    }

}
export async function PaymentRetrieveAccount(stripeId) {
    try {

        let response;
        let update = paymentRetrieveAccount.replace("$stripeId", stripeId);

        await API.graphql(graphqlOperation(update)).then((result) => {

            response = result;
        }).catch((error) => {

            response = error;
        });

        return response;
    }
    catch (e) {

        return e;
    }

}
export async function StripeCardPaymentCreate(input) {
    try {
        console.log(input);
        let response;

        let update = stripeCardPaymentCreate.replace("$amount", parseInt(input.amount)).
            replace("$destination", input.destination).
            replace("$email", input.email).
            replace("$token", input.token).
            replace("$name", input.name).
            replace("$milestoneid", input.milestoneid).
            replace("$contractid", input.contractid).
            replace("$expertid", input.expertid).
            replace("$clientid", input.clientid).
            replace("$paymentMethodType", input.paymentMethodType).
            replace("$clientStripeId", input.clientStripeId ? input.clientStripeId:"").
            replace("$bankAccountId", input.bankAccountId ? input.bankAccountId:"").
            replace("$taskName", input.taskName).
            replace("$clientName", input.clientName);
        console.log("StripeCardPaymentCreate", update);
        await API.graphql(graphqlOperation(update)).then((result) => {
            console.log("result",result);
            response = result;
        }).catch((error) => {
            console.log("error", error);
            response = error;
        });

        return response;
    }
    catch (e) {

        return e;
    }

}
export async function StripeUSBankPaymentCreate(input) {
    try {
        console.log(input);
        let response;

        let update = stripeUSBankPaymentCreate.replace("$amount", parseInt(input.amount)).
            replace("$destination", input.destination).
            replace("$email", input.email).
            replace("$token", input.token).
            replace("$name", input.name).
            replace("$milestoneid", input.milestoneid).
            replace("$contractid", input.contractid).
            replace("$expertid", input.expertid).
            replace("$clientid", input.clientid).
            replace("$paymentMethodType", input.paymentMethodType).
            replace("$clientStripeId", input.clientStripeId ? input.clientStripeId : "").
            replace("$bankAccountId", input.bankAccountId ? input.bankAccountId : "").
            replace("$taskName", input.taskName).
            replace("$clientName", input.clientName);
        console.log("stripeUSBankPaymentCreate", update);
        await API.graphql(graphqlOperation(update)).then((result) => {
            console.log("result", result);
            response = result;
        }).catch((error) => {
            console.log("error", error);
            response = error;
        });

        return response;
    }
    catch (e) {

        return e;
    }

}