import React, { useContext, useState, useEffect } from "react"; 
import GlobalContext from "../../context/GlobalContext";
import { GetClientJobs, DeleteClientJob, ClientJobsStatusUpdate } from "../../utils/apiCalls";
import {  getMonthDateYear  } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
import ReactCountryFlag from "react-country-flag";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";


export default function PostedJobs(props) {

    const gContext = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowsJobs, setRowsJobs] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [message, setMessage] = React.useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(rowsJobs, page, gContext.paginationSize);
    const [profileValues, setprofileValues] = useState(JSON.parse(gContext.userInfo.profileInfo));
    function LoadEditPage(guid, id) {

        gContext.setJobsDetail({ currentJobId: { guid: guid, id: id } });
        gContext.setCurrentPage("11");
    }

    const postJob = async (index, id) => {

        if (id) {
            gContext.setLoaderModalVisible(true);
            setMessage(false);

            try {

                const result = await ClientJobsStatusUpdate(id, gContext.userInfo.userID, 200);
              
                if (result.data.jobStatusUpdate.responseData.code == "200") {
                    BindJobs();
                    gContext.setSuccessMessage(ConfirmMessages.JobPostedMessage);
                    gContext.toggleSuccessModal();
                    props.callBackonPosted();
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }
                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
            }
        }
        else {
            gContext.setLoaderModalVisible(false);
        }
    }
    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);
            gContext.setLoaderModalVisible(true);
            try {

                const result = await DeleteClientJob(id, gContext.userInfo.userID);
               
                if (result.data.jobDelete.responseData.code == "200") {
                    BindJobs();
                }
                else {
                    gContext.setLoaderModalVisible(false);
                }
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
            }
        }
        else {
            gContext.setLoaderModalVisible(false);
        }
    }

    const postTableRows = (index, id) => {
        setMessage(false);
        if (id) {
            gContext.setconfirmModal({ header: "Job post confirmation", ["text"]: "Do you want to post this job?", ["confirmed"]: false, index: index, id: id, showButton: true, mode: 'post',page:"newjob" });
            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }
    /*For edu table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Job delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true, page: "newjob" });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }

    async function BindJobs() {
        try {


            if (props.jobList) {
                let filterData = props.jobList.draft;

                setRowsJobs(filterData);
                setLoaded(true);
            }



        }
        catch (e) {
            console.log(e);
        }
    }
    useEffect(() => {
        if (gContext.confirmModal.confirmed ) {

            if (!gContext.confirmModal.mode && gContext.confirmModal.page == "newjob") { deleteData(gContext.confirmModal.index, gContext.confirmModal.id); }
            else {
                if (gContext.confirmModal.mode == "post" && gContext.confirmModal.page == "newjob") {
                    postJob(gContext.confirmModal.index, gContext.confirmModal.id);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null,["page"]:""
                    });
                }
            }


        }
        else { setCurrentValue({ index: null, id: null,page:"" }); }
    }, [gContext.confirmModal]);

    useEffect(() => {
        BindJobs();

    }, [props.jobList]);

    useEffect(() => {

        document.title = "New Jobs | Syncuppro";
    }, []);
    return (
        <>

            {message && <div className="text-red">
                <Message message="Job deleted successfully" />
            </div>}

            {(loaded && rowsJobs && rowsJobs.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No job found</span>
            </div>}
            {(loaded && slice && slice.length > 0) &&

                <>
                     


                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                <th className="w-200 min-lg-cell">Job Title</th>
                                <th className="  min-lg-cell">Location</th>

                                <th className="  min-lg-cell">Weekly Hours</th>
                                <th className="  min-lg-cell">Project Length</th>
                                <th className="  min-lg-cell" >Created on</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slice && slice.map((data, index) => {
                                        const { id, title, city, state, projlength, countrycode, countryname, datecreated, weeklyhrs, explevel, guid } = data;

                                        return (
                                            <tr key={index}>
                                                
                                                 <td>
                                                <span className="d-flex justify-content-left align-items-left" onClick={() => { window.open("job/" + guid, "_blank"); }}>
                                                        <span className="d-flex title  pointer" data-toggle="tooltip" title={title}
                                                        ><span className="one-line">{title}</span> <a className="m-l-5 text-theme m-icon material-icons pointer">open_in_new</a></span>
                                                     
                                                </span>
                                            </td>
                                                
                                                <td className="d-none">
                                                    <div className="d-flex justify-content-left align-items-left" >
                                                        <div className="info-meta company pointer" data-toggle="tooltip" title={title}  onClick={() => { window.open("job/" + guid, "_blank"); }}>
                                                            <span className="table-title one-line pointer">{title}</span>
                                                            <a  className="m-l-5 text-theme m-icon material-icons pointer">open_in_new</a>
                                                            </div>
                                                    </div>
                                                </td>
                                                <td >
                                                    <div className="info-meta flag">
                                                        <ReactCountryFlag
                                                            countryCode={countrycode}
                                                            svg
                                                            style={{
                                                                width: '1.5em',
                                                                height: '.7em',
                                                            }}
                                                            title={countrycode}
                                                        />
                                                        <span className="one-line text-black m-l-5"> 
                                                            <span className={`    ${(city != null && city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(city)?city.toLowerCase():"" + '   '}{" "}</span>
                                                            <span className={`    ${(state != null && state.length > 2) ? 'text-capi' : 'text-upper'}`}>{(state)?state.toLowerCase():"" }</span>{' ' + countrycode}</span>
                                                    </div>
                                                </td>
                                                <td>
                                                    {weeklyhrs}
                                                </td>
                                                <td>
                                                    {projlength}
                                                </td>
                                                <td>
                                                    {getMonthDateYear(datecreated)}</td>

                                                <td>
                                                    <div className="btn-table">
                                                        {profileValues && profileValues.length > 0 && !profileValues[0].flagged && <button className="btn btn-sm btn-gray" onClick={() => (postTableRows(index, id))}>Post</button>}
                                                        <button className="btn btn-sm btn-gray"  onClick={() => (LoadEditPage(guid, id))}>Edit</button>
                                                        <button className="btn btn-sm btn-gray"  onClick={() => (deleteTableRows(index, id))}>Delete</button>
                                                    </div>
                                                </td>
                                            </tr>)
                                    })}</tbody>
                            </table> 

                </div>
                {(loaded && rowsJobs && rowsJobs.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}

                </>}
        </>);
};
