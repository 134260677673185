import React, { useContext,useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import { useParams } from "react-router-dom"; 
import JobView from "../sections/ClientDashboard/JobView";
 

export default function Job(props) {
    const { ID } = useParams();



    return (
        <>
            <PageWrapper headerConfig={{
               
                button: props.navType
            }}>
                <JobView id={ ID} />

            </PageWrapper>
        </>
    );
};
