import React, { useContext, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { useLocation } from 'react-router-dom';
import { API, graphqlOperation } from 'aws-amplify';
import validator from 'validator';
import Message from "../../components/Message";
import ConfirmMessages from "../../utils/confirmMessages";
import { getEncodedEmail } from "../../utils/helperFn"; 
import { SiteObjectCounts } from "../../utils/GetAPIUtils";
import { signInCall, confirmCode, codeResend, AccountFedLogin } from "../../utils/apiCalls";
import ModalUserType from "../ModalSignUp/ModalUserType";
import Icon from '@mui/material/Icon';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'

const imgLoader = "../assets/image/svg/spinning-circles.svg";
const ModalStyled = styled(Modal)`
   &.modal {
    
  }
`;

const ModalSignIn = (props) => {
    const [visibleLoader, setVisibleLoader] = useState(false);

    const [email, setEmail] = useState('');
    const [showPass, setShowPass] = useState(true);
    const gContext = useContext(GlobalContext);
    const [values, setvalues] = React.useState({ email: '', password: '', error: { email: '', password: '' } });

    const [errorMessage, setErrorMessage] = React.useState('');

    const [codeConfirm, setCodeConfirm] = React.useState('');
    const [totalExperts, setTotalExperts] = React.useState('');
    const [totalJobs, setTotalJobs] = React.useState('');
    const [showModal, setShowModal] = useState(false);
    const [confirmationValues, setConfirmationValues] = React.useState({ code: '', error: '' });
    const pathName = useLocation().pathname;
    
    const handleClose = () => {
        setErrorMessage('');
        setCodeConfirm('');
        setConfirmationValues({ code: '', error: '' });
        setvalues({ email: '', password: '', error: { email: '', password: '' } });
        gContext.toggleSignInModal();
    };
    function toggleModal(data) {

        setShowModal(!showModal);
        gContext.toggleSignInModal();
    }

    const responseFacebook = (response) => {
        
        AccountLogin(response);
        toggleModal(null);
    }
    async function AccountLogin(response) {
        
        try {
            const result = await AccountFedLogin(JSON.stringify(response));
            if (result.data && result.data.loginFed.responseData) {
               
                 
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }
    }
    const togglePassword = () => {
        setShowPass(!showPass);
    };

    const handleConfirmationChange = (e) => {
        setErrorMessage("");
        const { name, value } = e.target;
        setConfirmationValues({ code: value, error: (value == "") ? "Code is required" : "" });
    }
    async function verifyEmail() {
        // let data = registerCodeResend.replace("$email", email);
        try {
            const result = await codeResend(email);

            if (result.data && result.data.registerCodeResend.responseData) {
                 
                if (result.data.registerCodeResend.responseData.code == "200") {
                    localStorage.setItem("userEmail", JSON.stringify({ email: email }));
                    window.location.href = "/signupcode";
                    //setCodeConfirm('step2');
                }
                else {
                    setErrorMessage(JSON.parse(result.data.registerCodeResend.responseData.message));
                }
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }
    }

    async function checkConfirmation() {
        setErrorMessage("");
        if (confirmationValues.code == "") {
            setConfirmationValues({ ...confirmationValues, error: "Confirmation code is required" });
            return;
        }
        // let data = registerConfirm.replace("$email", email).replace("$code", confirmationValues.code.trim());
        try {
            const result = await confirmCode(email, confirmationValues.code.trim());

            if (result.data && result.data.registerConfirm.responseData) {
                 
                if (result.data.registerConfirm.responseData.code == "200") {

                    setCodeConfirm('step3');
                }
                else {
                    setErrorMessage(JSON.parse(result.data.registerConfirm.responseData.message));
                }
            }
        }
        catch (e) {
            console.log(e);
            setErrorMessage("Server error");
        }

    }
    function checkValidity() {
        let isValid = false;
        let { error, ...rest } = values;

        Object.values(rest).forEach(val => {
            if (val === '') {
                isValid = true;
            }
        });

        error.email = validator.isEmpty(rest.email) ? "Email is required" : error.email;

        error.password = validator.isEmpty(rest.password) ? "Password is required" : error.password;

        setvalues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    const handleChange = (e) => {
        setCodeConfirm('');
        const { name, value } = e.target;

        let error = { ...values.error };
        setErrorMessage("");

        switch (name) {

            case "password":
                error.password = validator.isEmpty(value) ? "*" : "";
                break;
            case "email":
                error.email = validator.isEmail(value) ? "" : "Invalid email";
                break;
            default:
                break;
        }

        setvalues({ ...values, error, [name]: value });

    }
    function loginUser() {

        setErrorMessage("");
        if (checkValidity()) {

            //const token = captchaRef.current.getValue();
            //console.log(token);
            //if (token == "") {
            //    setCaptchaMessage(true);
            //    return;
            //} 
           
            gContext.setLoaderModalVisible(true);
            signIn();
            //await axios.post(`${apiURL}?secret=${secretKey}&response=${token}`)
            //    .then(res => {
            //        console.log(res);
            //        signIn();})
            //    .catch((error) => {
            //        console.log(error);
            //       
            //        //setErrorMessage(error); 
            //    });

        }
    }




    useEffect(() => {
        //setShowModal(!showModal);
        setErrorMessage("");
        getUtils();  
    }, []);


    async function getUtils() {
        try {
            const result = await SiteObjectCounts();
            if (result.data && result.data.siteObjectCounts) {
                setTotalExperts(result.data.siteObjectCounts.totalExperts);
                setTotalJobs(result.data.siteObjectCounts.totalJobs);
               
            }
        }
        catch(e)
        {
            console.log(e);
        }
    }  
    async function signIn() {
         
        try {
            const result = await signInCall(values.email, values.password);
             

            if (result.data && result.data.login.responseData) {

                if (result.data.login.responseData.code == "200") {


                    let response = JSON.parse(result.data.login.responseData.message);
                   

                    if (response.payload["custom:usertype"] !== "3") {
                         
                        localStorage.setItem('connect', JSON.stringify(JSON.parse(result.data.login.responseData.message)));

                        if (pathName == "/") {
                            gContext.toggleSignInModal();
                            window.location.href = "/Auth";
                        } else if (pathName == "/signupcode" || pathName == "/signupsuccess" || pathName == "/signupcode_client" || pathName == "/signupsuccess_client" || pathName == "/signupcode_expert" || pathName == "/signupsuccess_expert")
                            window.location.href = "/Auth";
                        else
                            window.location.href = pathName;
                    } else
                        setErrorMessage("Invalid user.");
                }
                else {
                    if (result.data.login.responseData.message == '"User is not confirmed."') {

                        
                        setEmail(values.email);
                        setCodeConfirm('step1');
                    }
                    else
                        setErrorMessage(JSON.parse(result.data.login.responseData.message));
                }
            }
        }
        catch (error) {
           
            gContext.setLoaderModalVisible(false);
            console.log(error);
            setErrorMessage("Server error");
        }

        
        gContext.setLoaderModalVisible(false);
    }
    function handleLoginKeypress(e) {
        //it triggers by pressing the enter key 
 
        if (e.charCode === 13 || e.key === "Enter") {
            loginUser();
        }
    };

    return (
        <>
           
                 
            <ModalStyled className="modal fade" backdrop="static"
                size="lg"
           centered
            show={gContext.signInModalVisible}
            onHide={gContext.toggleSignInModal}
            >


             

                <Modal.Body className="p-0 d-none">
                    <button
                        type="button"
                        className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    <div className="login-modal-main bg-white rounded-8 overflow-hidden">
                        <div className="row no-gutters">
                            <div className="col-lg-5 col-md-6">
                                <div className="pt-10 pb-6 pl-11 pr-12  bg-green-2 h-100 d-flex flex-column dark-mode-texts"
                                >
                                    <div className="pb-9">
                                        <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                                            Welcome
                  </h3>
                                        <p className="mb-0 font-size-4 text-white">
                                            Connect, Engage ,Grow
                  </p>
                                    </div>
                                    <div className="border-top border-default-color-2 mt-auto">
                                        <div className="d-flex mx-n9 pt-6 flex-xs-row flex-column">
                                            <div className="pt-5 px-9">
                                                <h3 className="font-size-7 text-white">{totalJobs}</h3>
                                                <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                                                    Jobs posted
                      </p>
                                            </div>
                                            <div className="pt-5 px-9">
                                                <h3 className="font-size-7 text-white">{totalExperts}</h3>
                                                <p className="font-size-3 text-white gr-opacity-5 line-height-1p4">
                                                    New experts registered
                      </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7 col-md-6">
                                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">

                                    {errorMessage != "" && <><span className="error py-3   font-weight-semibold line-height-reset px-3 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span><br /></>}
                                    {codeConfirm === 'step1' && <><span className="py-3 px-3  font-weight-semibold   bg-green-opacity-2 rounded-5 font-size-4">
                                        {ConfirmMessages.ResendSignupConfirmation} <br />  <a className="btn btn-green-full" style={{ cursor: 'pointer' }} onClick={() => { verifyEmail();  }}> Verify Email</a>
                                    </span><br /></>}
                                    {(codeConfirm === 'step2') && <> <div className="row mt-2">


                                        <Message message={ConfirmMessages.SignupStep1Confirmation.replace("[$email]", getEncodedEmail(email))} />
                                        <div className=" col-12 form-group mb-8 mt-5 ">
                                            <form >

                                                <div className="form-group">

                                                    <label
                                                        htmlFor="code"
                                                        className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                                    >
                                                        Confirmation Code <span className="error font-size-3">*</span>
                                                    </label>
                                                    <input
                                                        type="code" name="code"
                                                        className={`form-control    ${(confirmationValues.error.length > 0) ? 'form-control-error' : ''}`}
                                                        placeholder="Confirmation Code"
                                                        id="code" required onChange={handleConfirmationChange}
                                                    />
                                                    {confirmationValues.error.length > 1 && (
                                                        <span className="text-red font-size-3">{confirmationValues.error}</span>
                                                    )}
                                                </div></form>

                                            <button type="button" className="btn btn-primary btn-medium  rounded-5 text-uppercase" onClick={(e) => {
                                                e.preventDefault();
                                                checkConfirmation();
                                            }}>
                                                <i className="fa fa-sign-in-alt mr-2 pt-1" > </i>   Submit
                                          </button>
                                        </div>



                                    </div></>}
                                    {(codeConfirm === 'step3') ?

                                        <Message message={ConfirmMessages.SignupStep2Confirmation} /> : ''}
                                    {(codeConfirm != 'step2') ? <form>
                                        <div className="form-group">
                                            <label
                                                htmlFor="email"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                            >
                                                E-mail <span className="error font-size-3">*</span>
                                            </label>
                                            <input
                                                name="email"
                                                type="email"
                                                className={`form-control    ${(values.error.email.length > 0) ? 'form-control-error' : ''}`}
                                                placeholder="example@gmail.com"
                                                id="email" required onChange={(e) => (handleChange(e))}
                                            /> {values.error.email.length > 1 && (
                                                <span className="text-red font-size-3">{values.error.email}</span>
                                            )}
                                        </div>

                                        <div className="form-group">
                                            <label
                                                htmlFor="password"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                            >
                                                Password <span className="error font-size-3">*</span>
                                            </label>
                                            <div className="position-relative  ">
                                                <fieldset>

                                                    <input
                                                        type={showPass ? "password" : "text"}
                                                        name="password"
                                                        className={`form-control font-weight-normal ${(values.error.password.length > 0) ? 'form-control-error ' : ''}   `}
                                                        id="password"
                                                        placeholder="Enter password" required onChange={(e) => (handleChange(e))} onKeyPress={(e) => (handleLoginKeypress(e))}
                                                    />
                                                    <a
                                                        href="/#"
                                                        className=" pos-abs-cr fas mr-6 text-black-2"
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            togglePassword();
                                                        }}
                                                    >
                                                        {showPass ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash " ></i>}
                                                        <span className="d-none">none</span>
                                                    </a></fieldset>
                                            </div>
                                            {values.error.password.length > 1 && (
                                                <span className="error font-size-3">{values.error.password}</span>
                                            )}
                                        </div>


                                        <div className="form-group d-flex flex-wrap justify-content-between">

                                            <a
                                                href="/#"
                                                className="font-size-3 text-dodger line-height-reset" onClick={(e) => {
                                                    e.preventDefault();
                                                    gContext.toggleForgotPasswordModal();
                                                    handleClose();
                                                }}
                                            >
                                                Forgot Password?
                    </a>
                                        </div>
                                        {/* <!--    <div className="form-group mb-8 text-center">
                                      <ReCaptcha ref={captchaRef} sitekey={siteKey} />
                                      {captchaMessage ===true && (
                                          <span className="error font-size-3">Select captcha</span>
                                      )}
                                  </div>--> */}
                                        <div className="col-lg-12  justify-content-md-end">
                                            <div className="media py-10 w-100  justify-content-md-end ml-5"  >
                                                <div className="p-0 m-0 position-relative">

                                                    <button type="button" onClick={loginUser}
                                                        className="btn-green-full font-weight-bold  font-size-3 border-0 rounded-5 text-uppercase"
                                                        style={{ width: '110px', height: '40px' }}>

                                                        Log in{" "}
                                                    </button>

                                                </div></div>
                                        </div>
                                        <p className="font-size-4 text-center heading-default-color">
                                            Don’t have an account?{" "}
                                            <a href="/#" className="text-primary" onClick={(e) => {
                                                e.preventDefault();
                                                gContext.toggleSignUpModal();
                                                handleClose();
                                            }}>
                                                Sign up
                    </a>
                                        </p>
                                    </form> : ""}
                                    <div className="or-devider">
                                        <span className="font-size-3 line-height-reset ">Or</span>
                                    </div>
                                    <div className="row ">
                                        <div className="col-12 col-xs-12 pt-5   ml-lg-1   ">
                                            <a
                                                href="/#"
                                                className="font-size-4 font-weight-semibold position-relative text-white bg-allports h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "                                             >
                                                <i className="fab fa-linkedin pos-abs-cl font-size-6 ml-xs-4 px-5"></i>{" "}
                                                <span>
                                                    Log in with  LinkedIn
                                                  </span>
                                            </a>
                                        </div>
                                        <div className="col-12 col-xs-12   ml-lg-1   ">
                                            <a
                                                href="/#"
                                                className="font-size-4 font-weight-semibold position-relative text-white bg-poppy h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4  "
                                            >
                                                <i className="fab fa-google pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                                <span >
                                                    Log in  with Google
                      </span>
                                            </a>
                                        </div>
                                        <div className="col-12 col-xs-12   ml-lg-1    ">
                                            <FacebookLogin
                                                appId="1204734280171739"
                                                autoLoad={false}
                                                callback={responseFacebook}
                                                render={renderProps => (

                                                    <a
                                                        onClick={renderProps.onClick}
                                                        className="font-size-4 font-weight-semibold position-relative text-white bg-marino h-px-48 flex-all-center w-100 px-6 rounded-5 mb-4 "
                                                    >
                                                        <i className="fab fa-facebook-square pos-abs-cl font-size-6 ml-xs-4  px-5"></i>{" "}
                                                        <span >
                                                            Log in with Facebook
                      </span>
                                                    </a>

                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                        <div className="modal-body">
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={handleClose}></button>

                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-6">
                                    <div className="login-info">
                                        <h1>Login to your Account</h1>
                                        <p className="m-0">Connect, Engage, Grow</p>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-6">

                                    <div className="login-info-text">

                                        
                                    {errorMessage != "" && <>   <div className="alert alert-danger">
                                        {errorMessage}
                                       
                                    </div>

                                    </>
                                    }
                                    {codeConfirm === 'step1' && <> 
                                    {ConfirmMessages.ResendSignupConfirmation} <br />
                                    <button type="button" className="btn btn-orange w-full m-t-10"

                                        onClick={() => { verifyEmail();   }}>
                                        Verify Email
                                    </button>


                                    <br /></>}

                                    {(codeConfirm === 'step2') && <>



                                        <span className="verification-mail p-b-30">{ConfirmMessages.SignupStep1Confirmation.replace("[$email]", getEncodedEmail(values.email))}</span>
                                        <form >
                                            {errorMessage != "" && <><div class="alert alert-danger">
                                                {errorMessage}
                                            </div></>}

                                            <div className="form-group icon-input">
                                                <label className="form-label">Confirmation Code<span>*</span></label>
                                                <Icon className="text-green m-icon material-icons ">pin</Icon>
                                                
                                            <input

                                                    type="code"
                                                    name="code" required onChange={handleConfirmationChange}
                                                    className={`form-control   ${(confirmationValues.error.length > 0) ? 'error' : ''}`}
                                                    placeholder="Confirmation Code"
                                                    id="code "
                                                />
                                                {confirmationValues.error.length > 1 && (
                                                    <span className="errortext ">{confirmationValues.error}</span>
                                                )}

                                            </div>
                                        </form>

                                        <button type="button" className="btn btn-green-full"

                                            onClick={(e) => {
                                                e.preventDefault();
                                                checkConfirmation();
                                            }}>
                                            Submit
                                          </button>



                                    </>}
                                    {(codeConfirm === 'step3') ?

                                        <Message message={ConfirmMessages.SignupStep2Confirmation} /> : ''}

                                {(codeConfirm != 'step2') ?
                                    <>  <form>

                                        <div className="form-group icon-input">
                                            <label for="email" className="form-label">E-mail<span>*</span></label>
                                            <Icon className="text-green m-icon material-icons ">mail</Icon>
                                            
                                            <input
                                                type="email"
                                                name="email"
                                                className={`form-control   ${(values.error.email.length > 0) ? 'error' : ''}`}
                                                placeholder="example@gmail.com"
                                                id="email " required onChange={(e) => (handleChange(e))}
                                            />
                                            {values.error.email.length > 1 && (
                                                <span className="errortext">{values.error.email}</span>
                                            )}

                                        </div>
                                        <div className="form-group icon-input">
                                            <label for="password2" className="form-label">Password<span>*</span></label>
                                            <Icon className="text-theme m-icon material-icons">lock</Icon>
                                            <Icon className="text-light-gray m-i-icon material-icons" onClick={(e) => {

                                                e.preventDefault();
                                                togglePassword();
                                            }}> {showPass ? "visibility_off" : "visibility_on"}</Icon>
                                            
                                            <input
                                                type={showPass ? "password" : "text"}
                                                className={`form-control   ${(values.error.password.length > 0) ? 'error' : ''}`}
                                                id="password" name="password" onKeyPress={(e) => (handleLoginKeypress(e))}
                                                placeholder="Password" required onChange={(e) => (handleChange(e))}
                                            />
                                            {values.error.password.length > 1 && (
                                                <span className="errortext">{values.error.password !== "valid" && values.error.password}</span>
                                            )}
                                        </div>
                                    </form>

                                        <div className="d-flex align-item-center justify-content-center m-b-20">
                                            <button className="btn btn-link" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                gContext.toggleForgotPasswordModal();
                                                handleClose();
                                            }}>Forgot password?</button>
                                        </div>
                                        <div class="d-grid">
                                            <button className="btn btn-green btn-block" onClick={loginUser}>Login</button> </div></>:""}
                                       
                                <div className="or-divider d-none">
                                    <span className="d-none">Or continue with </span>
                                        </div>
                                <div className="m-t-20 m-b-20 p-t-20 p-b-20">
                                    
                                </div>
                                        <div className="social-icon d-none">
                                            <ul className="list-type d-flex">
                                                <li>
                                                    <a className="btn-circle google" href="#"><img src="../images/google-icon.svg" alt="google-icon" /></a>
                                                </li>
                                        <li>
                                            <FacebookLogin
                                                appId="1204734280171739"
                                                autoLoad={false}
                                                callback={responseFacebook}
                                                render={renderProps => (
                                                    <a onClick={renderProps.onClick} className="btn-circle facebook pointer"  ><img src="../images/facebook-icon.svg" alt="facebook-icon" /></a>
                                                    

                                                )}
                                            />
                                                    
                                                </li>
                                                <li>
                                                    <a className="btn-circle linked" href="#"><img src="../images/linked-icon.svg" alt="linked-icon" /></a>
                                                </li>
                                            </ul>
                                        </div>

                                        <span className="text-light-gray d-flex align-item-center justify-content-center m-b-10">Don’t have account?</span>
                                    <div class="d-grid">  <button className="btn btn-dark-outline btn-block" type="button" onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleSignUpModal();
                                    handleClose();
                                }}>Sign up</button></div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    
                
            
        </ModalStyled>
       </>
    );
};

export default ModalSignIn;
