import React, { useEffect, useState, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { GetClientPreviewProfile } from "../../utils/apiCalls";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import StarRating from "../../components/Core/Rating";
import ReactCountryFlag from "react-country-flag";
import DOMPurify from 'isomorphic-dompurify';
import { getMonthDateYear } from "../../utils/addDays";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';
import { sinceDate } from "../../utils/addDays";
import Feedback from "../../sections/ClientDashboard/Feedback";
import Icon from '@mui/material/Icon';
import { Helmet } from "react-helmet";

const imgP = "../favicond1.png";
const s3URL = process.env.REACT_APP_AWS_S3_URL;

export default function ProfileView(props) {
    const gContext = useContext(GlobalContext);
    gContext.setUserMode(props.userMode);
    const [profileInfo, setProfileInfo] = React.useState({});
    const [initialValues, setInitialValues] = React.useState({ amountSpent: 0, datecreated: '', counts: null, yesfeedbacks: null, contracts: null, industryTpes: null, code: '', avgrating: 0, logo: '', industryname: '', countryname: '', desc: '', tagline: '', company: '', city: '', state: '' });
    const [values, setValues] = React.useState({ amountSpent:0, datecreated: '', counts: null, feedbacks: null, contracts: null, industryTpes: null, code: '', avgrating: 0, logo: '', industryname: '', countryname: '', desc: '', tagline: '', company: '', city: '', state: '' });
    const subParam = props.sub;
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [feedback, setFeedbackData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [allfeedbacks, setAllFeedbacks] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);


    const [pageCount, setPageCount] = useState(1);
    const [showMore, setShowMore] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper((values.contracts ? values.contracts.active : []), page, gContext.paginationSize);
    const [completedPage, setcompletedPage] = useState(1);
    const completed = tableHelper((profileInfo.contracts ? profileInfo.contracts.completed : []), completedPage, gContext.paginationSize);


    useEffect(() => {         
        ClientPreviewInfo();      
    }, [])

    useEffect(() => {
        document.title = "Profile View | Syncuppro";
    }, []);


    function number_test(n) {
        
        if ((n - Math.floor(n)) != 0) {
            var dec_value = parseFloat(n).toFixed(1);
            return dec_value;

        }
        else {
            return n;
        }

    }

    async function ClientPreviewInfo() {
        try {

            //[{"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","email":"sathya@springinfoservices.com","title":"manager","explevelid":3,"profile":"test","dailyrate":"230.00","weeklyhrsid":3,"skillids":"13,12","compids":"42,50","resume":""}]
            const result = await GetClientPreviewProfile(subParam);
            if (result.data.clientAllLoad.responseData.code == "200") {


                let profile = JSON.parse(result.data.clientAllLoad.responseData.message);
                console.log("profile", profile);
                if (profile && profile.length > 0) {

                    if ((profile[0].deleted || profile[0].flagged ) && !props.userMode) {
                        window.location.href = "/notavailable";
                        return;
                    }
                    // title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, profileDetails: "", skillSet: "", compliance: "", resume: ''
                    let profileData = { ...values };

                    profileData.desc = profile[0].desc;
                    profileData.tagline = profile[0].tagline;
                    profileData.company = profile[0].company;
                    profileData.logo = profile[0].logo;
                    profileData.countryname = profile[0].countryname;
                    profileData.industryname = profile[0].industryname;
                    profileData.city = profile[0].city;
                    profileData.state = profile[0].state;
                    profileData.code = profile[0].code;
                    profileData.industryTpes = profile[0].industryTpes;
                    profileData.contracts = profile[0].contracts;
                    profileData.feedbacks = profile[0].feedbacks;
                    profileData.counts = profile[0].counts;
                    profileData.datecreated = profile[0].datecreated;
                    profileData.amountSpent = profile[0].amountSpent;

                    setAllFeedbacks(profile[0].feedbacks);
                    setFeedbacks(profile[0].feedbacks.slice(0, (profile[0].feedbacks.length > (pageCount * 3)) ? (pageCount * 3) : profile[0].feedbacks.length));
                    setShowMore(profile[0].feedbacks.length > (pageCount * 3));


                    if (profile[0].avgrating == null) {
                        profileData.avgrating = 0;
                    }
                    else if (profile[0].avgrating !== null) {

                        profileData.avgrating = profile[0].avgrating;

                    }
                    setInitialValues(profileData);
                    setValues(profileData);
                    setProfileInfo(profile[0]);

                } else {

                    //if (subParam)
                    //    window.location.href = "/notavailable";

                }
            }
            setLoaded(true);
            //gContext.setLoaderModalVisible(false);
        } catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);

        }

    }

    function toggleModal(feedback) {
        setFeedbackData(feedback);

        //setRead(true);
        setShowModal(!showModal);
    }

    function LoadMore() {

        setPageCount(pageCount + 1);
    }




    useEffect(() => {


        if (pageCount > 1) {
            let data = allfeedbacks;
            setFeedbacks(data.slice(0, (data.length > (pageCount * 3)) ? (pageCount * 3) : data.length));

            setShowMore(data.length > (pageCount * 3));
        }

    }, [pageCount]);

    return (
        <>
            {loaded &&

                <>
                <Helmet>
                    <title>{(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"}</title>
                    <meta name="title" content={(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"} />
                    <meta name="description" content={values.desc?.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc?.replace(/(<([^>]+)>)/ig, '') : values.desc?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />
                    <meta name="og:title" content={(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"} />
                    <meta name="og:description" content={values.desc?.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc?.replace(/(<([^>]+)>)/ig, '') : values.desc?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />
                    <meta name="twitter:title" content={(values.company) ? "SYNCUPPRO | " + values.company : "SYNCUPPRO"} />
                    <meta name="twitter:description" content={values.desc?.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc?.replace(/(<([^>]+)>)/ig, '') : values.desc?.replace(/(<([^>]+)>)/ig, '').substring(0, 100)} />

                </Helmet>
                    <div className="page-wrapper">

                        <div className="section">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-3 col-md-12 col-sm-12">

                                        <div className="card profile-details">
                                            <div className="profile-img">
                                            <img src={(values.logo && values.logo != "" && values.logo !="null") ? s3URL + values.logo :"../images/user-default.png"} alt=" " />
                                            </div>

                                            <h4 className="client-name"> {values.company}</h4>

                                            <span className="company-tag">{values.tagline}</span>

                                            <div className="d-flex flex-column align-item-center justify-content-center">
                                                <div className="month-data text-light-gray text-center m-b-0">
                                                    <span>Active since <strong> <span className="text-black"> {(values.datecreated) ? sinceDate(values.datecreated, new Date()).replace('ago', '') : ''}</span></strong></span>
                                                </div>

                                                <div className="ratings m-t-0">
                                                    {(values.avgrating != 0) ? <>

                                                        <StarRating rating={values.avgrating} isSelectable="false" />
                                                        <span className="m-t-5 m-l-5" >
                                                            {number_test(values.avgrating)}/5

                                                        </span>
                                                </> : ''} </div>

                                            <div className="info-meta m-t-10">

                                                <Icon className=" pointer dropdown-toggle text-theme m-icon material-icons  m-r-10 " data-bs-toggle="dropdown" aria-expanded="false">share</Icon><span className="pointer dropdown-toggle  " data-bs-toggle="dropdown" aria-expanded="false" ><b>Share</b> </span>


                                                <ul className="dropdown-menu dropdown-menu-end share-dropdown">
                                                    <li> <FacebookShareButton
                                                        url={window.location.href}


                                                    >
                                                        <a className="dropdown-item d-flex align-item-center" target='_blank' href={window.location.href}><img className="m-r-10" src="../images/social-icon-sm/facebook.svg" />Facebook</a>

                                                    </FacebookShareButton></li> 
                                                    <li>   <TwitterShareButton title={(values.company) ? values.company : ''} url={window.location.href}
                                                    >
                                                        <a className="dropdown-item d-flex align-item-center   " href={window.location.href}><div className=" btn    btn-circle bg-black m-r-10"><img className="  w-half " src="../images/twitter.svg" /></div>X</a>
                                                    </TwitterShareButton></li>
                                                    <li>   <LinkedinShareButton title={(values.company) ? values.company : ''} summary={values.desc ? (values.desc.replace(/(<([^>]+)>)/ig, '').length < 100 ? values.desc.replace(/(<([^>]+)>)/ig, '') : values.desc.replace(/(<([^>]+)>)/ig, '').substring(0, 100)):""} url={window.location.href} >

                                                        <a className="dropdown-item d-flex align-item-center" href={window.location.href} target='_blank' ><img className="m-r-10" src="../images/social-icon-sm/linked.svg" />Linked</a>

                                                    </LinkedinShareButton></li>
                                                   


                                                </ul>
                                            </div>
                                        </div>
                                            <div className="divider-line-20"></div>
                                             
                                            <div className="meta-inline">
                                                <span>Location :</span>

                                                <div className=" info-meta flag w-150" data-toggle="tooltip" title={values.city + ' ' + values.state + ' ' + values.countryname} >
                                                <span className="text-theme m-icon material-icons m-r-10">location_on</span><span className=" text-capi one-line text-black m-r-10"><strong>       <span className={`    ${(values.city != null && values.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(values.city) ? values.city.toLowerCase() : "" + '   '}{" "}</span>
                                                    <span className={`    ${(values.state != null && values.state.length > 2) ? 'text-capi' : 'text-upper'}`}>{(values.state) ? values.state.toLowerCase() : ""}</span>{' ' + values.code}   </strong></span>
                                                    <ReactCountryFlag
                                                        countryCode={values.code}
                                                        svg
                                                        style={{
                                                            width: '1.5em',
                                                            height: '.7em',
                                                        }}
                                                        title={values.code}
                                                    />

                                                </div>
                                            </div>


                                            <div className="divider-line-20"></div>

                                        {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>  <div className="meta-inline ">
                                            <span>Amount Spent:</span>
                                            <span></span> <strong>${values.amountSpent ? new Intl.NumberFormat('en-US').format(parseInt(values.amountSpent)) : "0"}</strong>
                                        </div>
                                            <div className="divider-line-20 "></div> </>}
                                        </div>

                                    </div>

                                    <div className="col-lg-9 col-md-12 col-sm-12">
                                        <div className="card panel profile-details-desc">

                                            <div className="card-header">
                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link active" data-bs-toggle="tab" href="#company">Company</a>
                                                    </li>
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link" data-bs-toggle="tab" href="#company-jobs">Jobs</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active" id="company">
                                                        <div className="profile-details-desc-title">

                                                            <h6>About</h6>

                                                            <div className="text-light-gray f-16" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(values.desc) }} ></div>


                                                        </div>

                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <h6>Industry types</h6>

                                                            {(values && values.industryTpes) && values.industryTpes.map((data, index) => {
                                                                const { name } = data;
                                                                return (
                                                                    <span key={index} className="badge industry-types"> {name}</span>
                                                                )
                                                            })}

                                                        </div>
                                                        {<Feedback jobsList={feedbacks} />}

                                                        {(showMore) && <div className="text-center ">



                                                            <div className="load-more mt-5">
                                                                <button type="button" className="btn btn-orange-outline d-flex align-item-center" onClick={() => { LoadMore(); }}>
                                                                    <Icon className="m-icon material-icons m-r-10  ">refresh</Icon>Load more</button>
                                                            </div>

                                                        </div>}

                                                    </div>

                                                    <div className="tab-pane" id="company-jobs">
                                                        <div className="profile-jobs-block">

                                                            <div className="d-flex">
                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>
                                                                    <div className="col-lg-3 col-md-3 col-sm-6">
                                                                        <div className="icon-wrapper">
                                                                            <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                            <div className="d-flex flex-column">
                                                                                <span className="m-b-10 text-light-gray">Active jobs</span>
                                                                                <b>  {(values && values.contracts) ? values.contracts.active.length == 0 ? '-' : values.contracts.active.length : '-'}</b>
                                                                            </div>
                                                                        </div>
                                                                    </div></>}

                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="icon-wrapper">
                                                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="m-b-10 text-light-gray">Completed Jobs</span>
                                                                            <b>   {(values && values.counts) ? values.counts.jobs.completed.length == 0 ? '-' : values.counts.jobs.completed : '-'}</b>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-3 col-md-3 col-sm-6">
                                                                    <div className="icon-wrapper">
                                                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                                                        <div className="d-flex flex-column">
                                                                            <span className="m-b-10 text-light-gray">Experts hired</span>
                                                                            <b> {values.counts == null ? '' : values.counts.contracts.hired == 0 ? '-' : values.counts.contracts.hired}</b>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="accordion" id="accordionjobs">
                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' :
                                                                    ((values.contracts) && values.contracts.active.length > 0) ?
                                                                        <>     <div className="accordion-wrapper m-b-30">

                                                                            <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-active-job" aria-expanded="false"><h5>Active Jobs ({(values.contracts) ? values.contracts.active.length : 0})</h5>
                                                                                <span className="m-icon material-icons indicator show">expand_more</span>
                                                                                <span className="m-icon material-icons indicator hide">expand_less</span>
                                                                            </a>

                                                                            <div id="accordion-active-job" className="collapse" data-bs-parent="#accordionjobs">
                                                                                <div className="accordion-body card panel p-0">

                                                                                    <div className="card-body">
                                                                                        <div className="tab-content">
                                                                                            <div className="table-responsive">
                                                                                                <table className="table table-striped">
                                                                                                    <thead>
                                                                                                        <tr>
                                                                                                            <th className="min-lg-cell">Job Title</th>
                                                                                                            <th className="min-lg-cell">Type</th>
                                                                                                            <th className="min-lg-cell">Expert</th>
                                                                                                            <th className="min-md-cell">Duration</th>
                                                                                                        </tr>
                                                                                                    </thead>
                                                                                                    <tbody>
                                                                                                        {(values && values.contracts) && slice.map((data, index) => {
                                                                                                            const { guid, name, jobtype, firstname, lastname, startdate, duration, expertavatar, sub } = data;
                                                                                                            return (
                                                                                                                <tr>


                                                                                                                    <td>
                                                                                                                        <span className="d-flex justify-content-left align-items-left"
                                                                                                                         onClick={(e) => { window.open("/job/" + guid, "_blank") }}>
                                                                                                                            <span className="title one-line pointer" data-toggle="tooltip" style={{ fontSize: "14px" }}
                                                                                                                                title={name} >{name}</span>
                                                                                                                              <a href="#!" className=" m-l-5 text-theme m-icon material-icons">open_in_new</a>
                                                                                                                           
                                                                                                                        </span>
                                                                                                                    </td>



                                                                                                                    <td className="d-none">
                                                                                                                        <div className="d-flex align-items-left justify-content-left pointer" onClick={(e) => { window.open("/job/" + guid, "_blank") }}>
                                                                                                                            <span className="m-r-5" >  {name}</span><a href="#!" className="text-theme m-icon material-icons">open_in_new</a>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td className="text-capi ">
                                                                                                                        {jobtype ? (jobtype).toLowerCase() : ''}
                                                                                                                    </td>
                                                                                                                    <td>


                                                                                                                        <div className="d-flex align-items-center justify-content-center">
                                                                                                                            <div className="info-meta company pointer" onClick={(e) => { window.open("/expert/" + sub, "_blank") }}>
                                                                                                                                <img className="m-r-10 " src={s3URL + expertavatar} alt=" " />
                                                                                                                                <span className="one-line text-black "> {firstname + ' ' + lastname}</span>
                                                                                                                                <a href="#!" className=" m-l-5 text-theme m-icon material-icons">open_in_new</a>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </td>
                                                                                                                    <td>
                                                                                                                        {(startdate) ? getMonthDateYear(startdate) + ' - Present' : ''}
                                                                                                                    </td>
                                                                                                                </tr>



                                                                                                            )
                                                                                                        })}</tbody>
                                                                                                </table>

                                                                                            </div>  {(loaded && values.contracts && values.contracts.active.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                            </div>
                                                                        </div> </> : ''}

                                                                {(profileInfo.contracts && profileInfo.contracts.completed.length > 0) ? <>
                                                                    <div className="accordion-wrapper">

                                                                        <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-completed-jobs" aria-expanded="false"><h5>Completed Jobs ({(values.contracts) ? values.contracts.completed.length : 0})</h5>
                                                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                                                        </a>

                                                                        <div id="accordion-completed-jobs" className="collapse" data-bs-parent="#accordionjobs">
                                                                            <div className="accordion-body card panel p-0">

                                                                                <div className="card-body">
                                                                                    <div className="tab-content">
                                                                                        <div className="table-responsive">
                                                                                            <table className="table table-striped">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th className="min-lg-cell">Job Title</th>
                                                                                                        <th className="min-lg-cell">Type</th>
                                                                                                        <th className="min-lg-cell">Expert</th>
                                                                                                        <th className="min-lg-cell">Duration</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {(values && values.contracts) && completed.slice.map((data, index) => {
                                                                                                        const { guid, name, jobtype, firstname, lastname, duration, sub, expertavatar } = data;
                                                                                                        return (<tr key={index}>


                                                                                                            <td>
                                                                                                                <span className="d-flex justify-content-left align-items-left" onClick={(e) => { window.open("/job/" + guid, "_blank") }}>
                                                                                                                    <span className="title one-line pointer" data-toggle="tooltip" style={{ fontSize: "14px" }}
                                                                                                                        title={name} >{name}</span>
                                                                                                                    <a href="#!" className="m-l-5 text-theme m-icon material-icons">open_in_new</a>
                                                                                                                </span>
                                                                                                            </td>




                                                                                                            <td className="d-none">

                                                                                                                <div className="d-flex align-items-left justify-content-left pointer" onClick={(e) => { window.open("/job/" + guid, "_blank") }}>
                                                                                                                    <span className="one-line text-black ">  {name}</span><a href="#!" className="m-l-5 text-theme m-icon material-icons">open_in_new</a>
                                                                                                                </div>

                                                                                                            </td>
                                                                                                            <td className="text-capi ">
                                                                                                                {(jobtype).toLowerCase()}
                                                                                                            </td>
                                                                                                            <td>
                                                                                                                <div className="d-flex align-items-center justify-content-center pointer">
                                                                                                                    <div className="info-meta company pointer" onClick={(e) => { window.open("/expert/" + sub, "_blank") }}>
                                                                                                                        <img className="m-r-10  " src={s3URL + expertavatar} alt="" />
                                                                                                                        <span className="one-line text-black "> {firstname + ' ' + lastname}</span>
                                                                                                                        <a href="#!" className="m-l-5 text-theme m-icon material-icons">open_in_new</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td>    {(duration.years <= 0) ? '' : duration.years + ' Years'}
                                                                                                                {(duration.months <= 0) ? '' : duration.months + ' Months'}</td>
                                                                                                        </tr>)
                                                                                                    })}



                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>   {(loaded && values.contracts && values.contracts.completed.length > 5) && <TablePagination range={completed.range} slice={completed.slice} setPage={setcompletedPage} page={completedPage} />}
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div> </> : ''}

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>


                    <div
                        className={`jobDetails-section bg-default-1 pt-28 pt-lg-27 pb-xl-25 pb-12   d-none ${(props.sub) ? '' : 'd-none dashboard-main-container'}`}
                        id="dashboard-body"
                    >
                        <div className="container">
                            <div className="mb-15 mb-lg-23">
                                <div className="row ">
                                    {/* <!-- Company Profile --> */}
                                    <div className="col-12 col-xl-11 col-lg-11">
                                        <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-3 border-bottom border-width-1 border-default-color light-mode-texts">
                                            <div className="bg-white rounded-4 pt-11 shadow-9">

                                                <div className="row mb-0">
                                                    <div className="col-lg-6">
                                                        <div className="d-xs-flex align-items-center pl-xs-12 mb-8 text-center text-xs-left">

                                                            <a className="mr-xs-7 mb-5 mb-xs-11">


                                                                <img
                                                                    className="square-72 rounded-6"
                                                                    src={s3URL + values.logo}
                                                                    alt=""
                                                                />
                                                            </a>

                                                            <div className="">
                                                                <div className="pl-1">


                                                                    <a className="font-size-6 text-black-2 font-weight-semibold">
                                                                        {values.company}
                                                                    </a>


                                                                </div>
                                                                <div>
                                                                    <span className=" pb-1 mb-0 text-gray font-size-4 pl-1">
                                                                        {values.tagline}
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    <a className="  font-size-4 font-weight-semibold text-black-2 ">
                                                                        <span className="mr-3"> <ReactCountryFlag
                                                                            countryCode={values.code}
                                                                            svg
                                                                            style={{
                                                                                width: '2em',
                                                                                height: '1em',
                                                                            }}
                                                                            title={values.code}
                                                                        />
                                                                        </span>
                                                                        {values.city} {values.state} {values.countryname}
                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    {(values.avgrating != 0) ? <>
                                                                        <a className=" ">

                                                                            <StarRating rating={values.avgrating} />
                                                                            &nbsp;&nbsp;<span className="font-size-2  text-green-2 font-weight-semibold mr-5">
                                                                                {number_test(values.avgrating)}/5

                                                                            </span>

                                                                        </a></> : ''} </div><br />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className="font-size-6 text-black-2 text-xs-right font-weight-semibold mr-10 mt-2">
                                                            <span className="font-size-3"> Active since&nbsp;<a className=" text-gray font-size-3 ">
                                                                <i className="fa fa-clock mr-2 font-weight-bold"></i>{" "}

                                                                {(values.datecreated) ? sinceDate(values.datecreated, new Date()).replace('ago', '') : ''}

                                                            </a></span> </div>
                                                    </div>
                                                </div>
                                                {/* <!-- Tab Section Start --> */}
                                                <Tab.Container id="left-tabs-example" defaultActiveKey="company">
                                                    <Nav
                                                        className="nav border-bottom border-mercury pl-12"
                                                        role="tablist"
                                                    >
                                                        <li className="tab-menu-items nav-item pr-12">
                                                            <Nav.Link
                                                                eventKey="company"
                                                                className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0 pointer"
                                                            >
                                                                Company
                                                            </Nav.Link>
                                                        </li>

                                                        <li className="tab-menu-items nav-item pr-12 ">
                                                            <Nav.Link
                                                                eventKey="jobs"
                                                                className="text-uppercase font-size-3 font-weight-bold text-default-color py-3 px-0 pointer"
                                                            >
                                                                Jobs
                                                            </Nav.Link>
                                                        </li>
                                                    </Nav>
                                                    {/* <!-- Tab Content --> */}
                                                    <Tab.Content className="pl-12 pt-10 pb-7 pr-12 ">
                                                        <Tab.Pane eventKey="company">
                                                            {/* <!-- Middle Body Start --> */}

                                                            {/* <!-- Middle Body End --> */}
                                                            {/* <!-- Excerpt Start --> */}



                                                            <div className="pr-xl-0 pr-xxl-0 p-5 px-xs-0 pb-5">
                                                                <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                    About
                                                                </h4>
                                                                <div className="pt-5 ">
                                                                    <div className="font-size-4 text-black-2 mb-7">

                                                                    </div>

                                                                </div>


                                                                <div className="border-top pr-xl-0 pr-xxl-0 p-5 px-xs-0  pt-7 pb-5">
                                                                    <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                        Industry Types
                                                                    </h4>
                                                                    <ul className="list-unstyled d-flex align-items-center  flex-wrap">
                                                                        {(values && values.industryTpes) && values.industryTpes.map((data, index) => {
                                                                            const { name } = data;
                                                                            return (<li key={index}>
                                                                                <li className="bg-red-opacity-2 mr-3 h-px-33 text-center flex-all-center rounded-15 px-5 pl-2  font-size-3 text-black-2 mt-2">
                                                                                    {name}
                                                                                </li>

                                                                            </li>)
                                                                        })}
                                                                    </ul>
                                                                </div>

                                                                {(profileInfo.feedbacks) && (profileInfo.feedbacks.length > 0) ? <>
                                                                    {<Feedback jobsList={feedbacks} />}


                                                                </> : ''}

                                                            </div>
                                                            <br />
                                                            {/* <!-- Excerpt End --> */}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="jobs">
                                                            {/* <!-- Middle Body Start --> */}
                                                            <div className=" row">
                                                                {/* <!-- Single Widgets Start --> */}
                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' : <>
                                                                    <div className="col-12 col-lg-4 col-md-4 col-xs-6">



                                                                        <div className="mb-8">
                                                                            <p className="font-size-4">Active Jobs</p>

                                                                            <h5 className="font-size-4 font-weight-semibold">

                                                                                {(values && values.contracts) ? values.contracts.active.length == 0 ? '-' : values.contracts.active.length : '-'}

                                                                            </h5>


                                                                        </div>
                                                                    </div></>}
                                                                <div className="col-12 col-lg-4 col-md-4 col-xs-6">

                                                                    <div className="mb-8">
                                                                        <p className="font-size-4">Completed Jobs</p>
                                                                        <h5 className="font-size-4 font-weight-semibold">

                                                                            {(values && values.counts) ? values.counts.jobs.completed.length == 0 ? '-' : values.counts.jobs.completed : '-'}

                                                                        </h5>
                                                                    </div>
                                                                </div>

                                                                {/* <!-- Single Widgets End --> */}
                                                                {/* <!-- Single Widgets Start --> */}
                                                                <div className="col-12 col-lg-4 col-md-4 col-xs-6" visibility="hidden">
                                                                    <div className="mb-8">
                                                                        <p className="font-size-4">Experts hired</p>
                                                                        <h5 className="font-size-4 font-weight-semibold">
                                                                            {values.counts == null ? '' : values.counts.contracts.hired == 0 ? '-' : values.counts.contracts.hired}
                                                                        </h5>
                                                                    </div>
                                                                </div>
                                                                {/* <!-- Single Widgets End --> */}
                                                                {/* <!-- Single Widgets Start --> */}


                                                                {/* <!-- Single Widgets End --> */}
                                                            </div>
                                                            {/* <!-- Middle Body End --> */}
                                                            {/* <!-- Excerpt Start --> */}

                                                            {/* <!-- Excerpt End --> */}
                                                        </Tab.Pane>
                                                        <Tab.Pane eventKey="jobs">
                                                            {/* <!-- Middle Body Start --> */}
                                                            <div className=" ">


                                                                {gContext.userInfo.userType != '2' && gContext.userInfo.userType != '1' ? '' :
                                                                    ((values.contracts) && values.contracts.active.length > 0) ?
                                                                        <>
                                                                            <div className="row mb-11 align-items-center">
                                                                                <div className="col-lg-6 mb-lg-0 mb-2">
                                                                                    <h3 className="font-size-6 mb-0">Active Jobs ({(values.contracts) ? values.contracts.active.length : 0})</h3>
                                                                                </div>
                                                                                <div className="col-lg-6">

                                                                                </div>
                                                                            </div>
                                                                            <div className="bg-white  pt-5   pb-9 px-0">
                                                                                <div className="table-responsive ">
                                                                                    <table className="table table-striped">
                                                                                        <thead>
                                                                                            <tr className="bg-gray-opacity-2 border border-color-2  ">
                                                                                                <th
                                                                                                    scope="col"
                                                                                                    className="pl-4 border-0 font-size-4 font-weight-bold "
                                                                                                >
                                                                                                    Title
                                                                                                </th>
                                                                                                <th
                                                                                                    scope="col"
                                                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                                                >
                                                                                                    Type
                                                                                                </th>
                                                                                                <th
                                                                                                    scope="col"
                                                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                                                >
                                                                                                    Expert
                                                                                                </th>
                                                                                                <th
                                                                                                    scope="col"
                                                                                                    className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                                                >
                                                                                                    Duration
                                                                                                </th>

                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {(values && values.contracts) && values.contracts.active.map((data, index) => {
                                                                                                const { guid, name, jobtype, firstname, lastname, startdate, duration, expertavatar, sub } = data;
                                                                                                return (
                                                                                                    <tr className="border border-color-2">
                                                                                                        <th
                                                                                                            scope="row"
                                                                                                            className="pl-6 border-0 py-7 min-width-px-235"
                                                                                                        >
                                                                                                            <div className="">

                                                                                                                <a className="font-size-4 mb-0 font-weight-semibold text-black-2" href={"/job/" + guid}>
                                                                                                                    {name}
                                                                                                                </a><i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-1"></i>

                                                                                                            </div>
                                                                                                        </th>
                                                                                                        <td className="table-y-middle py-7 min-width-px-135">
                                                                                                            <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                                                                {jobtype}
                                                                                                            </h3>
                                                                                                        </td>
                                                                                                        <td className="table-y-middle py-7 min-width-px-125">

                                                                                                            <a className="media min-width-px-235 align-items-center " target="_blank" rel="noopener noreferrer" href={"/expert/" + sub} >
                                                                                                                <div className="circle-36 mr-6">
                                                                                                                    <img src={s3URL + expertavatar} alt="" className=" circle-36 w-100" />
                                                                                                                </div>
                                                                                                                <h3 className="font-size-4 mb-0 font-weight-normal text-gray "   >
                                                                                                                    {firstname + ' ' + lastname}
                                                                                                                </h3><i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-1"></i>
                                                                                                            </a>


                                                                                                        </td>
                                                                                                        <td className="table-y-middle py-7 min-width-px-155">
                                                                                                            <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">

                                                                                                                {(startdate) ? getMonthDateYear(startdate) + ' - Present' : ''}</h3>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                )
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div></> : ''}

                                                                {(profileInfo.contracts && profileInfo.contracts.completed.length > 0) ? <>
                                                                    <div className="row mb-11 align-items-center">
                                                                        <div className="col-lg-6 mb-lg-0 mb-2">
                                                                            <h3 className="font-size-6 mb-0">Completed Jobs ({(values.contracts) ? values.contracts.completed.length : 0})</h3>
                                                                        </div>
                                                                        <div className="col-lg-6">

                                                                        </div>
                                                                    </div>
                                                                    <div className="bg-white  pt-5   pb-9 px-0">
                                                                        <div className="table-responsive ">
                                                                            <table className="table table-striped">
                                                                                <thead>
                                                                                    <tr className="bg-gray-opacity-2  border border-color-2 ">




                                                                                        <th
                                                                                            scope="col"
                                                                                            className="pl-5 border-0 font-size-4 font-weight-bold "
                                                                                        >
                                                                                            Title
                                                                                        </th>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                                        >
                                                                                            Type
                                                                                        </th>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                                        >
                                                                                            Expert
                                                                                        </th>
                                                                                        <th
                                                                                            scope="col"
                                                                                            className="pl-4 border-0 font-size-4 font-weight-bold"
                                                                                        >
                                                                                            Duration
                                                                                        </th>


                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {(values && values.contracts) && values.contracts.completed.map((data, index) => {
                                                                                        const { guid, name, jobtype, firstname, lastname, duration, sub, expertavatar } = data;
                                                                                        return (
                                                                                            <tr className="border border-color-2">
                                                                                                <th
                                                                                                    scope="row"
                                                                                                    className="pl-6 border-0 py-7 min-width-px-235"
                                                                                                >


                                                                                                    <div className="" key={index}>

                                                                                                        <a className="font-size-4 mb-0 font-weight-semibold text-black-2" href={"/job/" + guid}>
                                                                                                            {name}
                                                                                                        </a><i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-1"></i>

                                                                                                    </div>
                                                                                                </th>
                                                                                                <td className="table-y-middle py-7 min-width-px-135">
                                                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                                                        {jobtype}
                                                                                                    </h3>
                                                                                                </td>
                                                                                                <td className="table-y-middle py-7 min-width-px-125">
                                                                                                    <a className="media min-width-px-235 align-items-center " target="_blank" rel="noopener noreferrer" href={"/expert/" + sub} >
                                                                                                        <div className="circle-36 mr-6">
                                                                                                            <img src={s3URL + expertavatar} alt="" className=" circle-36 w-100" />
                                                                                                        </div>
                                                                                                        <h3 className="font-size-4 mb-0 font-weight-normal text-gray "   >
                                                                                                            {firstname + ' ' + lastname}
                                                                                                        </h3><i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-1"></i>
                                                                                                    </a>

                                                                                                </td>
                                                                                                <td className="table-y-middle py-7 min-width-px-155">
                                                                                                    <h3 className="font-size-4 font-weight-normal text-black-2 mb-0">
                                                                                                        {(duration.years <= 0) ? '' : duration.years + ' Years'}
                                                                                                        {(duration.months <= 0) ? '' : duration.months + ' Months'}
                                                                                                    </h3>
                                                                                                </td>


                                                                                            </tr>)
                                                                                    })}

                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div></> : ''}


                                                                {/* <!-- Middle Body End --> */}
                                                                {/* <!-- Excerpt Start --> */}

                                                                {/* <!-- Excerpt End --> */}

                                                            </div>

                                                        </Tab.Pane>
                                                    </Tab.Content>
                                                    {/* <!-- Tab Content End --> */}
                                                    {/* <!-- Tab Section End --> */}
                                                </Tab.Container>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- Company Profile End --> */}
                                    {/* <!-- Sidebar --> */}

                                    {/* <!-- end Sidebar --> */}
                                </div></div></div></div>
                </>}

            {!loaded &&

                <>
                    <div className="page-wrapper">

                        <div className="section">

                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-lg-3 col-md-5 col-sm-12">

                                        <div className="card profile-details">
                                            <div className="profile-img">
                                                <img className="movingLoader btn-circle w-150" />
                                            </div>



                                            <div className="d-flex flex-column align-item-center justify-content-center">
                                                <div className="month-data text-light-gray text-center m-b-0 p-5 movingLoader w-150">
                                                    <span>  &nbsp; </span>
                                                </div>

                                                <h4 className="client-name movingLoader w-100 m-t-10 align-item-center justify-content-center  "> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>

                                                <span className="company-tag movingLoader align-item-center justify-content-center w-100">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                            </div>
                                            <div className="divider-line-20"></div>

                                            <div className="meta-inline movingLoader w-full">
                                                <span>&nbsp;</span>

                                            </div>


                                            <div className="divider-line-20"></div>
                                            <div className="meta-inline movingLoader w-full">
                                                <span>&nbsp;</span>



                                            </div>


                                        </div>

                                    </div>

                                    <div className="col-lg-9 col-md-7 col-sm-12">
                                        <div className="card panel profile-details-desc">

                                            <div className="card-header">
                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className="nav-link active movingLoader w-100" data-bs-toggle="tab" href="#company">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </a>
                                                    </li>
                                                    <li className="nav-item p-relative">
                                                        <a className="nav-link movingLoader w-100" data-bs-toggle="tab" > &nbsp;&nbsp;&nbsp;</a>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active w-full" id="company">
                                                        <div className="profile-details-desc-title movingLoader w-full">

                                                            <h6 className=" movingLoader w-100">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </h6>

                                                            <div className="d-flex justify-content-between m-b-0 w-full  movingLoader">
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                                                <h6> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h6>
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>
                                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;   &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;    </p>

                                                            </div>


                                                        </div>

                                                        <div className="divider-line-20"></div>

                                                        <div className="company-meta">
                                                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                            </div>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                        </div>
                                                        <div className="company-meta">
                                                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                            </div>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                        </div>
                                                        <div className="company-meta">
                                                            <div className="d-flex justify-content-between m-b-20 w-200  movingLoader">
                                                                <h4> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  </h4>
                                                            </div>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                            <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>


                                                        </div>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>

                    </div>


                </>
            }
        </>
    );
};