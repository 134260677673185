import React, { useState, useContext, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { Select } from "../../components/Core";
import { GetCountry, GetExpertDegrees } from "../../utils/GetAPIUtils";
import { isInvalid } from "../../utils/helperFn";
import { GetExpertEducation, UpdateExpertEducation, DeleteExpertRow } from "../../utils/apiCalls";
import CustomDatePicker from "../../components/Core/CustomDatePicker"
import validator from 'validator';
import { Collapse } from "react-bootstrap";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
const imgLoader = "../assets/image/svg/spinning-circles.svg";

const utils = () => {
   
}

const Education = (props) => {

    const gContext = useContext(GlobalContext);
    const [storedEdu, setStoredEdu] = useState([]);
    const [rowCount, setRowCount] = useState(0);
  //  const [gContext.countriesList, setgContext.countriesList] = useState([]);
    const [rowsEducation, setRowsEducation] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [degrees, setDegree] = useState([]);
    const [visibleLoader, setVisibleLoader] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(false); 
    const [loaded, setLoaded] = useState(false);
   
 
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);
    

    function prevPage() {
        gContext.setCurrentPage('12');
       // gContext.setLoaderModalVisible(true); 
    }

    function nextPage() {
        gContext.setCurrentPage('13');
      //  gContext.setLoaderModalVisible(true); 
    }
  

    async function getEducationInfo() {
        setLoaded(false);
        try {

            const result = await GetExpertEducation(gContext.userInfo.userID);
             
            if (result.data.expertEduLoad.responseData.code == "200") {

                BindData(JSON.parse(result.data.expertEduLoad.responseData.message));
                setStoredEdu(JSON.parse(result.data.expertEduLoad.responseData.message));
                gContext.setLoaderModalVisible(false); 
            }
            setTimeout(() => {
                
                setLoaded(true);

            }, 2000);
        }
        catch (e) {
            console.log(e);
            setLoaded(true);

        }

    }
    function setLoad(status, index) {
        const rows = [...rowsEducation];
        rows[index]['load'] = status;
        setRowsEducation(rows);
    }
    function spliceRow(index) {
        const rows = [...rowsEducation];
        rows.splice(index, 1);
        setRowsEducation(rows);
    }
    /*For edu table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Education delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
    }
    /*For edu table row delete*/
    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);
            setLoad(true, index);
            try {
                gContext.setLoaderModalVisible(true);
                const result = await DeleteExpertRow(id, 'edu', (storedEdu.length === 1) ? 0 : null);
                
                if (result.data.expertRowDelete.responseData.code == "200") {
                    getEducationInfo();
                }
                else {
                    setLoad(false, index);
                }
            }
            catch (e) {
                console.log(e);
                setLoad(false, index);gContext.setLoaderModalVisible(false);
            }
        } 
        else {
            setMessage(false);
            spliceRow(index);
        }
        gContext.setLoaderModalVisible(false);
    }
    function BindData(data) {
        //"[{\"sub\":\"f180bc26-27fa-4a1b-9b75-fee8dd832db0\",\"degreeid\":2,\"major\":\"\",\"school\":\"\",\"countryid\":61,\"startdate\":\"2010-06-01T00:00:00.000Z\",\"enddate\":\"2012-05-01T00:00:00.000Z\"}]"
        const rowsInput = []
        
        try {
            setRowCount(data.length);
            if (data.length > 0) {
                data.map((row) => {

                    
                    let degree = degrees.filter(item => item["value"] == row.degreeid);
                    let country = gContext.countriesList.filter(item => item["value"] == row.countryid);
                  
                    rowsInput.push({
                        load: false, id: row.id, expertId: gContext.userInfo.userID,
                        degree: (degree.length > 0) ? degree[0] : null, major: row.major, school: row.school, country: (country.length > 0) ? country[0] : null, fromDate: new Date(row.startdate.toString().replace("T00:00:00.000Z", "T08:00:00.000Z")), toDate: row.enddate? new Date(row.enddate.toString().replace("T00:00:00.000Z", "T08:00:00.000Z")):"", error: { degree: '', major: '', school: '', country: '', fromDate: '', toDate: '' }
                    });
                });
                setRowsEducation(rowsInput);
             
                 
            }
            else {
                
                setRowsEducation([]);
            }
           
        } catch (e) {
            console.log(e);
        }
       
    }

    function checkValidity() {
        let inValid = false;
        rowsEducation.map((values, index) => {

            let { error, ...rest } = values;

            Object.values(rest).forEach(val => {
                if (val === '' || val === null) {
                    inValid = true;
                }
            });

            error.degree = (rest.degree) ? (isInvalid(rest.degree) ? "Invalid format" : error.degree)   : "Degree is required";
            error.major = validator.isEmpty(rest.major) ? "Major is required" : (isInvalid(rest.major) ? "Invalid format" : error.major);
            error.school = validator.isEmpty(rest.school) ? "School is required" : (isInvalid(rest.school) ? "Invalid format" : error.school);
            error.country = (rest.country) ? error.country : "Country is required";
            error.fromDate = (rest.fromDate) ? error.fromDate : "Date required";
            error.toDate = (rest.toDate) ? error.toDate : "Date required";


            const rows = [...rowsEducation];
            rows[index][error] = error;
            setRowsEducation(rows);

            Object.values(error).forEach(val => {
                if (val.length > 0) {
                    inValid = true;
                }
            });
        });

        if (inValid)
            return false;
        else
            return true;

    }
    function checkRowValidity(index) {
        let inValid = false;

       
        let { error, ...rest } = rowsEducation[index];

        //Object.values(rest).forEach(val => {
        //    if (val === '' || val === null) {
        //        inValid = true;
        //    }
        //});

        error.degree = (rest.degree) ? "" : "Degree is required";

        error.major = validator.isEmpty(rest.major) ? "Major is required" : (isInvalid(rest.major) ? "Invalid format" : error.major);
        error.school = validator.isEmpty(rest.school) ? "School is required" : (isInvalid(rest.school) ? "Invalid format" : error.school);
        error.country = (rest.country) ? error.country : "Country is required";
        error.fromDate = (rest.fromDate) ? error.fromDate : "Date required";
        error.toDate = (rest.toDate) ? error.toDate : "Date required";


        const rows = [...rowsEducation];
        rows[index][error] = error;
        setRowsEducation(rows);

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });


        if (inValid)
            return false;
        else
            return true;

    }
    async function saveEducation(index) {
        
        setMessage(false);
        setErrorMessage('');
        if (checkRowValidity(index)) {
            const rowsInput = [...rowsEducation];
            rowsInput[index]['load'] = true;
            setRowsEducation(rowsInput);
            
            gContext.setLoaderModalVisible(true);
            try {


                const result = await UpdateExpertEducation(rowsEducation[index], rowCount);
               
                if (result.data.expertEduUpdate.responseData.code == "200") {
                    //gContext.setCurrentPage("5");
                    //gContext.setVisibleProfileMessage(true);

                    setMessage(true);
                    getEducationInfo();
                }
                else {
                    setErrorMessage(JSON.parse(result.data.expertEduUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                 gContext.setLoaderModalVisible(false);
                setErrorMessage("Server error");
               
            }

            gContext.setLoaderModalVisible(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'

            });
        }
        else { setMessage(false);
            setErrorMessage("Required fields are missing.");

        }
        const rowsInput1 = [...rowsEducation];
        rowsInput1[index]['load'] = false;
        setRowsEducation(rowsInput1);
    }
    async function getDegree() {
        const response = await GetExpertDegrees();

        if (response.data && response.data.expertDegrees) {
            let degreelist = [];
            response.data.expertDegrees.map((value) => (
                degreelist.push({ value: value.id, label: value.degree })));

            
            setDegree(degreelist);
        }
       
    }
    function cancelEdit() {


        setRowsEducation([]);

        gContext.setCurrentPage('4');
    }
    function addTableRows() {
        const rowsInput = {
            load: false, id: null, expertId: gContext.userInfo.userID, degree: '', major: '', school: '', country: '', fromDate: '', toDate: '', error: { degree: '', major: '', school: '', country: '', fromDate: '', toDate: '' }
        }
        setRowsEducation([...rowsEducation, rowsInput]);
    }


    const handleChange = (index, event, name) => {
        setMessage(false);
        const rowsInput = [...rowsEducation];
        if (name == "country" || name == "degree") {
            rowsInput[index][name] = event;

        }
        else if (name == "fromDate" || name == "toDate") {
            rowsInput[index][name] = event;
        }
        else {
            rowsInput[index][name] = event.target.value;
        }
        rowsInput[index]["error"][name] = "";

        if (name == "major" || name == "school") {
            rowsInput[index]["error"][name] = isInvalid(event.target.value) ? "Invalid format" : "";
        }        
        setRowsEducation(rowsInput);

    }

    useEffect(() => {
        getDegree();
         
      
        document.title = "Education | Syncuppro";
    }, []);
    useEffect(() => {  
        
       // gContext.setLoaderModalVisible(false);
    }, [gContext.countriesList]);
    useEffect(() => { //gContext.setLoaderModalVisible(true);timeout(2000);
        getEducationInfo();
       // gContext.setLoaderModalVisible(false);
    }, [degrees]);
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {
            deleteData(gContext.confirmModal.index, gContext.confirmModal.id);
        }
        else { setCurrentValue({ index: null, id: null }); }
        //gContext.setLoaderModalVisible(false);
    }, [gContext.confirmModal]);
    return (
        <>

            
            {loaded &&  <div className="card panel  p-0">

                    <div className="card-header">

                        <div className="client-title">
                            <h6>EDUCATION INFORMATION</h6>
                        </div>
                    </div>
                    <div className="row d-none"> {message && <Message message={ConfirmMessages.ProfileConfirmation.replace('profile', 'education')} />}
                        {errorMessage !== "" && <><span className="text-red">{errorMessage} </span><br /></>}

                    </div>
                 <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                    <th className="min-lg-cell w-200">Degree</th>
                                    <th className="min-lg-cell">Major</th>
                                    <th className="min-lg-cell">School/University</th>
                                    <th className="min-lg-cell">Country</th>
                                    <th className="w-150 min-md-cell">From Date</th>
                                    <th className="w-150 min-md-cell">To Date</th>
                                    <th className="w-50"></th>
                                    <th className="w-50"></th>
                                </tr>
                            </thead>
                            <tbody>

                                {rowsEducation.map((data, index) => {
                                    const { load, id, degree, major, school, country, fromDate, toDate, error } = data;

                                    return (
                                        <tr key={index}>
                                            <td>
                                                <Select
                                                    options={degrees} placeholder="Select..." menuPortalTarget={document.body} menuPosition={'fixed'}

                                                    className={`form-select padding0-select  ${error.degree.length > 1 ? "error" : ""}`}
                                                    border={false} onChange={(e) => (handleChange(index, e, 'degree'))} value={degree}
                                                />
                                                {error.degree.length > 1 && (
                                                    <span className="text-red">{error.degree}</span>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    name="major"
                                                    type="text"
                                                    className={`form-control ${error.major.length > 1 ? "error" : ""}`}
                                                    id="major"
                                                    placeholder="Major" defaultValue={major} value={major} onChange={(e) => (handleChange(index, e, 'major'))}
                                                />
                                                {error.major.length > 1 && (
                                                    <span className="text-red">{error.major}</span>
                                                )}
                                            </td>
                                            <td>
                                                <input
                                                    name="school"
                                                    type="text"
                                                    className={`form-control ${error.school.length > 1 ? "error" : ""}`}
                                                    id="link"
                                                    placeholder="School" defaultValue={school} value={school} onChange={(e) => (handleChange(index, e, 'school'))}
                                                />
                                                {error.school.length > 1 && (
                                                    <span className="text-red">{error.school}</span>
                                                )}
                                            </td>
                                            <td>
                                                <Select
                                                    options={gContext.countriesList} placeholder="Select..." menuPortalTarget={document.body} menuPosition={'fixed'}
                                                    className={`form-select padding0-select  ${error.country.length > 1 ? "error" : ""}`}
                                                    border={false} onChange={(e) => (handleChange(index, e, 'country'))} value={country}
                                                />
                                                {error.country.length > 1 && (
                                                    <span className="text-red">{error.country}</span>
                                                )}
                                            </td>
                                            <td>
                                                <CustomDatePicker className={`form-control ${error.fromDate.length > 1 ? "error" : ""}`}
                                                    placeholderText="From Date"
                                                    selected={fromDate}
                                                    onChange={(date) => (handleChange(index, date, 'fromDate'))}
                                                    selectsStart
                                                    startDate={fromDate}
                                                    endDate={toDate}
                                                />
                                                {error.fromDate.length > 1 && (
                                                    <span className="text-red">{error.fromDate}</span>
                                                )}
                                            </td>
                                            <td>
                                                <CustomDatePicker className={`form-control ${error.toDate.length > 1 ? "error" : ""}`}
                                                    selected={toDate}
                                                    onChange={(date) => (handleChange(index, date, 'toDate'))}
                                                    placeholderText="To Date"
                                                    selectsEnd
                                                    startDate={fromDate}
                                                    endDate={toDate}
                                                    minDate={fromDate}
                                                />
                                                {error.toDate.length > 1 && (
                                                    <span className="text-red">{error.toDate}</span>
                                                )}
                                            </td>
                                            <td>
                                                <a className="btn btn-circle light pointer" data-toggle="tooltip" title="Save Row" onClick={() => (saveEducation(index))}><span className="text-theme m-icon material-icons"> {(id) ? "edit" : "save"}</span></a>
                                            </td>
                                            <td>
                                                <a className="btn btn-circle light pointer" disabled={load} data-toggle="tooltip" title="Delete Row" onClick={() => (deleteTableRows(index, id))}><span className="text-theme m-icon material-icons">delete</span></a>
                                            </td>
                                        </tr>)
                                })}

                            </tbody>
                        </table>
                        {(rowsEducation && rowsEducation.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                            <span className="text-light-gray m-icon material-icons m-b-10">menu</span>
                            <span>No Education  found</span>
                        </div>}
                    </div>

                    <div className="add-more">
                        <button type="button" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                    </div>

                </div>

                </div>}
            {!loaded &&
                <>
                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title movingLoader w-150">
                                <h6> &nbsp;</h6>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="movingLoader">
                                        <tr><th></th>
                                            <th className="min-lg-cell"> </th>
                                            <th className="min-lg-cell"></th>
                                            <th className="min-lg-cell" > </th>
                                            <th className="w-100 min-md-cell" ></th>
                                            <th className="w-100 min-md-cell"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr><tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                            <div className="add-more">
                                <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center movingLoader w-100 p-5">&nbsp;</button>
                            </div>

                        </div>

                    </div>

                </>
            }
             
        </>
    );
};

export default Education;
