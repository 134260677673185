import React, { useState  } from "react";
import {
    Switch, Routes, Route, Link, Navigate
} from "react-router-dom";

import Layout from "./components/Layout";

import ModalLoader from "./components/ModalConfirm/ModalLoad";
import { GlobalProvider } from "./context/GlobalContext";
import ScaleLoader from "react-spinners/ScaleLoader";
//import "./assets/fonts/fontawesome-5/css/all.min.css";


//import "../node_modules/slick-carousel/slick/slick.css";
//import "../node_modules/slick-carousel/slick/slick-theme.css";
//import "../node_modules/aos/dist/aos.css";

//import "./assets/fonts/icon-font/css/style.css";


//import "./scss/bootstrap.scss";
//import "./scss/main.scss";

import MainPage from './pages/index.js';
import DashboardMain from './pages/dashboard.js';
import Profile from './pages/profile.js';
import Searchgrid from './pages/searchgrid.js';
import SearchExpert from './pages/searchExpert.js';
import ProfileView from './pages/ProfileView.js';
import JobView from './pages/JobView.js';
import Contact from './pages/contact.js';
import Contract from './pages/contract.js';
import Faq from './pages/faq.js';
import Error404 from './pages/404.js';
import ServerError from './pages/500.js';
import Deleted from './pages/Deleted.js';
import Policy from './pages/Policy.js';
import Terms from './pages/Terms.js';
import AuthProvider from './context/AuthProvider.js';
import { BrowserRouter } from 'react-router-dom';
import Chatbot from 'react-chatbot-kit'
import 'react-chatbot-kit/build/main.css'
import config from "../src/chatbot/config.js"
import MessageParser from '../src/chatbot/MessageParser.js';
import ActionProvider from '../src/chatbot/ActionProvider.js';
import Zendesk, { ZendeskAPI } from "../src/ZendeskConfig";
 
 

const user = localStorage.getItem('connect');
if (user) {
    localStorage.removeItem('userEmail');
}

function App() {
     
    return (

        <BrowserRouter>
            <div className="App" id="app">
               
                <AuthProvider> </AuthProvider>
                <Routes>
                    <Route path="/" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signup" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signupcode" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signupsuccess" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signupcode_expert" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signupsuccess_expert" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signupcode_client" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/signupsuccess_client" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/login" element={<GlobalProvider><Layout pageContext={{}} ><MainPage zone="5" navType={(user) ? "profile" : "account"} /></Layout>   </GlobalProvider>} />
                    <Route path="/logout" element={<GlobalProvider>   </GlobalProvider>} />
                    <Route path="/clientjob" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/reportissue" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/settings" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/messages" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/profile" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/dashboard" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/assessment" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/messages" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/notifications" element={<GlobalProvider><Layout pageContext={{ layout: "dashboard" }} ><Profile navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/contact" element={<GlobalProvider><Layout pageContext={{}} ><Contact navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/faq" element={<GlobalProvider><Layout pageContext={{}} ><Faq navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/policy" element={<GlobalProvider><Layout pageContext={{}} ><Policy navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/terms" element={<GlobalProvider><Layout pageContext={{}} ><Terms navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/SearchJob" element={<GlobalProvider><Layout pageContext={{}} ><Searchgrid navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/SearchJob/:KEY" element={<GlobalProvider><Layout pageContext={{}} ><Searchgrid navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/SearchExpert" element={<GlobalProvider><Layout pageContext={{}} ><SearchExpert navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/Auth" element={<GlobalProvider> <div className=" loader-base "  >
                        <div className=" loader-position   ">

                            <ScaleLoader color="#F7921E" className="loader" height="100px" width="8px" radius="10px" margin="5px" />

                        </div>
                    </div></GlobalProvider>} />
                    <Route path="/adminClient/:SUB" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView userType="Client" navType={(user) ? "profile" : "account"} userMode="admin" /></Layout></GlobalProvider>} />
                    <Route path="/adminExpert/:SUB" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView userType="Expert" navType={(user) ? "profile" : "account"} userMode="admin" /></Layout></GlobalProvider>} />
                    <Route path="/Client/:SUB" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView userType="Client" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/Expert/:SUB" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView userType="Expert" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/ExpertClientPreview" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView mode="client" userType="Expert" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/ExpertPublicPreview" element={<GlobalProvider><Layout pageContext={{}} ><ProfileView mode="public" userType="Expert" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/Job/:ID" element={<GlobalProvider><Layout pageContext={{}} ><JobView mode="public" userType="Expert" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/contract/:ID" element={<GlobalProvider><Layout pageContext={{}} ><Contract mode="public" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/*" element={<GlobalProvider><Layout pageContext={{}}><Error404 navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/error" element={<GlobalProvider><Layout pageContext={{}}><Error404 navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/servererror" element={<GlobalProvider><Layout pageContext={{}}><ServerError navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/deletemessage" element={<GlobalProvider><Layout pageContext={{}}><Deleted navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />

                    <Route path="/payment" element={<GlobalProvider><Layout pageContext={{}}><ProfileView mode="public" userType="Expert" navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />
                    <Route path="/notavailable" element={<GlobalProvider><Layout pageContext={{}}><Deleted navType={(user) ? "profile" : "account"} /></Layout></GlobalProvider>} />

                </Routes>
              
            </div></BrowserRouter>
    );
}

export default App;