import React, { useState, useEffect, useContext} from "react";
import PageWrapper from "../components/PageWrapper";
import Brands from "../sections/home/Brands";
import WhoWeAre from "../sections/home/WhoWeAre";
import FeaturedExperts from "../sections/home/FeaturedExperts"; 
import HowItWorks from "../sections/home/HowItWorks";
import Hero from "../sections/home/Hero";
import GlobalContext from "../context/GlobalContext";
import Slider from "../sections/home/Slider";
import { HomeFeaturedList } from "../utils/GetAPIUtils";
import { Helmet } from "react-helmet";
//import "../style/css/bootstrap.min.css";
import "../style/css/style.css";
import "../style/css/media.css";

import "../style/css/common.css";
import { useLocation } from 'react-router-dom';
export default function MainPage({ zone, navType }) {
    const gContext = useContext(GlobalContext);
    const [featuredData, setFeaturedData] = useState(null);
    const pathName = useLocation().pathname.toLowerCase();
    const hide = true;
    async function getHomeFeatureData() {
        try {
            
            const result = await HomeFeaturedList();

            if (result.data.homeFeaturedList.responseData.code == "200") {
             
                let data = JSON.parse(result.data.homeFeaturedList.responseData.message);
               
                setFeaturedData(data);
            }
           
        }
        catch (e) {

        }
    }
    useEffect(() => {
        document.title = "Home | Syncuppro";
        const user = localStorage.getItem('connect');
        if (user==null && pathName.trim().toLowerCase() == "/login") {

            gContext.toggleSignInModal();
        } 
        if (user == null && pathName.trim().toLowerCase() == "/signup") {
            gContext.toggleSignUpModal();
        }
        if (user == null && (pathName.trim().toLowerCase() == "/signupsuccess" || pathName.trim().toLowerCase() == "/signupsuccess_expert" || pathName.trim().toLowerCase() == "/signupsuccess_client") && localStorage.getItem('userEmail')) {
            gContext.toggleSignUpModal();
        }
        //else if (pathName.trim().toLowerCase() == "/signupsuccess")
        //{
        //    window.location.href = "/";
        //}
        if (user == null && localStorage.getItem('userEmail') && (pathName.trim().toLowerCase() == "/signupcode" || pathName.trim().toLowerCase() == "/signupcode_expert" || pathName.trim().toLowerCase() == "/signupcode_client")) {
            gContext.toggleSignUpModal();
        }
        else if (pathName.trim().toLowerCase() == "/signupcode") {
            window.location.href = "/";
        }
        if (window.location.href.split('#').length > 1)
            window.location.href = window.location.href.replace(window.location.origin, "");
       
        const passwordChanged = localStorage.getItem('passwordChanged');      
        if (passwordChanged) {
            localStorage.removeItem('passwordChanged');
           // gContext.toggleErrorModal();
            gContext.toggleSuccessModal();
        }
        getHomeFeatureData();

    }, [])

  return (
      <>  
      <PageWrapper
        headerConfig={{
                  bgClass: "dynamic-sticky-bg",
                  button: navType
        }}
          >
              
 
              <Hero />
                <Brands bindData={featuredData } />
              
       
              <WhoWeAre />
              <HowItWorks />
              { featuredData == null? '' : <FeaturedExperts bindData={featuredData}/> }
               
              
              {hide ? "" : <Slider />}
             
       
      </PageWrapper>
    </>
  );
};

