import React, { useContext, useState, useEffect } from "react";
import { GetCountry } from "../utils/GetAPIUtils";
import { useLocation } from "react-router-dom";
import { ClientJobsRead, SearchExpert, GetExpertPreviewLoad } from "../utils/apiCalls";
import PageWrapper from "../components/PageWrapper";
import ExpertSidebar from "../components/Sidebar/ExpertSidebar";
import ExpertAdvanceSearch from "../components/Sidebar/ExpertAdvanceSearch";
import ExpertSearchgrid from "../sections/search/ExpertSearchgrid";
import ExpertSearchlist from "../sections/search/ExpertSearchlist";
import { Select } from "../components/Core";
import { Switch } from "../components/Core";
import GlobalContext from "../context/GlobalContext";
import Icon from '@mui/material/Icon';
import StarRating from "../components/Core/Rating";
import { Helmet } from "react-helmet";
const imgLoader = "../assets/image/svg/spinning-circles.svg";

export default function ExpertSearch(props) {
    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const hide = true;
    const [values, setValues] = React.useState({ jobSuccessRate: "", avgRating: "", otherLangs: "", countryId: null, country: null, keyword: '', jobTypes: "", expLvlIds: '', skillIds: '', complianceCategoryIds: '', complianceStandardIds: '', weeklyHrsIds: '', projLengthIds: '', period: '', rateRange: '', industryIds: "", dateSince: '', rateRangeFrom: '10', rateRangeTo: '10000', rateType: '' });
    const [countriesList, setCountriesList] = useState([{}])
    const [loaded, setloaded] = useState(true)
    const [showList, setShowList] = useState(true);
    const [keyword, setKeyword] = useState("");
    const [country, setCountry] = useState("");
    const [alljobs, setAllJobs] = useState([]);
    const [job, setJobs] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
    const [pageCount, setPageCount] = useState(1);
    const [clear, setClear] = useState(false);
    const [showProfileFilter, setShowProfileFilter] = useState(false);
    const [searchHistory, setSearchHistory] = useState(localStorage.getItem('experthistory'));
    //  const [filterData, setFilterData] = usswiteState({ avgRating: "", expLvlIds: "", complianceCategoryIds: "", complianceStandardIds: "", weeklyHrsIds: "", rateType: "" })
    // const [advancedFilterData, setAdvancedFilterData] = useState({ skillIds: "", industryIds: "", otherLangs: ""})
    const user = localStorage.getItem('connect');

    function BindKeyword(keyword) {

        setValues({ ...values, "keyword": keyword });
        BindJobs({ ...values, "keyword": keyword });
    }

    const handleChange = (e, name) => {

        const value = e;
        switch (name) {
            case "country":
                setValues({ ...values, ["country"]: value.value, ["countryId"]: value });
                break;
            case "keyword":
                var regex = new RegExp('[^0-9a-zA-Z-,_.\:\s ]+');

                if (!regex.test(e.target.value)) {
                    setValues({ ...values, ["keyword"]: e.target.value });
                    if (localStorage.getItem("jobSearch"))
                        localStorage.removeItem("jobSearch");
                }
                break;
            default:
                break;
        }
    }
    function removeHistory(keyword) {
        let keys = localStorage.getItem('experthistory').split("|").filter((item) => item.toLowerCase() != keyword.toLowerCase());
        let data = "";
        keys.map((item, index) => data += (keys.length == index + 1) ? item : item + "|");
        
        localStorage.setItem('experthistory', data);
        setSearchHistory(data);

    }
    function clearFilterData(mode) {
        let filterValue = (mode == "side") ? {
            ...values, ["otherLangs"]: "", ["skillIds"]: '', ["dateSince"]: '', ["industryIds"]: '',["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '', ["rateRangeTo"]: '', ["rateType"]: '', ["avgRating"]: "", ["jobSuccessRate"]: ""
        } : { ...values, ["otherLangs"]: "", ["skillIds"]: '', ["dateSince"]: '', ["industryIds"]: '' };
        setValues(filterValue);
        setShowProfileFilter(false);
    }

    function clearFilter() {
        
        if (localStorage.getItem("jobSearch"))
            localStorage.removeItem("jobSearch");
        let filterValue = {
            ...values,
            ["country"]: null, ["countryId"]: null, ["skillIds"]: '', ["keyword"]: "", ["dateSince"]: '', ["industryIds"]: '', ["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '10', ["rateRangeTo"]: '10000', ["rateType"]: '', ["avgRating"]: "", ["otherLangs"]: "", ["jobSuccessRate"]: ""
        };
        setValues(filterValue);
        setClear(true);      
        setShowProfileFilter(false);
        BindJobs(filterValue);

    }

    function LoadMore() {
        setPageCount(pageCount + 1);
    }



    useEffect(() => {

        document.title = "Expert Search | Syncuppro";
        if (params.get('key')) {
            let key = params.get('key');
            if (params.get('key').toLowerCase().length >3 && params.get('key').toLowerCase().substring(0, 3) == "iso")
                key = "iso " + params.get('key').toLowerCase().substring(3, params.get('key').toLowerCase().length).trim();
            
            BindKeyword(key);
            
        }
        else {
            if (localStorage.getItem("jobSearch")) {
                const jobSearch = JSON.parse(localStorage.getItem("jobSearch"));

                setValues({ ...values, ["keyword"]: jobSearch.keyword });
                setShowMore(false);
                setPageCount(1);

                BindJobs({ ...values, ["keyword"]: jobSearch.keyword });
                localStorage.removeItem("jobSearch");
            }
            else {
                BindJobs(values);
            }

        }


    }, []);

    useEffect(() => {
        //let jobKeyword = "";
        //if (localStorage.getItem("jobSearch")) {
        //    const jobSearch = JSON.parse(localStorage.getItem("jobSearch"));
        //    jobKeyword = jobSearch.keyword;
        //    setShowMore(false);

        //    if (jobKeyword != "") {
        //        setPageCount(1);
        //        setValues({ ...values, ["keyword"]: jobKeyword });
        //        BindJobs({ ...values, ["keyword"]: jobKeyword });
        //    }

        //}
        //else if (gContext.userLocation) {
        //    const location = gContext.userLocation;


        //    let industryId = "";
        //    if (gContext.userInfo.userType == "2") {

        //        const data = (gContext.userInfo.profileInfo) ? JSON.parse(gContext.userInfo.profileInfo) : null;
        //        if (data && data.length > 0) {
        //            industryId = data[0].indtypeids;
        //            setShowAdvanceSearch(data[0].indtypeids != "" ? true : false);
        //        }
        //    }
        //    setShowMore(false);
        //    console.log("industryId", industryId);
        //    setValues({
        //        ...values, ["industryIds"]: industryId, ["keyword"]: jobKeyword, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null
        //    });
        //    setPageCount(1);
        //    BindJobs({ ...values, ["industryIds"]: industryId, ["keyword"]: jobKeyword, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null });

        //} 
        //else
        //    gContext.setLoaderModalVisible(false);


    }, [gContext.userLocation]);
    async function BindFilter() {
        const location = gContext.userLocation;
        let jobKeyword = "";

        let industryId = "";
        let data = null;
        setClear(false);
        if (gContext.userInfo.userType == "2") {

            const data = (gContext.userInfo.profileInfo) ? JSON.parse(gContext.userInfo.profileInfo) : null;
            if (data && data.length > 0) {
                industryId = data[0].indtypeids;
                setShowAdvanceSearch(data[0].indtypeids != "" ? true : false);
            }
            setValues({
                ["skillIds"]: '', ["keyword"]: "", ["dateSince"]: '', ["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '', ["rateRangeTo"]: '', ["rateType"]: '', ["avgRating"]: "", ["otherLangs"]: "", ["jobSuccessRate"]: "", ["industryIds"]: industryId, ["keyword"]: jobKeyword, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null
            });
            setPageCount(1);
           
            BindJobs({ ["skillIds"]: '', ["keyword"]: "", ["dateSince"]: '', ["jobTypes"]: '', ["expLvlIds"]: '', ["weeklyHrsIds"]: '', ["projLengthIds"]: '', ["complianceCategoryIds"]: '', ["complianceStandardIds"]: '', ["rateRangeFrom"]: '', ["rateRangeTo"]: '', ["rateType"]: '', ["avgRating"]: "", ["otherLangs"]: "", ["jobSuccessRate"]: "", ["industryIds"]: industryId, ["keyword"]: jobKeyword, ["countryId"]: (location) ? location : null, ["country"]: (location) ? location.value : null });
        }
        else if (gContext.userInfo.userType == "1") {
            try {

                const result = await GetExpertPreviewLoad(gContext.userInfo.sub);
                if (result.data.expertAllLoad && result.data.expertAllLoad.responseData.code == "200") {
                    let profile = JSON.parse(result.data.expertAllLoad.responseData.message);
                  
                    const location = gContext.userLocation;

                    data = { ...values, ["expLvlIds"]: profile[0].explevelid.toString(), ["skillIds"]: profile[0].skillids, ["complianceCategoryIds"]: profile[0].compcatids, ["complianceStandardIds"]: profile[0].complianceids, ["weeklyHrsIds"]: profile[0].weeklyhoursid.toString(), ["skillIds"]: profile[0].skillids, ["industryIds"]: (profile[0].indtypeidslist) ? profile[0].indtypeidslist : '', ["countryId"]: location, ["country"]: location.value, ["keyword"]: '' };
                   
                    setValues(data);
                    setPageCount(1);
                    BindJobs(data);
                }
            }
            catch (e) {
               console.log(e);

            }
            setShowAdvanceSearch(true);
        }
        setShowMore(false);
       
      



    }

    useEffect(() => {

        if (pageCount > 1) {
            let data = alljobs;
            setJobs(data.slice(0, (data.length > (pageCount * 10)) ? (pageCount * 10) : data.length));
            setShowMore(data.length > (pageCount * 10));
        }

    }, [pageCount]);

    async function BindJobs(job) {
        try {

            setloaded(false);
            setCountry("");
            // gContext.setLoaderModalVisible(true);
            const result = (job) ? await SearchExpert(job) : await SearchExpert(values);

            if (result.data.searchTalents.responseData.code == "200") {

                let data = JSON.parse(result.data.searchTalents.responseData.message);
                
                if (data.length > 0) {

                    let list = data.filter(item => item.id != gContext.userInfo.userID);
                    
                        setAllJobs(list);
                        setJobs(list.slice(0, (list.length > (pageCount * 10)) ? (pageCount * 10) : list.length));
                        setShowMore(list.length > (pageCount * 10));
                }
                else {
                    setJobs([]);
                    setAllJobs([]);
                    setShowMore(false);
                }

            } else {
                setJobs([]);
                setAllJobs([]);
                setShowMore(false);
            }
            setKeyword((job) ? job.keyword : values.keyword);
            setCountry(values.countryId ? values.countryId.label : "");
            // gContext.setLoaderModalVisible(false);

            setTimeout(() => {
                gContext.setLoaderModalVisible(false);
                setloaded(true);
            }, 2000);
            if (job.keyword != "") {
                if (localStorage.getItem('experthistory')) {
                    let keys = localStorage.getItem('experthistory').split("|").filter((item) => item.toLowerCase() == job.keyword.toLowerCase());
                  
                    if (keys.length == 0) {
                        localStorage.setItem('experthistory', localStorage.getItem('experthistory') + "|" + job.keyword);
                        setSearchHistory(localStorage.getItem('experthistory'));
                    }
                }
                else {
                    localStorage.setItem('experthistory', job.keyword);
                    setSearchHistory(job.keyword);
                }
                setValues({ ...values, ["keyword"]: "", ["country"]: null, ["countryId"]: null });

            }
        }
        catch (e) {
            console.log(e);
            setJobs([]);
            setAllJobs([]);
            setShowMore(false);
            setloaded(true);
            gContext.setLoaderModalVisible(false);
        }
    }
    function SetSearchCriteria(field, value, dataObject) {
        if (field)
            setValues({ ...values, [field]: value });
        else
            setValues({ ...values, ...dataObject });
    }
    function ApplySearch(filter, bind) {
        //localStorage.removeItem("jobSearch");
        setClear(false);
        //let filterValue = {
        //    ...values, ["jobTypes"]: filter.jobTypes, ["expLvlIds"]: filter.expLvlIds, ["weeklyHrsIds"]: filter.weeklyHrsIds, ["projLengthIds"]: filter.projLengthIds, ["complianceCategoryIds"]: filter.complianceCategoryIds, ["complianceStandardIds"]: filter.complianceStandardIds, ["rateRangeFrom"]: filter.rateRangeFrom, ["rateRangeTo"]: filter.rateRangeTo, ["rateType"]: filter.rateType, ["avgRating"]: filter.avgRating
        //};
        //setValues(filterValue);
        //if (bind)
        BindJobs(values);
    }
    function ApplyAdvancedSearch(filter, bind) {
        //localStorage.removeItem("jobSearch");
        setClear(false);
        //let filterValue = { ...values, ["skillIds"]: filter.skillIds, ["dateSince"]: filter.dateSince, ["industryIds"]: filter.industryIds, ["otherLangs"]: filter.otherLangs };
        //setValues(filterValue);
        //if (bind)
        BindJobs(values);
    }
    function handleKeypress(e) {
        //it triggers by pressing the enter key 
        if (e.charCode === 13) {
            setPageCount(1);
            BindJobs(values);
        }
    }
    useEffect(() => { 
        
        if (gContext.userInfo.userType == "1" && gContext.userInfo.userID ) {
            setPageCount(1);
            BindJobs(values);
}        
    }, [gContext.userInfo.userID]);
    useEffect(() => {

        document.body.classList.add('fix-inner-block');

    }, []);
    return (
        <>
            <Helmet>
                <title>ISO 27001 9001 consulting services & Certification Auditor  | Syncuppro</title>
                <meta name="description" content="Get ISO 27001 consulting services to secure your data. Our consultants ensure compliance, reduce risks, and strengthen your information security management system.Achieve ISO 9001 certification with expert auditors and consulting. Ensure quality management compliance, enhance efficiency, and drive continuous improvement." />
                <link rel="canonical" href={window.location.origin+"/searchexpert"} />
            </Helmet>
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>

                <div className="page-wrapper">
                    

                        <ExpertSidebar ApplySearch={() => ApplySearch()} ClearFilter={clear} filterData={values} SetSearchCriteria={SetSearchCriteria} clearFilterData={clearFilterData} showFilter={showFilter}  setShowFilter={() => { setShowFilter(!showFilter) }} />
                    <div className="pg-content-block">

                        <div className="search-group">
                            <div className="search-info p-relative">
                                <input type="text" className="form-control" id="search-input" name="keyword" defaultValue={values.keyword} value={values.keyword}
                                    placeholder="Keyword" onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} onKeyPress={(e) => (handleKeypress(e))} />
                                <Icon className="text-theme input-m-icon material-icons m-t-5">search</Icon>
                                <div className="divider"></div>
                                <Icon className="m-0 text-theme m-icon material-icons">location_on</Icon>
                                <Select name="country" options={gContext.countriesList} placeholder="Select..." value={values.countryId}
                                    className="form-control line"
                                    border={false} onChange={(e) => handleChange(e, 'country')}
                                />
                                <button className="btn btn-orange search-btn" type="button" onClick={() => { setPageCount(1); BindJobs(values) }}><Icon className="text-white m-icon material-icons">search</Icon><span className="text-none">Search</span></button>
                            </div>
                        </div>

                        <div className="btn-search-group">

                            {
                                searchHistory && searchHistory.length > 0 && searchHistory.split('|').map((item, index) => {

                                    return (

                                        <span key={index} className="btn btn-orange btn-sm pointer">  <button title="Click to view" onClick={() => BindKeyword(item)} data-toggle="tooltip" className="btn-icon text-white"> {item}</button> <button title="Click to remove" data-toggle="tooltip" className="btn-icon text-white m-l-5 m-icon material-icons" onClick={() => removeHistory(item)}>close</button></span>



                                    )
                                })}


                        </div>


                        
                        {user && <div className="form-check form-switch m-b-30">


                            <label className="form-check-label" for="mySwitch">{!showProfileFilter ? "Switch to view filtered experts" : "Switch to view all experts"}</label>
                            <input className="form-check-input" type="checkbox" id="mySwitch" onClick={(e) => { (showProfileFilter) ? clearFilter() : BindFilter(); setShowProfileFilter(!showProfileFilter); }} checked={showProfileFilter} />
                        </div>}
                        <div className="exports-group  ">
                            {loaded && <div className="job-available-block">
                                <h6>{alljobs.length} Experts available   {(keyword != "") ? " for '" + keyword + "'" : ""}  {country ? " - " + country : ""} </h6>

                                <div className="desktop-hide">
                                    <button type="button" className="btn btn-dark-outline btn-sm collapsed" data-bs-toggle="collapse" data-bs-target="#sidebar-menu">
                                        <Icon className="m-icon material-icons m-r-5">filter_alt</Icon>Filter
                                    </button>
                                </div>

                                <ul className="tab-wrapper nav nav-tabs m-b-0" role="tablist">



                                    <li className="nav-item" role="presentation">
                                        <a className="btn tab-btn active" data-bs-toggle="tab" href="#list" aria-selected="false" role="tab" tabindex="-1" onClick={() => setShowList(true)}><Icon className="m-icon material-icons">list</Icon></a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="btn tab-btn grid-btn" data-bs-toggle="tab" href="#grid-view" aria-selected="true" role="tab" onClick={() => setShowList(false)}><Icon className="m-icon material-icons">grid_view</Icon></a>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <a class="btn btn-dark-outline btn-sm collapsed filter-btn" onClick={(e) => { setShowFilter(!showFilter); }}>
                                            <span class="m-icon material-icons m-r-5">filter_alt</span>
                                        </a>
                                    </li>
                                </ul>

                            </div>}
                            {!loaded &&
                                <><div className="row" >
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card list-block">
                                            <div className="expert-info-details">

                                                <div className="expert-data">
                                                    <div className="expert-profile m-0 align-items-start">
                                                        <div className="expert-img w-50 movingLoader p-20">
                                                            &nbsp;

                                                        </div>

                                                        <div className="d-flex flex-column m-l-30">

                                                            <strong className="expert-name pointer movingLoader w-100"   >&nbsp;</strong>


                                                            <div className="d-flex ">
                                                                <span className="expert-desg movingLoader w-50">&nbsp;</span>

                                                                <div className="info-meta flag movingLoader w-50">
                                                                    <span className="one-line text-black m-r-10">
                                                                        &nbsp;
                                                                    </span>
                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>


                                                <div className="work-pay movingLoader w-50">
                                                    &nbsp;
                                                </div>

                                            </div>




                                            <div className="type-name movingLoader w-100 ">
                                                &nbsp;


                                            </div>

                                            <p className="text-gray three-line   movingLoader w-full m-t-10 ">
                                                &nbsp;
                                                <p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p><p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p>
                                            </p>

                                            <div className="badge-group">

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                            </div>




                                        </div>
                                    </div>
                            </div><div className="row" >
                                    <div className="col-lg-12 col-md-12 col-sm-12">
                                        <div className="card list-block">
                                            <div className="expert-info-details">

                                                <div className="expert-data">
                                                    <div className="expert-profile m-0 align-items-start">
                                                        <div className="expert-img w-50 movingLoader p-20">
                                                            &nbsp;

                                                        </div>

                                                        <div className="d-flex flex-column m-l-30">

                                                            <strong className="expert-name pointer movingLoader w-100"   >&nbsp;</strong>


                                                            <div className="d-flex ">
                                                                <span className="expert-desg movingLoader w-50">&nbsp;</span>

                                                                <div className="info-meta flag movingLoader w-50">
                                                                    <span className="one-line text-black m-r-10">
                                                                        &nbsp;
                                                                    </span>
                                                                </div>

                                                            </div>


                                                        </div>
                                                    </div>


                                                </div>


                                                <div className="work-pay movingLoader w-50">
                                                    &nbsp;
                                                </div>

                                            </div>




                                            <div className="type-name movingLoader w-100 ">
                                                &nbsp;


                                            </div>

                                            <p className="text-gray three-line   movingLoader w-full m-t-10 ">
                                                &nbsp;
                                                <p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p><p className="text-gray three-line   movingLoader w-full  ">
                                                    &nbsp;
                                                </p>
                                            </p>

                                            <div className="badge-group">

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>
                                                <span className="badge compliance-standard  m-t-5 movingLoader w-150 ">&nbsp;</span>

                                            </div>




                                        </div>
                                    </div>
                                </div> </>
                                 
                            
                            
                            }
                            {loaded && <div className="tab-content">
                                <div id="list" className={`tab-pane  ${!showList ? 'active show' : ''}`} role="tabpanel"><ExpertSearchgrid jobsList={job} /></div>
                                <div id="grid-view" className={`tab-pane ${showList ? 'active show' : ''}`} role="tabpanel"><ExpertSearchlist jobsList={job} /></div>




                            </div>}
                            <div className="load-more">
                                <button type="button" disabled={alljobs.length == job.length} className="btn btn-orange-outline d-flex align-item-center" onClick={() => { LoadMore(); }}><Icon className="m-icon material-icons m-r-10">refresh</Icon>Load more</button>
                            </div>
                        </div>

                    </div>

                </div>



                {!hide && <div className={`bg-default-1 pt-26 pt-lg-28 pb-13 pb-lg-25 d-none  display-none `}>
                    <div className="container d-none">
                        <div className="row">
                            <div className="col-12 col-md-3 col-xs-8">
                                <ExpertSidebar ApplySearch={() => ApplySearch()} ClearFilter={clear} filterData={values} SetSearchCriteria={SetSearchCriteria} clearFilterData={clearFilterData} />
                            </div>

                            <div className="col-12 col-md-9 col-xs-12 ">
                                {/* <!-- form --> */}
                                <form

                                    className="search-form search-2-adjustment ml-lg-0 ml-md-15"
                                >
                                    <div className="filter-search-form-2 bg-white rounded-sm shadow-7 pr-6 py-0  ">
                                        <div className="filter-inputs">
                                            <div className="form-group position-relative w-lg-45 w-xl-40 w-xxl-45">
                                                <input
                                                    className="form-control focus-reset pl-13 font-size-4"
                                                    type="text"
                                                    id="keyword"
                                                    name="keyword" defaultValue={values.keyword} value={values.keyword}
                                                    placeholder="Keyword" onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} onKeyPress={(e) => (handleKeypress(e))}
                                                />
                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-zoom-2 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                            {/* <!-- .select-city starts --> */}
                                            <div className="form-group position-relative w-lg-55 w-xl-60 w-xxl-55">
                                                <Select name="country" options={gContext.countriesList} placeholder="Select..." value={values.countryId}
                                                    className="pl-8 h-100 arrow-3 font-size-4 d-flex align-items-center w-100 "
                                                    border={false} onChange={(e) => handleChange(e, 'country')}
                                                />

                                                <span className="h-100 w-px-50 pos-abs-tl d-flex align-items-center justify-content-center font-size-6">
                                                    <i className="icon icon-pin-3 text-primary font-weight-bold"></i>
                                                </span>
                                            </div>
                                            {/* <!-- ./select-city ends --> */}
                                        </div>
                                        <div className="button-block position-relative  pt-4 text-right">
                                            <button type="button" className="btn btn-primary btn-small line-height-reset   text-uppercase rounded-5 "

                                                onClick={() => { setPageCount(1); BindJobs(values) }}>
                                                Search
                      </button>
                                        </div>

                                    </div>
                                </form>
                                <div className="col-lg-12 col-md-12 col-xs-12 text-left pt-5">
                                    <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">

                                        {
                                            searchHistory && searchHistory.length > 0 && searchHistory.split('|').map((item, index) => {

                                                return (<li key={index} className=" position-relative">

                                                    <a title="Click to search" data-toggle="tooltip" className="bg-green text-white  mr-6 px-7 py-0  font-size-3 rounded-15 min-height-32 d-flex align-items-center pointer " onClick={() => BindKeyword(item)}>
                                                        {item}


                                                    </a>
                                                    <i title="Click to remove" data-toggle="tooltip" className="fas fa-times text-black-2 pos-abs-tr    mr-6  p-1  font-size-4 pointer " onClick={() => removeHistory(item)}></i>

                                                </li>)
                                            })}
                                    </ul>

                                </div>
                                {user && <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 text-left pt-10 pb-5 my-auto media px-0 mx-0  "> <span className="mb-n5  px-0 mx-0">{!showProfileFilter ? "Switch to view filtered experts" : "Switch to view all experts"} </span>  <Switch className="" onClick={(e) => { (showProfileFilter) ? clearFilter() : BindFilter(); setShowProfileFilter(!showProfileFilter); }} active={showProfileFilter} /></div>}
                                {/*<!--
                                   <div className="col-lg-12 col-xl-12 col-md-12 col-xs-12 text-right  pt-5">   <a onClick={() => { setShowAdvanceSearch(!showAdvanceSearch); }} className="pointer text-green-2 font-size-3  rounded-3 px-2   hover-shadow-1">
                                    <i className="fa fa-filter mr-2 font-weight-bold"></i>{" "}
                                    {showAdvanceSearch ? "Hide" : "Show"}  advanced filter
                                </a></div>
                              
                                {showAdvanceSearch && <div className="col-12 col-md-12 col-xs-12 border-2 border round-10 py-5">
                                    <ExpertAdvanceSearch ApplySearch={ApplyAdvancedSearch} ClearFilter={clear} filterData={values} SetSearchCriteria={SetSearchCriteria} clearFilterData={clearFilterData} />
                                </div>
                                <div className="col-lg-12 col-md-12 col-xs-12 text-left pt-0 px-0">  </div>  -->*/}

                                {loaded && <div className="pt-8 ml-lg-0 ml-md-15">
                                    <div className="d-flex align-items-center justify-content-between mb-5">
                                        <h5 className="font-size-4 font-weight-normal text-default-color">
                                            <span className="heading-default-color">{alljobs.length} {" "}</span>
                                            {" "} Experts
                                            {(keyword != "") && <span className="heading-default-color">for keyword {" "}"{keyword}"</span>}
                                        </h5>
                                        <div className="d-flex align-items-center result-view-type">

                                            <a className={`heading-default-color pl-5 font-size-6 hover-text-hitgray ${(showList === true) ? 'active' : ''} pointer`} onClick={() => setShowList(true)}>
                                                <i className="fa fa-list-ul"></i>
                                            </a>

                                            <a className={`heading-default-color pl-5 font-size-6 hover-text-hitgray ${(showList === false) ? 'active' : ''} pointer`} onClick={() => setShowList(false)}>
                                                <i className="fa fa-th-large"></i>
                                            </a>
                                        </div>
                                    </div>
                                    {(showList === false) && <ExpertSearchgrid jobsList={job} />}
                                    {(showList === true) && <ExpertSearchlist jobsList={job} />}

                                    {showMore && <div className="text-center pt-5 pt-lg-13">

                                        <a className="text-green font-weight-bold text-uppercase font-size-3 d-flex align-items-center justify-content-center pointer" onClick={() => { LoadMore(); }}>
                                            Load More{" "}
                                            <i className="fas fa-sort-down ml-3 mt-n2 font-size-4"></i>
                                        </a>

                                    </div>}
                                </div>}
                                {/* <!-- form end --> */}

                         
                            </div>
                        </div>
                    </div>
                </div>}
                

            </PageWrapper>
        </>
    );
};
