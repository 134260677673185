import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { forgotPwd, forgotPwdConfirm } from './../../graphql/mutations';
import { API, graphqlOperation } from 'aws-amplify';
import validator from 'validator';
import Message from "../../components/Message";
import ConfirmMessages from "../../utils/confirmMessages";
import { getEncodedEmail } from "../../utils/helperFn";
import Icon from '@mui/material/Icon';
const ModalStyled = styled(Modal)`
   &.modal {
     
  }
`;
const imgLoader = "assets/image/svg/spinning-circles.svg";
const ModalForgotPassword = (props) => {
    const [visibleLoader, setVisibleLoader] = useState(false);
    const gContext = useContext(GlobalContext);
    const [values, setvalues] = React.useState({ email: '', error: { email: '' } });
    const [resetValues, setResetValues] = React.useState({ code: '', password1: '', password2: '', error: { code: '', password1: '', password2: '' } });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [successMessage, setSuccessMessage] = React.useState({ show: false, value: '' });
    const [showPassFirst, setShowPassFirst] = useState(true);
    const [showPassSecond, setShowPassSecond] = useState(true);
    const [errorPassword, setErrorPassword] = React.useState({ min8: false, upperCase: false, lowerCase: false, numeric: false, special: false });

    function handleClose() {
        setErrorMessage('');
        setvalues({ email: '', error: { email: '' } });
        setSuccessMessage({ show: false, value: '' });
        setResetValues({ code: '', password1: '', password2: '', error: { code: '', password1: '', password2: '' } });
        gContext.toggleForgotPasswordModal();

    };
    const togglePasswordFirst = () => {
        setShowPassFirst(!showPassFirst);
    };

    const togglePasswordSecond = () => {
        setShowPassSecond(!showPassSecond);
    };
    function checkValidity() {
        let inValid = false;
        let { error, ...rest } = resetValues;



        error.code = validator.isEmpty(rest.code) ? "Code is required" : "";
        // error.password1 = validator.isEmpty(rest.password1) ? "Password is required" : (rest.password1) != (rest.password2)?"The passwords doesnt match": "";
        // error.password2 = validator.isEmpty(rest.password2) ? "Confirm Password is required" : (rest.password1) != (rest.password2)?"The passwords doesnt match": "";  
        error.password1 = validator.isEmpty(rest.password1) ? "Password is required" : error.password1;
        error.password2 = validator.isEmpty(rest.password2) ? "Confirm Password is required" : error.password2;


        if (rest.password1 != '' && rest.password2 != '') {

            if ((rest.password1 != rest.password2)) { error.password2 = "The passwords do not match."; }
        }

        setResetValues({ ...resetValues, error });
      
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                if (val != "valid")
                    inValid = true;
            }
        });
        Object.values(rest).forEach(val => {
            if (val === '') {
                inValid = true;
            }
        });
        if (inValid)
            return false;
        else
            return true;
    }
    async function forgotPassword() {

        if (values.email == "") {
            setvalues({ ...values, ["error"]: { email: (values.email == "") ? 'Email is required' : "" } });
            return;
        }
        
        if (values.email != "")
         {
            
            if ((validator.isEmail(values.email) == false)) {
               
                setvalues({ ...values, ["error"]: { email: "Invalid email" } });
            return;
            }

            else {

                gContext.setLoaderModalVisible(true);
                let data = forgotPwd.replace("$email", values.email);

                try {
                    const result = await API.graphql(graphqlOperation(data));

                    if (result.data && result.data.forgotPwdInit.responseData) {
                       

                        if (result.data.forgotPwdInit.responseData.code == "200") {
                            setSuccessMessage({ show: true, value: 'We have sent you the password reset code to your registered email. Please check your mail.' })
                        }
                        else if (result.data.forgotPwdInit.responseData.code == "500") {

                            // setErrorMessage(JSON.parse(result.data.forgotPwdInit.responseData.message));
                            setErrorMessage("Invalid email");
                        }

                    }
                }
                catch (error) {
                    setErrorMessage("Server error"); gContext.setLoaderModalVisible(false);
                }

                gContext.setLoaderModalVisible(false);
            }

        }
    }
    async function resetPassword() {
        setErrorMessage("");
     

        if (checkValidity()) {


            gContext.setLoaderModalVisible(true);
            let data = forgotPwdConfirm.replace("$email", values.email).replace("$pwd", resetValues.password1).replace("$code", resetValues.code.trim());

            try {
                const result = await API.graphql(graphqlOperation(data));
                
                if (result.data && result.data.forgotPwdConfirm.responseData) {

                    if (result.data.forgotPwdConfirm.responseData.code == "200") {
                        setSuccessMessage({ show: true, value: '' })
                    }
                    else {
                        setErrorMessage(JSON.parse(result.data.forgotPwdConfirm.responseData.message));
                    }
                }
            }
            catch (error) {
                setErrorMessage("Server error");
                gContext.setLoaderModalVisible(false);
            }

        }


        gContext.setLoaderModalVisible(false);

    }
    function handleKeypress(e) {
        //it triggers by pressing the enter key 
        if (e.charCode === 13) {
            resetPassword();
        }
    }
    function handleForgotKeypress(e) {
        //it triggers by pressing the enter key 
        if (e.charCode === 13) {
            forgotPassword();
        }
    }


    function validatePassword(value) {
        const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const lowerChars = /[a-z]/;
        const upperChars = /[A-Z]/;
        const numericChars = /[0-9]/;

        setErrorPassword({ min8: (value.length >= 8), upperCase: upperChars.test(value), lowerCase: lowerChars.test(value), numeric: numericChars.test(value), special: specialChars.test(value) });

    }
    const handleResetChange = (e) => {

        const { name, value } = e.target;

        let error = { ...resetValues.error };

        setErrorMessage(false);

        switch (name) {

            case "code":
                error.code = validator.isEmpty(value) ? "Code is required" : "";
                break;
            case "password1":
                if (value != "") {
                    error.password1 = validator.isStrongPassword(value, {
                        minLength: 8, minLowercase: 1,
                        minUppercase: 1, minNumbers: 1, minSymbols: 1
                    }) ? "valid" : "*";

                    validatePassword(value);
                }

                else
                    error.password1 = ""; error.password2 = "";
            case "password2":
                if (value == "" && name == "password2") {
                    error.password2 = "Confirm Password is required";
                }

                else if (value != null && resetValues.password1 != null && name == "password2") {

                    error.password2 = (resetValues.password1 != value) ? "The passwords do not match." : '';


                }

                if (value == null && name == "password1") { //alert(value,name);
                    error.password2 = "";
                }

                // error.password2 = "";


                // error.password2 =  validator.isEmpty(value) ? "Confirm Password is required" : (value != null && resetValues.password1 != null) ? (resetValues.password1 != value) ?"Confirm password is not match with password.":"":"";

                //(values.password1 != value) ? "Confirm password is not match with password." :
                // error.password2 = (resetValues.password1 != value) ? "Confirm password is not match with password." : validator.isEmpty(value) ? "Confirm Password is required" : "";

                break;
            default:
                break;
        }

        setResetValues({ ...resetValues, error, [name]: value });

    }
    const handleChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        setErrorMessage(false);



        switch (name) {

            case "email":
                error.email = validator.isEmpty(value) ? "Email is required" : "";
                //error.email = validator.isEmail(value) ? "" : "Invalid email";
                break;
            default:
                break;
        }

        setvalues({ ...values, error, [name]: value });

    }

    return (
        <ModalStyled
            className="modal fade "
            size="sm"
            backdrop="static"
            centered
            show={gContext.forgotPasswordModalVisible}
            onHide={gContext.toggleForgotPasswordModal}
        >

            <div className="modal-content">


                <div className="modal-header">
                    <h4 className="modal-title">Forgot Password</h4>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={() => {
                        handleClose();
                    }}></button>
                </div>


                <div className="modal-body">
                    <div className="login-info-text m-0">
                        {successMessage.show === false && <>
                            <div class="alert alert-danger">
                                Note: You may not receive forgot password email if your email is not confirmed.
                            </div>
                            <p className="font-size-4 text-black" >
                                Enter your registered email address to reset your password.
                            </p>




                            <form>
                                {errorMessage != "" && <><div class="alert alert-danger">
                                    {errorMessage}
                                </div></>}
                                <div className="form-group  icon-input">
                                    <label for="email" className="form-label">E-mail<span>*</span></label>
                                    <span className="text-green m-icon material-icons">mail</span>
                                    <input
                                        type="email" name="email"
                                        className={`form-control    ${(values.error.email.length > 0) ? 'error' : ''}`}
                                        placeholder="example@gmail.com"
                                        id="email" required onChange={handleChange} onKeyPress={(e) => (handleForgotKeypress(e))}
                                    />
                                    {values.error.email.length > 1 && (
                                        <span className="errortext">{values.error.email}</span>
                                    )}
                                </div>
                            </form>

                            <button className="btn btn-green btn-block m-b-30" onClick={forgotPassword}>Submit</button>
                            <span className="text-gray m-b-10 d-flex align-item-center justify-content-center">Already know password?</span>
                            <button className="btn btn-dark-outline btn-block" onClick={(e) => {
                                e.preventDefault();
                                gContext.toggleSignInModal();
                                handleClose();
                            }}>Login</button>
                        </>}

                        {successMessage.show === true &&
                            <>
                                <Message message={(successMessage.value == "") ? ConfirmMessages.ForgotPwdConfirmation : ConfirmMessages.ForgotPwdResetCodeConfirmation.replace("[$email]", getEncodedEmail(values.email))} />


                                {(successMessage.show === true && successMessage.value != "") &&
                                    <form >
                                        {errorMessage != "" && <><span className="alert alert-danger">{errorMessage}</span> </>}
                                        <div className="form-group  icon-input">

                                            <label className="form-label">  Reset Code<span>*</span></label>
                                            <Icon className="text-theme m-icon material-icons">pin</Icon>
                                            <input
                                                type="code" name="code"
                                                className={`form-control    ${(resetValues.error.code.length > 0) ? 'error' : ''}`}
                                                placeholder="Reset code" onKeyPress={(e) => (handleKeypress(e))}
                                                id="code" required onChange={handleResetChange}
                                            />
                                            {resetValues.error.code.length > 1 && (
                                                <span className="errortext">{resetValues.error.code}</span>
                                            )}
                                        </div>

                                        <div className="form-group icon-input">



                                            <label className="form-label">Password<span>*</span></label>





                                            <Icon className="text-theme m-icon material-icons">lock</Icon>
                                            <Icon className="text-light-gray m-i-icon material-icons" onClick={(e) => {
                                                e.preventDefault();
                                                togglePasswordFirst();
                                            }}> {showPassFirst ? "visibility_off" : "visibility_on"}</Icon>

                                            <input
                                                type={showPassFirst ? "password" : "text"}
                                                className={`form-control    ${((resetValues.error.password1 != "valid" && resetValues.error.password1.length > 0)) ? 'error' : ''}`} name="password1"
                                                id="password1" minLength="12" placeholder="Enter password" required onChange={(e) => (handleResetChange(e))}
                                            />
                                            {resetValues.error.password1.length > 1 && (
                                                <span className="errortext">{resetValues.error.password1 !== "valid" && resetValues.error.password1}</span>
                                            )}
                                            {(resetValues.error.password1.length === 1) && (<>
                                                <span className={`${(errorPassword.min8) ? 'text-green' : 'errortext'} font-size-3`}> * Password must have minimum 8 characters</span><br />
                                                <span className={`${(errorPassword.upperCase) ? 'text-green' : 'text-error'} font-size-3`}>  * 1 uppercase letter</span> <br />
                                                <span className={`${(errorPassword.lowerCase) ? 'text-green' : 'text-error'} font-size-3`}>  * 1 lowercase letter</span> <br />
                                                <span className={`${(errorPassword.numeric) ? 'text-green' : ' text-error'} font-size-3`}> * 1 numeric value</span><br />
                                                <span className={`${(errorPassword.special) ? 'text-green' : ' text-error'} font-size-3`}> * 1 special character</span>
                                            </>)}
                                        </div>
                                        <div className="form-group m-b-30 icon-input">
                                            <label for="password2" className="form-label">Confirm Password<span>*</span></label>
                                            <Icon className="text-theme m-icon material-icons">lock</Icon>
                                            <Icon className="text-light-gray m-i-icon material-icons" onClick={(e) => {

                                                e.preventDefault();
                                                togglePasswordSecond();
                                            }}> {showPassSecond ? "visibility_off" : "visibility_on"}</Icon>

                                            <input
                                                type={showPassSecond ? "password" : "text"}
                                                className={`form-control   ${(resetValues.error.password2.length > 0) ? 'error' : ''}`}
                                                id="password2" name="password2"
                                                placeholder="Confirm password" required onChange={(e) => (handleResetChange(e))}
                                            />
                                            {resetValues.error.password2.length > 1 && (
                                                <span className="errortext">{resetValues.error.password2 !== "valid" && resetValues.error.password2}</span>
                                            )}
                                        </div>

                                        <button type="button" onClick={resetPassword} className="btn btn-green  btn-block"> Reset Password</button>


                                    </form>}

                                {(successMessage.show === true && successMessage.value == "") &&
                                    <button className="btn btn btn-dark-outline btn-block" href="#" onClick={(e) => {
                                        e.preventDefault();
                                        gContext.toggleSignInModal();
                                        handleClose();
                                    }}>Login</button>}
                            </>


                        }


                    </div>
                </div>

            </div>


            <Modal.Body className="p-0 d-none">
                <button
                    type="button"
                    className="circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper pointer"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <i className="fas fa-times"></i>
                </button>
                <div className="login-modal-main bg-white rounded-8 overflow-hidden">
                    <div className="row no-gutters">

                        <div className="col-lg-12 col-md-12">
                            <div className="pt-10 pb-6 pl-11 pr-12  bg-green-2 h-100 d-flex flex-column dark-mode-texts"
                            >
                                <div>
                                    <h3 className="font-size-8 text-white line-height-reset pb-4 line-height-1p4">
                                        Forgot Password
                                    </h3>

                                </div>

                            </div>
                        </div>

                        {successMessage.show === true && <>  <div className="row mt-10 mr-10 mx-10">


                            <Message message={(successMessage.value == "") ? ConfirmMessages.ForgotPwdConfirmation : ConfirmMessages.ForgotPwdResetCodeConfirmation.replace("[$email]", getEncodedEmail(values.email))} />
                            <div className=" col-12 form-group mb-8 mt-10  ">
                                {(successMessage.show === true && successMessage.value != "") &&
                                    <form >
                                        {errorMessage != "" && <><span className="error py-2   font-weight-semibold line-height-reset px-2 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span><br /></>}
                                        <div className="form-group">

                                            <label
                                                htmlFor="code"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                            >
                                                Reset Code <span className="error font-size-3">*</span>
                                            </label>
                                            <input
                                                type="code" name="code"
                                                className={`form-control    ${(resetValues.error.code.length > 0) ? 'form-control-error' : ''}`}
                                                placeholder="Reset code" onKeyPress={(e) => (handleKeypress(e))}
                                                id="code" required onChange={handleResetChange}
                                            />
                                            {resetValues.error.code.length > 1 && (
                                                <span className="text-red font-size-3">{resetValues.error.code}</span>
                                            )}
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="password1"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                            >
                                                New Password <span className="error font-size-3">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <input
                                                    type={showPassFirst ? "password" : "text"}
                                                    className={`form-control font-weight-normal  ${((resetValues.error.password1 != "valid" && resetValues.error.password1.length > 0)) ? 'form-control-error' : ''}`} name="password1"
                                                    onKeyPress={(e) => (handleKeypress(e))} id="password1" minLength="12" placeholder="Enter password" required onChange={(e) => (handleResetChange(e))}
                                                />
                                                <a

                                                    className="pos-abs-cr fas mr-6 text-black-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        togglePasswordFirst();
                                                    }}>
                                                    {showPassFirst ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash " ></i>}

                                                </a>
                                            </div>
                                            {resetValues.error.password1.length > 1 && (
                                                <span className="error font-size-3">{resetValues.error.password1 !== "valid" && resetValues.error.password1}</span>
                                            )}
                                            {(resetValues.error.password1.length === 1) && (<>
                                                <span className={`${(errorPassword.min8) ? 'text-green' : 'error'} font-size-3`}> * Password must have minimum 8 characters</span> <br />
                                                <span className={`${(errorPassword.upperCase) ? 'text-green' : 'error'} font-size-3`}>  * 1 uppercase letter</span> <br />
                                                <span className={`${(errorPassword.lowerCase) ? 'text-green' : 'error'} font-size-3`}>  * 1 lowercase letter</span> <br />
                                                <span className={`${(errorPassword.numeric) ? 'text-green' : 'error'} font-size-3`}> * 1 numeric value</span><br />
                                                <span className={`${(errorPassword.special) ? 'text-green' : 'error'} font-size-3`}> * 1 special character</span>
                                            </>)}
                                        </div>
                                        <div className="form-group">
                                            <label
                                                htmlFor="password2"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                            >
                                                Confirm Password <span className="error font-size-3">*</span>
                                            </label>
                                            <div className="position-relative">
                                                <input
                                                    type={showPassSecond ? "password" : "text"}
                                                    className={`form-control   ${(resetValues.error.password2.length > 0) ? 'form-control-error' : ''}`}
                                                    id="password2" name="password2"
                                                    placeholder="Confirm password" required onChange={(e) => (handleResetChange(e))}
                                                />
                                                <a

                                                    className=" pos-abs-cr fas mr-6 text-black-2"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        togglePasswordSecond();
                                                    }}
                                                >  {showPassSecond ? <i className="fa fa-eye" ></i> : <i className="fa fa-eye-slash " ></i>}
                                                    <span className="d-none">none</span>
                                                </a>
                                            </div>
                                            {resetValues.error.password2.length > 1 && (
                                                <span className="error font-size-3">{resetValues.error.password2}</span>
                                            )}
                                        </div>
                                        <div className="form-group mb-8 position-relative">
                                            <div className="w-100 w-lg-40 position-relative">    <button type="button" className="btn btn-primary btn-medium w-100  rounded-5 text-uppercase" onClick={resetPassword} >
                                                Reset Password
                                            </button>
                                            </div>   </div>

                                    </form>}



                            </div>

                            {(successMessage.show === true && successMessage.value == "") &&
                                <div className=" col-12 form-group mb-8 ">   <button type="button" className="btn btn-primary  btn-medium  rounded-5 text-uppercase" onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleSignInModal();
                                    handleClose();
                                }}>
                                    <i className="fa fa-sign-in-alt mr-2 pt-1" > </i>    Login
                                </button></div>}

                        </div>
                        </>}

                        {successMessage.show === false && <>
                            <div className="col-lg-12 col-md-12">
                                <div className="bg-white-2 h-100 px-11 pt-11 pb-7">
                                    <div className="pb-9">
                                        <h3 className="font-size-8 text-black line-height-reset pb-4 line-height-1p4">

                                        </h3>
                                        <p className="mb-0 font-size-4 text-black">
                                            Enter your registered email address to reset your password.
                                        </p><br />
                                        <p className="mb-0 font-size-4 text-red-2">
                                            Note: You may not receive forgot password email if your email is not confirmed.
                                        </p>
                                    </div>

                                    <form >
                                        {errorMessage != "" && <><span className="error py-2   font-weight-semibold line-height-reset px-2 bg-red-opacity-2 rounded-5 font-size-4">{errorMessage}</span><br /></>}
                                        <div className="form-group">
                                            <label
                                                htmlFor="email"
                                                className="font-size-4 text-black-2 font-weight-semibold line-height-reset"
                                            >
                                                E-mail <span className="error font-size-3">*</span>
                                            </label>
                                            <input
                                                type="email" name="email"
                                                className={`form-control    ${(values.error.email.length > 0) ? 'form-control-error' : ''}`}
                                                placeholder="example@gmail.com"
                                                id="email" required onChange={handleChange} onKeyPress={(e) => (handleForgotKeypress(e))}
                                            />
                                            {values.error.email.length > 1 && (
                                                <span className="text-red font-size-3">{values.error.email}</span>
                                            )}
                                        </div>
                                        <div className="text-center">
                                            <div className="w-100 w-lg-40 position-relative"> <button type="button" className="btn btn-primary  btn-medium w-100  rounded-5 text-uppercase" onClick={forgotPassword} >
                                                Submit
                                            </button> </div>
                                        </div>
                                        <p className="font-size-4 text-center heading-default-color mt-5">
                                            Back to {" "}
                                            <a className="text-primary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    gContext.toggleSignInModal();
                                                    handleClose();
                                                }}>
                                                Log in
                                            </a>
                                        </p>
                                    </form>
                                </div>
                            </div></>}
                    </div>
                </div>
            </Modal.Body>
        </ModalStyled>
    );
};

export default ModalForgotPassword;
