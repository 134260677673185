import React, { useContext, useEffect, useState } from "react";
import StarRatings from 'react-star-ratings';
import styled from "styled-components"; 
import GlobalContext from "../../context/GlobalContext"; 
import Message from "../../components/Message"; 
import validator from 'validator';
import ReactQuill from 'react-quill';
import { Modal } from "react-bootstrap";
import DOMPurify from 'isomorphic-dompurify';
import { CreateFeedback, CreateAlert } from "../../utils/apiCalls";
import { isInvalid } from "../../utils/helperFn";

import AlertUtils from "../../utils/AlertUtils";

const ModalStyled = styled(Modal)`
   &.modal {
    
 
  }
`;
export default function Feedback(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext); 
    const reactQuillRef = React.useRef();
    const [compvalues, setCompValues] = useState({ feedbackText: "", feedback: "", rating: 0, error: { feedback: "", rating:"" } });
    const [contract, setContract] = useState({});
    const [editvalue, setEditvalue] = useState('');
    const [editorText, seteditorText] = useState('');
    const [editLength, setEditLength] = useState(0);
    const [showUpdate, setShowUpdate] = React.useState(false);
    const [updateStatus, setUpdateStatus] = useState(false);
    const handleClose = () => {

    };
    const modules = {
       
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };
    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];
    const editorChange = (content, delta, source, editor) => {
        
        let { error, ...rest } = compvalues;
        if (editor.getText().trim().length <= 500) {
            setEditvalue(editor.getHTML());
            seteditorText(editor.getText().trim());
            setEditLength(editor.getText().trim().length);

            if (isHtml(editor.getText())) {
                error.feedback = "Invalid format.";
                setCompValues({ ...compvalues, error, ['feedbackText']: editor.getText().trim(), ['feedback']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
            else {
                error.feedback = "";
                setCompValues({ ...compvalues, error, ['feedbackText']: editor.getText().trim(), ['feedback']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
        } else {
            setEditvalue(editvalue + " ");
            seteditorText(editor.getText().trim()+" ");
            setEditLength(editLength);
        }
    };
    const editorKeyDown = (event) => {

        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 500 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };
    function changeRating(rating) {
       
        setCompValues({ ...compvalues, ["error"]: { ...compvalues.error,["rating"]:""}, ["rating"]: rating });
    }
    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }  
    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = compvalues; 
        error.rating = (rest.rating == 0) ? "Rating is required" : "";
        error.feedback = (editLength == 0) ? "Feedback is required" : (isInvalid(editorText)? "Invalid format" :error.feedback); 
        setCompValues({ ...compvalues, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    async function SendNotificationfromExpert() {

        const profileInfo = gContext.userInfo.profileInfo;
         
        
        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            let alert = AlertUtils.FeedbacktoClient.
                    replace("[EXPERTSUB]", gContext.userInfo.sub).
                    replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                    replace("[JOBTITLE]", contract.title).
                replace("[GUID]", contract.contractguid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: contract.clientid });

            if (result.data.createAlert.responseData.code == "200") {
                
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            
        }

    }
    async function SendNotificationfromClient() {

        const profileInfo = JSON.parse(gContext.userInfo.profileInfo);
        

        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = AlertUtils.FeedbacktoExpert.
                replace("[CLIENTSUB]", gContext.userInfo.sub).
                replace("[COMPANY]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].company : "").
                replace("[JOBTITLE]", contract.title).
                replace("[GUID]", contract.contractguid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 1, toUserId: contract.expertid });

            if (result.data.createAlert.responseData.code == "200") {
                
            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
           
        }

    }
    async function UpdateFeedback() {
        try {
            if (checkValidity()) { 
                
                const result = await CreateFeedback({ contractId: contract.id, clientId: (gContext.userInfo.userType == "1") ? contract.clientid : gContext.userInfo.userID, expertId: (gContext.userInfo.userType == "2") ? contract.expertid : gContext.userInfo.userID, jobId: contract.jobid, feedback: compvalues.feedback, rating: (compvalues.rating == 0) ? null : compvalues.rating, senderType: gContext.userInfo.userType });
                
                if (result.data.createFeedback.responseData.code == "200") {
                    if (gContext.userInfo.userType == "1")
                        SendNotificationfromExpert();
                    else if (gContext.userInfo.userType == "2")
                      SendNotificationfromClient();
                   // gContext.toggleRefreshDashboard("contract");
                    setUpdateStatus(true);
                } 
                
            }
            
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            console.log(e);
        }
    }
    useEffect(() => {
        console.log("props.contract", props.contract);
        if (props.contract) {
           
            setContract(props.contract);
        } 
    }, [props.contract]);

    useEffect(() => {
        if (!props.modalvisible  )
         { setUpdateStatus(false); setEditvalue(""); seteditorText(""); setEditLength(0);setCompValues({ feedbackText: "", feedback: "", rating: 0, error: { feedback: "", rating: "" } }); }

    }, [props.modalvisible]);


    return (
        <>
            <ModalStyled  
                 
                size={(updateStatus === true) ? "sm" : "lg"}
                centered
                show={props.modalvisible}
                backdrop="static"
                onHide={props.togglemodal}
            >  {updateStatus === false && <div className="modal-content">


                <div className="modal-header">
                    <h4 className="modal-title" >Feedback Form</h4>
                    <button type="button" className="btn-close" onClick={() => {

                            props.togglemodal();

                    }}></button>

                    </div>
                    {contract.id && <>   <div className="modal-body">
                        {gContext.userInfo.userType == "1" &&
                            <div className="company-post">
                                <div className="medie-info-img">
                                    {(contract.logo) && <img src={s3URL + contract.logo} alt="" />}
                                </div>

                                <div className="d-flex flex-column">
                                    <a className="m-b-10" href="#"><b> {(contract.company) ? contract.company : ''}</b></a>
                                    <span>{(contract.comptagline) ? contract.comptagline : ''}</span>
                                </div>
                            </div>
                        }
                        {gContext.userInfo.userType === "2" && 


                            <a className="   pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + contract.sub}  >
                                <div className="company-post">
                                    <div className="medie-info-img">
                                        <img src={s3URL + contract.avatar} alt="" />
                                    </div>

                                    <div className="d-flex flex-column">
                                        <a className="m-b-10 m-t-10" href="#"><b>{contract.firstname + ' ' + contract.lastname}</b></a>

                                    </div>
                                </div>

                            </a>}

                       

                        <div className="contract-agreement-block">

                            <div className="row">

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input m-b-20">
                                        <label for="first name" className="form-label m-b-10">Rating<span className="text-red">*</span></label>
                                        < StarRatings starDimension="24px"
                                            starSpacing="2px"
                                            starRatedColor="#F7921E"
                                            starSelectingHoverColor="#F7921E"
                                            starHoverColor="#F7921E" rating={compvalues.rating} changeRating={(e) => { changeRating(e); }} isSelectable="true" />

                                        {compvalues.error.rating.length > 1 && (
                                            <span className="text-red m-t-20">{compvalues.error.rating}</span>
                                        )}
                                    </div>
                                </div>

                                <div className="company-desc ">
                                    <div className="desc-info">
                                        <label className="form-label m-b-0 f-16"> Feedback   <span className="text-red"> * (Max 500 character )</span></label>
                                        <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green">   {500 - ((editLength) ? editLength : 0)}</span></p>
                                    </div>


                                    <ReactQuill modules={modules}
                                        formats={formats} theme="snow" id="editor" style={{ height: "15vh" }} className="m-b-30"
                                        onKeyDown={editorKeyDown}
                                        ref={reactQuillRef}
                                        value={editvalue} onChange={editorChange}
                                    ></ReactQuill>
                                    {compvalues.error.feedback.length > 1 && (
                                        <span className="text-red m-t-20">{compvalues.error.feedback}</span>
                                    )}

                                </div>




                            </div>


                        </div></div>
                        <div className="modal-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm bg-green m-r-20 text-white" onClick={() => {
                                    UpdateFeedback();

                                }}>Submit</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                    props.togglemodal();

                                }}>Cancel</button>
                            </div>
                        </div></>}

                </div>}
                {updateStatus === true &&
                    <div className="modal-body text-center">
                        <img className="m-b-20" src="../images/sucess-check.svg" alt="sucess-check" />
                        <h5 className="m-b-10">Successful!</h5>
                    <p className="text-light-gray">  The feedback has been updated successfully. </p>

                        <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green w-120" onClick={() => { props.togglemodal();}}>Ok</button>
                        </div>

                    </div>
                }
                <Modal.Body className="p-0 d-none">
                    <button
                        type="button"
                        className="d-none circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>

                    {updateStatus === false &&  
                        <div className="container px-0 py-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12  ">
                                    <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                        Feedback Form
                                        </div>
                                </div>
                                <div className="col-xl-12 col-lg-12  mx-auto ">
                                    {contract.id && <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                        {/* <!-- Single Featured Job --> */}
                                        <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                            <div className="row mb-6">
                                                <div className="col-lg-6">
                                                    {gContext.userInfo.userType == "1" &&
                                                        <div className="media align-items-center">
                                                            {/* <!-- media logo start --> */}
                                                            <div className="square-72   d-block mr-8">
                                                                {(contract.logo) && <img src={s3URL + contract.logo} className="w-100 rounded" alt="" />}
                                                            </div>
                                                            {/* <!-- media logo end --> */}
                                                            {/* <!-- media texts start --> */}
                                                            <div>
                                                                <h3 className="font-size-6 mb-0">
                                                                    {(contract.company) ? contract.company : ''}
                                                                </h3>
                                                                <span className="font-size-3 text-gray line-height-2">
                                                                    {(contract.comptagline) ? contract.comptagline : ''}
                                                                </span><br />

                                                            </div>
                                                            {/* <!-- media texts end --> */}
                                                        </div>}
                                                    { gContext.userInfo.userType === "2" && <a className="media  pointer" target="_blank" rel="noopener noreferrer" href={"/expert/" + contract.expertsub}  >
                                                        <div className="  mr-6">
                                                            <img src={s3URL + contract.avatar} alt="" className=" circle-72 w-100" />
                                                        </div>
                                                        <h4 className="font-size-6 mb-0 font-weight-semibold text-black-2 pointer my-auto"   >
                                                            {contract.firstname + ' ' + contract.lastname}
                                                        </h4>
                                                    </a>}
                                                </div>
                                                <div className="col-md-6 pt-10 pt-md-0 mt-md-n1 text-right">


                                                </div>
                                            </div> 
                                             
                                        </div>
                                        {/* <!-- End Single Featured Job --> */}
                                         

                                  
                                         
                                            <div className="container px-5 pt-5">
                                                <div className="row justify-content-center">
                                                    
                                                    <div className="col-lg-6 pl-10 pr-sm-11 pr-4 pt-5   ">
                                                        <div className="form-group  ">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                        Rating <span className="error font-size-3"> * </span> 
                                                                </label>
                                                            < StarRatings starDimension="24px"
                                                                starSpacing="2px"
                                                                starRatedColor="#569d11"
                                                                starSelectingHoverColor="#569d11"
                                                                starHoverColor="#569d11" rating={compvalues.rating} changeRating={(e) => { changeRating(e); }} isSelectable="true" />
                                                </div>
                                                {compvalues.error.rating.length > 1 && (
                                                    <span className="error font-size-3">{compvalues.error.rating}</span>
                                                )}
                                                    </div>
                                                    <div className="col-lg-6 pl-10 pr-sm-11 pr-4 pt-5  ">

                                                    </div>
                                                    <div className="col-lg-12  pl-sm-10 pl-10 pr-sm-11 pr-4 pt-5   " >
                                                        <div className="form-group   ">
                                                            <label
                                                                htmlFor="namedash"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                                Feedback  <span className="error font-size-3">*  ( Max 500 Character)</span>
                                                                <br /><span className="font-size-3 text-green-2 font-weight-semibold">  Remaining Character length: {500 - ((editLength) ? editLength : 0)}</span>
                                                            </label>

                                                            
                                                            {compvalues.error.feedback.length > 1 && (
                                                                <span className="text-red">{compvalues.error.feedback}</span>
                                                            )}
                                                        </div>
                                                    </div>


                                                    <div className="col-lg-12  justify-content-md-end pr-10"><div className="media py-10 w-100  justify-content-md-end "  >

                                                        <div className="p-0 m-0 position-relative ">

                                                            <button
                                                                type="button" className="btn btn-green  font-size-3 font-weight-bold border-0 text-white  rounded-5 text-uppercase"

                                                                onClick={() => {
                                                                    UpdateFeedback();

                                                                }}>
                                                                Submit
                            </button>
                                                        </div>

                                                        <button
                                                            type="button"

                                                            className="btn btn-green  font-size-3 font-weight-bold border-0 text-white 
                                                                    rounded-5 text-uppercase ml-5 ml-sm-3"

                                                            onClick={() => {
                                                                props.togglemodal();

                                                            }}>
                                                            Cancel
                            </button>

                                                    </div> </div>
                                                </div>

                                            </div>

                                      
                                       
                                    </div>}
                                </div>
                            </div>

                        </div>
                    }
                         
                       
                    {updateStatus === true &&
                        <div className="row  px-auto  text-center pt-5">
                    <Message message={'The feedback has been updated successfully.'} /> 
                    
                        </div>
                    }

                </Modal.Body>
            </ModalStyled>
        </>
    );
};
