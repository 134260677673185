import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import ConfirmMessages from "../../utils/confirmMessages";
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import { GetExpertCertificate, UpdateExpertCertificate, DeleteExpertRow } from "../../utils/apiCalls";
import { ExpertFileUpload, getExpertFile, createDirName } from "../../utils/fileUpload";
import { GetComplianceTypes } from "../../utils/GetAPIUtils";
import { isInvalid, isEmailContain } from "../../utils/helperFn";
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { Select } from "../../components/Core";
import Message from "../../components/Message";
import validator from 'validator';
const imgLoader = "../assets/image/svg/spinning-circles.svg";
const imgP = "../assets/image/avatar.png";
const Certificate = (props) => {
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const gContext = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [storedCertificate, setStoredCertificate] = useState([]);
    const [complianceList, setComplianceList] = useState([]);
    const [rowsCertificate, setRowsCertificate] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [message, setMessage] = React.useState(false);
    const [tooltip, setTooltip] = useState(false);
    const [loaded, setLoaded] = useState(false);
    async function getUtil() {
        const responseCompliance = await GetComplianceTypes();
        if (responseCompliance.data && responseCompliance.data.complianceTypes) {
            let compliance = [];
            let list = gContext.userInfo.profileInfo[0].compliances.filter(item => item.code != "Others");
            list.map((value) => (
                compliance.push({ value: value.code, label: value.code })));
            compliance.push({ value: "Others", label: "Others" })
            setComplianceList(compliance);
             
        }
    }
    const popoverCertificate = (
        <Popover className="   " >

             
            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p> Certificate Credential  and ID name is mandatory for certificate verification.<br/> Allowed format pdf,jpeg,png,jpg</p>
                 
            </div>


        </Popover>
    );
    function prevPage() {
        gContext.setCurrentPage('6');
        // gContext.setLoaderModalVisible(true); 
    }
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);

    function nextPage() {
        gContext.setCurrentPage('11');
        // gContext.setLoaderModalVisible(true); 
    }


    function timeout(delay: number) {
        return new Promise(res => setTimeout(res, delay));
    }


    function checkValidity(index) {
        let isValid = false;


        let { error, ...rest } = rowsCertificate[index];

        //Object.values(rest).forEach(val => {
        //    if (val === '' || val === null) {
        //        isValid = true;
        //    }
        //});

        error.name = validator.isEmpty(rest.name) ? " Name is required" : (isInvalid(rest.name)? "Invalid format": error.name);
        error.expDate = (rest.expDate) ? error.expDate : "Expiration Date is required";
        error.digitalLink = (validator.isEmpty(rest.digitalLink) && (!rest.fileName)  && (!rest.file))? "Enter link or file" : error.digitalLink;
        error.fileName = (validator.isEmpty(rest.digitalLink) && (!rest.fileName) && (!rest.file)) ? "Enter link or file" : "";

 
       
        const rows = [...rowsCertificate];
        rows[index][error] = error;
        setRowsCertificate(rows);

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });

        if (isValid)
            return false;
        else
            return true;

    }

    function cancelEdit() {
        setRowsCertificate([]);
        gContext.setCurrentValuePage('4');
    }
    async function saveCertificate(index) {

        setMessage(false);
        setErrorMessage('');

        if (checkValidity(index)) {
            const rowsInput = [...rowsCertificate];
            rowsInput[index]['load'] = true;
            setRowsCertificate(rowsInput);
            try {
                gContext.setLoaderModalVisible(true);
                var data = rowsCertificate[index];
                if (data.file) {
                    var file = getExpertFile(data.file);

                    if (file) {
                        ExpertFileUpload(file, "certificate");
                        data.fileName = createDirName() + "/certificate/" + file.name;
                    }
                    else {
                        setErrorMessage("File upload error");
                        return;
                    }
                }
                const result = await UpdateExpertCertificate(data);

                if (result.data.expertCerUpdate.responseData.code == "200") {
                    //gContext.setCurrentValuePage("5");
                    //gContext.setVisibleProfileMessage(true);
                    gContext.getProfileInfo(gContext.userInfo.userType, gContext.userInfo.email, gContext.userInfo.sub);
                    setMessage(true);
                    getCertificate();
                    setErrorMessage("");

                }
                else {

                    setMessage(false);
                    setErrorMessage(JSON.parse(result.data.expertCerUpdate.responseData.message));
                }
                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                setMessage(false);
                gContext.setLoaderModalVisible(false);
                setErrorMessage("Server error");
                console.log(e);
            }


            window.scrollTo({
                top: 0,
                behavior: 'smooth'

            });

        }
        else { setMessage(false);
            setErrorMessage("Required fields are missing.");

        }

    }

    function addTableRows(data) {
        setMessage(false);
        const rowsInput = {
            load: false, id: null, expertId: gContext.userInfo.userID,status:false,
            name: '', digitalLink: '', file: '', fileName: '', resetFileName: '', otherName:"", expDate: null, error: { name: '', digitalLink: '', fileName: '', expDate: '' }
        }

        setRowsCertificate([...rowsCertificate, rowsInput]);

    }

    function setLoad(status, index) {
        const rows = [...rowsCertificate];
        // rows[index]['load'] = status;
        setRowsCertificate(rows);
    }
    function spliceRow(index) {
        const rows = [...rowsCertificate];
        rows.splice(index, 1);
        setRowsCertificate(rows);
    }
    async function deleteData(index, id) {

        setMessage(false);
        setLoad(true, index);
        spliceRow(index);
        try {
            gContext.setLoaderModalVisible(true);
            const result = await DeleteExpertRow(id, 'cer', (storedCertificate.length === 1) ? 0 : null);

            if (result.data.expertRowDelete.responseData.code == "200") {
                getCertificate();
            }
            else {
                setLoad(false, index);
            }
        }
        catch (e) {
            console.log(e);
            setLoad(false, index); gContext.setLoaderModalVisible(false);
        }
        gContext.setLoaderModalVisible(false);
    }
    /*For Social table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Certificate delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true });

            gContext.toggleConfirmModal();
            //gContext.setLoaderModalVisible(false);
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
    }
    const handleDateChange = (index, name, value) => {
        setMessage(false);
        const rowsInput = [...rowsCertificate];

        rowsInput[index][name] = value;
        rowsInput[index]["error"][name] = "";
        setRowsCertificate(rowsInput);

    }

    const handleChange = (index, event,code) => {
        setMessage(false);
        const { value } = (code == "otherName") ? event : event.target;

        

        const rowsInput = [...rowsCertificate];
        if (code == "otherName" && event.label != "Others") {
            rowsInput[index]["otherName"] = "";
            rowsInput[index]["name"] = value;
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["name"]: "" }

        } else if (code == "otherName" && event.label == "Others"){
            rowsInput[index]["otherName"] = "Others";
            rowsInput[index]["name"] = "";
        }
        else
        rowsInput[index][code] = value;

        
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], [code]: "" }
            if (code == "digitalLink") {

                rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", [code]: (value.trim() != "") ? ((validator.isURL(value) && !isEmailContain(value)) ? "" : "Digital Link is invalid") : "" }
            }
         
        setRowsCertificate(rowsInput);
    }
    const onFileChange = (index, event) => {
        setMessage(false);
      
        if (event.target.files.length > 0) {

            TestFileType(index,event.target.files[0],['gif', 'jpg', 'png', 'jpeg','pdf']);

           /* const rowsInput = [...rowsCertificate];
            //  rowsInput[index]["fileName"] = event.target.files[0]["name"];
            rowsInput[index]["file"] = event.target.files[0];
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", ["digitalLink"]: "" }
            setRowsCertificate(rowsInput);*/
        }
    }



    function TestFileType(index, fileName, fileTypes )
    {       
      
       
        var type = fileName.type.substring(fileName.type.indexOf("/") + 1, fileName.type.length);
        
        if ((fileTypes.indexOf(type) != -1) == true) {
            const rowsInput = [...rowsCertificate];
            rowsInput[index]["file"] = fileName;
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", ["digitalLink"]: "" }
            setRowsCertificate(rowsInput);
        }
        else {
            
            const rowsInput = [...rowsCertificate];           
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "Invalid file format" }
            setRowsCertificate(rowsInput);
        }
     }
            const ChangeFile = (index, value) => {
            setMessage(false);
            const rowsInput = [...rowsCertificate];
            rowsInput[index]["file"] = null;
            rowsInput[index]["fileName"] = value;
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], ["fileName"]: "", ["digitalLink"]: "" }
            setRowsCertificate(rowsInput);

    }

    async function getCertificate() {
        try {
            setLoaded(false);
            const result = await GetExpertCertificate(gContext.userInfo.userID);

            if (result.data.expertCerLoad.responseData.code == "200") {
                
                BindData(JSON.parse(result.data.expertCerLoad.responseData.message));
                gContext.setLoaderModalVisible(false);
            }
            setLoaded(true);
        }
        catch (e) {
            setLoaded(true);
        }

    }

    function BindData(data) {
        //"[{\"sub\":\"f180bc26-27fa-4a1b-9b75-fee8dd832db0\",\"degreeid\":2,\"major\":\"\",\"school\":\"\",\"countryid\":61,\"startdate\":\"2010-06-01T00:00:00.000Z\",\"enddate\":\"2012-05-01T00:00:00.000Z\"}]"
        const rowsInput = [];

        try {
            setStoredCertificate(data);
            if (data.length > 0) {
                data.map((row) => {
                    rowsInput.push({
                        load: false, id: row.id, expertId: gContext.userInfo.userID, otherName: "",status:row.status,
                        expDate: (row.certexpirydate) ? new Date(row.certexpirydate.toString().replace("T00:00:00.000Z", "T08:00:00.000Z")) : null, name: row.name, digitalLink: row.url, file: '', resetFileName: row.filename, fileName: row.filename, error: { name: '', digitalLink: '', fileName: '', expDate: '' }
                    });
                });
                setRowsCertificate(rowsInput);
                gContext.setLoaderModalVisible(false);

            }
            else {
                setRowsCertificate([]);
                gContext.setLoaderModalVisible(false);
            }
        } catch (e) {
            console.log(e); gContext.setLoaderModalVisible(false);
        }



    }


    useEffect(() => {
        gContext.setLoaderModalVisible(true);
        timeout(2000);

        if (gContext.confirmModal.confirmed) {
            deleteData(gContext.confirmModal.index, gContext.confirmModal.id);
        }
        else { setCurrentValue({ index: null, id: null }); }
        gContext.setLoaderModalVisible(false);
    }, [gContext.confirmModal]);
    useEffect(() => {
        getUtil();
        document.title = "Certificate | Syncuppro";
        getCertificate({ index: null, id: null });
        

    }, []);
    return (
        <>
            

            {loaded && <>
                <div className="card panel  p-0">

                    <div className="card-header">

                        <div className="client-title">
                            <h6>CERTIFICATE INFORMATION</h6>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead>
                                    <tr><th></th>
                                        <th className="min-lg-cell">Certified for<span className="text-red"> * </span></th>
                                        <th className="min-lg-cell"></th>
                                        <th className="min-lg-cell" >Digital Link</th>
                                        <th  ></th>
                                        <th className="w-150"><div className="d-flex align-items-center">File Upload <OverlayTrigger trigger="hover" placement="auto" overlay={popoverCertificate} >
                                            <span className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                        </OverlayTrigger></div></th>
                                        <th className="w-150 min-md-cell">Expiration Date</th>
                                        <th className="w-50 " ></th>
                                        <th className="w-50  "></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rowsCertificate.map((data, index) => {
                                        const { load, id, name, digitalLink, expDate, fileName, resetFileName, file, error, otherName, status } = data;

                                        const cate = complianceList.filter(item => item["value"] == name);
                                        
                                        return (<tr key={index}>
                                            <td className="tablerow">{status && <span className="text-theme m-icon material-icons" data-toggle="tooltip" title="Verified" >check</span>}</td>
                                            <td className="w-200 tablerow">

                                                <Select id={`name{index}`} menuPortalTarget={document.body} menuPosition={'fixed'}
                                                    options={complianceList} value={(name == "" && otherName == "") ? null : ((cate.length > 0) ? { value: name, label: name } : { value: "Others", label: "Others" })}
                                                    className={`  form-select padding0-select w-200  ${(error.name.length > 0) ? ' error' : ''}`}
                                                    border={false} placeholder="Select..." onChange={(e) => { if(!status) handleChange(index, e, 'otherName') }}
                                                />{error.name.length > 1 && (
                                                    <span className="text-red ">{error.name}</span>
                                                )}
                                                
                                                
                                            </td>
                                            <td className="tablerow"> {(otherName == "Others" || ( cate.length == 0 && name !="")) && <input
                                                name="name"
                                                type="text"
                                                className={`form-control   ${(error.name.length > 0) ? ' error' : ''}`}
                                                id="name"
                                                placeholder="Name" disabled={status} defaultValue={name} onChange={(e) => (handleChange(index, e, 'name'))}
                                            />}</td>
                                            <td className="tablerow">
                                                <input
                                                    name="digitalLink"
                                                    type="text"
                                                    className={`form-control  ${(error.digitalLink.length > 0) ? 'error' : ''}`}
                                                    id="digitalLink"
                                                    placeholder="Digital Link" disabled={status} defaultValue={digitalLink} onChange={(e) => (handleChange(index, e, "digitalLink"))}
                                                /> {error.digitalLink.length > 1 && (
                                                    <span className="text-red ">{error.digitalLink}</span>
                                                )}
                                            </td>
                                            <td >
                                                <span>OR</span>
                                            </td>
                                            <td className="tablerow">
                                                


                                                    {(fileName === "") && <>

                                                        {(!file) &&
                                                        <div className="view-btn">
                                                        {status ? <label disabled={status} className="btn btn-dark-outline text-black file-btn d-flex align-item-center pointer"  >
                                                            <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                        </label>: <label htmlFor={`fileUpload${index}`} className="btn btn-dark-outline text-black file-btn d-flex align-item-center pointer" data-toggle="tooltip" title="Click here to add file" >
                                                            <span className="m-icon material-icons m-r-5">file_upload</span>Browse
                                                            </label>}
                                                            <input
                                                                type="file" key={file}
                                                                id={`fileUpload${index}`} accept="image/gif,image/png,image/jpeg,image/jpg,application/pdf"
                                                                className="sr-only"
                                                                onChange={(e) => onFileChange(index, e)}
                                                            />
                                                        </div>

                                                        }

                                                        {(file) &&
                                                            <div className="view-btn">
                                                            <a className="btn btn-dark-outline text-black file-btn d-flex  pointer" data-toggle="tooltip" title="Click here to view file" onClick={() => { window.open((file) ? window.URL.createObjectURL(file) : s3URL + fileName, "_blank"); }}  >
                                                              <span className="m-icon material-icons m-r-5">visibility</span>View File 
                                                           
                                                            </a>
                                                        <button type="button" disabled={status} className="btn-icon-close" data-toggle="tooltip" title="Remove file" onClick={() => ChangeFile(index, resetFileName)}><span className="m-icon material-icons">close</span></button>
                                                        </div>
                                                           }

                                                          

                                                       
                                                    </>}
                                                    {(fileName !== "") &&
                                                    <>

                                                    
                                                        <div className="view-btn">
                                                            <a className="btn btn-dark-outline text-black file-btn    pointer" data-toggle="tooltip" title="Click here to view file" onClick={() => { window.open((file) ? window.URL.createObjectURL(file) : s3URL + fileName, "_blank"); }}>
                                                             <span className="m-icon material-icons m-r-5">visibility</span>View File 
                                                            
                                                            </a>
                                                        <button type="button" disabled={status} className="btn-icon-close" data-toggle="tooltip" title="Remove file" onClick={() => ChangeFile(index, "")}><span className="m-icon material-icons">close</span></button>
                                                        </div>
                                                        </>}
                                                    {error.fileName.length > 1 && (
                                                        <>    <span className="text-red ">{error.fileName}</span></>
                                                    )}




                                                
                                            </td>
                                            <td className="tablerow">
                                                <CustomDatePicker className="form-control " disabled={status}
                                                    selected={expDate}
                                                    onChange={(date) => (handleDateChange(index, 'expDate', date))}
                                                    placeholderText="Expiration Date" minDate={new Date()}
                                                />  {error.expDate.length > 1 && (
                                                    <span className="text-red">{error.expDate}</span>
                                                )}
                                            </td>
                                            <td className="tablerow">
                                                { !status && <a className="btn btn-circle light"  data-toggle="tooltip" title="Save Row" onClick={() => { if(!status)  saveCertificate(index)   }}><span className="text-theme m-icon material-icons">  {(id) ? "edit" : "save"}</span></a>}
                                            </td>
                                            <td className="tablerow"> 
                                                { !status && <a className="btn btn-circle light"  disabled={load} title="Delete Row" onClick={() => { if(!status)  deleteTableRows(index, id)  }}><span className="text-theme m-icon material-icons">delete</span></a>}
                                            </td>
                                        </tr>)
                                    })}

                                     

                                </tbody>
                            </table>
                            {(rowsCertificate && rowsCertificate.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                                <span className="text-light-gray m-icon material-icons m-b-10">menu</span>
                                <span>No Certificate found</span>
                            </div>}
                        </div>

                        <div className="add-more">
                            <button type="button" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row"  className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                        </div>

                    </div>

                </div>

            </>}
            {!loaded &&
            <>
                <div className="card panel  p-0">

                    <div className="card-header">

                        <div className="client-title movingLoader w-150">
                            <h6> &nbsp;</h6>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <thead className="movingLoader">
                                    <tr><th></th>
                                        <th className="min-lg-cell"> </th>
                                        <th className="min-lg-cell"></th>
                                        <th className="min-lg-cell" > </th>                                         
                                        <th className="w-100 min-md-cell" ></th>
                                        <th className="w-100 min-md-cell"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr ><td></td>
                                        <td className="min-lg-cell"> </td>
                                        <td className="min-lg-cell"></td>
                                        <td className="min-lg-cell" > </td>
                                        <td className="w-100 min-md-cell" ></td>
                                        <td className="w-100 min-md-cell"></td>
                                    </tr>
                                    <tr className="movingLoader"><td></td>
                                        <td className="min-lg-cell"> </td>
                                        <td className="min-lg-cell"></td>
                                        <td className="min-lg-cell" > </td>
                                        <td className="w-100 min-md-cell" ></td>
                                        <td className="w-100 min-md-cell"></td>
                                    </tr>
                                    <tr ><td></td>
                                        <td className="min-lg-cell"> </td>
                                        <td className="min-lg-cell"></td>
                                        <td className="min-lg-cell" > </td>
                                        <td className="w-100 min-md-cell" ></td>
                                        <td className="w-100 min-md-cell"></td>
                                    </tr>
                                    <tr className="movingLoader"><td></td>
                                        <td className="min-lg-cell"> </td>
                                        <td className="min-lg-cell"></td>
                                        <td className="min-lg-cell" > </td>
                                        <td className="w-100 min-md-cell" ></td>
                                        <td className="w-100 min-md-cell"></td>
                                    </tr><tr ><td></td>
                                        <td className="min-lg-cell"> </td>
                                        <td className="min-lg-cell"></td>
                                        <td className="min-lg-cell" > </td>
                                        <td className="w-100 min-md-cell" ></td>
                                        <td className="w-100 min-md-cell"></td>
                                    </tr>
                                    <tr className="movingLoader"><td></td>
                                        <td className="min-lg-cell"> </td>
                                        <td className="min-lg-cell"></td>
                                        <td className="min-lg-cell" > </td>
                                        <td className="w-100 min-md-cell" ></td>
                                        <td className="w-100 min-md-cell"></td>
                                    </tr>

                                </tbody>
                            </table>
                            
                        </div>

                        <div className="add-more">
                            <button type="button"    className="btn btn-sm btn-orange-outline d-flex align-item-center movingLoader w-100 p-5">&nbsp;</button>
                        </div>

                    </div>

                </div>

            </>
                 }
             

        </>

    );
};

export default Certificate;
