import React, { useContext, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { isInvalid } from "../../utils/helperFn";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { getEncodedEmail } from "../../utils/helperFn";
import { StripeCardPaymentCreate, StripeUSBankPaymentCreate } from "../../utils/payment";
import { CreateAlert, PaymentHistoryUpdate } from "../../utils/apiCalls";
import AlertUtils from "../../utils/AlertUtils";
import GlobalContext from "../../context/GlobalContext";
import { Popover, OverlayTrigger } from 'react-bootstrap';
 

const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;

const Pay = (props) => {
    
    const gContext = useContext(GlobalContext);
    const stripe = useStripe();
    const elements = useElements(); 
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [data, setData] = React.useState(null); 
    const [successMessage, setSucMessage] = React.useState('');
    const [clientName, setClientName] = React.useState((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [inprogress, setInprogress] = React.useState(false);
    const [successModalVisible, setSuccessModalVisible] = React.useState(false);   
    const [paymentType, setPaymentType] = React.useState("bank"); 
   
    gContext.setSuccessMessage("");
    useEffect(() => {
        gContext.setErrorModal(errorMessage);
        if (errorMessage != "") {           
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);

    
    

    useEffect(() => {
        console.log("props",props);
        document.title = "Payment | Syncuppro";
    }, []);
     
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
        if (gContext.userInfo.profileInfo) {
            setData(JSON.parse(gContext.userInfo.profileInfo));            
        }
        if (gContext.userInfo.paymentInfo && gContext.userInfo.paymentInfo.length > 0)
        { 
            setPaymentType("bank");
        }
        else
            setPaymentType("card");

    }, [gContext.userInfo.profileInfo]);
    useEffect(() => {
       setClientName((data && data.length > 0 && data[0] != null) ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '');
    }, [data]);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage("");
        if (clientName.trim() == "") {
            props.setError("Name is required.");
            setErrorMessage("Name field is required.");
            return;
        }
        else if(isInvalid(clientName))
        {
            props.setError("Name is invalid.");
            setErrorMessage("Name is invalid.");
            return;             
        }
        
        gContext.setLoaderModalVisible(true);
        setInprogress(true);
        // We don't want to let default form submission happen here,
        // which would refresh the page.
       
      
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            setErrorMessage('Stripe has not yet loaded.');
           
            setInprogress(false);
            gContext.setLoaderModalVisible(false);
            return;
        }  
        if (props.paymentType != 'bank') {
            const element = elements.getElement(CardElement);
            const { error: tokenError, token } = await stripe.createToken(element);
            if (tokenError) {
                setErrorMessage(tokenError.message);
                setInprogress(false);
                gContext.setLoaderModalVisible(false);
                return;
            }

            try {

                const result = await StripeCardPaymentCreate({ name: clientName, amount: props.msAmount, destination: props.paymentInfo?.stripe_id, token: token?.id, email: gContext.userInfo.email, milestoneid: props.paymentInfo?.id, contractid: props.paymentInfo?.contractid, expertid: props.contract?.expertid, clientid: props.contract?.clientid, paymentMethodType: "card", clientStripeId: null, bankAccountId: null, taskName: props.paymentInfo?.task, clientName: props.contract?.hiringperson });

                if (result.data && result.data.stripePaymentCreate && result.data.stripePaymentCreate.responseData.code == "200") {

                    let intentResponse = JSON.parse(result.data.stripePaymentCreate.responseData.message);

                    const confirmPayment = await stripe.confirmCardPayment(
                        intentResponse.client_secret);

                    if (confirmPayment && confirmPayment.paymentIntent && confirmPayment.paymentIntent.status && confirmPayment.paymentIntent.status.toLowerCase() === "succeeded") {

                        SendNotification("success");
                        props.setSucMessage(`Payment completed successfully. `);
                        const updateResult = await PaymentHistoryUpdate(confirmPayment.paymentIntent.id, confirmPayment.paymentIntent.status);

                        if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
                            //console.log("console.log(updateResult);", updateResult);
                        }
                        setSuccessModalVisible(true);
                        setInprogress(false);
                        props.setShowPayment();
                        props.bindData();
                        gContext.setLoaderModalVisible(false);

                    }
                    else if (confirmPayment.error && confirmPayment.error.payment_intent) {
                        setInprogress(false);
                        const updateResult = await PaymentHistoryUpdate(confirmPayment.error.payment_intent.id, confirmPayment.error.message);

                        if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
                            //  console.log("updateResult",updateResult);
                        }
                        SendNotification("failure");
                        setInprogress(false);
                        props.setShowPayment();
                        setErrorMessage(confirmPayment.error.message);
                        gContext.setLoaderModalVisible(false);
                    }
                }
                else {
                    setInprogress(false);
                    SendNotification("failure");
                    setInprogress(false);
                    props.setShowPayment();
                    setErrorMessage(JSON.parse(result.data.stripePaymentCreate.responseData.message));
                    // console.log(result.data.stripePaymentCreate.responseData.message);
                    gContext.setLoaderModalVisible(false);
                }
            } catch (e) {
                console.log(e);
                setInprogress(false);
                setErrorMessage(e.message);
                gContext.setLoaderModalVisible(false);

            }
        }
        else {
            setErrorMessage("");
           
            if (props.paymentAccount) {
                try {

                    const result = await StripeUSBankPaymentCreate({ name: clientName, amount: props.msAmount, destination: props.paymentInfo?.stripe_id, token: "", email: gContext.userInfo.email, milestoneid: props.paymentInfo?.id, contractid: props.paymentInfo?.contractid, expertid: props.contract?.expertid, clientid: props.contract?.clientid, paymentMethodType: "", clientStripeId: (data && data.length > 0 && data[0] != null) ? data[0].stripeid : null, bankAccountId: props.paymentAccount?.id, taskName: props.paymentInfo?.task, clientName: props.contract?.hiringperson });

                    if (result.data && result.data.stripePaymentCreate && result.data.stripePaymentCreate.responseData.code == "200") {

                        let intentResponse = JSON.parse(result.data.stripePaymentCreate.responseData.message);
                        console.log("intentResponse",intentResponse);
                        const confirmPayment = await stripe.confirmUsBankAccountPayment(
                            intentResponse.client_secret);
                        console.log("confirmPayment",confirmPayment);
                        if (confirmPayment && confirmPayment.paymentIntent && confirmPayment.paymentIntent.status && confirmPayment.paymentIntent.status.toLowerCase() === "processing") {

                            SendNotification("processing");
                            props.setSucMessage(`Your bank account has been charged successfully. It may take few business days to reflect in your bank account. `);
                            const updateResult = await PaymentHistoryUpdate(confirmPayment.paymentIntent.id, confirmPayment.paymentIntent.status);

                            if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
                                //console.log("console.log(updateResult);", updateResult);
                            }
                            setSuccessModalVisible(true);
                            setInprogress(false);
                            props.setShowPayment();
                            props.bindData();
                            gContext.setLoaderModalVisible(false);

                        }
                        else if (confirmPayment.error && confirmPayment.error.payment_intent) {
                            setInprogress(false);
                            const updateResult = await PaymentHistoryUpdate(confirmPayment.error.payment_intent.id, confirmPayment.error.message);

                            if (updateResult.data && updateResult.data.stripePaymentIntentUpdate && updateResult.data.stripePaymentIntentUpdate.responseData.code != "200") {
                                //  console.log("updateResult",updateResult);
                            }
                            SendNotification("failure");
                            setErrorMessage("Charging your bank account is failed - " + confirmPayment.error.message +". Please verify your bank account and try again.");
                            setInprogress(false);
                            props.setShowPayment();
                            gContext.setLoaderModalVisible(false);
                        }
                    }
                    else {
                        setInprogress(false);
                        SendNotification("failure");
                        setInprogress(false);
                        props.setShowPayment();
                        setErrorMessage("Charging your bank account is failed - " + JSON.parse(result.data.stripePaymentCreate.responseData.message).replaceAll("payment_method", "payment method").replaceAll("`", "") + ". Please verify your bank account and try again.");
                        // console.log(result.data.stripePaymentCreate.responseData.message);
                        gContext.setLoaderModalVisible(false);
                    }
                } catch (e) {
                    console.log(e);
                    setInprogress(false);
                    setErrorMessage(e.message);
                    gContext.setLoaderModalVisible(false);
                }
            }
            else {               
                setInprogress(false);
                setErrorMessage("Select bank account.");
                gContext.setLoaderModalVisible(false);
                return;
            }
           
        } 
        gContext.setLoaderModalVisible(false);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
        //addMessage(`Payment ${paymentIntent.status}: ${paymentIntent.id}`);
    };
    async function SendNotification(status) { 
        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = (status == "success")? AlertUtils.PaymentSuccess.                 
                replace("[MTITLE]", props.paymentInfo.task).
                replace("[GUID]", props.contract.contractguid) : AlertUtils.PaymentFailure.
                    replace("[MTITLE]", props.paymentInfo.task).
                    replace("[GUID]", props.contract.contractguid);


            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Payment", display: true, status: 'N', toTypeId: 1, toUserId: props.contract?.expertid   });

            if (result.data.createAlert.responseData.code == "200") {

            }
            const result1 = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Payment", display: true, status: 'N', toTypeId: 2, toUserId: props.contract?.clientid });

            if (result1.data.createAlert.responseData.code == "200") {

            }

            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    
    return (
        <> 
           
            {props.paymentType == "card" && <div className="forn-group-input m-b-5 m-t-10">
                <label htmlFor="card" className="form-label m-b-10 d-none ">  Card <span className="text-red"> * </span></label>

                <CardElement id="card" className="form-control" disableLink={true} />

            </div>}
            <form id="payment-form" onSubmit={handleSubmit}>
                <div className="d-flex justify-content-center m-t-20">
                    <button disabled={inprogress} className="btn btn-green w-120" type="submit">Pay</button>
                </div>
            </form>
          
            
        </>
    );
};

export default Pay;