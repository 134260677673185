import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";
import Logo from "../Logo/Logo";
import Icon from '@mui/material/Icon';
import { isInvalid } from "../../utils/helperFn";
import { SiteFeedbackMutation } from "../../utils/apiCalls";
import { HashLink } from "react-router-hash-link";
import { constant } from "lodash";

const imgM = "/images/syncuppro-logo.svg";
const appVersion = process.env.REACT_APP_VERSION;
const prevyear = new Date().getFullYear();
const currentyear = new Date().getFullYear() + 1;
//const copyrightyear;

//var  copyrightyear;




const Footer = () => {
    const gContext = useContext(GlobalContext);
    const [values, setValues] = useState({   feedback: "", rating: 0, error: { feedback: "", rating: "" } });
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(false);
    const [location, setLocation] = React.useState(false);
    function ClearField() {
        setMessage(false);
        setValues({ ["feedback"]: "", ["rating"]: 0, ["error"]: { feedback: "", rating: "" } })
    }
    useEffect(() => {
        
        if (navigator.geolocation) {
            window.navigator.geolocation.watchPosition((loc) => {
                
                setLocation(loc.coords.latitude + "," + loc.coords.longitude);
           
            });
        }
      
        const newinterval = window.setInterval(() => {
            if (!localStorage.getItem('feedbacksubmit') && !localStorage.getItem('feedback')) {
                ClearField();
                document.getElementById("btnFeedback").click();
                localStorage.setItem('feedback', JSON.stringify({ count: 1 }));
            }                
        }, 600000);
        
        
        const handleTabClose = event => {
          
            if (!localStorage.getItem('feedbacksubmit')) {
                if (localStorage.getItem('feedback')) {

                    const feedbackCount = JSON.parse(localStorage.getItem('feedback'));
                    
                    if (feedbackCount.count == 1)
                        return;
                    else {
                        ClearField();

                        if (document.getElementById("btnFeedback"))
                        document.getElementById("btnFeedback").click();

                    }
                } else {
                    ClearField();
                    if (document.getElementById("btnFeedback"))
                    document.getElementById("btnFeedback").click();

                }
            }
        };       
        
        const handleBeforeUnload = (event) => {
            // Perform actions before the component unloads

            event.preventDefault();            
            event.returnValue = '';
            handleTabClose();
            return "";
            
        };
         
        //document.body.addEventListener('mouseleave', handleTabClose);
        //return () => {            
        //    window.clearInterval(newinterval);            
        //    document.body.removeEventListener('mouseleave', handleTabClose);
          
        //};
        
        
    }, []);
    
    function updateLater() {
       // localStorage.setItem('feedback', JSON.stringify({ count: 0 }));
        
        //if (!localStorage.getItem('feedback')) {
        //    console.log("tt", localStorage.getItem('feedback'));
        //    localStorage.setItem('feedback', JSON.stringify({ count: 1 }));
        //}
        //else {
        //    const feedbackCount = JSON.parse(localStorage.getItem('feedback'));
        //    if (feedbackCount.count<3)
        //    localStorage.setItem('feedback', JSON.stringify({ count: feedbackCount.count +1 }));

        //    console.log("ddddd", localStorage.getItem('feedback'));
        //}
    }
   
    async function SendFeedback() {       


        try {
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            let error = { ...values.error };

            if (values.feedback.trim() != "" && isInvalid(values.feedback)) {
                error.feedback =   "Invalid format" ;

                setValues({ ...values, error });
                return;
            }
            if (values.rating == 0) {
                error.rating = (values.rating == 0) ? "Select rating" : "";

                setValues({ ...values, error });
                return;
            }
            gContext.setLoaderModalVisible(true);
            const result = await SiteFeedbackMutation(values.rating, encodeURI(values.feedback), "guest", location);

            if (result.data.writeSiteFeedback.responseData.code == "200") {
                setMessage(true);
                
                localStorage.setItem('feedbacksubmit', JSON.stringify({ submit: true }));
            }
            gContext.setLoaderModalVisible(false);
          
        }
        catch (e) {

            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }

    return (
        <>

            
            <footer className="bg-gray">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 p-b-10">
                            <div className="footer-logo">
                                <img src={window.location.origin + imgM} alt="syncuppro-logo" />
                            </div>
                            <p className="footer-desc">Trusted Partner in Compliance Journey</p>
                             
                            

                        </div>
                       
                        <div className="col-lg-4 col-md-4 col-sm-12 ">
                           
                            <ul className="list-type   f-16 text-center    ">
                                    <li className="p-b-5">
                                        <b>Marketplace</b>
                                    </li>
                                    <li className="p-t-5 p-b-5">
                                        <a target="_blank" rel="noopener noreferrer" href="/contact">Contact Us</a>
                                    </li> 

                                </ul>
                           
                        </div>
                        
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                           
                                <ul className="list-type   f-16      ">
                                    <li className="    p-b-5">
                                        <b>Resource </b>
                                    </li>
                                    <li className="p-t-5  p-b-5">
                                    <a href={process.env.REACT_APP_EXURL +"/faq"} rel="noopener noreferrer" target="_blank">FAQ</a>
                                </li>
                                <li className="p-t-5  p-b-5">
                                    <a href={process.env.REACT_APP_EXURL + "/terms#contractlifecycle"} rel="noopener noreferrer" target="_blank">Contract Lifecycle</a>
                                </li>
                                
                                    <li className="p-t-5 p-b-5 d-none">
                                        <a href="#" rel="noopener noreferrer" target="_blank">Customer Stories</a>
                                    </li>
                                    <li className="p-t-5 p-b-5 d-none">
                                        <a href="#" rel="noopener noreferrer" target="_blank">Testimonials</a>
                                    </li>
                                    
                                </ul>
                            
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 text-center">
                          
                                <ul className="list-type   f-16  ">
                                    <li className="    p-b-5">
                                        <b>Legal </b>
                                    </li>
                                    <li className="p-t-5 p-b-5">
                                    <a href={process.env.REACT_APP_EXURL +"/policy"} rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                                    </li>
                                    <li className="p-t-5 p-b-5">
                                    <a href={process.env.REACT_APP_EXURL +"/terms"} rel="noopener noreferrer" target="_blank">Terms of Service</a>
                                    </li>
                                    <li className="p-t-5 p-b-5">
                                    <a href={process.env.REACT_APP_EXURL +"/policy#cookie"} rel="noopener noreferrer" target="_blank">Cookie Policy</a>
                                    </li>
                                    <li className="p-t-5 p-b-5">
                                    <a href={process.env.REACT_APP_EXURL +"/terms#codeofconduct"} rel="noopener noreferrer" target="_blank">Code of Conduct</a>
                                </li>
                                <li className="p-t-5 p-b-5">
                                    <a href={process.env.REACT_APP_EXURL + "/terms#paymentterms"} rel="noopener noreferrer" target="_blank">Payment Terms</a>
                                </li>
                                </ul>                            
                        </div>
                       
                        <div className="col-lg-12 col-md-12 col-sm-12 p-t-10"><div className="social-icon">
                            <ul className="list-type d-flex">
                                <li>
                                    <a className="btn-circle" href={process.env.REACT_APP_FB} target="_blank"><img src={window.location.origin + "/images/facebook.svg"} alt="facebook" /></a>
                                </li>
                                <li>
                                    <a className="btn-circle" href={process.env.REACT_APP_TWITTER} target="_blank"><img src={window.location.origin + "/images/twitter.svg"} alt="twitter" /></a>
                                </li>
                                <li>
                                    <a className="btn-circle" href={process.env.REACT_APP_LINKEDIN} target="_blank"><img src={window.location.origin + "/images/linked.svg"} alt="linked" /></a>
                                </li>
                            </ul>
                        </div></div>
                    </div>

                 

                                    <div className="menu d-none">
                                        <ul className="menu-list">
                                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="/contact">Contact us</a>
                                            </li>
                                            <li>
                                <a target="_blank" rel="noopener noreferrer" href="/faq">FAQ</a>
                                            </li>
                                            <li>
                                <a href="/Policy" rel="noopener noreferrer" target="_blank">Privacy Policy</a>
                                            </li>
                                            <li>
                                <a href="/terms" rel="noopener noreferrer" target="_blank">Terms and Conditions</a>
                                            </li>
                                        </ul>
                                    </div>

                    <p className="m-0 text-center"> <i class="fa-solid fa-copyright"/>  2022-2024 SyncupPro. All rights reserved.</p>

    </div>
  </footer>


            <button id="btnFeedback" type="button" className="btn btn-sm feedback-btn" data-bs-toggle="modal" data-bs-target="#feedback-modal" onClick={() => { ClearField();}}>Feedback</button>


            <div className="modal" id="feedback-modal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content"> 


      <div className="modal-body text-center">
                            <img className="m-b-20" src={window.location.origin + "/images/syncuppro-logo.svg"} alt="Syncuppro"/>
                            {message && <> <h5 className="m-b-20">Thank you!</h5>
                                <p>Your feedback submitted successfully.</p>
                                <div className="d-flex justify-content-center">
                                    <button type="button" className="btn btn-green w-120" data-bs-dismiss="modal">Ok</button>
                                </div> 

                         </>}
                            
                            {!message && <>  <h6 className="m-b-20 f-18 fw-400">Based on your experience, how likely are you to recommend Syncuppro from 1 (very unlikely) to 5 (very likely)? <span class="text-red">*</span></h6>

                                <div className="rate-wrap  ">
                                    <span data-bs-toggle="tooltip" title="Very unlikely" className={`rate-btn fw-600 ${values.rating == 1 ? "selected" : ""}`} onClick={() => setValues({ ...values, ["rating"]: 1, ["error"]: { ...values.error, ["rating"]: "" }})}>1</span>
                                    <span data-toggle="tooltip" title="Unlikely" className={`rate-btn fw-600 ${values.rating == 2 ? "selected" : ""}`} onClick={() => setValues({ ...values, ["rating"]: 2, ["error"]: { ...values.error, ["rating"]: "" }})}>2</span>
                                    <span data-toggle="tooltip" title="Nuetral/Depends" className={`rate-btn  fw-600  ${values.rating == 3 ? "selected" : ""}`} onClick={() => setValues({ ...values, ["rating"]: 3, ["error"]: { ...values.error, ["rating"]: "" }})}>3</span>
                                    <span data-toggle="tooltip" title="Likely" className={`rate-btn  fw-600  ${values.rating == 4 ? "selected" : ""}`} onClick={() => setValues({ ...values, ["rating"]: 4, ["error"]: { ...values.error, ["rating"]: "" }})}>4</span>
                                    <span data-toggle="tooltip" title="Very likely" className={`rate-btn  fw-600  ${values.rating == 5 ? "selected" : ""}`} onClick={() => setValues({ ...values, ["rating"]: 5, ["error"]: { ...values.error, ["rating"]: "" } })}>5</span>
                                   
                                </div>
                                {values.error.rating.length > 1 && (
                                    <><span className="text-red  ">{values.error.rating}</span></>
                                )}
                                <div className="form-group m-t-20">
                                    <label className="dark">Tell us your experience</label>
                                    <textarea className="form-control" placeholder="Write here..." value={values.feedback} onChange={(e) => setValues({ ...values, ["feedback"]: e.target.value, ["error"]: { ...values.error, ["feedback"]:""} })}></textarea>
                                    {values.error.feedback.length > 1 && (
                                        <><span className="text-red  ">{values.error.feedback}</span></>
                                    )}
                                </div>

                                <div className="d-grid m-b-20">
                                    <button type="button" className="btn btn-green btn-block"   onClick={()=>SendFeedback() }>Submit</button>
                                </div>
                                <div className="d-grid m-b-20"><button type="button" className="btn btn-link dark" data-bs-dismiss="modal" onClick={() => { updateLater(); } }>Later</button> </div></>}
      </div>

                        </div>
                    </div>
                </div>
        </>
    );
};

export default Footer;