import React, { useEffect, useState, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { GetClientPreviewProfile } from "../../utils/apiCalls";
import ProfileSidebar from "../../components/ProfileSidebar/ProfileSidebar";
import StarRating from "../../components/Core/Rating";
import ReactCountryFlag from "react-country-flag";
import DOMPurify from 'isomorphic-dompurify';
import { getMonthDateYear } from "../../utils/addDays";
import FeedbackDetails from "./FeedbackDetails";
import { sinceDate } from "../../utils/addDays";
import { isInvalid } from "../../utils/helperFn";
var imgP = "";
const imgSystem = "../favicon.png";
const imgB1 = "../assets/image/l2/png/featured-job-logo-1.png";
const imgB2 = "../assets/image/l1/png/feature-brand-1.png";
const imgB3 = "../assets/image/svg/harvard.svg";
const imgB4 = "../assets/image/svg/mit.svg";
const img1 = "../assets/image/l3/png/fimize.png";
const img2 = "../assets/image/svg/icon-shark-2.svg";
const img3 = "../assets/image/svg/icon-thunder.svg";
const img4 = "../assets/image/l3/png/asios.png";

const imgL = "../assets/image/svg/icon-loaction-pin-black.svg";



export default function Feedback(props) {
    {
        const gContext = useContext(GlobalContext);
    const [profileInfo, setProfileInfo] = React.useState({});
    const [initialValues, setInitialValues] = React.useState({ datecreated:'',counts: null, yesfeedbacks: null, contracts: null, industryTpes: null, code: '', avgrating: 0, logo: '', industryname: '', countryname: '', desc: '', tagline: '', company: '', city: '', state: '' });
    const [values, setValues] = React.useState({ datecreated:'',counts: null, feedbacks: null, contracts: null, industryTpes: null, code: '', avgrating: 0, logo: '', industryname: '', countryname: '', desc: '', tagline: '', company: '', city: '', state: '' });
    const subParam = props.sub;
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [feedback, setFeedbackData] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [show, setShow] = React.useState(false);
    const [allfeedbacks, setAllFeedbacks] = useState([]);
    const [feedbacks, setFeedbacks] = useState([]);
   
   
    const [pageCount, setPageCount] = useState(1);
    const [showMore, setShowMore] = useState(false);
    const [experts, setExperts] = useState([{}]);
       
        const [feedbackList, setFeedbackList] = useState([{}]);


    useEffect(() => {
       // gContext.setLoaderModalVisible(true);
        ClientPreviewInfo();
        // rating() ;
    }, [])

    useEffect(() => {

        document.title = "Profile View | Syncuppro";
    }, []);


    

    async function ClientPreviewInfo() {
        try {

            //[{"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","email":"sathya@springinfoservices.com","title":"manager","explevelid":3,"profile":"test","dailyrate":"230.00","weeklyhrsid":3,"skillids":"13,12","compids":"42,50","resume":""}]
            const result = await GetClientPreviewProfile(subParam);
            if (result && result.data.clientAllLoad.responseData.code == "200") {
               

                let profile = JSON.parse(result.data.clientAllLoad.responseData.message);
              
                if (profile && profile.length > 0) {
                    // title: '', expLevelId: null, expLevel: null, dailyRate: null, weeklyHrsId: null, weeklyHrs: null, profileDetails: "", skillSet: "", compliance: "", resume: ''
                    let profileData = { ...values };

                    profileData.desc = profile[0].desc;
                    profileData.tagline = profile[0].tagline;
                    profileData.company = profile[0].company;
                    profileData.logo = profile[0].logo;
                    profileData.countryname = profile[0].countryname;
                    profileData.industryname = profile[0].industryname;
                    profileData.city = profile[0].city;
                    profileData.state = profile[0].state;
                    profileData.code = profile[0].code;
                    profileData.industryTpes = profile[0].industryTpes;
                    profileData.contracts = profile[0].contracts;
                    profileData.feedbacks = profile[0].feedbacks;
                    profileData.counts = profile[0].counts;
                    profileData.datecreated = profile[0].datecreated;

                    setAllFeedbacks(profile[0].feedbacks);
                    setFeedbacks(profile[0].feedbacks.slice(0, (profile[0].feedbacks.length > (pageCount * 3)) ? (pageCount * 3) : profile[0].feedbacks.length));
                    //setShowMore(profile[0].feedbacks.length > (pageCount * 3));


                    if (profile[0].avgrating == null) {
                        profileData.avgrating = 0;
                    }
                    else if (profile[0].avgrating !== null) {

                        profileData.avgrating = profile[0].avgrating;

                    }
                    setInitialValues(profileData);
                    setValues(profileData);
                    setProfileInfo(profile[0]);

                } else {

                    if (subParam)
                        window.location.href = "/error";

                }
            }
            setLoaded(true);
            //gContext.setLoaderModalVisible(false);
        } catch (e) {
            gContext.setLoaderModalVisible(false);
            
        }

    }

    function toggleModal(feedback) {
        setFeedbackData(feedback);
       
        //setRead(true);
        setShowModal(!showModal);
    }

   

    useEffect(() => {

        //setExperts(props.jobsList);
        setFeedbackList(props.jobsList);
        
    }, [props.jobsList]);


   

        useEffect(() => {

            setShow(true);
        }, [profileInfo]);
        function toRoundOff(n) {
            //var result = ; 

            if ((n - Math.floor(n)) != 0) {
                var dec_value = parseFloat(n).toFixed(1);
                return dec_value;

            }
            else {
                return n;
            }

        }
        return (
            <>


                <div className="divider-line-20"></div>

                <div className="company-meta">
                    <h6>Feedback</h6>
                    <FeedbackDetails feedback={feedback} modalvisible={showModal} togglemodal={toggleModal} />
                    {(profileInfo && feedbackList) && feedbackList.map((data, index) => {
                    const { datecreated, exptfirstname, exptsub, exptlastname, companyname, exptavatar, rating, feedback } = data;
                                                                    return (<>
                                                                        <div className="divider-line-20"></div>
                                                                        <a className="expert-name m-0 m-b-5 pointer f-16" href={"/job/" + data.jobguid} target="_blank" > <b>{(data.jobtitle) ? data.jobtitle : ''}</b></a>
                    <div className="profile-details-img">
                        <div className="expert-profile m-b-0">
                            <div className="expert-img">
                                        <img src={s3URL + exptavatar} alt=  {exptfirstname + exptlastname} />
                                        
                            </div>
                            <div className="d-flex flex-column">
                                        <strong className="expert-name">  {exptfirstname} {exptlastname}</strong>
                                        <div className="info-meta f-12">
                                            {(rating == null) ? '' :
                                                <>
                                                    <StarRating rating={(rating) ? rating : ''} />
                                                    <strong className="m-t-5 m-l-5" >{"  "}  {(rating) ? toRoundOff(rating) + "/5" : ''}</strong>
                                                      
                                                    </>}
                                    
                                </div>
                            </div>
                        </div>

                        <div className="d-flex align-item-center">
                                    <span className="text-light-gray m-r-20">  {getMonthDateYear(datecreated)}</span>
                            <a className="btn btn-circle light dropdown-toggle d-none" data-bs-toggle="dropdown" aria-expanded="false"><span className="text-theme m-icon material-icons">share</span></a>
                            <ul className="dropdown-menu dropdown-menu-end share-dropdown">
                                <li><a className="dropdown-item d-flex align-item-center" href="#"><img className="m-r-10" src="../images/social-icon-sm/facebook.svg" />Facebook</a></li>
                                <li><a className="dropdown-item d-flex align-item-center" href="#"><img className="m-r-10" src="../images/social-icon-sm/twitter.svg" />Twitter</a></li>
                                <li><a className="dropdown-item d-flex align-item-center" href="#"><img className="m-r-10" src="../images/social-icon-sm/linked.svg" />Linked</a></li>
                            </ul>
                        </div>
                    </div>

                            <div className="m-b-0 text-light-gray three-line pointer" onClick={(e) => toggleModal(data)}   dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feedback).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>
                            </div>
                        </>)
                    })}
 </div>



                    <div>
                    <div className="container mr-30  d-none">
                            <div className="">
                                <div className="row ">
                                    {/* <!-- Company Profile --> */}
                                    <div className="">

                                        <div className="container">
                                            {/* <!-- back Button --> */}
                                           
                                            
                                            {/* <!-- back Button End --> */}
                                            <div className="row">
                                                {/* <!-- Left Sidebar Start --> */}
                                                
                                                {/* <!-- Left Sidebar End --> */}
                                                {/* <!-- Middle Content --> */}
                                                <div >
                                                   
                                                        <div className="bg-white rounded-4 shadow-9">
                                                            {/* <!-- Tab Section Start --> */}
                                                            

                                                            
                                                               
                                                                 <div className=" border-top pr-xl-0 pr-xxl-0 p-5 pl-xs-12 pt-7 pb-5">
                                                                            <h4 className="font-size-6 mb-7 mt-5 text-black-2 font-weight-semibold">
                                                                                Feedback 
                                                                            </h4>
                                                                            {(profileInfo && feedbackList) && feedbackList.map((data, index) => {
                                                                const { datecreated, exptfirstname, exptsub, exptlastname, companyname, exptavatar, rating, feedback } = data;
                                                                    return (

                                                                                    <div className="" key={index}>
                                                                                        <div className="media mr-7 ">

                                                                                            <div style={{ width: '770px' }} className={`   rounded-4 pl-7 pt-1 pb-1 pr-7 position-relative    mb-3    bg-gray-opacity-1  `}>

                                                                                                <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                                                                                    <div className="row mb-0">
                                                                                                        <div className="col-lg-6">

                                                                                                            <a className="media min-width-px-235 align-items-center " target="_blank" rel="noopener noreferrer" href={"/expert/" + exptsub} >
                                                                                                <div className="circle-36 mr-6">
                                                                                                    <img src={s3URL + exptavatar} alt="" className=" circle-36 w-100" />
                                                                                                </div>
                                                                                                <h3 className="font-size-4 mb-0 font-weight-bold text-gray justify-content-start   mt-0 p-0"   >
                                                                                                    {exptfirstname} {exptlastname}
                                                                                                </h3><i className="fa-solid fa-arrow-up-right-from-square font-size-3 text-green pl-1"></i>
                                                                                            </a>



                                                                                                        </div>
                                                                                                        <div className="col-lg-6 pl-30 text-right">

                                                                                            <a className="font-size-3 text-gray font-weight-semibold" rel="noopener noreferrer" target="blank">
                                                                                                {getMonthDateYear(datecreated)}
                                                                                            </a>

                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="row mb-6">
                                                                                                        <div className="col-lg-6 pl-17">

                                                                                                            {(rating == null) ? '' :
                                                                                                                <>
                                                                                                                    <StarRating rating={(rating) ? rating : ''} />
                                                                                                                    &nbsp;<span className="font-size-2  text-green-2 font-weight-semibold   mr-5">
                                                                                                                        {(rating) ? rating.toString() + "/5" : ''}
                                                                                                                    </span></>}

                                                                                                        </div>
                                                                                                    </div>


                                                                                                    <a onClick={(e) => toggleModal(data)} rel="noopener noreferrer" target="blank" className=" w-100 ">

                                                                                                        <div className=" font-size-4 text-gray mb-3 w-100 mt-2"
                                                                                                            style={{ textOverflow: 'ellipsis', height: '73px', overflow: 'hidden', wordWrap: "break-word" }}
                                                                                                            dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(feedback).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>
                                                                                                        </div>


                                                                                                        {(DOMPurify.sanitize(feedback).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``).length > 150) &&
                                                                                                            <div className=" mt-n7    text-right w-100"><i className="fa-solid fa-ellipsis mr-7 mt-7 pointer" ></i>
                                                                                                            </div>}
                                                                                                    </a>

                                                                                                    






                                                                                                </div>
                                                                                            </div>


                                                                                        </div>

                                                                                    </div>

                                                                                )
                                                                            })}



                                                                        </div>
                                                                       

                                                               

                                                                                                                                
                                                               
                                                           
                                                            {/* <!-- Tab Content End --> */}
                                                            {/* <!-- Tab Section End --> */}
                                                        </div>
                                                    
                                                </div>
                                                {/* <!-- Middle Content --> */}
              
                                                {/* <!-- Right Sidebar End --> */}
                                             </div>

                                                </div>
                                               </div>
                                            </div>
                                           </div>  
                                          </div>
                                    </div>
                
            </>
        );
    }
};
