import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { GetExpertExperience, UpdateExpertExperience, DeleteExpertRow } from "../../utils/apiCalls";
import CustomDatePicker from "../../components/Core/CustomDatePicker";
import validator from 'validator';
import ConfirmMessages from "../../utils/confirmMessages";
import { isInvalid } from "../../utils/helperFn";
import Message from "../../components/Message";
const imgP = "../assets/image/avatar.png";
const imgLoader = "../assets/image/svg/spinning-circles.svg";
const Experience = (props) => {
    const gContext = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowCount, setRowCount] = useState(0);
    const [rowsExperience, setRowsExperience] = useState([]);
    const [storedExperience, setStoredExperience] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [visibleLoader, setVisibleLoader] = useState(false);
    const [message, setMessage] = React.useState(false);
    const [currentRow, setCurrentRow] = useState(0);
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);

    function cancelEdit() {
        setRowsExperience([]);
        gContext.setCurrentPage('4');
    }

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    function addTableRows() {
        const rowsInput = {
            load: false, id: null, expertId: gContext.userInfo.userID, company: '', role: '', experience: '', current: true, fromDate: null, toDate: null, error: { company: '', role: '', experience: '', fromDate: '', toDate: '' }
        }
        setRowsExperience([...rowsExperience, rowsInput]);
    }

    function prevPage() {
        gContext.setCurrentPage('11');
        //gContext.setLoaderModalVisible(true); 
    }

    function nextPage() {
        gContext.setCurrentPage('16');
       // gContext.setLoaderModalVisible(true); 
    }

    function setLoad(status, index) {
        const rows = [...rowsExperience];
        rows[index]['load'] = status;
        setRowsExperience(rows);
    }
    function spliceRow(index) {
        const rows = [...rowsExperience];
        rows.splice(index, 1);
        setRowsExperience(rows);
    }
    /*For exp table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Experience delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
    }
    /*For Social table row delete*/
    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);
           
            try {
                gContext.setLoaderModalVisible(true);
                const result = await DeleteExpertRow(id, 'exp', rowCount === 1 ? 0:null);
               
                if (result.data.expertRowDelete.responseData.code == "200") {
                    getExperienceInfo();  //spliceRow(index);
                }
                else {
                    
                }
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
            }
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
        gContext.setLoaderModalVisible(false);
    }

    async function getExperienceInfo() {

        try {
            setLoaded(false);
            const result = await GetExpertExperience(gContext.userInfo.userID);
            
            if (result.data.expertExpLoad.responseData.code == "200") {
                 
                BindData(JSON.parse(result.data.expertExpLoad.responseData.message));
                gContext.setLoaderModalVisible(false); 
            }
            setLoaded(true); 
        }
        catch (e) {
            console.log(e);
            addTableRows();
            setLoaded(true);
        }

    }

    function BindData(data) {
        //"[{\"sub\":\"f180bc26-27fa-4a1b-9b75-fee8dd832db0\",\"degreeid\":2,\"major\":\"\",\"school\":\"\",\"countryid\":61,\"startdate\":\"2010-06-01T00:00:00.000Z\",\"enddate\":\"2012-05-01T00:00:00.000Z\"}]"
        const rowsInput = []
       
        try {
            setStoredExperience()
            setRowCount(data.length);
            
            if (data.length > 0) {
                data.map((row) => {

                    rowsInput.push({
                        load: false, id: row.id, expertId: gContext.userInfo.userID, current: row.current,
                        // id: null, company: row., role: '', experience: '', current: true, fromDate: '', toDate: ''
                        company: row.company, role: row.role, fromDate: (row.startdate) ? new Date(row.startdate.replace("T00:00:00.000Z", "T08:00:00.000Z")) : "", toDate: (row.current) ? null : new Date(row.enddate.toString().replace("T00:00:00.000Z", "T08:00:00.000Z")), error: { company: '', role: '', experience: '', fromDate: '', toDate: '' }
                    });
                });

                setRowsExperience(rowsInput);
                gContext.setLoaderModalVisible(false);
                
            }
            else { 
                setRowsExperience([]);
            }

        } catch (e) {
         //   addTableRows();
            console.log(e);
        }

        //  setRowsExperience(rowsInput);
    }

    const handleChange = (index, evnt) => {
        setErrorMessage('');
        setMessage(false);
        const { name, value } = evnt.target;
        const rowsInput = [...rowsExperience];
        rowsInput[index][name] = (name == "current") ? evnt.target.checked : value;
        if (name == "current" && evnt.target.checked)
            rowsInput[index]["toDate"] = null;
        rowsInput[index]["error"][name] = "";
        setRowsExperience(rowsInput);

    }
    function checkRowValidity(index) {
        let inValid = false;


        let { error, ...rest } = rowsExperience[index];

        //Object.values(rest).forEach(val => {
        //    if (val === '' || val === null) {
        //        inValid = true;
        //    }
        //});


        error.company = validator.isEmpty(rest.company) ? "Company is required" : (isInvalid(rest.company) ? "Invalid format" : error.company);
        error.role = validator.isEmpty(rest.role) ? "Role is required" : (isInvalid(rest.role) ? "Invalid format" : error.role);
        // error.experience = (rest.experience) ? error.experience : "Experience is required";
        error.fromDate = (rest.fromDate) ? error.fromDate : "FromDate is required";
        if (!rest.current)
            error.toDate = (rest.toDate) ? error.toDate : "ToDate is required";
        else
            error.toDate = "";


        const rows = [...rowsExperience];
        rows[index][error] = error;
        setRowsExperience(rows);

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });


        if (inValid)
            return false;
        else
            return true;

    }
    async function saveExperience(index) {

        setErrorMessage('');
        if (checkRowValidity(index)) {
            const rowsInput = [...rowsExperience];
            rowsInput[index]['load'] = true;
            setRowsExperience(rowsInput);
           
            gContext.setLoaderModalVisible(true);
            try {

                
                const result = await UpdateExpertExperience(rowsExperience[index], rowCount);

                if (result.data.expertExpUpdate.responseData.code == "200") {
                    //gContext.setCurrentPage("5");
                    //gContext.setVisibleProfileMessage(true);

                    setMessage(true);
                    getExperienceInfo();
                }
                else {
                    setErrorMessage(JSON.parse(result.data.expertExpUpdate.responseData.message));
                }
            }
            catch (e) {
                 gContext.setLoaderModalVisible(false);
                setErrorMessage("Server error");
                console.log(e);
            }

            gContext.setLoaderModalVisible(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'

            });
        }
        else {  setMessage(false);
            setErrorMessage("Required fields are missing.");

        }
        const rowsInput1 = [...rowsExperience];
        rowsInput1[index]['load'] = false;
        setRowsExperience(rowsInput1);
    }

    const handleDateChange = (index, name, value) => {
        setMessage(false);
        const rowsInput = [...rowsExperience];

        rowsInput[index][name] = value;
        rowsInput[index]["error"][name] = "";
        setRowsExperience(rowsInput);

    }
    useEffect(() => {

        if (gContext.confirmModal.confirmed) {
            deleteData(gContext.confirmModal.index, gContext.confirmModal.id);
        }
        else { setCurrentValue({ index: null, id: null }); }
        gContext.setLoaderModalVisible(false);
    }, [gContext.confirmModal]);
    useEffect(() => { 
        getExperienceInfo();//timeout(2000);
        
        document.title = "Experience | Syncuppro";
    }, [])
    return (
        <>
            {loaded &&
                
                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title">
                                <h6>EXPERIENCE INFORMATION</h6>
                            </div>
                        </div>
                <div className="row d-none ">
                    {message && <Message message={ConfirmMessages.ProfileConfirmation.replace('profile', 'experience')} />}
                    {errorMessage !== "" && <><span className="text-red">{errorMessage} </span><br /></>}
                </div>

            
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                    <th className="min-lg-cell">Company</th>
                                    <th className="min-lg-cell">Role</th>
                                            <th className="w-50">Current</th>
                                    <th className="min-md-cell">From Date</th>
                                    <th className="min-md-cell">To Date</th>
                                            <th className="w-50"></th>
                                            <th className="w-50"></th>
                                        </tr>
                                    </thead>
                            <tbody>
                                {rowsExperience.map((data, index) => {
                                    const { id, company, role, load, experience, fromDate, toDate, current, error } = data;

                                    return (
                                        <tr key={index}>
                                            <td className="tablerow">
                                                <input
                                                    name="company"
                                                    type="text"
                                                    className="form-control"
                                                    id="company"
                                                    placeholder="Company" defaultValue={company} value={company} onChange={(e) => (handleChange(index, e))}
                                                />
                                                {error.company.length > 1 && (
                                                    <span className="text-red">{error.company}</span>
                                                )}
                                            </td>
                                            <td className="tablerow">
                                                <input
                                                    name="role"
                                                    type="text"
                                                    className="form-control h-px-40"
                                                    id="role"
                                                    placeholder="Role" defaultValue={role} value={role} onChange={(e) => (handleChange(index, e))}
                                                />  {error.role.length > 1 && (
                                                    <span className="text-red">{error.role}</span>
                                                )}
                                            </td>
                                            <td className="tablerow">
                                                <div className="form-check">
                                                    <input
                                                        name="current"
                                                        style={{ width: '18px', height: '18px' }}
                                                        type="checkbox" className="form-check-input"
                                                        id="client-check" onChange={(e) => (handleChange(index, e))} checked={current}
                                                    />
                                                   
                                                </div>
                                            </td>
                                            <td className="tablerow">
                                                <CustomDatePicker className="form-control "
                                                    placeholderText="From Date"
                                                    selected={fromDate}
                                                    onChange={(date) => (handleDateChange(index, 'fromDate', date))}
                                                    maxDate={toDate ? toDate : new Date()}
                                                />
                                                {error.fromDate.length > 1 && (
                                                    <><br />  <span className="text-red">{error.fromDate}</span></>
                                                )}
                                            </td>
                                            <td className="tablerow">
                                                <CustomDatePicker className="form-control  " disabled={current}
                                                    selected={(current) ? '' : toDate}
                                                    onChange={(date) => (handleDateChange(index, 'toDate', date))}
                                                    placeholderText="To Date" minDate={fromDate} maxDate={new Date()}
                                                />  {error.toDate.length > 1 && (
                                                    <span className="text-red">{error.toDate}</span>
                                                )}
                                            </td>
                                            <td className="tablerow">
                                                <a className="btn btn-circle light pointer" data-toggle="tooltip" title="Save Row" onClick={() => (saveExperience(index))}><span className="text-theme m-icon material-icons"> {(id) ? "edit" : "save"}</span></a>
                                            </td>
                                            <td className="tablerow">
                                                <a className="btn btn-circle light pointer" data-toggle="tooltip" title="Delete Row" onClick={() => (deleteTableRows(index, id))}><span className="text-theme m-icon material-icons">delete</span></a>
                                            </td>
                                        </tr>

                                    )
                                })}


                                    </tbody>
                        </table>
                        {(rowsExperience && rowsExperience.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                            <span className="text-light-gray m-icon material-icons m-b-10">menu</span>
                            <span>No Experience  found</span>
                        </div>}
                            </div>

                            <div className="add-more">
                        <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row" ><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                            </div>

                        </div>

                    </div>
                 
            
            }
            {!loaded &&
                <>
                    <div className="card panel  p-0">

                        <div className="card-header">

                            <div className="client-title movingLoader w-150">
                                <h6> &nbsp;</h6>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="table-responsive">
                                <table className="table table-striped">
                                    <thead className="movingLoader">
                                        <tr><th></th>
                                            <th className="min-lg-cell"> </th>
                                            <th className="min-lg-cell"></th>
                                            <th className="min-lg-cell" > </th>
                                            <th className="w-100 min-md-cell" ></th>
                                            <th className="w-100 min-md-cell"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr><tr ><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>
                                        <tr className="movingLoader"><td></td>
                                            <td className="min-lg-cell"> </td>
                                            <td className="min-lg-cell"></td>
                                            <td className="min-lg-cell" > </td>
                                            <td className="w-100 min-md-cell" ></td>
                                            <td className="w-100 min-md-cell"></td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>

                            <div className="add-more">
                                <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center movingLoader w-100 p-5">&nbsp;</button>
                            </div>

                        </div>

                    </div>

                </>
            }
            <div
            className="dashboard-main-container mt-24 mt-lg-31 min-h-100vh d-none"
            id="dashboard-body"
        >
            <div className="container">
                <div className="mb-15 mb-lg-23">
                    <div className="row">
                        <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">


                        <div className="row d-none">
                                {message && <Message message={ConfirmMessages.ProfileConfirmation.replace('profile', 'experience')} />}
                                {errorMessage !== "" && <><span className="error py-10   w-auto  font-weight-semibold line-height-reset px-8   font-size-5">{errorMessage} </span><br /></>}
                            </div>

                        </div>
                        {loaded && <div className="col-xxxl-12  ">
                            <div className="pb-14">
                                <div className="width-100  float-left  height-100px background-gray margin-10">
                                    <div className="row justify-content-center">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">


                                                <button type="button"
                                                    className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                        prevPage();
                                                    }}>&laquo; PREV
                                                 </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                    <div className="row justify-content-end">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">


                                                <button type="button"
                                                    className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                        nextPage();
                                                    }}>NEXT &raquo;
                                                 </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="contact-form bg-white shadow-8 ">

                                <div className="font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                    Experience Information
                                </div>



                                <div className="col-lg-12">
                                    <label
                                        htmlFor="namedash"
                                        className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                    >
                                    </label>
                                    <div className="bg-white  pt-7 rounded pb-8  ">
                                        <div className="table-responsive  min-h-90vh">
                                            <table className="table table-striped ">
                                                <thead>
                                                    <tr className="bg-gray-opacity-2 text-left ">
                                                        <th
                                                            scope="col"
                                                            className=" pl-8  border-0 font-size-4 font-weight-bold   table-y-middle"
                                                        >
                                                            Company <span className="text-red"> </span>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="border-0 pl-8 font-size-4 font-weight-bold  table-y-middle"
                                                        >
                                                            Role <span className="text-red"> </span>
                                                        </th>

                                                        <th
                                                            scope="col" className="pl-5  border-0 font-size-4 font-weight-bold  table-y-middle"
                                                        >
                                                            Current
                                                            </th>

                                                        <th
                                                            scope="col"
                                                            className="border-0 pl-8 font-size-4 font-weight-bold    table-y-middle"
                                                        >
                                                            From Date <span className="text-red"> </span>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="border-0 pl-8 font-size-4 font-weight-bold   table-y-middle"
                                                        >
                                                            To Date <span className="text-red"> </span>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="pl-8  border-0 font-size-4 font-weight-bold  table-y-middle d-none"
                                                        >
                                                            Experience  <span className="text-red"> </span>
                                                        </th><th></th>
                                                        <th
                                                            scope="col"
                                                            className="border-0 font-size-4 font-weight-bold text-right  table-y-middle"
                                                        >
                                                            <button type="button" className="btn btn-primary   btn-mini  btn-green rounded-5 font-size-4 text-white pointer" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row"  > + Add </button>
                                                        </th>

                                                    </tr>
                                                </thead>


                                                <tbody>

                                                    {rowsExperience.map((data, index) => {
                                                        const { id, company, role, load, experience, fromDate, toDate, current, error } = data;

                                                        return (
                                                            <tr className="border border-color-2" key={index}>
                                                                <td scope="row" className="  border-0 py-1 pr-0 min-width-px-135">
                                                                    <input
                                                                        name="company"
                                                                        type="text"
                                                                        className="form-control h-px-40"
                                                                        id="company"
                                                                        placeholder="Company" defaultValue={company} value={company} onChange={(e) => (handleChange(index, e))}
                                                                    />
                                                                    {error.company.length > 1 && (
                                                                        <span className="text-red">{error.company}</span>
                                                                    )}</td>
                                                                <td className="  py-1 min-width-px-135 pr-0">
                                                                    <input
                                                                        name="role"
                                                                        type="text"
                                                                        className="form-control h-px-40"
                                                                        id="role"
                                                                        placeholder="Role" defaultValue={role} value={role} onChange={(e) => (handleChange(index, e))}
                                                                    />  {error.role.length > 1 && (
                                                                        <span className="text-red">{error.role}</span>
                                                                    )}
                                                                </td>


                                                                <td className=" py-1 table-y-middle  pr-0 text-center">
                                                                    <div className="w-100 text-center">

                                                                        <input
                                                                            name="current"
                                                                            style={{ width: '18px', height: '18px' }}
                                                                            type="checkbox"
                                                                            id="client-check" onChange={(e) => (handleChange(index, e))} checked={current}
                                                                        /></div>
                                                                </td>
                                                                <td className="  py-1 min-width-px-135 pr-0">
                                                                    <CustomDatePicker className="form-control h-px-40"
                                                                        placeholderText="From Date"
                                                                        selected={fromDate}
                                                                        onChange={(date) => (handleDateChange(index, 'fromDate', date))}
                                                                    />
                                                                    {error.fromDate.length > 1 && (
                                                                        <span className="text-red">{error.fromDate}</span>
                                                                    )}
                                                                </td>
                                                                <td className="  py-1 min-width-px-135 pr-0">
                                                                    <CustomDatePicker className="form-control h-px-40" disabled={current}
                                                                        selected={(current) ? '' : toDate}
                                                                        onChange={(date) => (handleDateChange(index, 'toDate', date))}
                                                                        placeholderText="To Date"
                                                                    />  {error.toDate.length > 1 && (
                                                                        <span className="text-red">{error.toDate}</span>
                                                                    )}
                                                                </td>
                                                                <td className="  py-1 min-width-px-135 pr-0 d-none">
                                                                    <input
                                                                        name="experience"
                                                                        type="text"
                                                                        className="form-control h-px-40 d-none"
                                                                        id="experience"
                                                                        placeholder="Experience" defaultValue={experience} value={experience} onChange={(e) => (handleChange(index, e))}
                                                                    />  {error.experience.length > 1 && (
                                                                        <span className="text-red">{error.experience}</span>
                                                                    )}

                                                                </td>
                                                                <td className="table-y-middle "> </td>
                                                                <td className="table-y-middle  min-width-px-235 pr-0 media text-right  justify-content-md-end pr-5 media">


                                                                    <div className="text-center ml-5 mr-2 table-y-middle" data-toggle="tooltip" title="Save Row">


                                                                        <a className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5   border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Save Row" onClick={() => (saveExperience(index))}>
                                                                            {(id) ? "Update" : "Save"}
                                                                        </a>

                                                                    </div>

                                                                    <div className="text-center table-y-middle " data-toggle="tooltip" title="Delete Row">
                                                                        <a disabled={load} className="border font-size-3 font-weight-bold text-red px-5     border-width-1 rounded-5 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" data-toggle="tooltip" title="Delete Row" onClick={() => (deleteTableRows(index, id))}>
                                                                            Delete
                                                                            </a>

                                                                    </div>

                                                                </td>
                                                            </tr>)
                                                    })}

                                                </tbody>
                                            </table>
                                        </div>

                                    </div></div>
                                <div className="media px-5  py-10 d-none"  >

                                    <button
                                        type="button"

                                        className="btn btn-primary min-width-px-200 btn-medium btn-green btn-h-60 text-white  rounded-5 text-uppercase"  >
                                        Save
                                    </button>



                                    <button
                                        type="button"

                                        className="btn btn-primary min-width-px-200 btn-medium btn-green btn-h-60 text-white  ml-5 rounded-5 text-uppercase" onClick={() => {
                                            cancelEdit();

                                        }}>
                                        Cancel
                                    </button>

                                </div>



                            </div>
                            <div className="width-100  float-left  height-100px background-gray margin-10">
                                <div className="row justify-content-center">
                                    <div className="col-12 dark-mode-texts">
                                        <div className="py-5">


                                            <button type="button"
                                                className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                    prevPage();
                                                }}>&laquo; PREV
                                                 </button>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                <div className="row justify-content-end">
                                    <div className="col-12 dark-mode-texts">
                                        <div className="py-5">


                                            <button type="button"
                                                className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                    nextPage();
                                                }}>NEXT &raquo;
                                                 </button>

                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>}
                
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default Experience;




