import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import { MessageSummary } from "../../utils/apiCalls";
import UserMessage from "../../sections/Message/Message";
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
const imgL = "assets/image/logo-main-black.png";
const Sidebar = (props) => {
  const s3URL = process.env.REACT_APP_AWS_S3_URL;
  const navigate = useNavigate();
  const pathName = useLocation().pathname.toLowerCase().trim();
  const gContext = useContext(GlobalContext);
  const [fromId, setfromId] = useState(null);
  const [showMessage, setShowMessage] = useState(false);
  const [openProfile, setOpenProfile] = useState(pathName == "/profile");
  const [openSetting, setOpenSetting] = useState(pathName == "/changepassword");
  const [openMessage, setOpenMessage] = useState(pathName == "/messages");
  const [messageSummary, setMessageSummary] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("/client/" + gContext.userInfo.sub);

  function logout() {
    gContext.logout();
  }


  function deleteProfile() {

    gContext.setVisibleProfileMessage(false);
    //  e.preventDefault();

    //  gContext.setconfirmModal({ ["text"]: "Are you sure you want to delete the account?  You'll lose all the data in this platform.", ["confirmed"]: false });

    //  gContext.toggleConfirmModal();

    let deletetext = ["Your information will be deleted from this platform permanently.",
      <br />, <br />,
      "Are you sure you want to delete?"];

    gContext.setconfirmModal({ header: "Profile delete confirmation", ["text"]: deletetext, ["confirmed"]: false, showButton: true, ["navigate"]: true, ["deleteaccount"]: true });

    gContext.toggleConfirmModal();
  }
  async function BindMessageSummary() {
    try {

      const result = await MessageSummary(gContext.userInfo.userID, gContext.userInfo.userType);

      if (result.data.messageSummary.responseData.code == "200") {

        let data = JSON.parse(result.data.messageSummary.responseData.message);

        if (data.length > 0) {

          setMessageSummary(data);

          setfromId(data[0].userid);

        }
        else
          setMessageSummary([]);

        if (pathName.toLowerCase() == "/messages") {
          setOpenMessage(true);
          gContext.setCurrentPage("message");
          setShowMessage(true);
        }
      }
    }
    catch (e) {
      console.log(e);
    }
  }
  useEffect(() => {

    BindMessageSummary();
  }, [gContext.userInfo]);
  useEffect(() => {

    BindMessageSummary();
  }, [gContext.msgCount]);

  return (
      <>
          {gContext.userInfo.idVerified && gContext.userInfo.profileComplete && 
              <li className="nav-item"> <a className={`nav-link  pointer ${(gContext.currentPage === "1" || gContext.currentPage === "11" || gContext.currentPage === "2" || gContext.currentPage === "3") ? 'active' : ''}`} style={{ cursor: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) ? '' : 'not-allowed' }} onClick={(e) => {
              e.preventDefault();
              if (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) {
                      gContext.setVisibleProfileMessage(false);
                      setOpenProfile(false); gContext.setCurrentPage("1");
                      navigate("/dashboard", { replace: true }); props.setShowCanvas();
                  }
              }}>Dashboard</a> </li>}
         
          <li className="nav-item dropdown">
              <a className={`nav-link dropdown-toggle d-flex align-item-center pointer ${(gContext.currentPage === "5") ? 'active' : ''}`}  data-bs-toggle="dropdown" aria-expanded="false">Profile<span className="m-l-10 m-icon material-icons">expand_more</span></a>
              <ul className={`dropdown-menu  profile-list ${(gContext.currentPage === "5") ? 'hide' : ''}`}>               
                  <li><a className="dropdown-item pointer" onClick={(e) => { e.preventDefault(); gContext.setVisibleProfileMessage(false); setOpenProfile(!openProfile); gContext.setCurrentPage("5"); navigate("/profile", { replace: true }); props.setShowCanvas(); }}>Profile</a></li>

                  {gContext.userInfo.idVerified && gContext.userInfo.profileComplete && <li><a className="dropdown-item pointer" onClick={(e) => { e.preventDefault(); props.setShowCanvas(); window.open(previewUrl, "_blank"); }}>Public View</a></li>}
              </ul>
          </li>
           
          <li className="nav-item"><a className={`nav-link d-flex pointer  ${(gContext.currentPage === "message") ? 'active' : ''}`} onClick={() => { setOpenMessage(!openMessage); gContext.setCurrentPage("message"); setShowMessage(true); navigate("/messages", { replace: true }); props.setShowCanvas();}}>Messages           
          {gContext.msgCount > 0 && <span className="count-msg  m-l-5">
                  {gContext.msgCount}
              </span>}
               </a></li>
        

         
      
    </>
  );
};

export default Sidebar;
