import React, { useContext, useEffect } from "react";

import PageWrapper from "../components/PageWrapper";
import { useParams } from "react-router-dom";
 
import ProfileView from "../sections/ExpertDashboard/ProfileView";
import ClientProfileView from "../sections/ClientDashboard/ProfileView";
import GlobalContext from "../context/GlobalContext";
export default function ProfileViewPage(props) {
    const { SUB } = useParams();
    const gContext = useContext(GlobalContext);
    gContext.setUserMode(props.userMode);

    useEffect(() => {        
        document.title = "Profile | Syncuppro";
    }, []); 
    return (
        <>
            <PageWrapper headerConfig={{
                bgClass: "dynamic-sticky-bg",
                button: props.navType
            }}>
                {props.userType == "Expert" && <ProfileView sub={SUB} mode={props.mode} userMode={props.userMode} />}
                {props.userType == "Client" && <ClientProfileView sub={SUB} userMode={props.userMode} />}

            </PageWrapper>
        </>
    );
};
