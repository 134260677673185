import React, { useContext, useEffect, useState } from "react";
import StarRating from "../../components/Core/Rating";
import styled from "styled-components";
import validator from 'validator';
import { sinceDate } from "../../utils/addDays";
import { FileUpload, getFileName } from "../../utils/fileUpload";
import { isInvalid } from "../../utils/helperFn";
import { ClientJobsRead, Createproposal, GetExpertPreviewLoad, CreateAlert } from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import Message from "../../components/Message";
import AlertUtils from "../../utils/AlertUtils";
import ReactCountryFlag from "react-country-flag";
import { Modal } from "react-bootstrap"; 
import ReactQuill from 'react-quill';
import DOMPurify from 'isomorphic-dompurify';
import { Popover, OverlayTrigger } from 'react-bootstrap';
const imgLoader = "../assets/image/svg/spinning-circles.svg";
 
const ModalStyled = styled(Modal)`
   &.modal {
   
  
  }
`;

export default function JobProposal(props) {
    const s3URL = process.env.REACT_APP_AWS_S3_URL; 
    const gContext = useContext(GlobalContext); 
    const [job, setJobs] = useState({}); 
    const [values, setValues] = React.useState({id: null, proposalSummary: '', proposalSummaryText: '', bidRate: null, resetResumeName: '', resumeName: '', resume: null, error: { bidRate: "", proposalSummary: "" } });
 
    const [errorMessage, setErrorMessage] = React.useState('');
    const [showUpdate, setShowUpdate] = React.useState(false);

    const reactQuillRef = React.useRef();
    const [editvalue, setEditvalue] = useState('');
    const [editLength, setEditLength] = useState(0);


    const modules = {
        toolbar: [
            [{ 'header': [false] }],
            ['bold'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };
    const popoverFile = (
        <Popover className="   " >

            <div className="tooltip-header bg-gray">
                <h5>Information</h5>
            </div>
            <div className="tooltip-meta">
                <p   >
                    Allowed format jpeg,png,jpg,doc,dox,pdf.
                </p>

            </div>



        </Popover>
    );

    const formats = [
        'header',
        'bold',
        'list', 'bullet',
    ];

    const editorKeyDown = (event) => {
        const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
        if (unprivilegedEditor.getText().trim().length > 4000 && event.key !== 'Backspace') {
            event.preventDefault();
        }
    };

    function isHtml(input) {
        return /<[a-z]+\d?(\s+[\w-]+=("[^"]*"|'[^']*'))*\s*\/?>|&#?\w+;/i.test(input);
    }
    function checkValidity() {
        let isValid = false;

        let { error, ...rest } = values; 
        error.bidRate = (rest.bidRate) ? error.bidRate : "Bid Rate is required";
       
        error.proposalSummary = validator.isEmpty(reactQuillRef.current.unprivilegedEditor.getText().trim()) ? "Cover Letter is required" : (isInvalid(reactQuillRef.current.unprivilegedEditor.getText()) ? "Invalid format" : "");
 
        setValues({ ...values, error });
        Object.values(error).forEach(val => {
            if (val.length > 0) {
                isValid = true;
            }
        });
        if (isValid)
            return false;
        else
            return true;

    }
    async function SendNotification() {

        const profileInfo = gContext.userInfo.profileInfo;
       

        try { 
            //[EXPERTSUB],[EXPERTNAME],[JOBTITLE],[JOBGUID]
            const alert = AlertUtils.NewProposal.
                replace("[EXPERTSUB]", gContext.userInfo.sub).
                replace("[EXPERTNAME]", (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "").
                replace("[JOBTITLE]", job.title).
                replace("[JOBGUID]", job.guid);
             

            const result = await CreateAlert({ id: null, alert: alert.replaceAll("\"", "''"), alertType: "Job", display: true, status: 'N', toTypeId: 2, toUserId: job.clientid });
            
            if (result.data.createAlert.responseData.code == "200") { 
               
               
            }
            
            gContext.setLoaderModalVisible(false);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            setErrorMessage("Server error");
        }

    }
    async function ApplyProposal() {
        if (checkValidity()) {
           
            gContext.setLoaderModalVisible(true);
            var data = { ...values, ['proposalSummary']: DOMPurify.sanitize(editvalue.replaceAll('"', `'`)), ["email"]: gContext.userInfo.email, ["clientId"]: job.clientid, ["expertId"]: gContext.userInfo.userID, ["jobId"]:job.id};
           
            try {

                if (values.resume) {
                   
                    data.resumeName = getFileName(values.resume, "resume-" + job.id);
                    FileUpload(values.resume, data.resumeName.split('/').pop());
                }
                
                const result = await Createproposal(data);
                 
                if (result.data.proposalCreate.responseData.code == "200") {
                   
                    const profile = await GetExpertPreviewLoad(gContext.userInfo.sub);

                    if (profile.data.expertAllLoad.responseData.code == "200") {
                        let data = JSON.parse(profile.data.expertAllLoad.responseData.message);
                        gContext.setUserInfo({ ...gContext.userInfo, ["appliedJobIds"]: (data[0].appliedJobIds) ? data[0].appliedJobIds : ""});
                    }

                    SendNotification();

                    setShowUpdate(true);
                    gContext.setLoaderModalVisible(false);
                }
                else {
                    setErrorMessage(JSON.parse(result.data.proposalCreate.responseData.message));
                }
                gContext.setLoaderModalVisible(false);
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
                setErrorMessage("Server error");
            }
        }
        gContext.setLoaderModalVisible(false);
    }
    const editorChange = (content, delta, source, editor) => {
        let { error, ...rest } = values;
        if (editor.getText().trim().length <= 4000) {
            setEditvalue(editor.getHTML());
            setEditLength(editor.getText().trim().length);

            if (isHtml(editor.getText())) {
                error.proposalSummary = "";
                //  setValues({ ...values, error, ['proposalSummaryText']: editor.getText().trim(), ['proposalSummary']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });


            }
            else {
                error.proposalSummary = "";
                // setValues({ ...values, error, ['proposalSummaryText']: editor.getText().trim(), ['proposalSummary']: DOMPurify.sanitize(editor.getHTML().replaceAll('"', `'`)) });
            }
        }
        else {
            setEditvalue(editvalue + " ");
            setEditLength(editLength);
        }
    };
    const handleFieldsChange = (e) => {

        const { name, value } = e.target;

        let error = { ...values.error };
        switch (name) {
           
            case "bidRate":
                error.bidRate = validator.isDecimal(value) ? "" : "Bid rate is invalid";
                break;
            default:
                if (!validator.isEmpty(value)) {
                    error[name] = "";
                }
                break;
        }
        setValues({ ...values, error, [name]: value });

    }
    const handleClose = () => {
        ClearData();
        
        props.toggleProposalModal();
    };

    /*const onFileChange = (event) => {
        if (event.target.files.length > 0) {

            setValues({ ...values, ["resume"]: event.target.files[0] });
        }
    }*/

    const onFileChange = (event) => {
        
        if (event.target.files.length > 0) 
        {

            TestFileType(event.target.files[0],['gif', 'jpg', 'png', 'jpeg','pdf','docx','doc','msword','vnd.openxmlformats-officedocument.wordprocessingml.document']);

            //setValues({ ...values, ["resume"]: event.target.files[0] });
        }

        
    }

    function TestFileType( fileName, fileTypes )
     {       
       
        
            var type = fileName.type.substring(fileName.type.indexOf("/")+1, fileName.type.length);

            if((fileTypes.indexOf(type) != -1) == true)
            {
              
                setValues({ ...values, ["resume"]: fileName });
                          
            }
            else {
          
            }

            }


    
   
    async function BindJobs() {
        try {
            if (props.id) {
                const result = await ClientJobsRead(props.id);
                
                if (result.data.jobRead && result.data.jobRead.responseData.code == "200") {

                    let data = JSON.parse(result.data.jobRead.responseData.message);
                    
                    if (data.length > 0) {
                        setJobs(data[0]);
                    }
                }
                if (props.mode && props.mode == "edit" && props.proposaldata) {
                    setEditvalue(props.proposaldata.cover_letter);
                    setValues({ ["id"]: props.proposaldata.id , ["proposalSummary"]: props.proposaldata.cover_letter, ["bidRate"]: (props.proposaldata.bid)? Math.floor(props.proposaldata.bid) : null , ["resetResumeName"]: (props.proposaldata.attachments) ? props.proposaldata.attachments : "", ["resumeName"]: (props.proposaldata.attachments)? props.proposaldata.attachments:"", ["resume"]: null, ["error"]: { ["bidRate"]: "", ["proposalSummary"]: "" } });
                }
            }
        }
        catch (e) {
            console.log(e);
            //window.location.href = "/error";
        }
    }
    function ClearData() {
        setEditvalue("");
        setEditLength(0); 
        setValues({["proposalSummary"]: '', ["proposalSummaryText"]: '', ["bidRate"]:  null, ["resetResumeName"]: '', ["resumeName"]: '', ["resume"]: null, ["error"]: { ["bidRate"]: "", ["proposalSummary"]: "" } });
    }


   
  
    useEffect(() => { 
        
    }, [props.id]);
    useEffect(() => {   
     BindJobs(); 
    }, [gContext.userInfo]);

    useEffect(() => {
        if (props.proposalModalVisible) { 
            BindJobs(); 
        }
        else
        {
            gContext.toggleRefreshDashboard("proposal");
            ClearData(); 
            setShowUpdate(false); 
        }
    }, [props.proposalModalVisible]);
    return (
        <>
            <ModalStyled className="modal fade"
                backdrop="static" size={((showUpdate === true && props.id) || !props.id) ? "sm" : "lg"}
                centered
                show={props.proposalModalVisible}
                onHide={props.toggleProposalModal}
            >

                {(!props.id) &&

                    <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/unsuccess-cross.svg"   />
                        <h5 className="m-b-10"></h5>
                        <p className="text-gray">{(!(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].exptcerts && gContext.userInfo.profileInfo[0].exptcerts.length > 0)) ? "Profile is not completed. Please update profile/check certificate's status." : "Profile is not completed. Please update profile/check certificate's status."} </p>

                    <div className="d-flex justify-content-center">
                            <a className="btn btn-orange m-r-10" href={(!(gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].exptcerts && gContext.userInfo.profileInfo[0].exptcerts.length > 0)) ? "/profile#certificate" : "/profile"} >  Update Profile</a>
                        <button type="button" className="btn  btn-dark-outline " onClick={() => { props.toggleProposalModal(); }}>Ok</button>
                        </div>

                    </div> 
                     }

                {(showUpdate === true && props.id) &&  
                    
                    <div className="modal-body text-center">
                        <img className="m-b-20" src="../images/sucess-check.svg" alt="sucess-check"/>
                            <h5 className="m-b-10">Successful!</h5>
                            <p className="text-light-gray">Your proposal has been submitted successfully.</p>

                            <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-green  " onClick={handleClose}>Ok</button>
                            </div>

         </div>}
                {(showUpdate === false && props.id) &&
                  
                    <div className="modal-content">


                        <div className="modal-header">
                        <h4 className="modal-title">Submit a Proposal</h4>
                        <button type="button" className="btn-close"   onClick={() => {
                            ClearData();
                            props.toggleProposalModal();
}}></button>
                        </div>


                        <div className="modal-body"   >
                            <div className="d-flex justify-content-between m-b-10">
                            
                                <h6><a href={window.location.origin + "/job/" + job.guid}  rel="noopener noreferrer" target="_blank">
                                {(job.title) ? job.title : ''}
                            </a></h6>

                            
                            </div>
                            <div className="d-flex justify-content-between m-b-20">

                              

                                {job.dateposted && <div className="info-meta">
                                    <span className="text-theme m-icon material-icons m-r-10">timer</span><span>Posted on : <b>{sinceDate(job.dateposted, Date.now())}</b></span>
                                </div>}

                            </div>

                        <p className="m-b-20 three-line" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}></p>

                            <div className="divider-line"></div>

                            <div className="row">

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper">
                                        <span className="text-theme m-icon material-icons m-r-10">monitor</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5 text-capi ">Job type</span>
                                            <span className="text-capi "> <b>{ (job.type)?job.type.toLowerCase():""}</b></span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper">
                                        <span className="text-theme m-icon material-icons m-r-10">location_on</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Location</span>
                                        <div className="info-meta flag">
                                            <ReactCountryFlag
                                                countryCode={(job.countrycode) ? job.countrycode : ''}
                                                svg
                                                style={{
                                                    width: '1.5em',
                                                    height: '.7em',
                                                }}
                                                title={(job.countryname) ? job.countryname : ''}
                                            />       <span className="one-line text-black m-l-5"><strong> {' '}
                                                <span className={`    ${(job.city != null && job.city.length > 2) ? 'text-capi' : 'text-upper'}`}>{(job.city) ? job.city.toLowerCase() : "" + '   '}{" "}</span>
                                                {' '}<span className={`    ${(job.state != null && job.state.length > 2) ? 'text-capi' : 'text-upper'}`}>{(job.state) ? job.state.toLowerCase() : ""}</span>
                                                {' '} {(job.countrycode) ? job.countrycode : ''}</strong></span>
                                                 
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper">
                                        <span className="text-theme m-icon material-icons m-r-10">straighten</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Project Length</span>
                                        <b>  {(job.projlength) ? job.projlength : ''}</b>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Weekly Hours</span>
                                        <b> {(job.weeklyhrs) ? job.weeklyhrs : ''}</b>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">work</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">Experience Level</span>
                                        <b>{job.explevel}</b>
                                        </div>
                                    </div>
                                </div>


                            {(job.ratefrom && job.rateto) ?

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5"> Rate Range (USD)</span>
                                            <b> ${(job.ratefrom && job.rateto) ? new Intl.NumberFormat('en-US').format(job.ratefrom) + " - " + new Intl.NumberFormat('en-US').format(job.rateto) : ''}</b>
                                        </div>
                                    </div>
                                </div>
                                :

                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <div className="icon-wrapper m-0">
                                        <span className="text-theme m-icon material-icons m-r-10">attach_money</span>
                                        <div className="d-flex flex-column">
                                            <span className="m-b-5">  {(job.dailyrate) ? "Daily " : ((job.fixedrate) ? "Fixed " : "Hourly ")}Rate (USD)</span>
                                            <b>  {(job.dailyrate) ?"$" +(new Intl.NumberFormat('en-US').format(job.dailyrate)) : ((job.hourlyrate) ? "$" + (new Intl.NumberFormat('en-US').format(job.hourlyrate)) : "$" +(new Intl.NumberFormat('en-US').format(job.fixedrate)))}</b>
                                       
                                        </div>
                                    </div>
                                </div>
                                 }
                             

                            </div>

                            <div className="divider-line"></div>

                       

                            <div className="row">
                            {errorMessage === "" && <>  <div className="col-lg-12 col-md-12 col-sm-12"><span className="text-red">{errorMessage} </span></div></>}
                                <div className="col-lg-6 col-md-6 col-sm-12 d-none">
                                    <div className="d-flex align-item-center">
                                        <div className="medie-info-img">
                                            <img src="images/Frame.svg" alt="frame.svg" />
                                        </div>
                                        <span className="expert-desg"><strong>ISO Consultment</strong></span>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="forn-group-input ">
                                    <label for="first name" className="form-label m-b-10">Bid Rate (USD) <span className="text-red"> *</span></label>
                                    <input name="bidRate"
                                        type="text" className={`form-control   ${(values.error.bidRate.length > 0) ? ' error' : ''}`}

                                        id="bidRate" defaultValue={values.bidRate} Value={values.bidRate} onKeyUp={handleFieldsChange}
                                        placeholder=" " onChange={handleFieldsChange}
                                    />
                                    {values.error.bidRate.length > 1 && (
                                        <span className="text-red">{values.error.bidRate}</span>
                                    )}
                                    </div>
                            </div>
                            <div className="company-desc ">
                                <div className="desc-info">
                                    <label className="form-label m-b-0 f-16"> Cover Letter   <span className="text-red"> * (Max 4000 character )</span></label>
                                    <p className="m-0 text-light-gray">Remaining Character length: <span className="text-green">  {4000 - ((editLength) ? editLength : 0)}</span></p>
                                </div>



                                <ReactQuill modules={modules}
                                    formats={formats} theme="snow" id="editor" style={{ height: "15vh" }} className="m-b-30"
                                    onKeyDown={editorKeyDown}
                                    ref={reactQuillRef}
                                    value={editvalue} onChange={editorChange}
                                ></ReactQuill>
                                {values.error.proposalSummary.length > 1 && (
                                    <span className="text-red  m-t-15">{values.error.proposalSummary} </span>
                                )}
                            </div>


                            <div className="company-meta m-b-20">
                                    <label className="d-flex form-label m-b-10 f-16">Upload Resume<OverlayTrigger trigger="hover" placement="auto" overlay={popoverFile} >
                                        <span className="text-theme m-icon material-icons m-l-10 pointer" >info</span>
                                    </OverlayTrigger></label>
                                    {(values.resumeName === "") &&
                                        <>
                                            <label
                                                htmlFor="fileUpload"
                                                className="mb-0  "
                                                style={{ cursor: 'pointer' }}>
                                                {(!values.resume) &&

                                                    <>    <div className="inline-icon-info">
                                                        <div className="upload-file p-relative">
                                                            <span className="text-light-gray m-icon material-icons m-r-10" data-toggle="tooltip" title="Click here to add file" >upload_file</span>Upload a resume

                    </div>
                                                    </div>


                                                    </>}

                                            </label>


                                            {(values.resume) && <>

                                                <div className="upload-doc-img m-b-10">
                                                    <div className="workers-img">
                                                        <img src="../images/file.png" htmlFor="idUpload" />
                                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: values.resetResumeName })}>
                                                    <span className="m-icon material-icons">close</span>
                                                </div>
                                            </div>


                                                   

                                                </div>


                                            </>
                                            }

                                            {(values.resume) && <span className="text-gray" > {(values.resume) ? values.resume.name : ''} </span>}




                                            <input
                                            type="file" key={values.resume}
                                                id="fileUpload" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                className="sr-only"
                                            onChange={onFileChange} onSelect={onFileChange}
                                            />

                                        </ >}
                                    {(values.resumeName !== "") &&
                                        <>

                                            <div className="upload-doc-img m-b-10">
                                            <div className="workers-img pointer"  >
                                                <img src="../images/file.png" data-toggle="tooltip" title="Click here to view." htmlFor="idUpload" className="pointer" onClick={() => { window.open(s3URL + values.resumeName, "_blank"); }} />
                                              


                                                <div className="btn-icon-close" data-toggle="tooltip" title="Remove Resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: "" })}>
                                                    <span className="m-icon material-icons">close</span>
                                                </div>
                                        </div>
                                            </div>
                                        </>}



                                    
                                    
                                </div>



                           
                             
                        </div>
                        <div className="modal-footer">
                            <div className="panel-action">
                                <button className="btn btn-orange btn-sm   m-r-20 text-white" onClick={() => {

                                    ApplyProposal();
                                }}>Apply</button>
                                <button type="button" className="btn btn-dark-outline btn-sm text-black d-flex align-item-center" onClick={() => {
                                    ClearData();
                                    props.toggleProposalModal();

                                }}>Cancel</button>
                            </div>
                        </div>

                        </div>

                    </div>
                   
                }


                <Modal.Body className={`p-0 d-none `}>
                    <button
                        type="button"
                        className="d-none circle-32 btn-reset bg-white pos-abs-tr mt-md-n6 mr-lg-n6 focus-reset z-index-supper"
                        onClick={handleClose}
                    >
                        <i className="fas fa-times"></i>
                    </button>
                    {(!props.id) &&
                        <div className="row no-gutters">
                        <div className="col-md-12 col-12">
                            <div className="media align-items-center px-10 py-7">
                                <div className=" align-items-left px-5 py-5">
                                    <div className="font-size-4   font-weight-semibold  line-height-2">
                                        Profile is not completed. Please click "Update Profile" to update your profile.
                                    </div>
                                   
                                    <div className=" mt-5 media pt-2 justify-content-md-end">  
                                     <a href="/profile"  
                                      className="btn-green  font-size-3 font-weight-bold border-0 text-white 
                                      rounded-5 text-uppercase pt-3 pl-4"
                                      style={{ width: '140px', height: '40px' }}     >
                                        Update Profile
                                          </a><button onClick={() => { props.toggleProposalModal(); }} type="button"
                                           className="btn-green  font-size-3 font-weight-bold border-0 text-white 
                                           rounded-5 text-uppercase pt-1 ml-5"
                                           style={{ width: '80px', height: '40px'  }}  >
                                            Cancel
                                          </button></div>
                                </div>
                            </div>
                        </div>


                        </div>}

                    {(showUpdate === false && props.id) &&
                        <div className="container px-0 py-0">
                            <div className="row justify-content-center">
                                <div className="col-xl-12 col-lg-12  ">
                                    <div className="font-size-7 font-weight-semibold    text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                        Submit a Proposal
                  </div>
                                </div>
                                <div className="col-xl-12 col-lg-12  mx-auto ">
                                    <div className="bg-white rounded-4 border border-mercury shadow-9 pb-10">
                                        
                                        <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5  border-bottom border-width-1 border-default-color light-mode-texts">
                                            <div className="row mb-6">
                                                <div className="col-lg-6">

                                                <a className="font-size-5 text-black-2 font-weight-semibold  " href={"job/"+job.guid} rel="noopener noreferrer" target="blank">
                                                    {(job.title) ? job.title : ''}
                                                </a>
                                                        <div className="media align-items-center d-none">
                                                            {/* <!-- media logo start --> */}
                                                            <div className="square-72   d-block mr-8">
                                                                {(job.logo) && <img src={s3URL + job.logo} className="w-100 rounded" alt="" />}
                                                            </div>
                                                            {/* <!-- media logo end --> */}
                                                            {/* <!-- media texts start --> */}
                                                            <div>
                                                                <h3 className="font-size-6 mb-0">
                                                                    {(job.company) ? job.company : ''}
                                                                </h3>
                                                                <span className="font-size-3 text-gray line-height-2">
                                                                    {(job.comp_tagline) ? job.comp_tagline : ''}
                                                                </span><br />
                                                                <span className="font-size-3 text-gray line-height-2">
                                                                    <StarRating rating={4.5} />
                                                                </span>
                                                            </div>
                                                            {/* <!-- media texts end --> */}
                                                        </div>
                                                   
                                                </div>
                                                <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                                                    {/* <!-- media date start --> */}
                                                    <div className="media justify-content-md-end">
                                                        <p className="font-size-4 text-gray mb-0">
                                                            {(job.dateposted) && <>   <h5 className="font-size-4 text-right   text-black-2 pt-5 mr-0 font-weight-semibold  media"><div className="image mr-5">
                                                                <i className="fa-regular fa-clock text-green-2"></i>
                                                            </div> <span className="font-size-4 text-gray font-weight-semibold">
                                                                Posted on : &nbsp;
                                                </span> {sinceDate(job.dateposted, Date.now())}</h5></>}
                                                        </p>
                                                    </div>
                                                    {/* <!-- media date end --> */}
                                            </div>
                                            <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20"> 
                                                <div className=" font-size-4 text-gray mb-0 " style={{ textOverflow: 'ellipsis', height: '73px', overflow: 'hidden', whitespace: 'nowrap' }} dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(job.desc).replaceAll(`<p>`, ``).replaceAll(`</p>`, ``) }}>

                                                </div><div className="mt-n7  mb-7 text-right w-100"><i className="fa-solid fa-ellipsis mr-n6"></i> </div>
                                            </div> 
                                            </div> 
                                        </div>
                                        {/* <!-- End Single Featured Job --> */}
                                        <div className="row bg-white px-5 m-0 mt-1 border-bottom border-width-1 border-default-color"><div className=" col-12   ">

                                            <div className="job-details-content pt-3 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-5    light-mode-texts">
                                                <div className="row">

                                                    <div className="col-md-4  pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6  ">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-desktop text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Job Type
                                                </p>
                                                        </div>
                                                    <h6 className="font-size-4 text-black-2 font-weight-semibold text-capi   ml-9"> {(job.type) ? job.type.toLowerCase() : ""}

                                                        </h6>
                                                    </div>
                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-location-dot text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Location
                                                </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-0">   <ReactCountryFlag
                                                            countryCode={(job.countrycode) ? job.countrycode : ''}
                                                            svg
                                                            style={{
                                                                width: '2em',
                                                                height: '1em',
                                                            }}
                                                            title={(job.countryname) ? job.countryname : ''}
                                                        />       {' '} {(job.city) ? job.city : ''}{' '}{(job.state) ? job.state : ''} {' '} {(job.countryname) ? job.countryname : ''}


                                                        </h6>

                                                    </div>
                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-ruler-horizontal text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Project Length
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {(job.projlength) ? job.projlength : ''}

                                                        </h6>
                                                    </div>

                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-business-time text-green-2 font-size-5 "></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0 ">
                                                                Weekly Hours
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {(job.weeklyhrs) ? job.weeklyhrs : ''}

                                                        </h6>
                                                    </div>

                                                    <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-briefcase text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                                Experience Level
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-9">  {job.explevel}

                                                        </h6>
                                                    </div>

                                                {(job.ratefrom && job.rateto) ?
                                                        <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                            <div className="media justify-content-md-start">
                                                                <div className="image mr-5">
                                                                    <i className="fa-solid fa-dollar-sign  text-green-2 font-size-5"></i>
                                                                </div>
                                                                <p className="font-weight-normal font-size-4 text-black-2 mb-0 pl-2 ">
                                                                    Rate Range (USD)
                                                        </p>
                                                            </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-8 pl-2">  {(job.ratefrom && job.rateto) ?"$"+new Intl.NumberFormat('en-US').format(job.ratefrom) + " - " + "$"+new Intl.NumberFormat('en-US').format(job.rateto) : ''}

                                                            </h6>
                                                    </div> : <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mt-6 mb-6">
                                                        <div className="media justify-content-md-start">
                                                            <div className="image mr-5">
                                                                <i className="fa-solid fa-dollar-sign text-green-2 font-size-5"></i>
                                                            </div>
                                                            <p className="font-weight-normal font-size-4 text-black-2 mb-0 pl-2">
                                                                {(job.dailyrate) ? "Daily " : ((job.fixedrate) ? "Fixed ":"Hourly ")}Rate (USD)
                                                    </p>
                                                        </div>
                                                        <h6 className="font-size-4 text-black-2 font-weight-semibold  ml-8 pl-2">
                                                            {(job.dailyrate) ? "$" + (new Intl.NumberFormat('en-US').format(job.dailyrate)) : ((job.hourlyrate) ? "$" + (new Intl.NumberFormat('en-US').format(job.hourlyrate)) : "$" +(new Intl.NumberFormat('en-US').format(job.fixedrate)))}
                                                        </h6>
                                                    </div>}
                                                    


                                                </div>


                                            </div>
                                        </div></div>

                                        <form action="/" className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                                            <fieldset>


                                                <div className="row ">

                                                    {errorMessage === "" && <><span className="error py-5   w-100  font-weight-semibold line-height-reset px-8   rounded-5 font-size-4">{errorMessage} </span><br /></>}
                                                </div>


                                                <div className="row mb-xl-1 mb-9">
                                                    <div className="col-lg-6 ">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="select2"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                                Bid Rate <span className="error font-size-3"> * </span> <span className="font-size-4  text-green-2 font-weight-semibold"> (USD)</span>
                                                            </label>
                                                            <input name="bidRate"
                                                                type="text" className={`form-control   ${(values.error.bidRate.length > 0) ? 'form-control-error' : ''}`}

                                                                id="bidRate" defaultValue={values.bidRate} Value={values.bidRate} onKeyUp={handleFieldsChange}
                                                                placeholder=" " onChange={handleFieldsChange}
                                                            />
                                                            {values.error.bidRate.length > 1 && (
                                                                <span className="error font-size-3">{values.error.bidRate}</span>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="aboutTextarea"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                                Cover Letter  <span className="error font-size-3">* ( Max 4000 Character)</span>
                                                            <br /><span className="font-size-3 text-green-2 font-weight-semibold"> Remaining Character length: {4000 - ((editLength) ? editLength : 0)}</span>
                                                            </label>

                                                           
                                                            {values.error.proposalSummary.length > 1 && (
                                                                <span className="error font-size-3">{values.error.proposalSummary}</span>
                                                            )}
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12 mb-8">
                                                        <div className="form-group">
                                                            <label
                                                                htmlFor="aboutTextarea"
                                                                className="d-block text-black-2 font-size-4 font-weight-semibold mb-4"
                                                            >
                                                                Upload Document     <br /> <span className="font-size-3 mt-5 text-green-2 font-weight-semibold"> (Upload only .pdf,.jpg,.png,.jpeg extensions file.)</span>
                                                            </label>
                                                            {(values.resumeName === "") && <div className="upload-file mb-8 text-left " style={{ cursor: 'pointer' }}>

                                                                <label
                                                                    htmlFor="fileUpload"
                                                                    className="mb-0 font-size-3"
                                                                    style={{ cursor: 'pointer' }}>
                                                                    {(!values.resume) && <div className=" m-0 p-0   text-center  "  ><i className="fa-solid   fa-file-arrow-up font-size-12 pointer text-green " data-toggle="tooltip" title="Click here to add file"></i> </div>}

                                                                </label>

                                                                {(values.resume) && <div className=" m-0 p-0 position-relative circle-100 bg-green-opacity-2 border border-width-1 text-center  image-container"  > <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i>  <div className="text-center border border-width-1 border-red circle-30 remove pos-abs-br bg-white "  > <i className="fas fa-trash   pointer font-size-4 text-red" data-toggle="tooltip" title="Remove resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: values.resetResumeName })}></i></div></div>}

                                                                {(values.resume) && <span className=" pt-5 font-size-4 text-green-2"> {(values.resume) ? values.resume.name : ''} </span>}




                                                                <input
                                                                    type="file"
                                                                    id="fileUpload" accept="image/*,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                    className="sr-only"
                                                                    onChange={onFileChange}
                                                                />

                                                            </div>}
                                                            {(values.resumeName !== "") &&
                                                                <> <div className=" m-0 p-0 position-relative circle-100 bg-green-opacity-2 border border-width-1 text-center  image-container"  ><a href={s3URL + values.resumeName} target="blank" data-toggle="tooltip" title="Click here to view."> <i className="fa-solid image fa-file-arrow-up font-size-9 pointer text-green-2 " ></i></a>  <div className="text-center border border-width-1 border-red circle-30 remove pos-abs-br bg-white "  > <i className="fas fa-trash   pointer font-size-4 text-red" data-toggle="tooltip" title="Remove resume" onClick={() => setValues({ ...values, ['resume']: null, ['resumeName']: '' })}></i></div></div></>}
                                                        </div>

                                                    </div> 

                                                </div>



                                            <div className="media py-10   text-right justify-content-md-end"  >
                                                    <div className="p-0 m-0 position-relative">  <button
                                                        type="button"  


                                                        className="btn btn-primary min-width-px-200 btn-medium btn-green btn-h-60 text-white  rounded-5 text-uppercase"
                                                     style={{ minWidth: '70px', height: '40px'}}
                                                        
                                                        onClick={() => {

                                                            ApplyProposal();
                                                        }}>
                                                        Apply
                            </button>
                                                        </div>


                                                    <button
                                                        type="button"

                                                        className="btn btn-primary min-width-px-200 btn-medium btn-green btn-h-60 text-white  ml-5 rounded-5 text-uppercase" 
                                                        style={{ minWidth: '80px', height: '40px'}}
                                                        onClick={() => {
                                                            ClearData();
                                                            props.toggleProposalModal();

                                                        }}>
                                                        Cancel
                            </button>

                                                </div> 

                                            </fieldset>
                                        </form>

                                    </div>
                                </div>
                            </div>

                        </div>}
                    {(showUpdate === true   && props.id) && <div className="row  px-auto  text-center pt-5">
                        <Message message={'Your proposal has been submitted successfully'} />
                    </div>}

                  
  </Modal.Body>
            </ModalStyled>
        </>
    );
};
