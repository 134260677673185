
import React, { useState, useContext, useEffect } from "react";
import { Collapse } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext"; 
import { Select } from "../../components/Core";
import validator from 'validator';
import { GetSocialProviders } from "../../utils/GetAPIUtils";
import { isInvalid } from "../../utils/helperFn";
import { GetExpertSocial, UpdateExpertSocial, DeleteExpertRow} from "../../utils/apiCalls";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
 

const imgLoader = "../assets/image/svg/spinning-circles.svg";
 

const Profile = (props) => {
    const gContext = useContext(GlobalContext);   
    const [rowCount, setRowCount] = useState(0);
    const [rowsSocial, setRowsSocial] = useState([]);
    const [storedSocial, setStoredSocial] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [socialList, setSocialList] = useState([]); 
    const [visibleLoader, setVisibleLoader] = useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [message, setMessage] = React.useState(gContext.visibleProfileMessage);
    const [loaded, setLoaded] = useState(false);
    
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {

        if (message && !gContext.loaderModalVisible) {
            gContext.setSuccessMessage(ConfirmMessages.ProfileConfirmation);
            gContext.toggleSuccessModal();
        }
    }, [message, gContext.loaderModalVisible]);

    async function getUtils() {
       
        const response = await GetSocialProviders();

        if (response.data && response.data.socialProviders) {
            let sociallist = [];
            response.data.socialProviders.map((value) => (
                sociallist.push({ value: value.id, label: value.link })));
            setSocialList(sociallist);
        } 
    }  
   /*Cancel profile edit*/
    
    /*For Social table*/
    function addTableRows() {
        
        const rowsInput = { 
           load:false,id:null , expertId: gContext.userInfo.userID,  provider: null,
            link: '', error: {  provider: '', link: '' } 
        } 
        setRowsSocial([...rowsSocial, rowsInput]);

    }

    function prevPage() {
        gContext.setCurrentPage('13');
        //gContext.setLoaderModalVisible(true); 
    }

    function nextPage() {
        gContext.setCurrentPage('14');
       // gContext.setLoaderModalVisible(true); 
    }

    function timeout(delay: number) {
        return new Promise( res => setTimeout(res, delay) );
    }

    function setLoad(status, index) {
        const rows = [...rowsSocial];
        rows[index]['load'] = status;
        setRowsSocial(rows);
    }
    function spliceRow(index) {
        const rows = [...rowsSocial];
        rows.splice(index, 1);
        setRowsSocial(rows);
    }
    /*For Social table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Social delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true  });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
    }
/*For Social table row delete*/
    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);
          
            try {
                gContext.setLoaderModalVisible(true);
                const result = await DeleteExpertRow(id, 'soc', storedSocial.length ===1? 0:null);
                
                if (result.data.expertRowDelete.responseData.code == "200") {
                    // spliceRow(index);
                    getSocialInfo();
                }
                else {
                     
                }
                
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
            }
        }
        else {
            setMessage(false);
            spliceRow(index);
        }
        gContext.setLoaderModalVisible(false);
    }

    
   
    /*For Social table row change*/
    const handleChange = (index, event,field) => {
        setMessage(false);
        let value = (field === "link") ? event.target.value :   event  ; 
        
        const rowsInput = [...rowsSocial]; 
        if (value !="" || value !=null)
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], [field]: "" } 
        if ((field === "link"))
            rowsInput[index]["error"] = { ...rowsInput[index]["error"], [field]: validator.isURL(value) ? "" : "Digital Link is invalid" }
        rowsInput[index][field] = value; 
        setRowsSocial(rowsInput); 
        
    }
  async  function updateSocial(index) {
    setMessage(false);
    setErrorMessage('');
        if (checkSocialValidity(index)) {
            try {
                gContext.setLoaderModalVisible(true);
                const rowsInput = [...rowsSocial];
                rowsInput[index]['load'] = true;
                setRowsSocial(rowsInput);
                const result = await UpdateExpertSocial(rowsSocial[index], rowCount);

                if (result.data.expertSocUpdate.responseData.code == "200") {
                    setMessage(true);
                    getSocialInfo();
                }
                else {
                    setErrorMessage(JSON.parse(result.data.expertSocUpdate.responseData.message));
                }
            }
            catch (e) {
                console.log(e);
                gContext.setLoaderModalVisible(false);
                setErrorMessage("Server error");
                
            } 
            gContext.setLoaderModalVisible(false);
            window.scrollTo({
                top: 0,
                behavior: 'smooth'

            });
        }
        else {   setMessage(false);
            setErrorMessage("Required fields are missing.");

        }
    }
    function checkSocialValidity(index) {
        let inValid = false;
        let { error, ...rest } = rowsSocial[index];

        error.provider = (rest.provider) ? error.provider : "Social Provider is required";
        error.link = (validator.isEmpty(rest.link)) ? "Social Link is required" : error.link;
        const rows = [...rowsSocial];
        rows[index][error] = error;
        setRowsSocial(rows);

        Object.values(error).forEach(val => {
            if (val.length > 0) {
                inValid = true;
            }
        });

        if (inValid)
            return false;
        else
            return true;

    }
    async function getSocialInfo() {
       
        setLoaded(false);
        try {
            if (gContext.userInfo.userID) {
                const result = await GetExpertSocial(gContext.userInfo.userID);
                
                if (result.data.expertSocLoad.responseData.code == "200") {
                    
                    BindData(JSON.parse(result.data.expertSocLoad.responseData.message));
                    setStoredSocial(JSON.parse(result.data.expertSocLoad.responseData.message));
                   
                }
                
            }
            setLoaded(true);
        }
        catch (e) {
            console.log(e);
            setLoaded(true);
        } 
    }

    function BindData(data) {
        //"[{\"sub\":\"f180bc26-27fa-4a1b-9b75-fee8dd832db0\",\"degreeid\":2,\"major\":\"\",\"school\":\"\",\"countryid\":61,\"startdate\":\"2010-06-01T00:00:00.000Z\",\"enddate\":\"2012-05-01T00:00:00.000Z\"}]"
        const rowsInput = []
       
        try {
            setRowCount(data.length);
            if (data.length > 0) {
                data.map((row) => {                    
                    let provider = socialList.filter(item => item["value"] == row.linkid);                 
                    rowsInput.push({
                        id: row.id, expertId:row.expertid,load:false,
                        provider: (provider.length > 0) ? provider[0] : null, link: row.url, error: { provider: '', link: '' }
                    });
                });
                setRowsSocial(rowsInput);
                gContext.setLoaderModalVisible(false);
                 
            }
            else {
                
                setRowsSocial([]);
            }
        } catch (e) {
            console.log(e);
        }
      
        
    } 
    useEffect(() => {

        if (gContext.confirmModal.confirmed) {
            deleteData(gContext.confirmModal.index, gContext.confirmModal.id);
        }
        else { setCurrentValue({ index: null, id: null }); }
        gContext.setLoaderModalVisible(false);
    }, [gContext.confirmModal]);
   /* To bind on load */
    useEffect(() => { 
        getUtils();  
        document.title = "Social | Syncuppro";
    }, []) 
    useEffect(() => { 
        if(socialList.length>0)  getSocialInfo();
       
    }, [socialList])  
    
  return (
      <>
         

       
          {
              loaded &&
          
              <div className="card panel  p-0">

                  <div className="card-header">

                      <div className="client-title">
                          <h6>SOCIAL INFORMATION</h6>
                      </div>
                  </div>
                  <div className="row d-none">

                      {errorMessage !== "" && <><span className="text-red">{errorMessage} </span><br /></>}
                      {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                  </div>
                  <div className="card-body">
                      <div className="table-responsive">
                          <table className="table table-striped">
                              <thead>
                                  <tr>
                                      <th className="w-half min-lg-cell">Social Provider</th>
                                      <th className="min-lg-cell">Social Link</th>
                                      <th className="w-50"></th>
                                      <th className="w-50"></th>
                                  </tr>
                              </thead>
                              <tbody>
                                
                                  {rowsSocial.map((data, index) => {
                                      const { id, provider, link, error, load } = data;

                                      return (
                                          <tr key={ index}>
                                      <td>
                                                  <Select id={`social${index}`} menuPortalTarget={document.body} menuPosition={'fixed'}
                                                      options={socialList} value={provider}
                                                      className="form-select padding0-select w-full "
                                                      border={false} placeholder="Select..." onChange={(e) => (handleChange(index, e, 'provider'))}
                                                  />{error.provider.length > 1 && (
                                                      <span className="text-red ">{error.provider}</span>
                                                  )}
                                      </td>
                                      <td>
                                                  <input
                                                      name="link"
                                                      type="text"
                                                      className="form-control "
                                                      id={`link${index}`}
                                                      placeholder="Link" defaultValue={link} onChange={(e) => (handleChange(index, e, 'link'))}
                                                  />{error.link.length > 1 && (
                                                      <span className="text-red ">{error.link}</span>
                                                  )}
                                      </td>
                                      <td>
                                                  <a className="btn btn-circle light"   onClick={() => (updateSocial(index))}><span className="text-theme m-icon material-icons"> {(id) ? "edit" : "save"}</span></a>
                                      </td>
                                      <td>
                                                  <a disabled={load} onClick={() => (deleteTableRows(index, id))} className="btn btn-circle light"><span className="text-theme m-icon material-icons">delete</span></a>
                                      </td>
                                  </tr>
                                      )
                                  })}
                                   
                              </tbody>
                          </table>
                          {(rowsSocial && rowsSocial.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                              <span className="text-light-gray m-icon material-icons m-b-10">menu</span>
                              <span>No Social  found</span>
                          </div>}
                      </div>

                      <div className="add-more">
                          <button type="button" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row" className="btn btn-sm btn-orange-outline d-flex align-item-center"><span className="m-icon material-icons m-r-5">add</span>Add more</button>
                      </div>

                  </div>

              </div>}
          {!loaded &&
              <>
                  <div className="card panel  p-0">

                      <div className="card-header">

                          <div className="client-title movingLoader w-150">
                              <h6> &nbsp;</h6>
                          </div>
                      </div>

                      <div className="card-body">
                          <div className="table-responsive">
                              <table className="table table-striped">
                                  <thead className="movingLoader">
                                      <tr><th></th>
                                          <th className="min-lg-cell"> </th>
                                          <th className="min-lg-cell"></th>
                                          <th className="min-lg-cell" > </th>
                                          <th className="w-100 min-md-cell" ></th>
                                          <th className="w-100 min-md-cell"></th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                      <tr ><td></td>
                                          <td className="min-lg-cell"> </td>
                                          <td className="min-lg-cell"></td>
                                          <td className="min-lg-cell" > </td>
                                          <td className="w-100 min-md-cell" ></td>
                                          <td className="w-100 min-md-cell"></td>
                                      </tr>
                                      <tr className="movingLoader"><td></td>
                                          <td className="min-lg-cell"> </td>
                                          <td className="min-lg-cell"></td>
                                          <td className="min-lg-cell" > </td>
                                          <td className="w-100 min-md-cell" ></td>
                                          <td className="w-100 min-md-cell"></td>
                                      </tr>
                                      <tr ><td></td>
                                          <td className="min-lg-cell"> </td>
                                          <td className="min-lg-cell"></td>
                                          <td className="min-lg-cell" > </td>
                                          <td className="w-100 min-md-cell" ></td>
                                          <td className="w-100 min-md-cell"></td>
                                      </tr>
                                      <tr className="movingLoader"><td></td>
                                          <td className="min-lg-cell"> </td>
                                          <td className="min-lg-cell"></td>
                                          <td className="min-lg-cell" > </td>
                                          <td className="w-100 min-md-cell" ></td>
                                          <td className="w-100 min-md-cell"></td>
                                      </tr><tr ><td></td>
                                          <td className="min-lg-cell"> </td>
                                          <td className="min-lg-cell"></td>
                                          <td className="min-lg-cell" > </td>
                                          <td className="w-100 min-md-cell" ></td>
                                          <td className="w-100 min-md-cell"></td>
                                      </tr>
                                      <tr className="movingLoader"><td></td>
                                          <td className="min-lg-cell"> </td>
                                          <td className="min-lg-cell"></td>
                                          <td className="min-lg-cell" > </td>
                                          <td className="w-100 min-md-cell" ></td>
                                          <td className="w-100 min-md-cell"></td>
                                      </tr>

                                  </tbody>
                              </table>

                          </div>

                          <div className="add-more">
                              <button type="button" className="btn btn-sm btn-orange-outline d-flex align-item-center movingLoader w-100 p-5">&nbsp;</button>
                          </div>

                      </div>

                  </div>

              </>
          }
          <div
              className="dashboard-main-container mt-20 mt-lg-25 min-h-100vh d-none"
              id="dashboard-body"
          >
              <div className="container">
                  <div className="mb-15 mb-lg-23">
                      <div className="row">
                          <div className="font-size-4 font-weight-semibold  bg-athens  w-100  text-green-2  pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">


                              <div className="row d-none">
                                
                                  {errorMessage !== "" && <><span className="error py-10   w-auto  font-weight-semibold line-height-reset px-8   font-size-5">{errorMessage} </span><br /></>}
                                  {message && <Message message={ConfirmMessages.ProfileConfirmation} />}
                              </div> 

                          </div>
                          {loaded && <div className="col-xxxl-12  ">  
                          <div className="pb-14">   
                            <div className="width-100  float-left  height-100px background-gray margin-10">
                                    <div className="row justify-content-center">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">

                                                
                                                <button  type="button"
                                                 className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                  prevPage(); }}>&laquo; PREV
                                                 </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                    <div className="row justify-content-end">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">

                                               
                                                <button  type="button"
                                                  className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                  nextPage(); }}>NEXT &raquo;
                                                 </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                </div>
 
                                 <>  
                                  <div className="contact-form  bg-white rounded-4 ">
                                      
                                  <div className="font-size-6 font-weight-semibold mb-11   bg-gray-opacity-3 pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-5">
                  Social Information 
                                      </div>    
                                      <form className=" pl-sm-10 pl-4 pr-sm-11 pr-4 pt-5 pb-10">
                                          <fieldset>
                                              <div className="row mb-xl-1 mb-9  ">
                                                
                                                  <div className="col-lg-12  ">

                                                  <div className="bg-white  pt-7 rounded pb-8  ">
                                        <div className="table-responsive  min-h-90vh">
                                            <table className="table table-striped ">
                                                                  <thead>
                                                                      <tr className=" bg-gray-opacity-2 border border-color-2">
                                                                          <th
                                                                              scope="col"
                                                                              className=" table-y-middle pl-11 min-width-px-155 border-0 font-size-4 font-weight-bold " style={{ width: '200px' }}
                                                                          >
                                                                              Social Provider
                        </th>
                                                                          <th
                                                                              scope="col"
                                                                              className=" table-y-middle border-0 pl-8 font-size-4 min-width-px-235 font-weight-bold  "
                                                                          >
                                                                              Social Link
                        </th>
                                                                          <th></th>

                                                                          <th
                                                                              scope="col"
                                                                              className="table-y-middle border-0 font-size-4 font-weight-bold text-right" style={{ width: '200px' }}
                                                                          >
                                                                              <button type="button" className="btn btn-primary    btn-mini btn-green rounded-5 text-white   pointer" onClick={() => addTableRows()} data-toggle="tooltip" title="Add Row"  > + ADD </button>
                                                                              <span className="font-size-3   text-green-2 font-weight-semibold">  </span>
                                                                          </th>

                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                      {rowsSocial.map((data, index) => {
                                                                          const { id, provider, link, error,load } = data;

                                                                          return (
                                                                              <tr className="border border-color-2" key={index}>
                                                                                  <td scope="row " className="pl-6 border-0 py-1 min-width-px-155 pr-0 table-y-middle" >
                                                                                      <Select id={`social${index}`} menuPortalTarget={document.body} menuPosition={'fixed'}
                                                                                          options={socialList} value={provider}
                                                                                          className=" pl-0 border  min-width-px-155 h-px-40 font-size-4    " 
                                                                                          border={false} placeholder="Select..." onChange={(e) => (handleChange(index, e, 'provider'))}
                                                                                      />{error.provider.length > 1 && (
                                                                                          <span className="text-red ">{error.provider}</span>
                                                                                      )}
                                                                                  </td>
                                                                                  <td className="table-y-middle py-1  min-width-px-235 pr-0">
                                                                                      <input
                                                                                          name="link"
                                                                                          type="text"
                                                                                          className="form-control h-px-40 table-y-middle"
                                                                                          id={`link${index}`}
                                                                                          placeholder="Link" defaultValue={link} onChange={(e) => (handleChange(index, e, 'link'))}
                                                                                      />{error.link.length > 1 && (
                                                                                          <span className="text-red ">{error.link}</span>
                                                                                      )}
                                                                                  </td>
                                                                                  <td className="table-y-middle "> </td>
                                                                                  <td className="table-y-middle py-1  min-width-px-170  media text-right justify-content-md-end">
                                                                                      <div className="text-center mr-2 table-y-middle ml-5" data-toggle="tooltip" title="Save Row">
                                                                                        
                                                                                          <a style={{width:"80px"}} className="border font-size-3 font-weight-bold text-green-2 px-5 rounded-5   border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer"  data-toggle="tooltip"  title="Save Row" onClick={() => (updateSocial(index))}>
                                                                                                   {(id) ? "Update" : "Save"}
                                                                                          </a>
                                                                                         

                                                                                      </div>
                                                                               
                                                                                      <div className="text-center table-y-middle " data-toggle="tooltip" title="Delete Row">

                                                                                          <a className="border font-size-3 font-weight-bold text-red-2 px-5 rounded-5   border-width-1 bg-gray-opacity-2 hover-shadow-2 shadow-8 text-uppercase pointer" disabled={load} data-toggle="tooltip" title="Delete Row" onClick={() => (deleteTableRows(index,id))}>
                                                                 Delete
                              </a>

                                                                                      </div>
                                                                                  </td>
                                                                              </tr>)
                                                                      })}

                                                                  </tbody>
                                                              </table>
                                                          </div>

                                                      </div></div></div>
                                              <div className="media d-none "  >
                                                  <div className="p-0 m-0 position-relative">  <button
                                                      type="button" disabled={visibleLoader}

                                                      className="btn btn-primary btn-medium    btn-green  text-white  rounded-5 text-uppercase" onClick={() => {
                                                          updateSocial();

                                                      }}>
                                                     Save
                            </button>
                                                      {visibleLoader === true && <img src={imgLoader} width="40" className="pos-abs-cr px-1" />}</div>

                                                  <button
                                                      type="button"

                                                      className="btn btn-primary  btn-medium  btn-green  text-white  ml-5 ml-sm-3 rounded-5 text-uppercase" onClick={() => {


                                                      }}>
                                                      Cancel
                            </button>
                                                  {/* 
                                                   <div className="col-md-3 col-lg-2">


                                                      <button
                                                          type="button"

                                                          className="btn btn-primary min-width-px-200 btn-medium btn-green btn-h-60 text-white min-width-px-210 rounded-5 text-uppercase" onClick={() => {
                                                              gContext.setCurrentPage('10'); window.scrollTo({
                                                                  top: 0,
                                                                  behavior: 'smooth'

                                                              });
                                                          }}>
                                                          Preview
                            </button>
                                                  </div> 
                                                  */}
                                              </div>
                                          </fieldset>
                                      </form>

                                     
                                       
                                  </div>
                                  <div className="width-100  float-left  height-100px background-gray margin-10">
                                    <div className="row justify-content-center">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">

                                                
                                                <button  type="button"
                                                className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                  prevPage(); }}>&laquo; PREV
                                                 </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="width-100  float-right  height-100px background-gray margin-10 pl-30">
                                    <div className="row justify-content-end">
                                        <div className="col-12 dark-mode-texts">
                                            <div className="py-5">

                                               
                                                <button  type="button"
                                                  className="btn btn-mini btn-primary bg-green px-3 rounded-5   font-size-3 text-white  text-uppercase " onClick={() => {
                                                  nextPage(); }}>NEXT &raquo;
                                                 </button>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                  <div className="contact-form  bg-white rounded-4 mt-10 "> 
                                      
                                  </div>
                                  



                              </>
                           
                                
                               
                             
                          </div>}

                          {!loaded &&

                              <div className="col-lg-12">
                                  <div className="container">


                                      <div
                                          className="accordion rounded-10 border  pl-1  mt-10"

                                      >
                                          <div className="border-bottom overflow-hidden ">
                                              <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                                  <button
                                                      data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset  hover-shadow-1 font-size-6 font-weight-semibold  text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                                      type="button"

                                                      aria-expanded={true}
                                                  >
                                                      <span className="  border border-color-2 movingLoader px-25 py-8"></span>
                                                  </button>
                                              </div>

                                          </div>
                                          <div className="border-bottom overflow-hidden ">

                                              <Collapse in={true}>
                                                  <div className="row bg-white p-0 m-0 mt-10">

                                                      <div className="col-12 mt-5">
                                                          <div className="table-responsive">
                                                              <table className="table table-striped border border-color-2 ">
                                                                  <thead>
                                                                      <tr className=" py-7 border border-color-2 movingLoader">
                                                                          <th
                                                                              scope="col"
                                                                              className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                                          >

                                                                          </th>
                                                                          <th
                                                                              scope="col"
                                                                              className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                                          >

                                                                          </th>
                                                                          <th
                                                                              scope="col"
                                                                              className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                                          >

                                                                          </th>


                                                                          < th
                                                                              scope="col"
                                                                              className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                                          >

                                                                          </th>

                                                                      </tr>
                                                                  </thead>
                                                                  <tbody>
                                                                      <tr className="border border-color-2" >
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                      </tr>
                                                                      <tr className="border border-color-2 movingLoader" >
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                      </tr>
                                                                      <tr className="border border-color-2" >
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                      </tr>
                                                                      <tr className="border border-color-2 movingLoader" >
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                      </tr>
                                                                      <tr className="border border-color-2" >
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>

                                                                      </tr>   <tr className="border border-color-2 movingLoader" >
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>
                                                                          <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                                          </td>

                                                                      </tr>

                                                                  </tbody>
                                                              </table>
                                                          </div>
                                                      </div>

                                                  </div>
                                              </Collapse>
                                          </div>


                                      </div>




                                  </div>
                              </div>}
                      </div>
                  </div>
              </div>
          </div>
    </>
  );
};

export default Profile;
