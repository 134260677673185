import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { ExpertContractUpdate, LoadContract } from "../../utils/apiCalls";
import { getMonthDateYear, addDateDays } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message"; 
import { Popover, OverlayTrigger } from 'react-bootstrap';
import ModalContract from "../../components/JobProposal/ClientContract";
import Feedback from "../../components/JobProposal/Feedback";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";
import styled from "styled-components";
import { Modal } from "react-bootstrap";

export default function ExpertContract(props) {

    const gContext = useContext(GlobalContext);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [errorMessage, setErrorMessage] = React.useState('');
    const [contract, setContract] = useState({});
    const [rowsContracts, setRowsContracts] = useState([]);
    const [id, setId] = useState(null);
    const [guid, setGuid] = useState(null);
    const [message, setMessage] = React.useState(false);    
    const [contractmodalvisible, setContractmodalvisible] = useState(false);
    const [modalvisible, setmodalvisible] = useState(false);
    const [currentValue, setCurrentValue] = useState({ code: null, id: null });
    const [mode, setMode] = useState("");
    const [showScrollup, setShowScrollup] = useState(null);
    const [toggleSetting, setToggleSetting] = useState(false);
    const [loaded, setLoaded] = useState(false);

    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(rowsContracts, page, gContext.paginationSize);
    const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050;
  }
`;
    const togglecontractmodal = () => {
        setContractmodalvisible(!contractmodalvisible);
    };
    const togglemodal = () => {
        setmodalvisible(!modalvisible);
    };


    async function contractComplete(data) {
        try {

            setErrorMessage("");


            //if (data.statuscode == 100) {
            //    setId(data.id); setGuid(data.contractguid); setMode("terminate"); togglecontractmodal(data.contractguid);
            //    return;
            //}
            gContext.setLoaderModalVisible(true);
            const result = await LoadContract(data.contractguid);

            if (result.data.loadContract && result.data.loadContract.responseData.code == "200") {

                let response = JSON.parse(result.data.loadContract.responseData.message);

                if (response.length > 0) {
                     
                let filteredData = response[0].milestones.filter(item => (item["mscode"] == 200 || item["mscode"] == 300 || item["mscode"] == 450));
                if (filteredData.length > 0) {
                    gContext.setconfirmModal({ header: " ", ["text"]: ConfirmMessages.contractTerminationAlert.replace("[DATE]", getMonthDateYear(new Date().toDateString())), ["confirmed"]: false, code: data.contractguid, id: data.id, showInfo: true, showButton: true, page: "contract", mode: 'post'  });
                    gContext.toggleConfirmModal();
                }
                else {
                    setId(data.id); setGuid(data.contractguid); setMode("terminate"); togglecontractmodal(data.contractguid);

                }
                    
                }
            }
            gContext.setLoaderModalVisible(false);
            setLoaded(true);
        }
        catch (e) {
            console.log(e);
            gContext.setLoaderModalVisible(false);
            //  window.location.href = "/error";
        }


    }
    useEffect(() => {
        if (errorMessage != "") {
            gContext.setErrorModal(errorMessage);
            gContext.toggleErrorModal();
        }
    }, [errorMessage]);
    useEffect(() => {
        if (gContext.confirmModal.confirmed) {
           
            if (gContext.confirmModal.mode == "post" && gContext.confirmModal.page == "contract") {
                setId(gContext.confirmModal.id);
                setGuid(gContext.confirmModal.code);
                setMode("terminate");
                togglecontractmodal(gContext.confirmModal.code);
                gContext.setconfirmModal({
                    ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["code"]: null,["page"]:""
                });

            }
            else
                setCurrentValue({ code: null, id: null,page: "" });
        }
        else { setCurrentValue({ code: null, id: null, page: "" }); }
    }, [gContext.confirmModal]);
   
    async function UpdateStatus(code, id) {
        try {
            if (code == "accept") {
                const profileInfo = gContext.userInfo.profileInfo; 

                gContext.setLoaderModalVisible(true);
                const result = await ExpertContractUpdate(id, (profileInfo && profileInfo.length > 0) ? profileInfo[0].firstname + " " + profileInfo[0].lastname : "" , gContext.userInfo.userID);
                
                if (result.data.expertContractUpdate.responseData.code == "200") {
                    gContext.toggleRefreshDashboard("contract");
                    
                } gContext.setLoaderModalVisible(false);
            }
            if (code == "terminate") { } 
        }
        catch (e) {
            console.log(e);
        }
        window.scrollTo({
            top: showScrollup,
            behavior: 'smooth'

        });
    }

    const contractStatusChange = (id, mode) => {
        setShowScrollup(document.documentElement.scrollTop);
        if (id) { 
            
            gContext.setconfirmModal({ header: " ", ["text"]: "Do you want to " + mode + " this contract?", ["confirmed"]: false, code: mode, id: id, showButton: true, mode: 'post' });
            gContext.toggleConfirmModal();
        }
    }
    function LoadContracts() {
        if (props.contractData) {
            let data;
            setPage(1);
            if (props.mode === "new") {

                data = props.contractData.new;
              
            }
            else if (props.mode === "Active") {

                data = props.contractData.active;
               
            }
            else if (props.mode === "Completed") {

                data = props.contractData.completed;
               
            }
            else if (props.mode === "Terminated") {

                data = props.contractData.terminated;
              
            }
            
            setRowsContracts(data);
            setLoaded(true);
        }
    }
    useEffect(() => {
        LoadContracts();
    }, [props.contractData]);

    useEffect(() => {
       
        LoadContracts();
    }, [props.mode]);
     

    useEffect(() => {
       // console.log(gContext.userInfo.profileInfo);
        LoadContracts();
        gContext.setLoaderModalVisible(false);
    }, []);

    useEffect(() => {
        if (gContext.confirmModal.confirmed) {

            if (gContext.confirmModal.mode == "post") {

                UpdateStatus(gContext.confirmModal.code, gContext.confirmModal.id);
                gContext.setconfirmModal({
                    ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["code"]: null
                });

            }
            else
                setCurrentValue({ code: null, id: null });
        }
        else { setCurrentValue({ code: null, id: null }); }
    }, [gContext.confirmModal]);

    useEffect(() => {

        document.title = "New Contracts | Syncuppro";
    }, []);
    return (
        <>
             

            {message && <div className="text-red">
                <Message message="Job deleted successfully" />
            </div>}

            {(loaded && rowsContracts && rowsContracts.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                <span>No contract found</span>
            </div>}
            {(loaded && rowsContracts && rowsContracts.length > 0) &&
                <>
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>


                            <tr >
                                <th
                                    className="w-200 min-lg-cell"
                                  
                                >
                                      Title
                        </th>
                                <th
                                    className="  min-lg-cell"
                                  
                                >
                                    Company
                        </th> 
                                <th className="  min-lg-cell">
                                    Hiring Person
                        </th>


                                <th className="  min-lg-cell">
                                   Rate
                        </th>
                                {(props.mode == "Active" || props.mode == "Completed") &&
                                    <>  <th className="  min-lg-cell" >Hours Spent</th> </>}

                        
                        <th className="  min-lg-cell">
                                    Weekly Hours
                        </th>

                       
                               
                                {(props.mode == "new" ||  props.mode == "Completed" || props.mode == "Terminated") && <><th className="  min-lg-cell">
                                    {props.mode == "Terminated" ? "Terminated on" : (props.mode == 'new')?'Starts On': props.mode +' on'}
                        </th></>}
                                {props.mode == "Terminated" && <th className="  min-lg-cell"


                                >
                                    Termination  Reason
                        </th>}
                                <th className="  min-lg-cell"
                                   
                                  
                                ></th>
                            </tr>

                                 

                            </thead>
                            <tbody>
                            {slice && slice.map((data, index) => {


                                    return (
                                        <tr key={index}>
                                            <td>
                                                <span className="d-flex justify-content-left align-items-left">
                                                    <span className="title one-line pointer" data-toggle="tooltip" title={data.title} onClick={() => { setId(data.id);setGuid(data.contractguid); setMode("view"); togglecontractmodal(); }}>{data.title}</span>
                                                </span>
                                            </td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <div className="info-meta company pointer" onClick={(e) => { window.open("/Client/" + data.sub, "_blank") }}   >
                                                        <img className="m-r-10 "  src={s3URL + data.logo} alt={data.company  } />
                                                        <span className="one-line text-black ">{data.company}</span> <a  className="m-l-5 text-theme m-icon material-icons">open_in_new</a> 
                                                    </div>

                                                   
                                                </div>
                                            </td>
                                            <td> {data.hiringperson}</td>

                                           

                                          
                                            <td>  ${(( data.dailyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.dailyrate)) : (data.fixedrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.fixedrate)) : (data.hourlyrate) ? new Intl.NumberFormat('en-US').format(parseInt(data.hourlyrate)) : (data.ratefrom && data.rateto) ? new Intl.NumberFormat('en-US').format(parseInt(data.ratefrom)) + ' - ' + new Intl.NumberFormat('en-US').format(parseInt(data.rateto)) : '-')}</td>
                                            {(props.mode == "Active" || props.mode == "Completed") && <>
                                                <td  >

                                                    {data.totalhoursspent ? (data.totalhoursspent) : ""}

                                                </td></>}
                                            <td>{(data.hours) ? data.hours :''}</td>

                                            {props.mode == "new" && <><td 
                                            >
                                                <span className="d-flex align-item-center justify-content-center"> {(data.startdate) ? getMonthDateYear(data.startdate) : ""} <OverlayTrigger trigger="hover" placement="auto" overlay={<Popover className="" >

                                                    <div className="tooltip-header bg-gray">
                                                        <h5>Information</h5>
                                                    </div>
                                                    <div className="tooltip-meta">
                                                        <p>E-Sign contract before expiry date {data.datecreated ? getMonthDateYear(addDateDays(data.datecreated,14) ):""} </p>

                                                    </div>
                                                </Popover>} ><span className="text-theme m-icon material-icons m-l-5 pointer"  >info</span></OverlayTrigger> </span> 
                                            </td></>} 


                                            <td className="d-none">
                                                    {(data.enddate) ? getMonthDateYear(data.enddate) : ""}
                                                
                                            </td>
                                            {props.mode == "Completed" && <><td 
                                            >
                                                {(data.completeddate) ? getMonthDateYear(data.completeddate) : ""}
                                            </td></>}  {props.mode == "Terminated" && <><td 
                                            >
                                                {(data.terminateddate) ? getMonthDateYear(data.terminateddate) : ""}
                                            </td> <td  
                                            >
                                                    {(data.reason) ? data.reason : ""}
                                                </td></>}





                                         
                                            <td>
                                                <div className="btn-table"> 

                                                    <button className="btn btn-sm btn-gray pointer"  onClick={() => { window.open("/contract/" + data.contractguid, "_blank"); }}>
                                                        View Contract
                                                            </button>
                                                    {props.mode == "new" &&


                                                        <button className="btn btn-sm btn-gray"  onClick={() => { if (gContext.userInfo.paymentConnected) { setId(data.id);setGuid(data.contractguid); setMode("accept"); togglecontractmodal(); } else { setToggleSetting(true); } }}> e-Sign</button>
                                                    }


                                                    {(props.mode == "Terminated") &&
                                                        <button className="btn btn-sm btn-gray"  onClick={() => { setContract(data); togglemodal(); }}>
                                                            Feedback</button>

                                                    }
                                                    {gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && !gContext.userInfo.profileInfo[0].flagged && props.mode == "Active" &&
                                                        <button className="btn btn-sm btn-gray" onClick={() => { contractComplete(data); }}>
                                                        Terminate</button>
                                                    }
                                                    

                                                </div>
                                            </td>
                                        </tr>)
                                })}</tbody>
                        </table>
                </div>
                {(rowsContracts && rowsContracts.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}
                     </> }

                        
                    
            {contractmodalvisible && <ModalContract id={id} guid={guid} mode={mode} contractmodalvisible={contractmodalvisible} togglecontractmodal={() => { togglecontractmodal() }} updateData={() => LoadContracts()} />}
            {modalvisible && <Feedback contract={contract} modalvisible={modalvisible} togglemodal={togglemodal} />}
            <ModalStyled size="sm"
                centered
                show={toggleSetting}
                onHide={() => setToggleSetting(!toggleSetting)}
            >

                <div className="modal-body text-center">
                    <img className="m-b-20" src="../images/unsuccess-cross.svg" alt="error" />
                    <h5 className="m-b-10">Error!</h5>
                    <p className="text-light-gray">Your payment account is not connected. Update payment details in settings page. </p>

                    <div className="d-flex justify-content-center">
                        <button type="button" className="btn btn-orange m-r-10" onClick={() => { window.open("/settings", "_blank"); }}>Settings</button> <button type="button" className="btn btn-dark-outline " onClick={() => { setToggleSetting(!toggleSetting); }}>Ok</button>
                    </div>

                </div>
            </ModalStyled >
        </>
    );
};