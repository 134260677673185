import React, { useContext, useState, useEffect,useRef } from "react";
import CountUp from "react-countup";
import Message from "../../components/Message";
import LazyLoad from "react-lazyload";
import { ClientDashboardCounts, ProposalLoadAll, GetClientJobs, LoadClientOffers, ClientContractsLoad} from "../../utils/apiCalls";
import GlobalContext from "../../context/GlobalContext";
import ClientNewJobs from "../../sections/ClientDashboard/NewJobs";
import ClientPostedJobs from "../../sections/ClientDashboard/PostedJobs";
import ClientWithdrawJobs from "../../sections/ClientDashboard/WithdrawJobs";
import ClientNewContract from "../../sections/ClientDashboard/Contracts";
import ClientOffers from "../../sections/ClientDashboard/Offers";

import ClientProposal from "../../sections/ClientDashboard/Proposal";
import { Collapse } from "react-bootstrap";

export default function DashboardMain() {
    const gContext = useContext(GlobalContext);
    const offerRef = useRef();
    const propsalRef = useRef();
    const contractRef = useRef();
    const [showJobs, setShowJobs] = useState("posted");
    const [showProposals, setShowProposals] = useState("open");
    const [showContracts, setShowContracts] = useState("new");
    const [showOffers, setShowOffers] = useState("new");
    
    const [jobsCount, setJobsCount] = useState(null);
    const [proposalCount, setProposalCount] = useState(null);
    const [contractCount, setContractCount] = useState(null);
    const [offersCount, setOffersCount] = useState(null); 
    const [openItem, setOpenItem] = useState({ job: true, proposal: true, contract: true, offer: true });
    const [loader, setLoader] = useState({ job: true, proposal: true, contract: true, offer: true });
    const [proposalDataList, setProposalDataList] = useState([]);
    const [jobList, setJobList] = useState([]);
    const [offerDataList, setOfferDataList] = useState([]);
    const [contractDataList, setContractDataList] = useState([]);
    const [loaded, setLoaded] = useState(false);
    async function BindJobs() {
        try {
            
            const result = await GetClientJobs(gContext.userInfo.userID);

            if (result.data.clientJobsLoad.responseData.code == "200") {

                let data = JSON.parse(result.data.clientJobsLoad.responseData.message);
                
                if (data) {
                   

                    setJobList(data);

                }
            }
            setLoader({ ...loader, ["job"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["job"]: true });
        }
    }
    async function BindCounts() {
        try {
           // gContext.setLoaderModalVisible(true);
            const result = await ClientDashboardCounts(gContext.userInfo.userID);

            if (result.data.clientDashboardCounts.responseData.code == "200") {

                let data = JSON.parse(result.data.clientDashboardCounts.responseData.message);
                 
                if (data) { 
                    setJobsCount(data.jobCounts);
                    setProposalCount(data.proposalCounts);
                    setContractCount(data.contractCounts);
                    setOffersCount(data.offerCounts);

                }
            }
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
        }
    }
    async function BindProposals() {
        try {
           // gContext.setLoaderModalVisible(true);
            const result = await ProposalLoadAll(gContext.userInfo.userID);
            
            if (result.data.proposalLoadAll.responseData.code == "200") {

                let data = JSON.parse(result.data.proposalLoadAll.responseData.message);

                if (data) {
                    setProposalDataList(data);
                }
            }
            setLoader({ ...loader, ["proposal"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["proposal"]: true });
        }
    }
    async function BindOffers() {
        try {
           // gContext.setLoaderModalVisible(true);
            const result = await LoadClientOffers(gContext.userInfo.userID);

            if (result.data.loadClientOffers.responseData.code == "200") {

                let data = JSON.parse(result.data.loadClientOffers.responseData.message);
                
                if (data) {
                   // let filterData = data.filter(item => item["statuscode"] == 100); 
                    setOfferDataList(data); 
                }
            }
            setLoader({ ...loader, ["offer"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["offer"]: true });
        }
    }
    async function BindContracts() {
        try {
           // gContext.setLoaderModalVisible(true);
            const result = await ClientContractsLoad(gContext.userInfo.userID);

            if (result.data.clientContractsLoad.responseData.code == "200") {

                let data = JSON.parse(result.data.clientContractsLoad.responseData.message);
               
                if (data) {
                    // let filterData = data.filter(item => item["statuscode"] == 100); 
                    setContractDataList(data);
                }
            }
            setTimeout(() => {
                setLoaded(true);

            }, 2000);
            setLoader({ ...loader, ["contract"]: true });
        }
        catch (e) {
            gContext.setLoaderModalVisible(false);
            setLoader({ ...loader, ["contract"]: true });
        }
    }


    

    //clientDashboardCounts
    
    useEffect(() => {
        BindCounts();
        BindProposals();
        BindJobs();
        BindOffers();
        BindContracts();
        
        document.title = "Dashboard | Syncuppro";
    }, []);
    useEffect(() => {
        if (gContext.refreshDashboard == "proposal") 
            BindProposals();
        if(gContext.refreshDashboard =="offer") 
            BindOffers();
        if (gContext.refreshDashboard == "contract") { BindOffers(); BindContracts(); }


        BindCounts();
        gContext.toggleRefreshDashboard("");
    }, [gContext.refreshDashboard]);
    useEffect(() => {
       // offerRef.current?.scrollIntoView({ behavior: "smooth", block: "start" }); 
    }, [offerDataList]);

    useEffect(() => {
      //  propsalRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [proposalDataList]);
    useEffect(() => {
     //  contractRef.current?.scrollIntoView({ behavior: "smooth", block: "start" });
    }, [contractDataList]);
    
    return (
        <>



            {loaded &&
                <>
                <span className="screen-darken"></span>

<div className="page-wrapper">

                 
<div className="section statics-block-wrapper">

                        <div className="container-fluid   ">

                            <div className="statistic-div-wrapper align-item-center justify-content-center">

                                <div className="statistic-div d-none">
                                    <p className="m-b-10"><b>Total Jobs</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(jobsCount) ? jobsCount.total : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/jobs.svg" alt="Shape" />
                                        <img src="../images/dashboard-icons/statistic-shape-1.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div ">
                                    <p className="m-b-10"><b>Active Jobs</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(jobsCount) ? jobsCount.active : 0} </h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/jobs.svg" alt="jobs" />
                                        <img src="../images/dashboard-icons/statistic-shape-2.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div d-none">
                                    <p className="m-b-10"><b>Total Applications</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(proposalCount) ? proposalCount.total : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/application.svg" alt="application" />
                                        <img src="../images/dashboard-icons/statistic-shape-3.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Shortlisted Proposals</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(proposalCount) ? proposalCount.shortlisted : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/interviewing.svg" alt="interviewing" />
                                        <img src="../images/dashboard-icons/statistic-shape-4.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Accepted Offers</b></p>{/* need to update count*/}
                                    <div className="statistic-shape">
                                        <h3 className="value">{(offersCount) ? offersCount.accepted : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/offers.svg" alt="offers" />
                                        <img src="../images/dashboard-icons/statistic-shape-5.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div d-none">
                                    <p className="m-b-10"><b>Total Hired</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(contractCount) ? contractCount.hired : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/hired.svg" alt="hired" />
                                        <img src="../images/dashboard-icons/statistic-shape-6.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Active Contract</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(contractCount) ? contractCount.active : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/active-contract.svg" alt="active-contract" />
                                        <img src="../images/dashboard-icons/statistic-shape-7.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div">
                                    <p className="m-b-10"><b>Contract Completed </b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(contractCount) ? contractCount.completed : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/completed-contract.svg" alt="completed-contract" />
                                        <img src="../images/dashboard-icons/statistic-shape-8.svg" alt="Shape" />
                                    </div>
                                </div>

                                <div className="statistic-div d-none">
                                    <p className="m-b-10"><b>Terminated Contract</b></p>
                                    <div className="statistic-shape">
                                        <h3 className="value">{(contractCount) ? contractCount.terminated : 0}</h3>
                                        <img className="statistic-icon" src="../images/dashboard-icons/terminated-contract.svg" alt="terminated-contract" />
                                        <img src="../images/dashboard-icons/statistic-shape-9.svg" alt="Shape" />
                                    </div>
                                </div>
                            </div>
<div className="row d-none">

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(jobsCount) ? jobsCount.total : 0}</h3>
<span className="statics-label">Total Jobs</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/msg-icon.svg" alt="msg-icon.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/msg-icon.svg" alt="msg-icon.svg"/>

</div>

</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(jobsCount) ? jobsCount.active : 0} </h3>
<span className="statics-label">Posted Jobs</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(proposalCount) ? proposalCount.total : 0}</h3>
<span className="statics-label">Total Applications</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/user-icon-2.svg" alt="user-icon-2.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/user-icon-2.svg" alt="user-icon-2.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(proposalCount) ? proposalCount.shortlisted : 0}</h3>
<span className="statics-label">Interviewing</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/user-plus-icon.svg" alt="user-plus-icon.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/user-plus-icon.svg" alt="user-plus-icon.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(offersCount) ? offersCount.total : 0}</h3>
<span className="statics-label">Total Offers</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/user-icon-1.svg" alt="user-icon-1.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(contractCount) ? contractCount.hired : 0}</h3>
<span className="statics-label">Total Hired</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/user-search-icon.svg" alt="user-search-icon.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/user-search-icon.svg" alt="user-search-icon.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(contractCount) ? contractCount.active : 0}</h3>
<span className="statics-label">Active Contract</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/list-icon.svg" alt="list-icon.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/list-icon.svg" alt="list-icon.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(contractCount) ? contractCount.completed : 0}</h3>
<span className="statics-label">Completed Contract</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/list-icon.svg" alt="list-icon.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/list-icon.svg" alt="list-icon.svg"/>

</div>
</div>

<div className="col-lg-3 col-md-6 col-sm-6 col-6">
<div className="card statics-block">

<div className="statics-no">
                                            <h3>{(contractCount) ? contractCount.terminated : 0}</h3>
<span className="statics-label">Terminated Contract</span>
</div>

<div className="statics-img">
<img src="../images/statics-icon/terminated-icon.svg" alt="terminated-icon.svg"/>
</div>

<img className="img-first" src="../images/statics-icon/terminated-icon.svg" alt="terminated-icon.svg"/>

</div>
</div>


</div>
</div> 

</div> 

<div className="section p-t-0">
<div className="container-fluid">
<div className="accordion" id="accordionExample">
<div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn " data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true" onClick={() => {   setOpenItem({ ...openItem, ["job"]: !openItem.job });   }}><h5>JOBS</h5>
<span className="m-icon material-icons indicator show">expand_more</span>
<span className="m-icon material-icons indicator hide">expand_less</span>
</a>

                                    <div id="accordion-job" className="collapse show"  data-bs-parent="#accordionExample">
<div className="accordion-body card panel p-0">
<div className="card-header">

<ul className="nav nav-tabs tab-light">
<li className="nav-item p-relative">
                                                        <a className={`nav-link pointer ${(showJobs == 'jobs') ? 'active' : ' '}`} href="#"     onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowJobs('jobs');

                                                        }}>Draft</a>
</li>
<li className="nav-item p-relative">
                                                        <a className={`nav-link pointer ${(showJobs == 'posted') ? 'active' : ' '}`} href="#"   onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowJobs('posted');

                                                        }}>Posted</a>
</li>
<li className="nav-item p-relative">
                                                        <a className={`nav-link pointer ${(showJobs == 'hired') ? 'active' : ' '}`} href="#"    onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowJobs('hired');

                                                        }}>Hired</a>
</li>
<li className="nav-item p-relative">
                                                        <a className={`nav-link pointer ${(showJobs == 'withdrawn') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setShowJobs('withdrawn');

                                                        }}>Closed</a>
</li>
</ul> 

                                                <button type="button"   className="btn btn-circle" onClick={() => { setLoader({ ...loader, ["job"]: false }); BindJobs() }} ><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

</div> 

<div className="card-body">
<div className="tab-content">

<div className="tab-pane active" >
<div className="d-flex justify-content-end m-b-20">
                                                            {(showJobs === 'jobs' || showJobs === 'posted') && <button className="btn btn-orange" onClick={() => {
                                                                gContext.setJobsDetail({ currentJobId: null }); gContext.setCurrentPage("11");
                                                            }} >+ New Job</button>}
                                                        </div> 

                                                        {loader.job && <>   {jobList.count == 0 && <div className="d-flex flex-column align-item-center justify-content-center">
                                                            <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                                                            <span>No job found</span>
                                                        </div>}

                                                            {jobList && jobList.count > 0 && <> {(showJobs === 'jobs') && <ClientNewJobs jobList={jobList} callBackonPosted={() => { BindJobs(); BindCounts(); }} />}

                                                                {(showJobs === 'posted') &&  <ClientPostedJobs jobList={jobList} callBackonPosted={() => { BindJobs(); BindCounts(); }} /> }


                                                                {(showJobs === 'withdrawn' || showJobs == "archived" || showJobs === 'hired') && <ClientWithdrawJobs jobList={jobList} mode={showJobs} callBackonPosted={() => { BindJobs(); BindCounts(); }} />}
                                                            </>}</>}

                                                        {!loader.job &&
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                        <th className="w-130 movingLoader "> </th>
                                                                        <th className="w-130 movingLoader "> </th>
                                                                        <th className="w-130 movingLoader "> </th>
                                                                        <th className="w-130 movingLoader "> </th>
                                                                        <th className="w-130 movingLoader "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                        <td className="w-130 movingLoader ">                                                                             
                                                                            </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>


                                                                            
                                                                            
                                                                                  
                                                                                     
        
                            </tbody>
                          </table>
                        </div>

                                                             }
</div>

 
                    
</div> 
</div> 

</div>
</div>
</div> 

<div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-proposals" aria-expanded="false" onClick={() => setOpenItem({ ...openItem, ["proposal"]: !openItem.propsal })}><h5>PROPOSALS</h5>
                                        <span className="m-icon material-icons indicator show">expand_more</span>
                                        <span className="m-icon material-icons indicator hide">expand_less</span>
                                    </a>

<div id="accordion-proposals" className="collapse" data-bs-parent="#accordionExample">
<div className="accordion-body card panel p-0">
<div className="card-header">

<div className="tab-light">
    <ul className="nav nav-tabs">
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'open') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowProposals('open');

                                                            }}  >Open</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'shortlisted') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowProposals('shortlisted');
                                                            }}  >ShortListed</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'rejected') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowProposals('rejected');

                                                            }}  >Rejected</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'withdrawn') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowProposals('withdrawn');

                                                            }} >Withdrawn</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showProposals == 'archived') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowProposals('archived');
                                                            }}  >Archived</a>
        </li>
    </ul>
</div> 

                                                <button type="button" data-toggle="tooltip" title="Click here to refresh" className="btn btn-circle" onClick={() => { setLoader({ ...loader, ["proposal"]: false }); BindProposals() }}><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

</div> 

<div className="tab-content">

<div className="tab-pane active" >
    <div className="card-body">
        

        

                                                        {loader.proposal &&  <ClientProposal mode={showProposals} proposalData={proposalDataList} callbackUpdateProposal={() => BindProposals()} /> }


                                                        {!loader.proposal &&
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>







                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        }

                                                    </div> 
</div>
 

</div> 


</div>
</div>
</div> 

<div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-offers" aria-expanded="false" onClick={() => setOpenItem({ ...openItem, ["offer"]: !openItem.offer })}><h5>OFFERS</h5>
<span className="m-icon material-icons indicator show">expand_more</span>
<span className="m-icon material-icons indicator hide">expand_less</span>
</a>

<div id="accordion-offers" className="collapse" data-bs-parent="#accordionExample">
<div className="accordion-body card panel p-0">
<div className="card-header">

<div className="tab-light">
    <ul className="nav nav-tabs">
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showOffers == 'new') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowOffers('new');

                                                            }}>New</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showOffers == 'Accepted') ? 'active' : ' '}`} href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowOffers('Accepted');

                                                                }}>Accepted</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showOffers == 'Rejected') ? 'active' : ' '}`} href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowOffers('Rejected');

                                                                }}>Rejected</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showOffers == 'Expired') ? 'active' : ' '}`} href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowOffers('Expired');

                                                                }}>Expired</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showOffers == 'Withdrawn') ? 'active' : ' '}`} href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowOffers('Withdrawn');

                                                                }}>Withdrawn</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showOffers == 'Archived') ? 'active' : ' '}`} href="#"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowOffers('Archived');

                                                                }}>Archived</a>
        </li>
    </ul>
</div> 

                                                <button type="button" className="btn btn-circle" data-toggle="tooltip" title="Click here to refresh"  onClick={() => { setLoader({ ...loader, ["offer"]: false }); BindOffers() }}><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

</div> 

<div className="tab-content">

<div className="tab-pane active" >
    <div className="card-body">
                                                        {loader.offer &&  <ClientOffers proposalData={proposalDataList} mode={showOffers} offerData={offerDataList} callbackUpdateOffer={() => BindOffers()} />}
                                                        {!loader.offer &&
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>







                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        }

    </div>
</div>

 

</div>


</div>
</div>
</div> 

<div className="accordion-wrapper accordion-dashboard">
                                    <a className="accordion-btn collapsed" data-bs-toggle="collapse" href="#accordion-contracts" aria-expanded="false" onClick={() => setOpenItem({ ...openItem, ["contract"]: !openItem.contract })}><h5>CONTRACTS</h5>
<span className="m-icon material-icons indicator show">expand_more</span>
<span className="m-icon material-icons indicator hide">expand_less</span>
</a>

<div id="accordion-contracts" className="collapse" data-bs-parent="#accordionExample">
<div className="accordion-body card panel p-0">
<div className="card-header">

<div className="tab-light">
    <ul className="nav nav-tabs">
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showContracts == 'new') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowContracts('new');

                                                            }}>
                                                                New</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showContracts == 'Active') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowContracts('Active');

                                                            }}>
                                                                Active</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showContracts == 'Completed') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowContracts('Completed');

                                                            }}>
                                                                Completed</a>
        </li>
        <li className="nav-item p-relative">
                                                            <a className={`nav-link pointer ${(showContracts == 'Terminated') ? 'active' : ' '}`} href="#" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowContracts('Terminated');

                                                            }}>
                                                                Terminated</a>
        </li>
    </ul>
</div> 

                                                <button type="button" className="btn btn-circle" data-toggle="tooltip" title="Click here to refresh"   onClick={() => { setLoader({ ...loader, ["contract"]: false }); BindContracts() }} ><span className="text-theme m-icon material-icons m-r-10">refresh</span></button>

</div> 

<div className="tab-content">

<div className="tab-pane active" >
    <div className="card-body">
                                                        {loader.contract && <ClientNewContract mode={showContracts} contractData={contractDataList} />}
                                                        {!loader.contract &&
                                                            <div className="table-responsive">
                                                                <table className="table table-striped">
                                                                    <thead>
                                                                        <tr >
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                            <th className="w-130 movingLoader "> </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>
                                                                            <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>  <td className="w-130 movingLoader ">
                                                                            </td>
                                                                        </tr>







                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        }

    </div>
</div>

 

</div> 


</div>
</div>
</div> 

</div>
</div>
</div>
   
</div>

            <div className="dashboard-main-container mt-25 mt-lg-31 min-h-100vh d-none">
                <div className="container mb-10">
                    <div className="row mb-7">
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-yellow bg-yellow-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-briefcase"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1 ">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={6} end={(jobsCount) ? jobsCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Jobs
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green-2 bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-server"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={3} end={(jobsCount) ? jobsCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Posted Jobs
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-pink bg-pink-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Applicants
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-orange bg-orange-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-check"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(proposalCount) ? proposalCount.shortlisted : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Interviewing 
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green-3 bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-user-pen"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(offersCount) ? offersCount.total : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Offers
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-egg-blue bg-egg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-address-card"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                                <CountUp duration={4} end={(contractCount) ? contractCount.hired : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Total Hired
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category -->  fa-archive,fa-address-book,fa-map-pin*/}
                        </div>

                       

                        



                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-blue bg-blue-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-contract"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                               <CountUp duration={3} end={(contractCount) ? contractCount.active : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Active Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-green  bg-green-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-file-signature"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                            <CountUp duration={3} end={(contractCount) ? contractCount.completed : 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Completed Contract
                    </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>

                        <div className="col-xxl-3 col-xl-3 col-lg-4 col-md-6 col-sm-6">
                            {/* <!-- Single Category --> */}
                            <a

                                className="media bg-white rounded-4  pl-7 pt-5 pb-5 pr-5 hover-shadow-1 mb-9 shadow-8"
                            >
                                <div className="text-red  bg-red-opacity-1 circle-56 font-size-6 mr-7">
                                    <i className="fas fa-ban"></i>
                                </div>
                                {/* <!-- Category Content --> */}
                                <div className="">
                                    <h5 className="font-size-8 font-weight-semibold text-black-2 line-height-reset font-weight-bold mb-1">
                                        <LazyLoad>
                                            <span className="counter">
                                            <CountUp duration={3} end={(contractCount) ? contractCount.terminated: 0} />
                                            </span>
                                        </LazyLoad>
                                    </h5>
                                    <p className="font-size-4 font-weight-normal text-gray mb-0">
                                        Terminated Contract
                            </p>
                                </div>
                            </a>
                            {/* <!-- End Single Category --> */}
                        </div>


                        
                        
                       
                    </div>




                    <div
                        className="accordion rounded-10 border  pl-0  mt-10"

                    >
                        <div className="border-bottom overflow-hidden ">
                            <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="w-80 btn-reset font-size-6 font-weight-semibold  text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => { setOpenItem({ ...openItem, ["job"]: !openItem.job }); setShowJobs('jobs'); }}
                                    aria-expanded={openItem.job}
                                >
                                    Jobs  
                        </button>
                               
                            </div>
                            <Collapse in={openItem.job}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media  d-xs-flex ">
                                            <div className=" pl-0  mr-0 w-100" >

                                                <a
                                                    className={`btn ${(showJobs == 'jobs') ? 'btn-primary mx-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowJobs('jobs');

                                                    }}
                                                >
                                                    Draft
                </a>
                                                <a
                                                    className={`btn ${(showJobs == 'posted') ? 'btn-primary mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowJobs('posted');

                                                    }}
                                                >
                                                    Posted
                </a>
                                                <a
                                                    className={`btn ${(showJobs == 'hired') ? 'btn-primary mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowJobs('hired');

                                                    }}
                                                >
                                                    Hired
                </a>
                                                <a
                                                    className={`btn ${(showJobs == 'withdrawn') ? 'btn-primary mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowJobs('withdrawn');

                                                    }}
                                                >
                                                    Closed
                </a>
                                               
                                                
                                            </div>
                                            <a
                                                className="   text-uppercase font-size-3 rounded-0 px-5 justify-content-md-end "

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center  pointer mr-0" onClick={() => { setLoader({ ...loader, ["job"]: false }); BindJobs() }} >
                                                    <i className="fa fa-refresh mr-0 bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {(showJobs === 'jobs' || showJobs === 'posted') && <div className="col-lg-12 col-xl-12 text-right  mt-lg-5 pr-10">
                                        <button type="button"
                                            className=" mr-1   btn-green   font-size-3 font-weight-bold border-0 text-white 
                             rounded-5 text-uppercase ml-3 ml-sm-3"

                                            onClick={() => {
                                                gContext.setJobsDetail({ currentJobId: null }); gContext.setCurrentPage("11");
                                            }} >
                                            + New job
                                                        </button>


                                    </div>}
                                     
                                    {loader.job && <>   {jobList.count == 0 && <div className=" col-12   "> <Message message="No job found" /></div>}
                                        {jobList && jobList.count > 0 && <> {(showJobs === 'jobs') && <div className=" col-12   "> <ClientNewJobs jobList={jobList} callBackonPosted={() => { BindJobs(); BindCounts(); }} /></div>}

                                            {(showJobs === 'posted') && <div className="col-12  "><ClientPostedJobs jobList={jobList} callBackonPosted={() => { BindJobs(); BindCounts(); }} /> </div>}

                                            {(showJobs === 'withdrawn' || showJobs == "archived" || showJobs === 'hired') && <div className="col-12  pt-10"><ClientWithdrawJobs jobList={jobList} mode={showJobs} callBackonPosted={() => { BindJobs(); BindCounts(); }} /> </div>}</>}
                                    </>}
                                    {!loader.job && <div className="col-12 px-10 pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}
                                </div>
                            </Collapse>
                        </div>
                        <div className="border-bottom overflow-hidden " ref={propsalRef}>
                            <div className={`mb-0 m-0 border-bottom-0  bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6 font-weight-semibold text-green-2 text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => { setOpenItem({ ...openItem, ["proposal"]: !openItem.proposal }); }}
                                    aria-expanded={openItem.proposal}
                                >
                                    Proposals
                        </button>
                            </div>
                            <Collapse in={openItem.proposal}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media   d-xs-flex ">
                                            <div className="pl-0  mr-0 w-100" >    <a
                                                className={`btn ${(showProposals == 'open') ? 'btn-primary  mx-1'  : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                href="/#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    setShowProposals('open');

                                                }}
                                            >
                                                Open
                </a>
                                                <a
                                                    className={`btn  ${(showProposals === 'shortlisted') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('shortlisted');
                                                    }}
                                                >
                                                    ShortListed
                </a><a
                                                    className={`btn ${(showProposals == 'rejected') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('rejected');

                                                    }}
                                                >
                                                    Rejected
                </a>
                                                <a
                                                    className={`btn ${(showProposals == 'withdrawn') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-5`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('withdrawn');

                                                    }}
                                                >
                                                    Withdrawn
                </a>
                                                
                                                <a
                                                    className={`btn  ${(showProposals == 'archived') ? 'btn-primary  mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-5`}

                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowProposals('archived');
                                                    }}
                                                > Archived</a> 
                                                
                                            </div><a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["proposal"]: false }); BindProposals() }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>

                                        </div>
                                    </div>
                                    {loader.proposal && <div className=" col-12   "> <ClientProposal mode={showProposals} proposalData={proposalDataList} callbackUpdateProposal={() => BindProposals()} /></div>}
                                    {!loader.proposal && <div className="col-12 px-10 pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}
                                </div>
                            </Collapse>
                        </div>
                        <div className="border-bottom overflow-hidden " ref={offerRef}>
                            <div className={`mb-0 border-bottom-0 bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6  text-green-2  font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => setOpenItem({ ...openItem, ["offer"]: !openItem.offer })}
                                    aria-expanded={openItem.offer}
                                >
                                    Offers
                        </button>
                            </div>
                            <Collapse in={openItem.offer}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns  media d-xs-flex ">
                                            <div className="pl-0  mr-0 w-100" >

                                                <a
                                                    className={`btn ${(showOffers == 'new') ? 'btn-primary mx-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('new');

                                                    }}
                                                >
                                                    New
                </a><a
                                                    className={`btn ${(showOffers == 'Accepted') ? 'btn-primary mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Accepted');

                                                    }}
                                                >
                                                    Accepted
                </a>
                                                <a
                                                    className={`btn  ${(showOffers == 'Rejected') ? 'btn-primary mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Rejected');
                                                    }}
                                                >
                                                    Rejected
                </a>
                                                <a
                                                    className={`btn  ${(showOffers == 'Expired') ? 'btn-primary mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Expired');
                                                    }}
                                                >
                                                    Expired
                </a><a
                                                    className={`btn ${(showOffers == 'Withdrawn') ? 'btn-primary mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Withdrawn');

                                                    }}
                                                >
                                                    Withdrawn
                </a>  
                                                <a
                                                    className={`btn ${(showOffers == 'Archived') ? 'btn-primary  mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowOffers('Archived');

                                                    }}
                                                >
                                                    Archived
                </a>   
                                            </div>
                                            <a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["offer"]: false }); BindOffers() }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {loader.offer && <div className=" col-12   "> <ClientOffers proposalData={proposalDataList} mode={showOffers} offerData={offerDataList} callbackUpdateOffer={() => BindOffers()} /></div>}
                                    {!loader.offer && <div className="col-12 px-10 pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}

                                </div>
                            </Collapse>
                        </div>
                        <div className="border-bottom overflow-hidden " ref={contractRef}>
                            <div className={`mb-0 border-bottom-0 bg-athens `} id="heading2-3">
                                <button
                                    data-toggle="tooltip" title="Click here to Expand/Collapse" className="btn-reset font-size-6  text-green-2  font-weight-semibold text-left px-5 pb-6 pt-7 accordion-trigger arrow-icon w-100 border-left-0 border-right-0 focus-reset mt-n2"
                                    type="button"
                                    onClick={() => setOpenItem({ ...openItem, ["contract"]: !openItem.contract })}
                                    aria-expanded={openItem.contract}
                                >
                                    Contracts
                        </button>
                            </div>
                            <Collapse in={openItem.contract}>
                                <div className="row bg-white p-0 m-0 mt-10">
                                    <div className="col-12  bg-athens  p-0 m-0">
                                        <div className="header-btns media  d-xs-flex ">
                                            <div className="pl-0  mr-0 w-100" >

                                                <a
                                                    className={`btn ${(showContracts == 'new') ? 'btn-primary mx-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('new');

                                                    }}
                                                >
                                                    new
                </a><a
                                                    className={`btn ${(showContracts == 'Active') ? 'btn-primary mr-1' : 'btn-white'}     btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('Active');

                                                    }}
                                                >
                                                    Active
                </a>
                                                <a
                                                    className={`btn  ${(showContracts == 'Completed') ? 'btn-primary mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('Completed');
                                                    }}
                                                >
                                                    Completed
                </a>
                                                <a
                                                    className={`btn  ${(showContracts == 'Terminated') ? 'btn-primary mr-1' : 'btn-white'}    btn-medium  text-uppercase font-size-3 rounded-0 px-10`}
                                                    href="/#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setShowContracts('Terminated');
                                                    }}
                                                >
                                                    Terminated
                </a>
                                                

                                            </div>
                                            <a
                                                className="  text-uppercase font-size-3 rounded-0 px-5 mr-0"

                                            > <a data-toggle="tooltip" title="Click here to refresh" className="d-flex align-items-center ml-4 pointer mr-0" onClick={() => { setLoader({ ...loader, ["contract"]: false }); BindContracts() }} >
                                                    <i className="fa fa-refresh bg-green square-40 px-3 rounded  hover-shadow-1 shadow-8 mr-5 font-size-5 text-white font-weight-bold shadow-8"></i></a>
                                            </a>
                                        </div>
                                    </div>
                                    {loader.contract && <div className=" col-12   "> <ClientNewContract mode={showContracts} contractData={contractDataList} /></div>}
                                    {!loader.contract && <div className="col-12 px-10 pt-10 pb-10 m-0">
                                        <table className="table table-striped border border-color-2 p-0 m-0">
                                            <thead>
                                                <tr className=" py-7 border border-color-2 movingLoader">
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle pl-8 min-width-px-235 py-7  border-0 font-size-4 font-weight-bold "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className=" table-y-middle border-0 pl-8 font-size-4 py-7 min-width-px-235 font-weight-bold  "
                                                    >

                                                    </th>


                                                    < th
                                                        scope="col"
                                                        className="table-y-middle border-0 font-size-4 py-7 font-weight-bold text-center"
                                                    >

                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                </tr>
                                                <tr className="border border-color-2" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>   <tr className="border border-color-2 movingLoader" >
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>
                                                    <td scope="row" className="pl-6 border-0 py-7  min-width-px-235 pr-0 " >

                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table></div>}
                                  
                                </div>
                            </Collapse>
                        </div>
                    </div>

                </div>
                </div></>}
            {!loaded && 
                <>
                    <span className="screen-darken"></span>

                    <div className="page-wrapper">

                    <div className="section statics-block-wrapper">

                        <div className="container-fluid">
                            <div className="row">

                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>

                                        
                                       

                                    </div>

                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div><div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                    <div className="card statics-block">

                                        <div className="statics-no  ">
                                            <h3 className=" w-200 movingLoader"> &nbsp; </h3>
                                            <span className=" w-200 movingLoader"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
                                        </div>




                                    </div>

                                </div> 
                               


                            </div>
                        </div>

                    </div>

                        <div className="section p-t-0">
                            <div className="container-fluid">
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn movingLoader" data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true"  >&nbsp;
                                            <span className="m-icon material-icons indicator show">expand_more</span>
                                            <span className="m-icon material-icons indicator hide">expand_less</span>
                                        </a>

                                        <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                            <div className="accordion-body card panel p-0">
                                                <div className="card-header">

                                                    <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li> <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li> <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li>
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                            <a className={`nav-link pointer `} >&nbsp;</a>
                                                        </li>
                                                    </ul>

                                                    

                                                </div>

                                                <div className="card-body">
                                                    <div className="tab-content">

                                                        <div className="tab-pane active" >
                                                             
                                                       
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped">
                                                                        <thead>
                                                                            <tr >
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                                <th className="w-130 movingLoader "> </th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>
                                                                                <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>  <td className="w-130 movingLoader ">
                                                                                </td>
                                                                            </tr>







                                                                        </tbody>
                                                                    </table>
                                                                </div>

                                                            
                                                        </div>



                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                <div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn movingLoader" data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true"  >&nbsp;
                                        <span className="m-icon material-icons indicator show">expand_more</span>
                                        <span className="m-icon material-icons indicator hide">expand_less</span>
                                    </a>

                                    <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body card panel p-0">
                                            <div className="card-header">

                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li> <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li> <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li>
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li>
                                                </ul>



                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active" >


                                                        <div className="table-responsive">
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr >
                                                                        <th className="w-130   "> </th>
                                                                        <th className="w-130    "> </th>
                                                                        <th className="w-130   "> </th>
                                                                        <th className="w-130    "> </th>
                                                                        <th className="w-130   "> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130   ">
                                                                        </td>
                                                                        <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130   ">
                                                                        </td>
                                                                        <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>
                                                                    </tr>







                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div><div className="accordion-wrapper accordion-dashboard">

                                    <a className="accordion-btn movingLoader" data-bs-toggle="collapse" href="#accordion-job" aria-expanded="true"  >&nbsp;
                                        <span className="m-icon material-icons indicator show">expand_more</span>
                                        <span className="m-icon material-icons indicator hide">expand_less</span>
                                    </a>

                                    <div id="accordion-job" className="collapse show" data-bs-parent="#accordionExample">
                                        <div className="accordion-body card panel p-0">
                                            <div className="card-header">

                                                <ul className="nav nav-tabs tab-light">
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li> <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li> <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li>
                                                    <li className="nav-item p-relative movingLoader w-100">
                                                        <a className={`nav-link pointer `} >&nbsp;</a>
                                                    </li>
                                                </ul>


                                            </div>

                                            <div className="card-body">
                                                <div className="tab-content">

                                                    <div className="tab-pane active" >


                                                        <div className="table-responsive">
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr >
                                                                        <th className="w-130   "> </th>
                                                                        <th className="w-130    "> </th>
                                                                        <th className="w-130   "> </th>
                                                                        <th className="w-130    "> </th>
                                                                        <th className="w-130   "> </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130   ">
                                                                        </td>
                                                                        <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>
                                                                        <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>  <td className="w-130 movingLoader ">
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td className="w-130   ">
                                                                        </td>
                                                                        <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>  <td className="w-130   ">
                                                                        </td>
                                                                    </tr>







                                                                </tbody>
                                                            </table>
                                                        </div>


                                                    </div>



                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                     




                                     

                                </div>
                            </div>
                        </div>

                    </div>

                     </> }
        </>
    );
};