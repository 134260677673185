import React, { useContext, useState, useEffect } from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import GlobalContext from "../../context/GlobalContext";
import StarRating from "../../components/Core/Rating";
import { GetExpertPreviewLoad } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays";
import DOMPurify from 'isomorphic-dompurify';
import Icon from '@mui/material/Icon';
const ModalStyled = styled(Modal)`
   &.modal {
    z-index: 10050; 
  }
`;

const FeedbackDetails = (props) => {

    const gContext = useContext(GlobalContext);
    const [reasonId, setReasonId] = useState(null);
    const [error, setError] = useState("");
    //const [showModal, setShowModal] = useState(false);
    const [reportedIssues, setReportedIssues] = useState([]);
    const imgL = "../assets/image/svg/icon-loaction-pin-black.svg";

    //const gContext = useContext(GlobalContext);
    const subParam = props.sub;
    // const [profileInfo, setProfileInfo] = React.useState({ "id": null, "sub": "", "firstname": "", "lastname": "", "email": "", "phone": "", "address1": "", "address2": "", "city": "", "state": "", "countryid": null, "countryname": "", "avatar": "", "photo": "", "title": "", "profile": "", "dailyrate": "", "otherlangs": "", "idverified": false, "timezoneid": null, "timezonecode": "", "timezonename": "", "timezonevalue": "", "online": null, "explevelid": null, "explevelname": "", "profilecomplete": false, "approved": false, "active": false, "flagged": false, "flaggedreasonid": null, "flaggedreason": null, "avgrating": null, "weeklyhoursid": null, "weeklyhours": "", "resume": "null", "skillids": "", "complianceids": "", "skills": [], "compliances": []});
    const [profileInfo, setProfileInfo] = React.useState({});
    const [rating, setRating] = React.useState(null);
    const [show, setShow] = React.useState(false);
    const [message, setMessage] = React.useState(false);
    const [feedbackLength, setFeedbackLength] = React.useState(false);
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const [feedback, setFeedbackData] = useState(null);
    const [showModal, setShowModal] = useState(false);

    var imgP = "";
    var localtime;









    async function ExpertPreviewInfo() {
        // try {
        // setMessage(false);
        const result = await GetExpertPreviewLoad();
        //alert(result.data.feedback);
        

        if (result && result.data && result.data.expertAllLoad) {
             
            let list = [];
            //response.data.reportedIssues.filter(item => item["userid"] == 1).map((value) => (
            //  list.push({ value: value.id, label: value.reason })));

            // response.data.issueReportList.map((value) => (
            //    list.push({ userid: value.userid, avatar: value.avatar,fname:value.firstname,lname:value.lastname,date:value.datecreated,title:value.title,cat:value.issuecategory })));

            // console.log(list);
            setReportedIssues(result.data.expertAllLoad);
            
        }
        /*if (result.data.expertAllLoad.responseData.code == "200") {
            //[{"id":66,"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","firstname":"Karthik","lastname":"K","email":"sathya@springinfoservices.com","phone":"","address1":"address","address2":"","city":"LA","state":"CA","countryid":61,"countryname":"India","avatar":"","photo":"","title":"SE","profile":"Apple Inc. is an American multinational technology company specializing in consumer electronics, software and online services headquartered in Cupertino, California, United States. Apple is the largest technology company by revenue (totaling US$365.8 billion in 2021) and, as of June 2022, is the world's biggest company by market capitalization, the fourth-largest personal computer vendor by unit sales and second-largest mobile phone manufacturer. It is one of the Big Five American information technology companies, alongside Alphabet, Amazon, Meta, and Microsoft.","dailyrate":"250.00","otherlangs":"","idverified":false,"timezoneid":586,"timezonecode":"IST","timezonename":"India Standard Time","timezonevalue":"UTC+05:30","online":null,"explevelid":1,"explevelname":"Entry level (0-3 years)","profilecomplete":false,"approved":false,"active":false,"flagged":false,"flaggedreasonid":null,"flaggedreason":null,"avgrating":null,"weeklyhoursid":2,"weeklyhours":"10 hrs/week","resume":"null","skillids":"13,12,9","complianceids":"42,50,44","skills":[{"skill":"Cybersecurity"},{"skill":"Compliance Audit"},{"skill":"Certification audit"}],"compliances":[{"code":"AS9100"},{"code":"CMMI"},{"code":"CE"}]}]

            let profile = JSON.parse(result.data.expertAllLoad.responseData.message);
            console.log('profileview', profile);
            if (profile && profile.length > 0) {
                setProfileInfo(profile[0]);
                imgP = (profile[0].avatar != "") ? process.env.REACT_APP_AWS_S3_URL + profile[0].avatar : "../ assets/image/avatar.png";
                console.log( 'profileinfo',profileInfo );
                // profile[0].avgrating  = 0;
                if (profile[0].avgrating == null || profile[0].avgrating == 0) {
                    setRating(null);

                }
                else if (profile[0].avgrating > 0) {
                    setRating(profile[0].avgrating);
                }

                gContext.setLoaderModalVisible(false);
            }
            else {
                if (subParam)
                    window.location.href = "/error";
                else
                    setMessage(true);
            }
        }
        else {
            setMessage(true);
        }

    }
    catch (e) {
        setMessage(true);
        console.log(e);
    }*/

    }

    function toggleModal() {

        props.togglemodal();
        
    }



    useEffect(() => {
        ExpertPreviewInfo();
    }, []);

    function roundOne(n) {
        //var result = ; 

        if ((n - Math.floor(n)) != 0) {
            var dec_value = parseFloat(n).toFixed(1);
            return dec_value;

        }
        else {
            return n;
        }

    }


    return (
        <ModalStyled
            {...props}
            size="lg"
            centered
            show={props.modalvisible}
            onHide={toggleModal}
        >
           {props.feedback != null && <>




<div className="modal-content">


    <div className="modal-header">
        <h4 className="modal-title">Feedback Details</h4>
        <button type="button" className="btn-close" onClick={() => {
           
           toggleModal();

       }}></button>
       

    </div>

    
            <div className="modal-body">





                 <div className="company-post">
                        <div className="medie-info-img">
                            {(props.feedback.exptavatar) && <img src={s3URL + props.feedback.exptavatar} alt="" />}
                        </div>

                            <div className="d-flex flex-column">
                                <a className="expert-name m-0 m-b-5 pointer f-16" href={"/job/" + props.feedback.jobguid} target="_blank" > <b>{(props.feedback.jobtitle) ? props.feedback.jobtitle : ''}</b></a>
                            <a className="m-b-10" href={"../client/" + props.feedback.exptsub}>
                                <b>  {props.feedback.exptfirstname} {props.feedback.exptlastname} </b></a>
                            <span> 
                                
                            <div className="info-meta flag m-r-20">
                                    <Icon className="text-theme m-icon material-icons m-r-5">timer</Icon>
                                        
                                    <span className="font-size-3 text-gray font-weight-semibold">{getMonthDateYear(props.feedback.datecreated)}</span>
                                </div>
                                </span>

                                <div className="info-meta ">
                                <div className="info-meta f-12">
                                            {(props.feedback.rating == null) ? '' :
                                                <>
                                                    <StarRating rating={(props.feedback.rating) ? props.feedback.rating : ''} />
                                                    <strong className="m-t-5 m-l-5" >{"  "}  {(props.feedback.rating) ? roundOne(props.feedback.rating) + "/5" : ''}</strong>
                                                      
                                                    </>}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                    

                    <div className="divider-line"></div>


                <div className="d-flex justify-content-between m-b-20">
                    <h6> Feedback</h6>
                </div>

                <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.feedback.feedback) }}></p>



                
                <div className="divider-line"></div>

               

           
               
               


                
               


            </div>


            
           
       

    
</div>

</>}
        </ModalStyled >
    );
};

export default FeedbackDetails;
