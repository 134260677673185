
import { loadAllMilestoneStatus, siteObjectCounts, homeFeaturedList, getIssueCategory, getFlagReasons, getComplianceCategory, getCountry, getComplianceTypes, getIndustryTypes, getSkills, getExpertDegrees, getProjectLength, getExpLevels, getSocialProviders, getLanguages, getLanguagesProficiencyLevel, getWeeklyHrs, getRateTypes, getJobUtils} from './../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify';


export async function GetCountry() {

    let response;
    await API.graphql(graphqlOperation(getCountry)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetComplianceTypes() {

    let response;
    await API.graphql(graphqlOperation(getComplianceTypes)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetIndustryTypes() {

    let response;
    await API.graphql(graphqlOperation(getIndustryTypes)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetSkills() {

    let response;
    await API.graphql(graphqlOperation(getSkills)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetExpertDegrees() {

    let response;
    await API.graphql(graphqlOperation(getExpertDegrees)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetExpLevels() {

    let response;
    await API.graphql(graphqlOperation(getExpLevels)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetSocialProviders() {
    let response;
    await API.graphql(graphqlOperation(getSocialProviders)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetLanguages() {

    let response;
    await API.graphql(graphqlOperation(getLanguages)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetLanguagesProficiencyLevel() {
    let response;
    await API.graphql(graphqlOperation(getLanguagesProficiencyLevel)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetWeeklyHrs() {
    let response;
    await API.graphql(graphqlOperation(getWeeklyHrs)).then((result) => {
        response = result;
    }).catch((error) => { response = error; });
    return response;
}
export async function GetProjectLength() {
    let response;
    await API.graphql(graphqlOperation(getProjectLength)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;

}
export async function GetComplianceCategory() {
    let response;
    await API.graphql(graphqlOperation(getComplianceCategory)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;

}
export async function GetRateTypes() {
    let response;
    await API.graphql(graphqlOperation(getRateTypes)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;

}
export async function GetFlagReasons() {
    let response;
    await API.graphql(graphqlOperation(getFlagReasons)).then((result) => {
        console.log("flag",result);
        response = result;
    }).catch((error) => {
        console.log("flagerror", error);
        response = error;
    });
    return response; 
}

export async function GetIssueCategory() {
    let response;
    await API.graphql(graphqlOperation(getIssueCategory)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;
}

export async function HomeFeaturedList() {
    let response;
    await API.graphql(graphqlOperation(homeFeaturedList)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;  
}


export async function SiteObjectCounts() {
    let response;
    await API.graphql(graphqlOperation(siteObjectCounts)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;  
}
export async function GetJobUtils() {
    let response;
    await API.graphql(graphqlOperation(getJobUtils)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;
}
export async function LoadAllMilestoneStatus() {
    let response;
    await API.graphql(graphqlOperation(loadAllMilestoneStatus)).then((result) => {
        response = result;
    }).catch((error) => {
        response = error;
    });
    return response;
}
