import React, { useContext, useState, useEffect } from "react";
import GlobalContext from "../../context/GlobalContext";
import { GetClientJobs, DeleteClientJob, ClientJobsStatusUpdate } from "../../utils/apiCalls";
import { getMonthDateYear } from "../../utils/addDays";
import ConfirmMessages from "../../utils/confirmMessages";
import Message from "../../components/Message";
import ReactCountryFlag from "react-country-flag";
import TablePagination from "../../components/Table/TablePagination";
import tableHelper from "../../components/Table/tableHelper";

 


export default function PostedJobs(props) {

    const gContext = useContext(GlobalContext);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [rowsJobs, setRowsJobs] = useState([]);
    const [currentValue, setCurrentValue] = useState({ index: null, id: null });
    const [message, setMessage] = React.useState(false);
    const [loaded, setLoaded] = useState(false);
    const [page, setPage] = useState(1);
    const { slice, range } = tableHelper(rowsJobs, page, gContext.paginationSize);
    function formatdate(date) {
        var d1 = new Date(date);
        var newdate = (d1.getMonth() + 1) + '/' + d1.getDate() + '/' + d1.getFullYear();

        return newdate
    }

    function LoadEditPage(guid, id) {
        //console.log("edit", id);
        gContext.setJobsDetail({ currentJobId: { guid: guid, id: id }, mode: 'new' });
        gContext.setCurrentPage("11");
    }
    const withDrawJob = (index, id) => {
        setMessage(false);
        if (id) {
            gContext.setconfirmModal({ header: "Job withdraw confirmation", ["text"]: "Do you want to withdraw this job?", ["confirmed"]: false, index: index, id: id, showButton: true, mode: 'post',page:"postedjob" });
            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }


    const closeJob = (index, id) => {
        setMessage(false);
        if (id) {
            gContext.setconfirmModal({ header: "Job close confirmation", ["text"]: "Do you want to close this job?", ["confirmed"]: false, index: index, id: id, showButton: true, mode: 'close',page:"postedjob" });
            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }

    const hireJob = (index, id) => {
        setMessage(false);
        if (id) {
            gContext.setconfirmModal({ header: "Job hire confirmation", ["text"]: "Are you done hiring for this job?", ["confirmed"]: false, index: index, id: id, showButton: true, mode: 'hire', page: "postedjob" });
            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }

    const jobHire = async (index, id) => {

        if (id) {
            setMessage(false);
            try { 

                const result = await ClientJobsStatusUpdate(id, gContext.userInfo.userID,300, gContext.userInfo.email);
                if (result.data.jobStatusUpdate.responseData.code == "200") { 
                    BindJobs();
                    props.callBackonPosted();
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            setMessage(false);
        }
    }



    const jobClose = async (index, id) => {

        if (id) {
            setMessage(false);
            try {
                const result = await ClientJobsStatusUpdate(id, gContext.userInfo.userID,400, gContext.userInfo.email);
                if (result.data.jobStatusUpdate.responseData.code == "200") {
                    //console.log('close',result.data.jobStatusUpdate.responseData);
                    BindJobs();
                    props.callBackonPosted();
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            setMessage(false);
        }
    }


    const jobWithDraw = async (index, id) => {

        if (id) {
            setMessage(false);
            try {
                const result = await ClientJobsStatusUpdate(id, gContext.userInfo.userID, 250);
                if (result.data.jobStatusUpdate.responseData.code == "200") {
                    BindJobs();
                    props.callBackonPosted();
                }
            }
            catch (e) {
                console.log(e);
            }
        }
        else {
            setMessage(false);
        }
    }
    useEffect(() => {
        if (gContext.confirmModal.confirmed && gContext.confirmModal.page=="postedjob") {
            if (gContext.confirmModal.mode == "post") {
                jobWithDraw(gContext.confirmModal.index, gContext.confirmModal.id);
                //jobClose(gContext.confirmModal.index, gContext.confirmModal.id);
               // jobHire(gContext.confirmModal.index, gContext.confirmModal.id);
            }
            if (gContext.confirmModal.mode == "close") {
                //jobWithDraw(gContext.confirmModal.index, gContext.confirmModal.id);
                jobClose(gContext.confirmModal.index, gContext.confirmModal.id);
                //jobHire(gContext.confirmModal.index, gContext.confirmModal.id);
            }
            if (gContext.confirmModal.mode == "hire") {
                //jobWithDraw(gContext.confirmModal.index, gContext.confirmModal.id);
                //jobClose(gContext.confirmModal.index, gContext.confirmModal.id);
                jobHire(gContext.confirmModal.index, gContext.confirmModal.id);
            }
        }
        else { setCurrentValue({ index: null, id: null ,page:""}); }
    }, [gContext.confirmModal]);

    const deleteData = async (index, id) => {

        if (id) {
            setMessage(false);

            try {

                const result = await DeleteClientJob(id, gContext.userInfo.userID);
                // console.log(result);
                if (result.data.jobDelete.responseData.code == "200") {
                    BindJobs();
                }

            }
            catch (e) {

            }
        }
        else {
            setMessage(false);

        }
    }



    /*For edu table row delete*/
    const deleteTableRows = (index, id) => {
        setMessage(false);
        if (id) {

            gContext.setconfirmModal({ header: "Job delete confirmation", ["text"]: "Do you want to delete this information?", ["confirmed"]: false, index: index, id: id, showButton: true, page: "postedjob" });

            gContext.toggleConfirmModal();
        }
        else {
            setMessage(false);
        }
    }
    async function BindJobs() {
        try {
            if (props.jobList) {
                let filterData = props.jobList.posted;

                setRowsJobs(filterData);
                setLoaded(true);
            }

        }
        catch (e) {

            console.log(e);
        }
    }
    useEffect(() => {
        if (gContext.confirmModal.confirmed && gContext.confirmModal.page == "postedjob") {

            if (!gContext.confirmModal.mode) { deleteData(gContext.confirmModal.index, gContext.confirmModal.id); }
            else {
                if (gContext.confirmModal.mode == "post") {

                    jobWithDraw(gContext.confirmModal.index, gContext.confirmModal.id);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null,["page"]:""
                    });
                   /* jobClose(gContext.confirmModal.index, gContext.confirmModal.id);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
                    });
                    jobHire(gContext.confirmModal.index, gContext.confirmModal.id);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null
                    });*/
                }

                if (gContext.confirmModal.mode == "close") {
                    jobClose(gContext.confirmModal.index, gContext.confirmModal.id);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["page"]: ""
                    });
                }

                if (gContext.confirmModal.mode == "hire") {
                    jobHire(gContext.confirmModal.index, gContext.confirmModal.id);
                    gContext.setconfirmModal({
                        ...gContext.confirmModal, ["confirmed"]: false, ["id"]: null, ["page"]: ""
                    });
                }
            }

        }
        else { setCurrentValue({ index: null, id: null, page: "" }); }
    }, [gContext.confirmModal]);

    useEffect(() => {
        BindJobs();

    }, [props.jobList]);

    useEffect(() => {

        document.title = "Posted Jobs | Syncuppro";
    }, []);
    return (
        <>
           



                            {message && <div className="col-lg-12 col-xl-12 text-left ml-6">
                                <Message message="Job deleted successfully" />
                            </div>}
                        
                        {(loaded && rowsJobs && rowsJobs.length == 0) && <div className="d-flex flex-column align-item-center justify-content-center">
                            <span className="text-light-gray m-icon material-icons m-b-10">work</span>
                            <span>No job found</span>
                        </div>}
            {(loaded && slice && slice.length > 0) &&
              
                <>

                    <div className="table-responsive">
                        <table className="table table-striped">
                            <thead>
                                <tr>
                                <th className="w-200 min-lg-cell">Job Title</th>
                                <th className="  min-lg-cell">Location</th>
                                <th 
                                    className="  min-lg-cell"
                                >
                                    Proposal #
                                                </th>
                                <th className="  min-lg-cell">Weekly Hours</th>
                                <th className="  min-lg-cell">Project Length</th>
                                <th className="  min-lg-cell">Posted on</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            {slice && slice.map((data, index) => {
                                const { id, title, city, state, projlength, countrycode, countryname, proposalcount, weeklyhrs, explevel, guid, dateposted } = data;


                                    return (
                                        <tr key={index}>
                                            
                                            <td>
                                                <span className="d-flex justify-content-left align-items-left"  onClick={() => { window.open("job/" + guid, "_blank"); }}>
                                                    <span className="d-flex title   pointer" data-toggle="tooltip" title={title}
                                                    ><span className="one-line">{title}</span> <a className="m-l-5 text-theme m-icon material-icons pointer">open_in_new</a></span>
                                                    
                                                </span>
                                            </td>
                                            
                                            <td className="d-none">
                                                <div className="d-flex justify-content-left align-items-left" >
                                                    <div className="info-meta company pointer" data-toggle="tooltip" title={title} ><span className="table-title one-line pointer"
                                                     style={{ wordWrap: "break-word" }} onClick={() => { window.open("job/" + guid, "_blank"); }}>{title}</span>
                                                     <a   className="m-l-5 text-theme m-icon material-icons">open_in_new</a></div>
                                                </div>
                                            </td>
                                            <td style={{width : '190px'}}>
                                                <div className="info-meta flag">
                                                    <ReactCountryFlag
                                                        countryCode={countrycode}
                                                        svg
                                                        style={{
                                                            width: '1.5em',
                                                            height: '.7em',
                                                        }}
                                                        title={countrycode}
                                                    />
                                                    <span className="one-line text-black m-l-5 "> 
                                                    <span className={`    ${(city != null && city.length > 2) ? 'text-capi' : 'text-upper'}`}>{city?city.toLowerCase():'' }{' '}</span>
                                                    <span className={`    ${(state != null && state.length > 2) ? 'text-capi' : 'text-upper'}`}>{state?state.toLowerCase():'' }</span>{' ' + countrycode}</span>
                                                </div>
                                            </td>
                                            <td>{proposalcount}</td>
                                            <td>
                                                {weeklyhrs}
                                            </td>
                                            <td>
                                                {projlength}
                                            </td>
                                            <td>
                                                {(dateposted) ? getMonthDateYear(dateposted) : ''}</td>

                                            <td>
                                                <div className="btn-table text-center">
                                                    <button className="btn btn-sm btn-gray" style={{ width: '115px' }} onClick={() => {hireJob(index, id);}}>Mark as Hired</button>
                                                    <button className="btn btn-sm btn-gray" onClick={() => { withDrawJob(index, id); } }>Withdraw</button>
                                                    <button className="btn btn-sm btn-gray" onClick={() => { closeJob(index, id); }}>Close</button>
                                                </div>
                                            </td>
                                        </tr>)
                                })}</tbody>
                        </table>
                    </div>
                    
                {(loaded && rowsJobs && rowsJobs.length > 5) && <TablePagination range={range} slice={slice} setPage={setPage} page={page} />}


                </>
                             }
                     

        </>
    );
};