import React, { useContext } from "react";

 
import { useParams } from "react-router-dom";
 
import GlobalContext from "../../context/GlobalContext";

const imgF1 = "../assets/image/l2/png/featured-job-logo-1.png";
const iconD = "../assets/image/svg/icon-dolor.svg";
const iconB = "../assets/image/svg/icon-briefcase.svg";
const iconL = "../assets/image/svg/icon-location.svg";

export default function JobClientView(props) {
    
    const gContext = useContext(GlobalContext);
 
    const mode = gContext.jobsDetail.mode;

    function LoadapplicantPage(id) {
        gContext.setJobsDetail({ currentJobId: id, mode: 'new' });
        gContext.setCurrentPage("3");
    }
    return (
        <>
            <div
                className="dashboard-main-container mt-10 mt-lg-31 min-h-100vh"
                id="dashboard-body"
            >
              
                    <div className="container">
                        <div className="row justify-content-center">
                            {/* <!-- back Button --> */}
                           
                            {/* <!-- back Button End --> */}
                            <div className="col-xl-9 col-lg-11 mb-8 px-xxl-15 px-xl-0">
                                <div className="bg-white rounded-4 border border-mercury shadow-9">
                                    {/* <!-- Single Featured Job --> */}
                                    <div className="pt-9 pl-sm-9 pl-5 pr-sm-9 pr-5 pb-8 border-bottom border-width-1 border-default-color light-mode-texts">
                                        <div className="row">
                                            <div className="col-md-6">
                                                {/* <!-- media start --> */}
                                                <div className="media align-items-center">
                                                    {/* <!-- media logo start --> */}
                                                    
                                                    {/* <!-- media logo end --> */}
                                                    {/* <!-- media texts start --> */}
                                                    <div>
                                                        <h3 className="font-size-7 mb-0">
                                                            Product Designer
                            </h3>
                                                    
                                                    </div>
                                                    {/* <!-- media texts end --> */}
                                                </div>
                                                {/* <!-- media end --> */}
                                            </div>
                                            <div className="col-md-6 text-right pt-7 pt-md-0 mt-md-n1">
                                                {/* <!-- media date start --> */}
                                                <div className="media justify-content-md-end">
                                                    <p className="font-size-4 text-gray mb-0">
                                                        20 December 2022
                          </p>
                                                </div>
                                                {/* <!-- media date end --> */}
                                            </div>
                                        </div>
                                        <div className="row pt-9">
                                            <div className="col-12">
                                                {/* <!-- card-btn-group start --> */}
                                                <div className="card-btn-group">
                                                {(mode && mode === 'new') &&
                                                    <a className="btn btn-green text-uppercase btn-medium rounded-3 w-180 mr-4 mb-5 pointer">
                                                        Post this job
                            </a>}
                                                {(mode && mode === 'posted') &&
                                                    <a className="btn btn-primary   text-uppercase h-px-48 rounded-3 mb-5 px-5 pointer" onClick={() => LoadapplicantPage()} >
                                                        <i className="fa fa-user ght-bold mr-4   font-size-4"></i>{" "}
                              View applicants
                            </a>}
                                                      
                                                    
                                                </div>
                                                {/* <!-- card-btn-group end --> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- End Single Featured Job --> */}
                                    <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts  border-bottom border-width-1 border-default-color">
                                        <div className="row">
                                            <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">
                                                <div className="">
                                                    <p className="mb-4 font-size-4 text-gray font-weight-semibold">
                                                        Job Description
                          </p>
                                                    <p className="font-size-4 text-black-2 mb-7">
                                                        Gubagoo is a fast growing provider of messaging and
                                                        commerce solutions for automotive dealers changing
                                                        the future of how people find, buy and service their
                            vehicles.{" "}
                                                    </p>
                                                    <p className="font-size-4 text-black-2 mb-7">
                                                        We re looking for a passionate individual to design
                                                        beautiful and functional products for our customers
                                                        at Gubagoo. We move very fast and you will be
                                                        expected to contribute to a cross-functional product
                                                        development squad, that includes product managers
                                                        and developers, to deliver the UX and UI for the
                            team to bring to life.{" "}
                                                    </p>
                                                </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                    <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10    light-mode-texts">
                                        <div className="row mb-7">
                                            <div className="col-md-4 mb-md-0 mb-6">
                                                <div className="media justify-content-md-start">
                                                    <div className="image mr-5">
                                                        <img src={iconD} alt="" />
                                                    </div>
                                                    <p className="font-weight-semibold font-size-4 text-black-2 mb-0">
                                                       Daily Rate (USD)
                          </p>
                                                </div>
                                                <h6 className="font-size-4 text-black-2 font-weight-semibold  "> 450  </h6>
                                            </div>
                                            <div className="col-md-4 pr-lg-0 pl-lg-10 mb-md-0 mb-6">
                                                <div className="media justify-content-md-start">
                                                    <div className="image mr-5">
                                                        <img src={iconB} alt="" />
                                                    </div>
                                                    <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                        <span className="font-size-4 d-block mb-4 text-gray">
                                                            Experience
                          </span>   
                          </p>
                                                </div>
                                                <h6 className="font-size-4 text-black-2 font-weight-semibold  "> Expert ({'>'} 5 years) </h6>
                                            </div>
                                            <div className="col-md-4 pr-lg-0 pl-lg-0 mb-md-0 mb-6">
                                                <div className="media justify-content-md-start">
                                                    <div className="image mr-5">
                                                        <img src={iconL} alt="" />
                                                    </div>
                                                    <p className="font-weight-normal font-size-4 text-black-2 mb-0">
                                                        <span className="font-size-4 d-block mb-4 text-gray">
                                                            Location
                          </span>
                                                    </p>
                                                </div>
                                                <h6 className="font-size-4 text-black-2 font-weight-semibold  "> LA,CA,USA</h6>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4 mb-lg-0 mb-10">
                                                <div className="">
                                                    <span className="font-size-4 d-block mb-4 text-gray">
                                                       Job Title
                          </span>
                                                    <h6 className="font-size-4 text-black-2 font-weight-semibold mb-9">
                                                        Project Manangement
                          </h6>
                                                </div>
                                                 
                                            </div>
                                            <div className="col-md-4 pr-lg-0 pl-lg-10 mb-lg-0 mb-8">
                                                <div className="">
                                                    <span className="font-size-4 d-block mb-4 text-gray">
                                                        Project Length
                          </span>
                                                    <h6 className="font-size-4 text-black-2 font-weight-semibold mb-9">
                                                        2 Months
                          </h6>
                                                </div>
                                                
                                            </div>
                                            <div className="col-md-4 pl-lg-0">
                                                <div className="">
                                                    <span className="font-size-4 d-block mb-4 text-gray">
                                                       Weekly Hours
                          </span>
                                                    <h6 className="font-size-4 text-black-2 font-weight-semibold mb-0">
                                                        15-30 Hours
                          </h6>
                                                </div>
                                            </div>

                                          
                                        </div>
                                        <div className="row  ">
                                   
                                            <div className="col-md-12 pl-lg-5">
                                                <div className="tags">
                                                    <p className="font-size-4 text-gray mb-3 font-weight-semibold">
                                                        Skills
                          </p>
                                                    <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            Editing
                            </li>
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            Wire-framing
                            </li>
                                                        <li className="bg-green-opacity-15 mr-md-0 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            XD
                            </li>
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            User Personal
                            </li>
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            Sketch
                            </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="col-md-12 pl-lg-5">
                                                <div className="tags">
                                                    <p className="font-size-4 text-gray font-weight-semibold mb-3">
                                                        Compliances
                          </p>
                                                    <ul className="d-flex list-unstyled flex-wrap pr-sm-25 pr-md-0">
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            Editing
                            </li>
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            Wire-framing
                            </li>
                                                        <li className="bg-green-opacity-15 mr-md-0 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            XD
                            </li>
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            User Persona
                            </li>
                                                        <li className="bg-green-opacity-15 mr-3 h-px-33 text-center flex-all-center rounded-3 px-5 font-size-3 text-black-2 mt-2">
                                                            Sketch
                            </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                </div> {(mode && mode === 'new') &&
                                    <div className="job-details-content pt-8 pl-sm-9 pl-6 pr-sm-9 pr-6 pb-10 light-mode-texts">
                                        <div className="row">
                                            <div className="col-xl-11 col-md-12 pr-xxl-9 pr-xl-10 pr-lg-20">

                                                <div className="">

                                                    <a className="btn btn-green text-uppercase btn-medium w-180 h-px-48 rounded-3 mr-4 mt-6">
                                                        Post this job
                            </a>

                                                </div>
                                            </div>
                                        </div>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
             
</div>
            
        </>
    );
};
