const ConfirmMessages = {
    SignupStep1Confirmation: "Congratulation! A confirmation code has been sent to your [$email] mailbox. Please check your email and enter the verification code to activate your account.",
    SignupStep2Confirmation: "Registration Successful! Please click below button to Login with your SyncupPro account.",
    ResendSignupConfirmation: "Your account is already registered with us. Please click the button below to verify your email address. ",
    ForgotPwdResetCodeConfirmation: "We've sent an email to [$email]. Check your email and enter One Time Password to reset your password. If you don't see the email, check other places it might be, like spam, social, or other folders.",
    ForgotPwdConfirmation: "Your password has been updated successfully. Please click below button to Login with your SyncupPro account.",
    ChangePwdConfirmation: "Your password has been changed successfully. Please click below button to Login.",
    ProfileConfirmation: "Your profile information updated successfully.",
    AssmtCompleted: "You have completed assessment.",
    AssmtSuccess: "Congratulations [USERNAME]!<br/><p>You've successfully completed the assessment on Syncuppro. Your dedication and effort are appreciated as you've taken a significant step forward in showcasing your skills and expertise.</p>", 
    AssmtnotCompleted: "Oh No! It seems you did not pass the assessment.<br/><p> Please click Try Again button to retake it.",
    AssmtSubmit: "Are you sure you want to submit your answers?",
    ClientWebsite: "* Company Website is verified. Company website cannot be modified",
    ClientCertificate: "* Company Registration Certificates are verified. Certificate information cannot be modified",
    ExpertInitialMessage: "* Update your personal and professional information to complete the profile setup.",
    ExpertProfessionalMessage: "* Update your professional and certificate information to complete the profile setup.",
    ExpertPersonalMessage: "* Update your personal and certificate information to complete the profile setup.",
    ExpertCertificateMessage: "* Update your certificate information to complete the profile setup.",
    ProfilenotCompleted: "* Assessment is not completed.",
    ProfileidnotApproved:"* Photo ID is not approved.",
    Profileidpending: "* Photo Id verification is pending.",
    ProfileidVerified: "* Photo ID is verified. Photo ID information cannot be changed.",
    CertificatePending: "* Certificate verification is pending.",
    CertificatenotApproved: "* Certificate is not approved. ",
    CertificateApproved: " * Certificates are verified.Certificate information cannot be modified.",
    Nocompanyfound: "No Company has registered with this website.",
    existingCompany: "Given website has matched with the registered company's website. Do you want to fill existing company information?",
    contractTerminationAlert: "You have inprogress tasks. The pending milestone will be 50% paid by [DATE]. Do you still want to terminate the contract?",
    contractCompleteAlert: "You have inprogress tasks.You cannot complete contract.",
    JobPostedMessage: "Job posted successfully. Await proposal notifications and invite experts based on their profiles.",
    OfferCreatedMessage: "Offer created successfully. Awaiting expert's response."
}
export default ConfirmMessages; 