import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { Container, Dropdown } from "react-bootstrap";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useWindowSize } from "../../hooks/useWindowSize";
import GlobalContext from "../../context/GlobalContext";
import ExpertSidebarDashboard from "../../sections/ExpertDashboard/Header";
import ClientSidebarDashboard from "../../sections/ClientDashboard/Header";
import { useLocation, useNavigate } from 'react-router-dom';
import Offcanvas from "../Offcanvas/Offcanvas";
import NestedMenu from "../NestedMenu/NestedMenu";
import { GetExpertPreviewLoad, GetClientPreviewProfile, NewMessageCount, NewAlertCount } from "../../utils/apiCalls";
 
import ReactCountryFlag from "react-country-flag";
import Icon from '@mui/material/Icon';
const imgM = "../images/syncuppro-logo.svg";
 
 

const SiteHeader = styled.header`
  

`;

const ToggleButton = styled.button`
  color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.heading}!important;
  border-color: ${({ dark, theme }) =>
        dark ? theme.colors.lightShade : theme.colors.heading}!important;
`;
 
const Header = () => {
    const navigate = useNavigate();
    const gContext = useContext(GlobalContext);
    const pathName = useLocation().pathname.toLowerCase();
    const [showScrolling, setShowScrolling] = useState(false);
    const [showReveal, setShowReveal] = useState(false);
    const [userName, setUserName] = useState('');
    const [msgCount, setMsgCount] = useState(null);
    const [alertCount, setalertCount] = useState(null);
    const [country, setcountryCode] = useState('');
    const [avatar, setAvatar] = useState('../images/user-default.png');
    const [showFlash, setShowFlash] = useState(false);
    const [showCanvas, setShowCanvas] = useState(false);
    const [currentTitle, setCurrentTitle] = useState('');
    const [currentMenu, setCurrentMenu] = useState('whoweare');
    const size = useWindowSize();
    const s3URL = process.env.REACT_APP_AWS_S3_URL;
    const appVersion = process.env.REACT_APP_VERSION;
    const blogURL = process.env.REACT_APP_BLOGURL;
    const [toggleSearchMenu, setToggleSearchMenu] = React.useState(false);
    const [searchMode, setSearchMode] = React.useState('expert');
    const [userData, setUserData] = useState(null);
    
    const [values, setValues] = React.useState({ keyword: '' });
    let userId = null;
    let userType = null;

    const handleChange = (e, name) => {

        const value = e;
        switch (name) {
            case "country":
                setValues({ ...values, ["country"]: value.value, ["countryId"]: value });
                break;
            case "keyword":
                var regex = new RegExp('[^0-9a-zA-Z-,_.\:\s ]+');

                if (!regex.test(e.target.value)) {
                    setValues({ ...values, ["keyword"]: e.target.value });
                }
                break;
            default:
                break;
        }
    }

    function navigateSearch() {
       
        localStorage.setItem('jobSearch', JSON.stringify(values));
        if (searchMode == 'job') {
             window.location.href = "/searchjob";
            //window.open("/searchjob", '_blank');
        }
        else
            window.location.href = "/searchexpert";
           // window.open("/searchexpert", '_blank');


    }
    function toggleSearch() {
        let current = toggleSearchMenu;
        setToggleSearchMenu(!current);

    }
    function toggleSearchMode(mode) {
        setSearchMode(mode);
        toggleSearch();
        document.getElementById("keyword").focus();

    }
    function showSearchOption() {
        setToggleSearchMenu(true);
        
    }
    const handleKeyDown = (e) => {
        if (e.charCode === 13) {

            navigateSearch();
        }
    };
    //document.addEventListener("DOMContentLoaded", function () {
    //    document.querySelectorAll('[data-trigger]').forEach(function (everyelement) {

    //        let offcanvas_id = everyelement.getAttribute('data-trigger');

    //        everyelement.addEventListener('click', function (e) {
    //            e.preventDefault();
    //            show_offcanvas(offcanvas_id);

    //        });
    //    });

    //    document.querySelectorAll('.btn-close').forEach(function (everybutton) {

    //        everybutton.addEventListener('click', function (e) {
    //            e.preventDefault();
    //            close_offcanvas();
    //        });
    //    });

    //    document.querySelector('.screen-darken').addEventListener('click', function (event) {
    //        close_offcanvas();
    //    });

    //}); 

    //function darken_screen(yesno) {
    //    if (yesno == true) {
    //        document.querySelector('.screen-darken').classList.add('active');
    //    }
    //    else if (yesno == false) {
    //        document.querySelector('.screen-darken').classList.remove('active');
    //    }
    //}

    //function close_offcanvas() {
    //    darken_screen(false);
    //    document.querySelector('.mobile-offcanvas.show').classList.remove('show');
    //    document.body.classList.remove('offcanvas-active');
    //}

    //function show_offcanvas(offcanvas_id) {
    //    alert();
    //    darken_screen(true);
    //    document.getElementById(offcanvas_id).classList.add('show');
    //    document.body.classList.add('offcanvas-active');
    //}

    useScrollPosition(({ prevPos, currPos }) => {
        if (currPos.y < 0) {
            setShowScrolling(true);
        } else {
            setShowScrolling(false);
        }
        if (currPos.y < 0) {
            setShowReveal(true);
        } else {
            setShowReveal(false);
        }
    });
    async function ExpertPreviewInfo() {
        try {
            const result = await GetExpertPreviewLoad(null);
            if (result.data.expertAllLoad && result.data.expertAllLoad.responseData.code == "200") {
                //[{"id":66,"sub":"f180bc26-27fa-4a1b-9b75-fee8dd832db0","firstname":"Karthik","lastname":"K","email":"sathya@springinfoservices.com","phone":"","address1":"address","address2":"","city":"LA","state":"CA","countryid":61,"countryname":"India","avatar":"","photo":"","title":"SE","profile":"Apple Inc. is an American multinational technology company specializing in consumer electronics, software and online services headquartered in Cupertino, California, United States. Apple is the largest technology company by revenue (totaling US$365.8 billion in 2021) and, as of June 2022, is the world's biggest company by market capitalization, the fourth-largest personal computer vendor by unit sales and second-largest mobile phone manufacturer. It is one of the Big Five American information technology companies, alongside Alphabet, Amazon, Meta, and Microsoft.","dailyrate":"250.00","otherlangs":"","idverified":false,"timezoneid":586,"timezonecode":"IST","timezonename":"India Standard Time","timezonevalue":"UTC+05:30","online":null,"explevelid":1,"explevelname":"Entry level (0-3 years)","profilecomplete":false,"approved":false,"active":false,"flagged":false,"flaggedreasonid":null,"flaggedreason":null,"avgrating":null,"weeklyhoursid":2,"weeklyhours":"10 hrs/week","resume":"null","skillids":"13,12,9","complianceids":"42,50,44","skills":[{"skill":"Cybersecurity"},{"skill":"Compliance Audit"},{"skill":"Certification audit"}],"compliances":[{"code":"AS9100"},{"code":"CMMI"},{"code":"CE"}]}]

                let profile = JSON.parse(result.data.expertAllLoad.responseData.message);
                
                
                if (profile && profile.length > 0) {
                    setcountryCode(profile[0].countrycode);                     
                    gContext.setUserLocation({ code: profile[0].countrycode, value: profile[0].countryid, label: profile[0].countryname });
                    localStorage.setItem('countryCode', JSON.stringify({ code: profile[0].countrycode, value: profile[0].countryid, label: profile[0].countryname }));
                    

                } 
                else
                    gContext.setUserLocation(null); 

            }

        }
        catch (e) {
            
        }

    }
    async function ClientPreviewInfo() {
        try { 
            const result = await GetClientPreviewProfile(null); 
            
            if (result.data.clientAllLoad &&  result.data.clientAllLoad.responseData.code == "200") {

                let profile = JSON.parse(result.data.clientAllLoad.responseData.message);
                
                let name = (profile && profile.length > 0 && profile[0] != null) ? ((profile[0].firstname) ? profile[0].firstname : "") + " " + ((profile[0].lastname) ? profile[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '';
                if (profile && profile.length > 0) {  
                    setcountryCode((profile[0].code) ? profile[0].code : ""); 
                   
                    setAvatar((profile[0].logo && profile[0].logo != "") ? s3URL + profile[0].logo : "../images/user-default.png");

                    gContext.setUserLocation({ code: profile[0].code, value: profile[0].countryid, label: profile[0].countryname });
                    localStorage.setItem('countryCode', JSON.stringify({ code: profile[0].code, value: profile[0].countryid, label: profile[0].countryname }));

                    setUserData({ type: userType, countryCode: profile[0].code, name: name, logo: (profile[0].logo && profile[0].logo != "") ? s3URL + profile[0].logo : "images/user-default.png", showDashboard: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete), previewURL: "/client/" + gContext.userInfo.sub });
                }
                else
                    gContext.setUserLocation(null); 
            }

        }
        catch (e) { 
        }

    }
    async function MessageCount( userID,  userType) {
        try {
           
            if (userID && userType) {
               
                const result = await NewMessageCount(userID, userType);
               
                if (result.data.newMessageCount && result.data.newMessageCount.responseData.code == "200") {
                    let count = JSON.parse(result.data.newMessageCount.responseData.message);
                   
                  
                    setMsgCount((count.length > 0) ? count[0].newmsgcount : 0);                   
                }
                const alertResult = await NewAlertCount(userID, userType);
               
                if (alertResult.data.newAlertCount && alertResult.data.newAlertCount.responseData.code == "200") {
                    let count = JSON.parse(alertResult.data.newAlertCount.responseData.message);
                    
                    setalertCount((count.length > 0) ? count[0].newmsgcount : 0);
                    gContext.setPlatformCount({
                        ...gContext.platformCount, ["alertCount"]: count[0].newmsgcount
                    });
                }                 
            }           
        }
        catch (e) {
            
        }
    }
    useEffect(() => { 
        gContext.setMsgCount(msgCount);
       
    }, [msgCount]);
    useEffect(() => {
        gContext.setAlertCount(alertCount);
    }, [alertCount]);
    useEffect(() => {
        if (gContext.msgCount && gContext.msgCount > 0) {
            setCurrentTitle(document.title);
            const newinterval = window.setInterval(() => flashTitle("Message | Syncuppro",  gContext.msgCount + ' New messages'), 30000);
            return () => {
                window.clearInterval(newinterval);
                document.title = currentTitle;
            }
        }
    }, [gContext.msgCount]);
    useEffect(() => {
        if (gContext.alertCount && gContext.alertCount > 0) {
            setCurrentTitle(document.title);
            const newinterval = window.setInterval(() => flashTitle("Message | Syncuppro", gContext.alertCount + ' New alerts'), 30000);
            return () => {
                window.clearInterval(newinterval);
                document.title = currentTitle;
            }
        }
    }, [gContext.alertCount]);
    function flashTitle(pageTitle, newTitle) {
        if (document.title == pageTitle) {
            document.title = newTitle;
        } else {
            document.title = pageTitle;
        }
    }
    useEffect(() => {
        if (gContext.userInfo.userType == "1") {
            ExpertPreviewInfo();
        }
        else if (gContext.userInfo.userType == "2") {
            ClientPreviewInfo();
        } 
         
       
    }, []);
    useEffect(() => {
        if (userData) {
            let data = { ...userData, msgCount: msgCount, alertCount: alertCount }
           
           // if (window.parent && window.parent.window[0] && window.parent.window[0].location && window.parent.window[0].location.ancestorOrigins[0].toLowerCase() == process.env.REACT_APP_EXURL) {
     
            window.parent.postMessage(JSON.stringify(data), process.env.REACT_APP_EXURL);
           //  window.parent.postMessage(JSON.stringify(data),"http://localhost:8080");
               
          //  }
        }
        else {
           // if (window.parent && window.parent.window[0] && window.parent.window[0].location && window.parent.window[0].location.ancestorOrigins[0].toLowerCase() == process.env.REACT_APP_EXURL)
           window.parent.postMessage(JSON.stringify(userData), process.env.REACT_APP_EXURL);
           //  window.parent.postMessage(JSON.stringify(userData), "http://localhost:8080");
        }
    }, [userData, msgCount,alertCount]);
    useEffect(() => {
       
        MessageCount(gContext.userInfo.userID, gContext.userInfo.userType);
        userId = gContext.userInfo.userID;
        userType = gContext.userInfo.userType;

        if (gContext.userInfo.userType == "1") {
            ExpertPreviewInfo();
            const data = gContext.userInfo.profileInfo;
             
            let name = (data && data.length > 0 && data[0] != null && data[0].firstname != "") ? ((data[0].firstname) ? data[0].firstname : gContext.userInfo.email.split('@')[0]) + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '';
            let logo = (data && data.length > 0 && data[0] != null && data[0].avatar && data[0].avatar != "") ? s3URL + data[0].avatar : "../images/user-default.png";
            setUserName(name);
            setAvatar(logo);

            setUserData({ type: userType, countryCode: (data && data.length > 0 && data[0] != null && data[0].countrycode)? data[0].countrycode:"", name: name, logo: logo, showDashboard: (gContext.userInfo.idVerified && gContext.userInfo.profileComplete && gContext.userInfo.profileInfo && gContext.userInfo.profileInfo.length > 0 && gContext.userInfo.profileInfo[0].assessment) });
        }
        else if(gContext.userInfo.userType == "2") {
            
             ClientPreviewInfo();
            const data = JSON.parse(gContext.userInfo.profileInfo)
             
            let name = (data && data.length > 0 && data[0] != null && data[0].firstname !="") ? ((data[0].firstname) ? data[0].firstname : "") + " " + ((data[0].lastname) ? data[0].lastname.substring(0, 1) : "") : (gContext.userInfo.email) ? gContext.userInfo.email.split('@')[0] : '';

            setUserName(name);
            //setAvatar((data && data.length > 0 && data[0] != null && data[0].logo != "") ? s3URL + data[0].logo : "../assets/image/avatar.png");
           
        }
        
        if (gContext.userInfo.userID) {

            const newinterval = window.setInterval(() => MessageCount(userId, userType), 30000);
            return () => {
                window.clearInterval(newinterval);
            }
        }
        
    }, [gContext.userInfo]);


    return (
        <>

            {gContext.header.button === "account" && <>
                <div className="respo-header">
                    <div className="d-flex align-item-center justify-content-center">
                        <button data-trigger="navbar_main" className="btn btn-icon" type="button" onClick={(e) => { e.preventDefault(); setShowCanvas(!showCanvas) }}><span className="m-icon material-icons icon show">menu</span></button>

                        <a className="navbar-brand" href="/#" title="Syncuppro- Hire the Best Compliance Expert for your needs">
                            <img className="logo" src={window.location.origin + "/images/syncuppro-logo.svg"} alt="syncuppro-logo" />
                        </a>
                    </div>

                    <button className="btn outline-btn pointer" onClick={(e) => {
                        e.preventDefault();
                        gContext.toggleSignInModal();
                    }}><span className="m-icon material-icons">person</span></button>

                </div>

                <header className="fixed-top"  >

                    <nav id="navbar_main" className={`mobile-offcanvas navbar navbar-expand-lg bg-white ${showCanvas?"show":""}` } >
                        <div className="container-fluid p-relative">
                            <div className="offcanvas-header">
                                <button className="btn-close float-end" onClick={(e) => { e.preventDefault(); setShowCanvas(!showCanvas) }}></button>
                            </div>
                            <a className="navbar-brand" onClick={() => { setShowCanvas(false); }} href="/#" title="Syncuppro- Hire the Best Compliance Expert for your needs">
                                <img className="logo" src={window.location.origin + "/images/syncuppro-logo.svg"} alt="syncuppro-logo" />
                            </a>

                            <ul className="navbar-nav menu-bar">
                                {gContext.header.button !== "profile" && <><li className="nav-item"> <HashLink onClick={() => { setCurrentMenu("whoweare"); setShowCanvas(false); }} className={`nav-link d-flex justify-content-between   ${currentMenu == "whoweare" ? "active" : ""}`} to="/#whoweare">Who we are<Icon className="resp-display text-gray m-icon material-icons">chevron_right</Icon></HashLink> </li>
                                    <li className="nav-item"> <HashLink onClick={() => { setCurrentMenu("howitworks"); setShowCanvas(false); }} className={`nav-link d-flex justify-content-between   ${currentMenu == "howitworks" ? "active" : ""}`} to="/#howitworks">How it works<Icon className="resp-display text-gray m-icon material-icons">chevron_right</Icon></ HashLink></li>

                                    <li className="nav-item d-none"> <a className="pointer" onClick={() => { gContext.setCurrentPage("18"); setShowCanvas(false); }}>Report Issues </ a></li>

                                    <li className="nav-item "> <a target="_blank" className={`nav-link d-flex justify-content-between   `} href="/searchjob">Find Job</ a></li>
                                    <li className="nav-item"> <a target="blank" href={blogURL} onClick={() => { setShowCanvas(false); }} className={`nav-link d-flex justify-content-between   `}  >Blog<Icon className="resp-display text-gray m-icon material-icons">chevron_right</Icon></ a></li></>

                                }
                            </ul>


                            <ul className="navbar-nav ms-auto user-info-btn "> {!showCanvas && <li className="nav-item  ">
                                {pathName.trim().toLowerCase() != "/" &&
                                    < div className="search-compact">
                                        <div className="search-info p-relative">
                                            <input className="form-control" id="keyword" placeholder="Search" placeholder={(searchMode == 'job') ? " ISO 27001" : " ISO 27001"} onKeyPress={(e) => handleKeyDown(e)} onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} />
                                            <span className="text-theme m-icon material-icons">search</span>
                                            <div className="divider"></div>

                                            <a className="btn btn-link d-flex align-item-center  " style={{ cursor: "default" }} >
                                                <span className="text-black pointer" onClick={() => { toggleSearchMode("expert"); navigateSearch(); }}>{searchMode == 'job' ? "Job" : "Expert"}</span><span className="text-black m-l-10 m-r-0 m-icon material-icons d-none">expand_more</span>
                                            </a>
                                            <ul className="dropdown-menu dropdown-menu-end">
                                                <li><a className="dropdown-item d-flex align-item-center pointer" href="#" onClick={() => { toggleSearchMode("expert"); navigateSearch(); }}>Expert</a></li>
                                                <li><a className="dropdown-item d-flex align-item-center" href="#" onClick={() => toggleSearchMode("job")}>Job</a></li>

                                            </ul>


                                        </div>
                                    </div>}
                            </li>} <li className="nav-item"><a className="btn btn-orange" href="#" onClick={(e) => {
                                e.preventDefault();
                                    gContext.toggleSignInModal();
                                    setShowCanvas(false);
                                }}><Icon className="m-icon material-icons m-r-10">login</Icon> <span>Login</span></a></li>
                                <li className="nav-item">
                                    <button type="button" id="signup1" className="btn btn-dark-outline" onClick={(e) => {
                                    e.preventDefault();
                                    gContext.toggleSignUpModal();
                                    setShowCanvas(false);
                                }}><Icon className="m-icon material-icons m-r-5">person</Icon> <span>Sign up</span></button></li>
                            </ul> 
                        </div>
                    </nav>
                </header></>}

            {gContext.header.button == "profile" && <>
                <div className="respo-header">
                <div className="d-flex align-item-center justify-content-center">
                    <button data-trigger="navbar_main" className="btn btn-icon" type="button" onClick={(e) => { e.preventDefault(); setShowCanvas(!showCanvas) }}><span className="m-icon material-icons icon show">menu</span></button>

                        <a className="navbar-brand" onClick={() => { setShowCanvas(false); }} href="/#" title="Syncuppro- Hire the Best Compliance Expert for your needs">
                            <img className="logo" src={window.location.origin + "/images/syncuppro-logo.svg"} alt="syncuppro-logo"/>
      </a>
    </div>

                <ul className="navbar-nav ms-auto user-info"> 

                        <li className="nav-item p-ralative  mobile-view-none m-0">
                            <a data-toggle="tooltip" title="View expert search" className="btn btn-circle light pointer "><span className="text-theme m-icon material-icons" onClick={() => { toggleSearchMode("expert"); navigateSearch(); }}>search</span> </a>
                        </li>
                        <li className="nav-item p-ralative  mobile-view-none m-0 ">
                            {alertCount && <a className="btn btn-circle light pointer" data-toggle="tooltip" title="View Notifications" onClick={() => { if (pathName.trim().toLowerCase() == "/notifications") window.location.reload(); else { gContext.setCurrentPage("alerts"); navigate("/notifications", { replace: true }); }  }} ><span className="text-theme m-icon material-icons">notifications</span>{alertCount && alertCount > 0 && <span className="count">{alertCount}</span>}</a>}
                             
                        </li>
                        <li className="nav-item p-ralative  mobile-view-none m-0">
                        <a className="btn btn-circle light pointer"><span className="text-theme m-icon material-icons" onClick={() => { gContext.setCurrentPage("message"); navigate("/messages", { replace: true }); }}>chat</span>{msgCount && msgCount > 0 && <span className="count">{msgCount}</span>}</a>
                        </li>
                         
     </ul>

  </div> 

                <header className="fixed-top" >

                    <nav id="navbar_main" className={`mobile-offcanvas navbar navbar-expand-lg bg-white ${showCanvas?"show":""}`}>
                                    <div className="container-fluid p-relative">
                                        <div className="offcanvas-header">
                                <button className="btn-close float-end" onClick={(e) => { e.preventDefault(); setShowCanvas(!showCanvas) }}></button>
                                        </div>
                                        <a className="navbar-brand" href="/#" title="Syncuppro- Hire the Best Compliance Expert for your needs">
                                <img className="logo" src={window.location.origin + "/images/syncuppro-logo.svg"} alt="syncuppro-logo"/>
       </a>

                                            <ul className="navbar-nav menu-bar">
                                {gContext.header.button !== "profile" && <><li className="nav-item"> <HashLink onClick={() => { setCurrentMenu("whoweare") }} className={`nav-link d-flex justify-content-between   ${currentMenu == "whoweare" ? "active" : ""}`} to="/#whoweare">Who we are<Icon className="resp-display text-gray m-icon material-icons">chevron_right</Icon></HashLink> </li>
                                    <li className="nav-item"> <HashLink onClick={() => { setCurrentMenu("howitworks") }} className={`nav-link d-flex justify-content-between   ${currentMenu == "howitworks" ? "active" : ""}`} to="/#howitworks">How it works<Icon className="resp-display text-gray m-icon material-icons">chevron_right</Icon></ HashLink></li>

                                    <li className="nav-item d-none"> <a className="pointer" onClick={() => { gContext.setCurrentPage("18"); }}>Report Issues </ a></li>
                                    <li className="nav-item "> <a className={`nav-link d-flex justify-content-between   `} target="_blank" href="/searchjob">Find Job</ a></li>
                                    <li className="nav-item"> <a target="blank" href={blogURL} className={`nav-link d-flex justify-content-between   `}  >Blog<Icon className="resp-display text-gray m-icon material-icons d-none">chevron_right</Icon></ a></li></>
                                     
                                }
                                 
                                {gContext.header.button == "profile" && <>
                                        {gContext.userInfo.userType === "2" && <ClientSidebarDashboard showCanvas={showCanvas} setShowCanvas={() => { setShowCanvas(false); }} />}
                                        {gContext.userInfo.userType === "1" && <ExpertSidebarDashboard showCanvas={showCanvas} setShowCanvas={() => { setShowCanvas(false); }}/>}
                                    <li className="nav-item "> <a target="_blank" className={`nav-link d-flex justify-content-between   `} href="/searchjob">Find Job</ a></li>

                                    <li className="nav-item"> <a target="blank" href={blogURL} onClick={() => { setShowCanvas(false); }} className={`nav-link d-flex justify-content-between   `}  >Blog<Icon className="resp-display text-gray m-icon material-icons d-none">chevron_right</Icon></ a></li>
                                    {showCanvas && <li className="nav-item"><a href="#" className="nav-link  pointer" onClick={() => { gContext.logout(); }}><Icon className="m-icon text-theme material-icons m-r-20">logout</Icon>Logout</a></li>}
                                </>
                                }
                                            </ul>

                            {gContext.header.button === "profile" && (
                                <ul className="navbar-nav ms-auto user-info  ">
                                    <li className="nav-item">
                                        {pathName.trim().toLowerCase() != "/" && <div className="search-compact">
                                            <div className="search-info p-relative">
                                                <input className="form-control" id="keyword" placeholder="Search" placeholder={(searchMode == 'job') ? " ISO 27001" : " ISO 27001"} onKeyPress={(e) => handleKeyDown(e)} onChange={(e) => handleChange(e, 'keyword')} onKeyUp={(e) => handleChange(e, 'keyword')} />
                                                <span className="text-theme m-icon material-icons">search</span>
                                                <div className="divider"></div>
                                                <a className="btn btn-link d-flex align-item-center  " style={{ cursor: "default" }} >
                                                    <span className="text-black pointer" onClick={() => { toggleSearchMode("expert"); navigateSearch(); }}>{searchMode == 'job' ? "Job" : "Expert"}</span><span className="text-black m-l-10 m-r-0 m-icon material-icons d-none">expand_more</span>
                                                </a>
                                                <ul className="dropdown-menu dropdown-menu-end">
                                                    <li><a className="dropdown-item d-flex align-item-center pointer" href="#" onClick={() => { toggleSearchMode("expert"); navigateSearch();   }}>Expert</a></li>
                                                    <li><a className="dropdown-item d-flex align-item-center" href="#" onClick={() => toggleSearchMode("job")}>Job</a></li>

                                                </ul>
                                            </div>
                                        </div>}
                                    </li>
                                    <li className="nav-item p-ralative  mobile-view-none m-0 d-none">
                                        <a className="   pointer " onClick={() => { gContext.setCurrentPage("message"); navigate("/messages", { replace: true }); }}><span className="text-theme m-icon material-icons">chat</span>{msgCount && msgCount > 0 && <span className="count">{msgCount}</span>}</a>
                                    </li>
                                    <li className="nav-item p-ralative  mobile-view-none  ">
                                        {alertCount && <a className=" btn btn-circle    light  pointer" data-toggle="tooltip" title="View Notifications" onClick={() => { if (pathName.trim().toLowerCase() == "/notifications") window.location.reload(); else { gContext.setCurrentPage("alerts"); navigate("/notifications", { replace: true }); } }}><Icon className="text-theme m-icon material-icons">notifications</Icon>{alertCount > 0 && <span className="count">{alertCount}</span>}</a>}

                                        {/*   <li className="nav-item p-ralative  mobile-view-none dropdown">   {alertCount && <a className="btn btn-circle light dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" href="/notifications"><Icon className="text-theme m-icon material-icons">notifications</Icon>{alertCount > 0 && <span className="count">{alertCount}</span>}</a>} */}
                                        <ul className="dropdown-menu dropdown-menu-end notification-list  d-none">
                                            <li>
                                                <a className="border border-0 shadow-none p-0" href="#">
                                                    <div className="notification-item">
                                                        <span className="notification-icon f-24">$</span>
                                                        <span>Great news! You got paid entry. Your available balance is <span className="text-theme"> $ </span>568.78</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="border border-0 shadow-none p-0" href="#">
                                                    <div className="notification-item">
                                                        <span className="notification-icon"><Icon className="text-gray m-icon material-icons">work</Icon></span>
                                                        <span>You have received an invitation to interview for the job.</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="border border-0 shadow-none p-0" href="#">
                                                    <div className="notification-item">
                                                        <span className="notification-icon"><Icon className="text-gray m-icon material-icons">person</Icon></span>
                                                        <span>Change the user name.</span>
                                                    </div>
                                                </a>
                                            </li>
                                            <li>
                                                <a className="border border-0 shadow-none p-0" href="#">
                                                    <div className="notification-item justify-content-center">
                                                        <span className="text-theme"><strong>View All Notification</strong></span>
                                                    </div>
                                                </a>
                                            </li>

                                        </ul>
                                    </li>

                                    <li className="nav-item user-dropdown">
                                        <a className="nav-link dropdown-toggle p-0 shadow-none d-flex" href="#" data-bs-toggle="dropdown" aria-expanded="false">

                                            <div className="user-view">
                                                <span className="user-img-block d-flex">
                                                    <img className="img-user" src={avatar} alt={userName} />

                                                    <div className="d-flex flex-column">
                                                        <span className="text-gray f-12 m-b-5">Signed in as</span>
                                                        <div className="d-flex ">
                                                            <span className="text-theme f-14 m-r-5">{userName}</span>
                                                            {country == '' ? "" : <div className="p-t-1"><ReactCountryFlag
                                                                countryCode={country}
                                                                svg

                                                            /></div>}
                                                        </div>
                                                    </div>
                                                </span>

                                                <Icon className="m-icon material-icons">expand_more</Icon>
                                            </div>

                                        </a>
                                        <ul className="dropdown-menu user-profile-list">
                                            <li><a className="dropdown-item d-flex align-item-center" href="/profile"><Icon className="m-icon text-theme material-icons m-r-20">person</Icon>Profile</a></li>
                                            { (gContext.userInfo.idVerified && gContext.userInfo.profileComplete) &&<> <li>

                                                <a className="dropdown-item d-flex align-item-center" href="/settings"><Icon className="m-icon text-theme material-icons m-r-20">settings</Icon>Setting</a></li>
                                            <li><a className="dropdown-item d-flex align-item-center" href="/reportissue"><Icon className="m-icon text-theme material-icons m-r-20">flag</Icon>Report Issues</a></li>
                                            </>}
                                            {!(gContext.userInfo.idVerified && gContext.userInfo.profileComplete) && <> <li>

                                                <a className="dropdown-item d-flex align-item-center text-gray" ><Icon className="m-icon text-theme material-icons m-r-20">settings</Icon>Setting</a></li>
                                                <li><a className="dropdown-item d-flex align-item-center text-gray" ><Icon className="m-icon text-theme material-icons m-r-20">flag</Icon>Report Issues</a></li>
                                            </>}                                            <li className="p-0"><hr className="dropdown-divider m-0" /></li>
                                            {!showCanvas && <li><a className="dropdown-item d-flex align-item-center" href="#" onClick={() => { gContext.logout(); }}><Icon className="m-icon text-theme material-icons m-r-20">logout</Icon>Logout</a></li>}
                                        </ul>
                                    </li>
                                </ul>)}

     
      </div>  
    </nav>
  </header></>}


        </>
    );
};
export default Header;